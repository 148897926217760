import React, { useEffect } from 'react';
import { useState } from 'react'
import './offersMultiselectChips.scss'
import { Micro } from '@vds/typography'
import { useTranslation } from 'react-i18next';

const MultiselectChipsOffers = ({ optionItems, selected, onChange, maxSelect, orOption, showOrOption }) => {
  const [selectedChips, setSelectedChips] = useState([])
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if(selected != undefined){
        setSelectedChips([...selectedChips,selected]);
    }
  }, [selected]);

  const selectChips = (item) => {
    console.log("item",item);
    console.log("orOption",orOption);
    if (selectedChips.filter((selected) => selected.label === item.label).length > 0) {
      if (item.label === "All") {
        setSelectedChips([
          ...selectedChips.filter((selected) => selected.label !== item.label),
        ]);
        onChange([
          ...selectedChips.filter((selected) => selected.label !== item.label),
        ]);
      } else {
        setSelectedChips([
          ...selectedChips.filter((selected) => selected.label !== item.label),
        ]);
        onChange([
          ...selectedChips.filter((selected) => selected.label !== item.label),
        ]);
      }
    } else if (item.label === "All") {
      setSelectedChips([item]);
      onChange([item]);
    } else if (maxSelect ? selectedChips.length < maxSelect : true) {
      if (item.label !== orOption[0].label) {
        setSelectedChips([
          ...selectedChips.filter(
            (selected) => selected.label !== (selected.label !== orOption[0].label) && (selected.label !== "All")
          ),
          item,
        ]);
        onChange([
          ...selectedChips.filter(
            (selected) => (selected.label !== orOption[0].label) && (selected.label !== "All")
          ),
          item,
        ]);
      }
    }
  };

  const onKeyDown = (event, item) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
              selectChips(item);
          }
  }
  const options = optionItems;
  console.log(options)
  return (
    <>
      <div className='chip-container'>
        {
          options.map(item => {
            return (
              <div role='button' ariaLabel={item.label} onKeyDown={(e) => { onKeyDown(e, item) }} key={item.label} className={(selectedChips.filter(selected => selected.label === item.label).length > 0) ? 'chip fs-3X ph-4X pv-2X chip-selected' : 'chip fs-3X ph-4X pv-2X chip-unselected'} onClick={() => { selectChips(item) }} tabIndex="0" aria-label={selectedChips.length >= 3 ? "Only three Buttons can be selected" : selectedChips.filter(
                (selected) => selected.label === item.label
             ).length > 0
                ? "selected" + item.label
              : "unselected" + item.label}>
                <Micro bold={true}>{t(item.label)}</Micro>
              </div>
            )
          })
        }
        {showOrOption &&
          <>
            <div className='fs-3X ph-4X pv-2X mt-2x u-flex'>
              <Micro bold={true}>or</Micro>
            </div>
            <div className={(selectedChips.filter(selected => selected.label === orOption[0].label).length > 0) ? 'chip fs-3X ph-4X pv-2X chip-selected' : 'chip fs-3X ph-4X pv-2X chip-unselected'} onClick={() => { selectChips(orOption[0]) }} tabIndex="0">
              <Micro bold={true}>{orOption[0].label}</Micro>
            </div>
          </>
        }
      </div>
    </>
  )
}

export default MultiselectChipsOffers