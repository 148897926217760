import React from 'react';
import "./FilterBadge.scss";
import { Icon } from '@vds/icons';
import { ButtonIcon } from '@vds/button-icons';
import { Micro } from '@vds/typography';
import { useTranslation } from 'react-i18next';
const FilterBadge = (props) => {
  console.log("FilterBadge", props);
  const { t, i18n } = useTranslation();
  const modalClick = () => {
    props.modal(true);
  };
  const handleChange = (e) => {
    if (props.handleChange) {
      props.handleChange(e);
    }
  };
  const onKeyDown = (event, item) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      props.handleChange(item);
    }
  };
  return (<div className='filter-badge-wrap'>
    {props.showFilterBadge && !props.filterReverse &&
      <div className='filter-icon'>
        <ButtonIcon
          onClick={modalClick}
          kind={props?.iconKind}
          size="small"
          surfaceType={props?.surfaceType}
          hideBorder={true}
          renderIcon={(props) => <Icon name="filter" {...props} />
          }
        />
      </div>
    }
    <div className={(props.showFilterBadge && !props.filterReverse) ? 'filter-badges ml-3X' : 'filter-badges'}>
      <div className={(props.disabled) ? 'chip-container disabled' : 'chip-container'}>
        {
          props.options.map(item => {
            return (
              <div role='button' aria-label={`${props.selectedOptions?.label === item.label ? 'selected' : 'unselected'} ${t(item.label) + props.nav}`} onKeyDown={(e) => { onKeyDown(e, item); }} key={item.label} className={props.selectedOptions?.label === item.label ? 'chip fs-3X ph-4X pv-2X chip-selected' : 'chip fs-3X ph-4X pv-2X chip-unselected'} onClick={() => { handleChange(item); }} tabIndex="0">
                <Micro bold={true} >{t(item.label)}</Micro>
              </div>
            );
          })
        }
      </div>
    </div>
    {(props.showFilterBadge && props.filterReverse) &&
      <div className='filter-icon ml-3X'>
        <ButtonIcon
          onClick={modalClick}
          kind={props?.iconKind}
          size="small"
          surfaceType={props?.surfaceType}
          hideBorder={true}
          disabled={props?.disabled}
          ariaLabel={t('Filter')}
          renderIcon={(props) => <Icon name="filter" {...props} />
          }
        />
      </div>
    }
  </div >);
};

export default FilterBadge;