// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.secondaryCourceCard {
  width: 100%;
}
.secondaryCourceCard .badge {
  background-color: #0077b4;
  color: white;
  transform: translate(24px, 54px);
  display: inline-block;
  border-radius: 8px;
  position: absolute;
  margin-top: -30px;
}
.secondaryCourceCard .description {
  width: 100%;
  bottom: 12px;
  box-sizing: border-box;
}
.secondaryCourceCard .description .info p {
  display: inline-block;
  text-transform: capitalize;
}
.secondaryCourceCard .description .label {
  overflow: hidden;
}
.secondaryCourceCard .description .label h5 {
  padding-top: 0;
}
.secondaryCourceCard .description .duration > svg {
  position: absolute;
  bottom: 0;
  right: 0;
}
.secondaryCourceCard .bg-image {
  height: 70%;
  background-position: center;
  background-size: cover;
  border-radius: 10px 10px 0 0;
}`, "",{"version":3,"sources":["webpack://./src/elements/SecondaryCourseCardComponent.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AACE;EACE,yBAAA;EACA,YAAA;EACA,gCAAA;EACA,qBAAA;EACA,kBAAA;EACA,kBAAA;EACA,iBAAA;AACJ;AAEE;EACE,WAAA;EACA,YAAA;EACA,sBAAA;AAAJ;AAEM;EACE,qBAAA;EACA,0BAAA;AAAR;AAII;EACE,gBAAA;AAFN;AAGM;EACE,cAAA;AADR;AAKI;EACE,kBAAA;EACA,SAAA;EACA,QAAA;AAHN;AAOE;EAGE,WAAA;EACA,2BAAA;EACA,sBAAA;EACA,4BAAA;AAPJ","sourcesContent":[".secondaryCourceCard {\n  width: 100%;\n\n  .badge {\n    background-color: #0077b4;\n    color: white;\n    transform: translate(24px, 54px);\n    display: inline-block;\n    border-radius: 8px;\n    position: absolute;\n    margin-top: -30px;\n  }\n\n  .description {\n    width: 100%;\n    bottom: 12px;\n    box-sizing: border-box;\n    .info {\n      p {\n        display: inline-block;\n        text-transform: capitalize;\n      }\n    }\n\n    .label {\n      overflow: hidden;\n      h5 {\n        padding-top: 0;\n      }\n    }\n\n    .duration > svg {\n      position: absolute;\n      bottom: 0;\n      right: 0;\n    }\n  }\n\n  .bg-image {\n    // width: 107%;\n    // margin-left: -12px;\n    height: 70%;\n    background-position: center;\n    background-size: cover;\n    border-radius: 10px 10px 0 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
