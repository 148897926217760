import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Title } from '@vds/typography';

import { userRegistrationStep, pageLoadStarted, pageLoadCompleted } from '../../../../common/Analytics/GAWrapper';
import './RegistrationHeaderComponent.scss';
import DOMPurify from 'dompurify';
import { useParams } from 'react-router-dom';

const RegistrationHeaderComponent = ({ currentStep }) => {
  const isMobile = useSelector((state) => state.MediaType.isMobile);

  let { userType } = useParams();
  const isPartner = userType == "partner" ? true : false;

  const partnerSpanishData = useSelector((state) => state.Aem.partnerSpanishLang);
  const partnerEngData = useSelector((state) => state.Aem.partnerRegisterData);

  const spanishData = useSelector((state) => state.Aem.spanishLang);
  const engData = useSelector((state) => state.Aem.registerData);
  let isSpanish = localStorage.getItem("lng")==='es' ? true : false;

  const [aemData, setAemData]=useState({});

  const selectedTopics = useSelector(
    (state) => state.Aem.registrationStepsData.businessData.topics
  );
  
  useEffect(() => {
    if(isPartner){
      setAemData(!isSpanish ? partnerEngData?.registerModule : partnerSpanishData?.registerModule)
    } else {
      setAemData(!isSpanish ? engData?.registerModule : spanishData?.registerModule);
    }
  }, [isSpanish, engData?.registerModule, spanishData?.registerModule, partnerEngData?.registerModule, partnerSpanishData?.registerModule, isPartner]);

  const [titles, setTitles] = useState({});

  useEffect(() => {
    let url = '';
    let prevUrl = '';
    console.log("Language", localStorage.getItem("lng"))

    if(aemData && Object.keys(aemData)?.length > 0){
      switch (currentStep) {
        case 1:
          setTitles({
            title: aemData?.registerTitle1,
            subtitle: aemData?.registersubTitle1,
          });
          userRegistrationStep(
            aemData?.registerTitle1,
            currentStep,
            [...selectedTopics]?.sort(),
            isPartner
          );
          // url = localStorage?.getItem('url');
          // localStorage.setItem('prevUrl', url);
          // localStorage.setItem('url', path);
          if (localStorage?.getItem('prevUrl')?.startsWith('/')) {
            pageLoadStarted(aemData?.registerTitle1, window.location.origin + localStorage.getItem('prevUrl'), selectedTopics?.filter(Boolean)?.sort()?.join(', '),currentStep);        }
          else {
            pageLoadStarted(aemData?.registerTitle1, localStorage.getItem('prevUrl'), selectedTopics?.filter(Boolean)?.sort()?.join(', '),currentStep);        }
          pageLoadCompleted();
          break;
        case 2:
          setTitles({
            title: aemData?.registerTitle2,
            subtitle: aemData?.registersubTitle2,
          });
          console.log('title2');
          userRegistrationStep(
            aemData?.registerTitle2,
            currentStep,
            [...selectedTopics]?.sort(),
            isPartner
          );
          pageLoadStarted(aemData?.registerTitle2, localStorage.getItem('prevUrl'), selectedTopics?.filter(Boolean)?.sort()?.join(', '),currentStep);        //pageLoadStarted("Do you identify with any of the following groups?",window.location.host + '/onboarding',selectedTopics?.toString());
          pageLoadCompleted();
          break;
        case 3:
          setTitles({
            title: aemData?.registerTitle3,
            subtitle: aemData?.registersubTitle3,
          });
          console.log('title3');
          userRegistrationStep(
            aemData?.registerTitle3,
            currentStep,
            [...selectedTopics]?.sort(),
            isPartner
          );
          // pageLoadStarted("And where’s your business located?",window.location.host + '/onboarding',selectedTopics?.toString());
          pageLoadStarted(aemData?.registerTitle3, localStorage.getItem('prevUrl'), selectedTopics?.filter(Boolean)?.sort()?.join(', '),currentStep);    
          pageLoadCompleted();
          break;
        case 4:
          setTitles({
            title: aemData?.registerTitle4,
            subtitle: '',
          });
          console.log('title4');
          userRegistrationStep(
            aemData?.registerTitle4,
            currentStep,
            [...selectedTopics]?.sort(),
            isPartner
          );
          pageLoadStarted(aemData?.registerTitle4, localStorage.getItem('prevUrl'), selectedTopics?.filter(Boolean)?.sort()?.join(', '),currentStep);        //pageLoadStarted("Excellent. To finish registering, enter an email and password.",window.location.host + '/onboarding',selectedTopics?.toString());
          pageLoadCompleted();
          break;
        default:
          setTitles({
            title: '',
            subtitle: '',
          });
      }
    }
  }, [currentStep,isPartner,aemData?.registerTitle1,aemData?.registersubTitle1,aemData?.registerTitle2,aemData?.registersubTitle2,
    aemData?.registerTitle3,aemData?.registersubTitle3,aemData?.registerTitle4]);

  return (
    <>
      <div
        className={`title-container ${titles.subtitle !== '' ? 'mb-3X' : ''} ${isPartner ? 'partner-title' : ''}`}
      >
        <Title primitive="h1" size={isMobile ? 'XLarge' : 'large'} bold={true} color="#000000">
          {titles.title}
        </Title>
      </div>
      {titles.subtitle ? <div className={`${isPartner ? 'partner-sub-title' : 'widthfix'}`} >
        <Title size={isMobile ? 'medium' : 'small'} bold={false}>
          <p className='mt-0X mb-0X' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(titles.subtitle) }}></p>
          {/*titles.subtitle*/}
        </Title>
      </div> : <div className="widthfix">
        <Title size={isMobile ? 'medium' : 'small'} bold={false}>
          {titles.subtitle}
        </Title>
      </div>}

    </>
  );
};

export default RegistrationHeaderComponent;
