// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.superbowl .grant-status {
  background-color: #F3EDE0;
}
.superbowl .progress {
  max-width: 1232px;
  display: flex;
  gap: 36px;
  flex-wrap: wrap;
  justify-content: center;
}

.superbowl-container {
  max-width: 1440px;
  margin: 0pc auto;
}

.superbowl-promotion-container {
  max-width: 1232px;
  margin: 0pc auto;
}

.faq-answers {
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/components/specialGrants/SuperBowl/superBowl.scss"],"names":[],"mappings":"AACI;EACE,yBAAA;AAAN;AAEI;EACE,iBAAA;EACA,aAAA;EACA,SAAA;EACA,eAAA;EACA,uBAAA;AAAN;;AAKE;EACI,iBAAA;EACA,gBAAA;AAFN;;AAIE;EACE,iBAAA;EACA,gBAAA;AADJ;;AAIE;EACE,gBAAA;AADJ","sourcesContent":[".superbowl{\n    .grant-status{\n      background-color: #F3EDE0;\n    }\n    .progress{\n      max-width: 1232px;\n      display: flex;\n      gap: 36px;\n      flex-wrap: wrap;\n      justify-content: center;\n    }\n  }\n  \n  \n  .superbowl-container{\n      max-width: 1440px;\n      margin: 0pc auto;\n  }\n  .superbowl-promotion-container{\n    max-width: 1232px;\n    margin: 0pc auto;\n  }\n\n  .faq-answers{\n    text-align: left;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
