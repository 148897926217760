import React from 'react';
import { TileContainer } from "@vds/tiles";
import { Body, Micro, Title } from '@vds/typography';
import './CalendarViewCards.scss';
import { Icon } from '@vds/icons';
import { Badge } from '@vds/badges';
import { ButtonIcon } from '@vds/button-icons';
import { useNavigate } from "react-router";

const CalendarViewCardComponent = (props) => {
  
  const history = useNavigate();

  return (
    <div className='calendar-card'>
      <div className='event-item'>
        <div className='event-profile pa-3X' style={{ backgroundImage: 'url(' + props?.data.image + ')' }}>
          {props?.data?.saveIcon &&
            <span className='event-badge'>
              <ButtonIcon
                kind="lowContrast"
                size="small"
                surfaceType="media"
                renderIcon={(props) => <Icon name="bookmark" {...props} />}
              />
            </span>
          }
        </div>
        <div className='event-info pa-6X sm-pa-3X'>
          {(props?.data?.additionalStatus || props?.data?.duration) &&
            <div className='event-top mb-2X'>
              <span className='event-left'>
                {props?.data?.duration &&
                  <span className='event-time'>
                    <span className='calendar-icon mr-1X'>
                      {(props?.data.enrollmentStatus == "ENROLLED" || props?.data.enrollmentStatus == "WAITING" || props?.data.enrollmentStatus == "STARTED") && <Icon
                        name={props?.data?.additionalStatus?.tagIcon}
                        size="medium"
                        color={props?.data?.additionalStatus?.color}
                      />}
                      {props?.data.enrollmentStatus == "NOT_ENROLLED" && <Icon
                        name="calendar"
                        size="medium"
                        color={props?.data?.duration?.color}
                      />}
                    </span>
                    <Micro
                      color={props?.data?.duration?.color} bold={true}>
                      {props?.data?.duration?.dateTime}
                    </Micro>
                  </span>
                }
                {props?.data?.additionalStatus &&
                  <span className='additional-tag mr-1X'>
                    <span className='tag-icon mr-1X'>
                      {props?.data.enrollmentStatus == "NOT_ENROLLED" && <Icon
                        name={props?.data?.additionalStatus?.tagIcon}
                        size="medium"
                        color={props?.data?.additionalStatus?.color}
                      />}
                    </span>
                    <Micro
                      color={props?.data?.additionalStatus?.color} bold={false}>
                      {props?.data?.additionalStatus?.tagName}
                    </Micro>
                  </span>
                }
              </span>
            </div>
          }
          <div className='event-content'>
            <div className='event-text'>
              <Title
                size="small"
                color="#000000"
                bold={false}>
                {props?.data.title}
              </Title>
            </div>
            <span className='right-arrow' onClick={e => history("/course-details/"+props.data.id)} tabIndex={0}>
              <Icon
                name="right-arrow"
                size="medium"
                color="#000000"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CalendarViewCardComponent;