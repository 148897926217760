import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from 'i18next-http-backend';

i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    lng: localStorage.getItem("lng") || "en",
    supportedLngs: ["en","es"],
    interpolation: {
      escapeValue: false
    },
    backend: {
      loadPath: `${global.config.AEM_URL}static-contents/translation-files/{{lng}}.json`
    }
  });

export default i18n;