import React , { useState, useRef, useEffect } from 'react';
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector} from 'react-redux';

import { Col, Grid, Row } from "@vds/grids";
import { Title, Micro, Body } from '@vds/typography';
import { Icon } from '@vds/icons';
import { ButtonIcon } from '@vds/button-icons';

import '../CategoryPage.scss';
import '../CategoryPage.scss';


const CategoryFeatureComponent = () => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    /* if (!coursesDetails) return <div>Loading...</div>; */


    return (
<div className='live-event-wrap pa-12X sm-pa-6X'>
    <div className='event-topic mb-6X'>
        <div className='topic-title'>
            <Title size="medium" color="#000000" bold={true}>{t('We’re hosting a live in-person event near you.')}</Title>
        </div>
        <div className='event-close' tabIndex={0}>
            <Icon name="close" size="large" color="#000000" />
        </div>
    </div>
    <div className='event-container'>
        <div className='event-wrap'>
            <div className='event-profile pa-3X' style={{ backgroundImage: 'url(https://ss7.vzw.com/is/image/VerizonWireless/black-test)' }}></div>
			<div className='event-info pa-6X sm-pa-3X'>
				<div className='event-top mb-2X'>
				  <span className='event-time'>
					<span className='calendar-icon mr-1X'> <Icon name="calendar" size="medium" color="#6F7171" />  </span>
					<Micro color="#6F7171" bold={true}> 07/06 12:30-2:30pm </Micro>
				  </span>
				  <span className='event-tag ph-3X pv-2X'>
					<Body size="small" color="#000000" bold={true}>
					  {t('Near you')}
					</Body>
				  </span>
				</div>
				<div className='live-event-content'>
					<div className='live-event-text'>
						<Title size="small" color="#000000" bold={false}>
							{t('Resource title')} (74 characters max), Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
						</Title>
					</div>
					<span className='event-right-arrow' tabIndex={0}>
						<Icon name="right-arrow" size="medium" color="#000000" />
					</span>
				</div>
			</div>
		</div>
		<div className='event-map'>
			<iframe width="100%" height="136" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=1%20Grafton%20Street,%20Dublin,%20Ireland+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
		</div>
	</div>
</div>

    );

};

export default CategoryFeatureComponent;

