import React, { useEffect } from 'react';
import { Icon } from '@vds/icons';
import { Title } from '@vds/typography';
import { TextLinkCaret } from '@vds/buttons';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import './ApplicationSubmissionLink.scss';

const ApplicationSubmissionLinkNotification = () => {
    const aemData = useSelector((state) => state.Aem.data);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    var notificationTitle = aemData?.superbowl?.grantApplicationText;
    var notificationSubtitle = t('Learn more');
    useEffect(() => { 
        const grantsTop = document.getElementById(`grants-section`)
          window.scrollTo({ top: grantsTop?.offsetTop, behavior: 'smooth' });
      }, []);
    return (
        <div className='Application-submission-div pa-4X' >
                <span className='Application-submission-sub-div'>
                <div className='mr-2X'>
                    <Icon
                        name='ideas-solutions'
                        size='medium'
                    />
                </div>
                <Title size='small'>{notificationTitle}</Title>
                </span>
                <div className='Application-submission-link'>
                <TextLinkCaret surface="light" disabled={false} iconPosition="right" onClick={(e) => navigate('/grants')} ariaLabel={'Learn more about small business grant'} role='link'> {notificationSubtitle}</TextLinkCaret>
                </div>
        </div>)

}
export default ApplicationSubmissionLinkNotification;