import { createSlice } from '@reduxjs/toolkit';
import { createEventObject, createCourseObject, createLearningPathObject } from '../utility/utils.js';

export const resources = createSlice({
  name: 'adoberesources',
  initialState: {
    selectedTags: [],
    catalogData: [],
    registerPageSelectedTopics: [],
    allEventsWithTimeZone: null,
    allCourses: null,
    allQuickTips: null,
    eventCategoryPageFilters: [],
    courseCategoryPageFilters: [],
    quickTipCategoryPageFilters: [],
    combinedQuickTips: null,
    allNotifications: null,
    recommendedCourses:[],
    learningPaths: null
  },

  reducers: {
    updateSelectedTags: (state, action) => {
      state.selectedTags = action.payload;
    },
    updateCatalogData: (state, action) => {
      if(action.payload) {
        console.log(`[REDUCER] [updateCatalogData]`, action || []);
        let allEvents = action.payload.allEvents;
        let allCourses = action.payload.allCourses;
        let learningPaths = action.payload.learningPaths;
        let allCoursesForState = [];
        let allEventsForState = [];
        let allLearningPaths = [];
        let userLanguage = localStorage.getItem("lng") ? localStorage.getItem("lng") : 'en';
        allEvents.forEach((event) => {
          allEventsForState.push(createEventObject(event, userLanguage));
        });
        allCourses.forEach((course) => {
          allCoursesForState.push(createCourseObject(course, userLanguage));
        });
        learningPaths.forEach((learningPath) => {
          allLearningPaths.push(createLearningPathObject(learningPath, userLanguage));
        });

        state.allCourses = allCoursesForState;
        state.allEvents = allEventsForState;
        state.allEventsWithTimeZone = allEventsForState;
        state.learningPaths = allLearningPaths;
        state.catalogData = allEvents.concat(allCourses);
        state.learningPaths = allLearningPaths;
      }
    },
    updateRegisterPageSelectedTopics: (state, action) => {
      console.log(action);
      state.registerPageSelectedTopics = action.payload;
    },
    updateQuickTips: (state, action) => {
      if(action.payload) {
        console.log(`[REDUCER] [updateCatalogData]`, action || []);
        state.allQuickTips = action.payload
      }
    },
    updateQuickTipCategoryFilterData: (state, action) => {
      if(action.payload) {
        console.log(`[REDUCER] [updateQuickTipCategoryFilterData]`, action || []);
        state.quickTipCategoryPageFilters = action.payload
      }
    },
    updateEventsCategoryFilterData: (state, action) => {
      if(action.payload) {
        console.log(`[REDUCER] [updateEventsCategoryFilterData]`, action || []);
        state.eventCategoryPageFilters = action.payload
      }
    },
    updateCoursesCategoryFilterData: (state, action) => {
      if(action.payload) {
        console.log(`[REDUCER] [updateCoursesCategoryFilterData]`, action || []);
        state.courseCategoryPageFilters = action.payload
      }
    },
    updateNotifications: (state, action) => {
      if(action.payload) {
        console.log(`[REDUCER] [updateNotifications]`, action || []);
        state.allNotifications = action.payload;
      }
    },
    updateRecommendedCourses: (state, action) => {
      if(action.payload) {
        console.log(`[REDUCER] [updateRecommendedCourses]`, action || []);
        state.recommendedCourses = action.payload;
      }
    },
  },
});

export const {
  updateSelectedTags,
  updateCatalogData,
  updateRegisterPageSelectedTopics,
  updateQuickTips,
  updateQuickTipCategoryFilterData,
  updateEventsCategoryFilterData,
  updateCoursesCategoryFilterData,
  updateNotifications,
  updateRecommendedCourses
} = resources.actions;

export default resources.reducer;
