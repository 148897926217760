/**
 *
 * @format
 */
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import Slider from '@material-ui/core/Slider';
import { Icon } from '@vds/icons';
import './AudioPlayer.scss';

export const AudioPlayer = (props) => {
  const audio = useRef(null);
  const [playing, setplaying] = useState(false);
  const [width, setwidth] = useState(0);
  const [ct, setcurrenttime] = useState('00:00');

  function onSeek(e, newValue) {
    const { duration } = audio.current;

    let time = (newValue / 100) * duration;
    audio.current.currentTime = time;

    setwidth(newValue);
    if (props.onSeek) {
      props.onSeek();
    }
  }

  function TimeUpdate(e) {
    const { currentTime } = audio.current;
    setcurrenttime(calcTime(currentTime));
  }

  function calcTime(d) {
    if (isNaN(d)) {
      return '00:00';
    }
    let hours = d / 3600;
    let hh = d % 3600;
    let time = hh / 60;
    let h = Math.floor(hours);
    let f = Math.floor(time);
    let seconds = d % 60;
    seconds = Math.floor(seconds);
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    if (f < 10) {
      f = `0${f}`;
    }
    if (h <= 0) {
      h = ``;
    } else if (h < 10 && h > 0) {
      h = `0${h}:`;
    }

    return `${h}${f}:${seconds}`;
  }

  function timeChanging(e) {
    const { currentTime, duration } = audio.current;
    const w = (currentTime / duration) * 100;
    setwidth(w);
    if (props.onTimeUpdate) {
      props.onTimeUpdate(e, currentTime, w);
    }
  }

  function play() {
    audio.current.play();
    setplaying(true);
    if (props.onPlay) {
      props.onPlay();
    }
  }

  function pause() {
    audio.current.pause();
    setplaying(false);
    if (props.onPause) {
      props.onPause();
    }
  }

  const isMobile = useSelector((state) => state.MediaType.isMobile);

  return (
    <section className={`react-audio-covering-div ${props.className}`}>
      <audio
        ref={audio}
        autoPlay={props.autoPlay ? true : false}
        onPlay={() => {
          setplaying(true);
        }}
        onPause={() => {
          setplaying(false);
        }}
        onTimeUpdate={(e) => {
          TimeUpdate(e);
          timeChanging(e);
        }}
      >
        <source src={props.src} type="audio/mpeg"></source>
      </audio>

      <div className="pv-12X audio-cover">
        <div className="player-icon">
          <img src="https://mb.verizonwireless.com/content/dam/vsbdr/static-contents/common/image/v_brand.svg" alt="logo" />
        </div>

        <div className="player-info">
          <div className="audio-title">
            {props.label}
          </div>
          <div className="audio-published-date"> {props.publishedOnDate}</div>
          <div
            className={
              isMobile ? 'audio-player audio-player-mobile' : 'audio-player'
            }
          >
            <div className="player-progress">
              <Slider
                value={width}
                onChange={onSeek}
                aria-labelledby="continuous-slider"
                className="slider-color"
              />
              <div className="player-timestamps">
                <div className="player-time">{ct}</div>
                <div className="player-time">
                  {audio.current ? (
                    calcTime(audio.current.duration)
                  ) : (
                    <>00:00</>
                  )}
                </div>
              </div>
            </div>
            <div className="player-button">
              {playing ? (
                <button onClick={pause}>
                  <Icon name="pause" size="XLarge" color="#ffffff" />
                </button>
              ) : (
                <button onClick={play}>
                  <Icon name="play" size="XLarge" color="#ffffff" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
