// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-ribbon {
  background-color: #AAD8F9;
}
.notification-ribbon .ribbon-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .notification-ribbon .ribbon-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .notification-ribbon .ribbon-container div [class^=ButtonGroupRow] {
    margin-top: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/results/Results.scss"],"names":[],"mappings":"AAAA;EAEI,yBAAA;AAAJ;AACI;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;AACR;AAAQ;EAJJ;IAKQ,sBAAA;IACA,2BAAA;IACA,uBAAA;EAGV;EADc;IACI,gBAAA;EAGlB;AACF","sourcesContent":[".notification-ribbon{\n\n    background-color: #AAD8F9;\n    .ribbon-container{\n        display: flex;\n        align-items: center;\n        justify-content: space-between;\n        @media (max-width: 767px) {\n            flex-direction: column;\n            justify-content: flex-start;\n            align-items: flex-start;\n\n                div [class^=ButtonGroupRow]{\n                    margin-top: 16px;\n                }\n            button{\n                // margin-top: 16px;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
