export const eventFilters = [
  {
    category: 'Topic',
    type: 'checkbox',
    subCategory: [
      {
        options: [],
        selectedOptions: []
      }
    ]
  },
  {
    category: 'Dates',
    type: 'dateRange',
    startDateTitle: 'Start date',
    endDateTitle: 'End date',
    viewAllDateTitle: 'All Dates'
  },
  {
    category: 'Status',
    type: 'checkbox',
    subCategory: [
      {
        name: '',
        options: [
          {
            children: "View all",
            value: "View all",
            selected: true,
            showFilterName: "All Statuses",
          },
          {
            children: "Seats available",
            value: "availableSeats",
          },
          // {
          //   children: "Pre-recorded events",
          //   value: "preRecordedEvents"
          // },
        ]
      }
    ]
  },
  {
    category: 'Type',
    type: 'checkbox',
    subCategory: [
      {
        name: '',
        options: [
          {
            children: "View all",
            value: "View all",
            selected: true,
            showFilterName: "All Types",
          },
          {
            children: "Ask the experts",
            value: "Group Session"
          },
          {
            children: "Hands-on help",
            value: "Workshop/Networking"
          },
          // {
          //   children: "Course Q&As",
          //   value: "Office Hours"
          // },
          {
            children: "Networking sessions",
            value: "Community"
          }
        ]
      }
    ],
    text: {
      text: 'Learn more about the types of event formats we host by ',
      clickLabel: 'clicking here.',
      clickEvent: ''
    }
  },
  {
    category: 'Skill level',
    type: 'checkbox',
    subCategory: [
      {
        name: '',
        options: [
          {
            children: "View all",
            value: "View all",
            selected: true,
            showFilterName: "All Skill Levels",
          },
          {
            children: "Foundational",
            value: "1"
          },
          {
            children: "Intermediate",
            value: "2"
          },
        ]
      }
    ]
  },
  {
    category: 'Language',
    type: 'checkbox',
    subCategory: [
      {
        name: '',
        options: [
          {
            children: "View all",
            value: "View all",
            selected: true,
            showFilterName: "All Languages",
          },
          {
            children: "English events",
            value: "en-US"
          },
          {
            children: "Spanish events",
            value: "es-ES"
          },
          {
            children: "Bilingual events",
            value: "bilingual-events"
          },
        ]
      }
    ]
  }
];

export const courseFilters = [
  {
    category: 'Topic',
    type: 'checkbox',
    subCategory: [
      {
        options: [],
        selectedOptions: []
      }
    ]
  },
  {
    category: 'Type',
    type: 'checkbox',
    subCategory: [
      {
        name: '',
        options: [
          {
            children: "View all",
            value: "View all",
            selected: true,
            showFilterName: "All Types",
          },
          {
            children: "Standard courses",
            value: "Standard Course"
          },
          {
            children: "Mini courses",
            value: "Mini Course"
          }
        ]
      }
    ],
    text: {
      text: 'Learn more about the types of course formats we host by ',
      clickLabel: 'clicking here.',
      clickEvent: ''
    }
  },
  {
    category: 'Skill level',
    type: 'checkbox',
    subCategory: [
      {
        name: '',
        options: [
          {
            children: "View all",
            value: "View all",
            selected: true,
            showFilterName: "All Skill Levels",
          },
          {
            children: "Foundational",
            value: "1"
          },
          {
            children: "Intermediate",
            value: "2"
          },
        ]
      }
    ]
  },
  {
    category: 'Language',
    type: 'checkbox',
    subCategory: [
      {
        name: '',
        options: [
          {
            children: "View all",
            value: "View all",
            selected: true,
            showFilterName: "View all Language",
          },
          {
            children: "Available in Chinese",
            value: "zh-CN",
            hasToolTip: true,
            toolTipText: 'Available in Chinese Tooltip',
            toolTipTextTitle: 'Available in Chinese Tooltip Title',
            toolTipTextAriaLabel: 'More information tooltip button',
            showFilterName: "Available in Chinese"
          },
          {
            children: "Available in Spanish",
            value: "es-ES",
            hasToolTip: true,
            toolTipText: 'Available in Spanish Tooltip',
            toolTipTextTitle: 'Available in Spanish Tooltip Title',
            toolTipTextAriaLabel: 'More information tooltip button',
            showFilterName: "Available in Spanish"
          }
        ]
      }
    ]
  }
];

export const quickTipFilters = [
  {
    category: 'Topic',
    type: 'checkbox',
    subCategory: [
      {
        options: [],
        selectedOptions: []
      }
    ]
  },
  {
    category: 'Type',
    type: 'checkbox',
    subCategory: [
      {
        name: '',
        options: [
          {
            children: "View all",
            value: "View all",
            selected: true,
            showFilterName: "All Types",
          },
          {
            children: "Articles",
            value: "Article"
          },
          {
            children: "Audio clips",
            value: "Audio"
          },
          {
            children: "Videos",
            value: "Video"
          }
        ]
      }
    ]
  },
  {
    category: 'Language',
    type: 'checkbox',
    subCategory: [
      {
        name: '',
        options: [
          {
            children: "View all",
            value: "View all",
            selected: true,
            showFilterName: "All Language",
          },
          {
            children: "English Tips",
            value: "en-US"
          },
          {
            children: "Spanish Tips",
            value: "es-ES"
          }
        ]
      }
    ]
  }
];

export const filters = [
  {
    category: 'Topic',
    type: 'checkbox',
    subCategory: [
      {
        name: 'My Topics',
        edit: true,
        options: [],
        selectedOptions: []
      },
      {
        name: 'Others',
        options: [],
        selectedOptions: []
      }
    ]
  },
  {
    category: 'Dates',
    type: 'dateRange',
  },
  {
    category: 'Status',
    type: 'checkbox',
    subCategory: [
      {
        name: '',
        options: [
          {
            children: "View all",
            value: "View all",
            selected: true,
            showFilterName: "View all Statuses",
          },
          {
            children: "Seats available",
            value: "Seats available"
          },
          {
            children: "Waitlist available",
            value: "Waitlist available"
          },
          {
            children: "Registered for event",
            value: "Registered for event"
          },
          {
            children: "On waitlist for event",
            value: "On waitlist for event"
          },
          {
            children: "Pre-recorded events",
            value: "Pre-recorded events"
          },
        ]
      }
    ]
  },
  {
    category: 'Type',
    type: 'checkbox',
    subCategory: [
      {
        name: '',
        options: [
          {
            children: "View all",
            value: "View all",
            selected: true,
            showFilterName: "View all Type",
          },
          {
            children: "Ask The Experts",
            value: "Ask The Expert"
          },
          {
            children: "Hands-on Help",
            value: "1:1 Reviews"
          },
          {
            children: "Courses Q&As",
            value: "Office Hours"
          },
          {
            children: "Networking Sessions",
            value: "Community"
          }
        ]
      }
    ],
    text: {
      text: 'Learn more about the types of event formats we host by ',
      clickLabel: 'clicking here.',
      clickEvent: ''
    }
  },
  {
    category: 'Skill level',
    type: 'checkbox',
    subCategory: [
      {
        name: '',
        options: [
          {
            children: "View all",
            value: "View all",
            selected: true,
            showFilterName: "View all Skill level",
          },
          {
            children: "Foundational",
            value: "Foundational"
          },
          {
            children: "Intermediate",
            value: "Intermediate"
          },
        ]
      }
    ]
  },
  {
    category: 'Language',
    type: 'checkbox',
    subCategory: [
      {
        name: '',
        options: [
          {
            children: "View all",
            value: "View all",
            selected: true,
            showFilterName: "View all Language",
          },
          {
            children: "English events",
            value: "en-US"
          },
          {
            children: "English events",
            value: "es-ES"
          },
          {
            children: "Bilingual events",
            value: "bilingual-events"
          },
        ]
      }
    ]
  }
];

export const filteredResults = [
  {
    desktopImage: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test',
    label: 'Building remote work into your ',
    duration: { time: 'in progress:', color: "#FF8027", extLink: { text: 'Join now', link: 'link' } },
    courceLabel: 'Online Marketing',
    // tag: 'Viewed',
    showBookmarkIcon: false,
  },
  {
    desktopImage: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test',
    label: 'Lorem ipsum dolor sit quis nostrud  ',
    duration: { time: 'Starts soon', color: "#FF8027", preText: '07/06 3pm', icon: 'warning' },
    courceLabel: 'Course',
    courceType: '[Course type]',
    // tag: 'Recommended',
    showBookmarkIcon: false,
  },
  {
    desktopImage: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test',
    label: 'Lorem ipsum',
    duration: { time: 'Need to complete survey', color: "#006FC1", icon: 'survey' },
    courceLabel: '[Article, Video, Audio]',
    // tag: 'Live Event',
    showBookmarkIcon: false,
  },
  {
    desktopImage: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test',
    label: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    duration: { time: '38 minutes', color: "#6F7171" },
    courceLabel: 'online marketing',
    // tag: 'Course',
    showBookmarkIcon: false,
  },
  {
    desktopImage: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test',
    label: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit last. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    duration: { time: '38 minutes', color: "#6F7171" },
    courceLabel: 'online marketing',
    // tag: 'Course',
    showBookmarkIcon: false,
  },
  {
    desktopImage: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test',
    label: 'Lorem ipsum',
    duration: { time: '24 minutes remaining', color: "#006FC1" },
    courceLabel: '[Article, Video, Audio]',
    // tag: 'Live Event',
    showBookmarkIcon: false,
  },
  {
    desktopImage: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test',
    label: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    duration: { time: '38 minutes', color: "#6F7171" },
    courceLabel: 'online marketing',
    // tag: 'Course',
    showBookmarkIcon: false,
  },
  {
    desktopImage: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test',
    label: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit last. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    duration: { time: '38 minutes', color: "#6F7171" },
    courceLabel: 'online marketing',
    // tag: 'Course',
    showBookmarkIcon: false,
  },
  {
    desktopImage: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test',
    label: 'Lorem ipsum',
    duration: { time: '24 minutes remaining', color: "#006FC1" },
    courceLabel: '[Article, Video, Audio]',
    // tag: 'Live Event',
    showBookmarkIcon: false,
  },
  {
    desktopImage: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test',
    label: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    duration: { time: '38 minutes', color: "#6F7171" },
    courceLabel: 'online marketing',
    // tag: 'Course',
    showBookmarkIcon: false,
  },
  {
    desktopImage: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test',
    label: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit last. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    duration: { time: '38 minutes', color: "#6F7171" },
    courceLabel: 'online marketing',
    // tag: 'Course',
    showBookmarkIcon: false,
  },
  {
    desktopImage: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test',
    label: 'Lorem ipsum',
    duration: { time: '24 minutes remaining', color: "#006FC1" },
    courceLabel: '[Article, Video, Audio]',
    // tag: 'Live Event',
    showBookmarkIcon: false,
  },
  {
    desktopImage: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test',
    label: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    duration: { time: '38 minutes', color: "#6F7171" },
    courceLabel: 'online marketing',
    // tag: 'Course',
    showBookmarkIcon: false,
  },
  {
    desktopImage: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test',
    label: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit last. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    duration: { time: '38 minutes', color: "#6F7171" },
    courceLabel: 'online marketing',
    // tag: 'Course',
    showBookmarkIcon: false,
  },
  {
    desktopImage: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test',
    label: 'Lorem ipsum',
    duration: { time: '24 minutes remaining', color: "#006FC1" },
    courceLabel: '[Article, Video, Audio]',
    // tag: 'Live Event',
    showBookmarkIcon: false,
  },
  {
    desktopImage: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test',
    label: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    duration: { time: '38 minutes', color: "#6F7171" },
    courceLabel: 'online marketing',
    // tag: 'Course',
    showBookmarkIcon: false,
  },
  {
    desktopImage: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test',
    label: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit last. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    duration: { time: '38 minutes', color: "#6F7171" },
    courceLabel: 'online marketing',
    // tag: 'Course',
    showBookmarkIcon: false,
  },
  {
    desktopImage: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test',
    label: 'Lorem ipsum',
    duration: { time: '24 minutes remaining', color: "#006FC1" },
    courceLabel: '[Article, Video, Audio]',
    // tag: 'Live Event',
    showBookmarkIcon: false,
  }
];

