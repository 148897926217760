import { Col, Grid, Row } from '@vds/grids';
import React from 'react';
import './QuickTips.scss';
import { Body, Title } from '@vds/typography';
import { Button } from '@vds/buttons';
import { Carousel } from '@vds/carousels';
import { isDesktop, isMobile } from 'react-device-detect';
import CourseCardComponent from '../../components/common/CourseCards/CourseCards.jsx'
import { courseDetailssecondary, filterOptions,quickTip_en,quickTip_es } from './quickTips.js';
import { Icon } from '@vds/icons';
import FilterBadge from '../common/FilterBadge/FilterBadge.jsx'
import { Modal, ModalTitle, ModalBody, ModalFooter } from '@vds/modals';
import { useEffect, useState } from 'react';
import { Checkbox, CheckboxGroup } from '@vds/checkboxes';
import { useSelector } from "react-redux";
import mockdata from './mockdata.json'
import mockdataSpanish from './mockdataSpanish.json'
import { topicMap } from './questionsMock.js';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { loadQuickTips ,mapperForCourseCardDesktop} from '../../utility/utils.js';
import { useNavigate } from 'react-router';
import { ButtonIcon } from '@vds/button-icons';

const QuickTips = (props) => {
  //const userDataFromStore = useSelector((state) => state.data);
  const history=useNavigate();
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [carouselData, setCarouselData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setfilter] = useState([]);
  const [hasValidTips, setHasValidTips] = useState(false);
  
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [topicsAnalytics, setTopicsAnanlytics] = useState([]);
  const quicktip=useSelector((state) => state.AdobeResources.allQuickTips);
  console.log("Quicktips",quicktip);
 
  const aemData = useSelector((state) => state.Aem.data);
  let aemQuickTips = useSelector((state) => state.Aem.quickTipData);
  const topicMapaem=aemData?.topicCard?.topicSubtext;
  console.log("topicMapaem===",topicMapaem.forEach((item)=>console.log(item[0])))
  const newTopic=topicMapaem.filter((item)=>item[0]===props.topic)
  const aemDataResults=aemData?.results;
  const topicsI=[newTopic[0][1]];
  const [selectedFilterOptions, setSelectedFilterOptions] = useState({ id: '1', label: aemDataResults?.recommended, data: '' });
  useEffect(() => {
    
    const label = selectedFilterOptions.label;
    if(label === aemDataResults?.new){
      const newTips = (carouselData.filter(e=>e.resourceInfoTag==='New')??[]).sort((a,b) => new Date(b.publishedOnDate) - new Date(a.publishedOnDate)).slice(0, 12);
      console.log("newTips==>",newTips);

      setFilteredData(newTips);
    }
    else if(label === aemDataResults?.recommended){
      //const attributes =["Branding"];
      // userDataFromStore.business?.attributes??{};
      let attr = topicsI
      //Object.keys(attributes).filter(key => attributes[key] === 'Yes');
      setTopicsAnanlytics(attr);
      const recommended = (carouselData.filter(e=>e.tags.includes(topicsI.toString()))??[]).sort((a,b) => new Date(b.publishedOnDate) - new Date(a.publishedOnDate)).slice(0, 12);
      //console.log(combinedArray, 'combinedArray');
      console.log("recommended",recommended)
      setFilteredData(recommended);      
    }
  
},[selectedFilterOptions]);
  useEffect(()=>{
    let lng = localStorage.getItem("lng")??'en';

      const data = Object.values(aemQuickTips ?? {});
      const carouselData = data.filter(e=>e.format==='Quick Tip').filter(e=>e.tags.includes(topicsI.toString()));
     // const carouselDataRecom = data.filter(e=>e.format==='Quick Tip').filter(e=>e.resourceInfoTag==='Recommended');
      console.log(carouselData,"carouselData==")
      console.log(data,"carouselData==")
      let newTempTips = (carouselData.filter(e=>e.resourceInfoTag==='New')??[]).sort((a,b) => new Date(b.publishedOnDate) - new Date(a.publishedOnDate)).slice(0, 12);
      let recommendedFilteredData = (carouselData.filter(e=>e.tags.includes(topicsI.toString()))??[]).sort((a,b) => new Date(b.publishedOnDate) - new Date(a.publishedOnDate)).slice(0, 12);
      
      aemDataResults?.filterOptionsTips?.map((option) => {
        if (recommendedFilteredData?.length > 0 && option?.label == 'Recommended') {
          setHasValidTips(true);
          setSelectedFilterOptions(option);
          setFilteredData(carouselData?.filter(e=>e.tags.includes(topicsI.toString())));
        }
        else if (recommendedFilteredData?.length == 0 && newTempTips?.length > 0 && option?.label == 'New') {
          setHasValidTips(true);
          setSelectedFilterOptions(option);
        }
      });
      setCarouselData(carouselData);
    
   
    
    
  },[aemQuickTips]);


 
  const modalFunction = (e) => {
    setShowFilterModal(true);
  };
  const modalChange = (modalOpened) => {
    if (modalOpened) {
      setShowFilterModal(true);
    } else {
      setShowFilterModal(false);
    }
  };

  const handleChange = (e) => {
    setSelectedFilterOptions(e);
  };
 
  return (
    <>
    {hasValidTips && 
    <div className={props.bgGray ? 'quick-tips bg-gray pv-16X sm-pv-12X' : 'quick-tips bg-white pv-16X sm-pv-12X'}>
      <Grid>
        <Row>
          <Col
            colSizes={{
              mobile: 4,
              tablet: 12,
              desktop: 12,
            }}
          >
            <section className='text-area mb-8X'>
              <div className=''>
                <Title ariaLabel={aemDataResults?.quicktips} size='XLarge' bold={true}>{aemDataResults?.quicktips}</Title>
              </div>
              <div className='top-action-links'  aria-label="View all">
                <span className='action-link n-cursor-auto'>
                  <Body
                    size="large"
                    color="#000000"
                    bold={false}>
                    {aemDataResults?.viewall}
                  </Body>
                  <span className="ml-3X">
                  <ButtonIcon
                  ariaLabel={aemDataResults?.viewall+t('Quick Tips')}
                  role="link"
                      kind="ghost"
                      size="large"
                      renderIcon={() => <Icon
                        name="right-arrow"
                        size="medium"
                        color="#000000"
                      />}
                      onClick={e => {history(`/category/quicktips?topic=${topicsI}`);}}
                    />
                  </span>
                </span>
              </div>
            </section>
            <div className='mb-12X'>
              <FilterBadge nav={t('Quick Tips')} handleChange={handleChange} options={aemDataResults?.filterOptionsTips} selectedOptions={selectedFilterOptions} showFilterBadge={false} iconKind="lowContrast" surfaceType="colorFill" onChange={e => {console.log("Inside quickTips filter",e);}}/>
            </div>
          </Col>
        </Row>
      </Grid>
          {filteredData.length > 0 && 
          <div className='carousel-container-fix'>
            
            <Carousel
        peek="standard"
        layout={isMobile ? "1UP" : "3UP"}
        gutter="24px"
        surface="light"
        paginationInset="12px"
        paginationDisplay="persistent"
        aspectRatio='none'
        data={filteredData.map(e=>mapperForCourseCardDesktop(e))}
        renderItem={props => <CourseCardComponent data={props} topics={topicsAnalytics} />}
      /></div>}
      <Modal
        opened={showFilterModal}
        surface="light"
        fullScreenDialog={false}
        disableAnimation={false}
        disableOutsideClick={false}
        ariaLabel="Testing Modal"
        onOpenedChange={modalChange}
      >
        <ModalTitle>
          {t('Filter your results.')}
        </ModalTitle>
        <ModalBody>
          <div className='filter-check-list pb-6X'>
            <CheckboxGroup
              data={[
                {
                  children: "Articles",
                  value: "Articles"
                },
                {
                  children: "Audio clips",
                  value: "Audio clips"
                },
                {
                  children: "Videos",
                  value: "Videos"
                },
                {
                  children: "Show all media formats",
                  value: "Show all media formats"
                },
              ]}
            />
          </div>
          <div className='pt-6X tips-checkbox'>
            <Checkbox
              name="default"
              width="auto"
              label="Hide previously viewed Quick Tips"
              disabled={false}
              error={false}
              errorText="Please agree to the terms and conditions."
              selected={true}
            >
            </Checkbox>
          </div>
        </ModalBody>
        <ModalFooter
          buttonData={{
            primary: {
              children: 'View results',
              onClick: () => alert('Clicked Primary'),
            },
            close: {
              children: 'Clear all',
              onClick: () => alert('Clicked Secondary'),
            },
          }}
        />
      </Modal>
    </div>
    }</>
  );
};

export default QuickTips;