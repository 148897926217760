// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.networking-guidelines {
  position: relative;
  width: 100%;
  background-color: #fff;
}
.networking-guidelines .guideline-card {
  max-width: 1020px;
  margin: auto;
  display: flex;
  gap: 40px;
}
.networking-guidelines .guideline-card .col-left {
  width: 384px;
}
.networking-guidelines .guideline-card .col-left .slack-image {
  width: 138px;
  height: 138px;
  border-radius: 100%;
}
.networking-guidelines .guideline-card .col-right {
  width: calc(100% - 384px);
}
.networking-guidelines .guideline-card .col-right .detail-item {
  border-bottom: 1px solid #D8DADA;
}
.networking-guidelines .guideline-card .col-right .detail-item.border-none {
  border: none;
}
@media (max-width: 767px) {
  .networking-guidelines .guideline-card {
    gap: 48px;
    flex-wrap: wrap;
  }
  .networking-guidelines .guideline-card .col-left {
    width: 100%;
    text-align: center;
  }
  .networking-guidelines .guideline-card .col-left .left-title {
    display: flex;
    justify-content: center;
  }
  .networking-guidelines .guideline-card .col-left .slack-image {
    margin: 0 auto;
    width: 101px;
    height: 101px;
  }
  .networking-guidelines .guideline-card .col-right {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Networking/Guidelines/Guidelines.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,sBAAA;AACF;AACE;EACE,iBAAA;EACA,YAAA;EACA,aAAA;EACA,SAAA;AACJ;AAGI;EACE,YAAA;AADN;AAGM;EACE,YAAA;EACA,aAAA;EACA,mBAAA;AADR;AAKI;EACE,yBAAA;AAHN;AAKM;EACE,gCAAA;AAHR;AAKQ;EACE,YAAA;AAHV;AAQI;EA9BF;IA+BI,SAAA;IACA,eAAA;EALJ;EAOI;IACE,WAAA;IACA,kBAAA;EALN;EAOM;IACE,aAAA;IACA,uBAAA;EALR;EAQM;IACE,cAAA;IACA,YAAA;IACA,aAAA;EANR;EAUI;IACE,WAAA;EARN;AACF","sourcesContent":[".networking-guidelines {\n  position: relative;\n  width: 100%;\n  background-color: #fff;\n\n  .guideline-card {\n    max-width: 1020px;\n    margin: auto;\n    display: flex;\n    gap: 40px;\n\n\n\n    .col-left {\n      width: 384px;\n\n      .slack-image {\n        width: 138px;\n        height: 138px;\n        border-radius: 100%;\n      }\n    }\n\n    .col-right {\n      width: calc(100% - 384px);\n\n      .detail-item {\n        border-bottom: 1px solid #D8DADA;\n\n        &.border-none {\n          border: none;\n        }\n      }\n    }\n\n    @media (max-width: 767px) {\n      gap: 48px;\n      flex-wrap: wrap;\n\n      .col-left {\n        width: 100%;\n        text-align: center;\n\n        .left-title {\n          display: flex;\n          justify-content: center;\n        }\n\n        .slack-image {\n          margin: 0 auto;\n          width: 101px;\n          height: 101px;\n        }\n      }\n\n      .col-right {\n        width: 100%;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
