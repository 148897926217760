// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language-card-container {
  display: flex;
  gap: 16px;
  align-items: flex-start;
}
@media screen and (max-width: 767px) {
  .language-card-container {
    flex-direction: column;
  }
}

.language-card {
  position: relative;
}
.language-card .icon-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 8px 16px 16px 16px;
  text-align: center;
}
.language-card .bottom-desc {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #d8dada;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Language/languageSupport.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,SAAA;EACA,uBAAA;AAAF;AACE;EAJF;IAKI,sBAAA;EAEF;AACF;;AAAA;EACE,kBAAA;AAGF;AAFE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,QAAA;EACA,2BAAA;EACA,kBAAA;AAIJ;AAFE;EACE,kBAAA;EACA,SAAA;EACA,OAAA;EACA,WAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,6BAAA;AAIJ","sourcesContent":["\n.language-card-container{\n  display: flex;\n  gap: 16px;\n  align-items: flex-start;\n  @media screen and (max-width: 767px) {\n    flex-direction: column;\n  }\n}\n.language-card{\n  position: relative;\n  .icon-area{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    gap: 4px;\n    padding: 8px 16px 16px 16px;\n    text-align: center;\n  }\n  .bottom-desc{\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    border-top: 1px solid #d8dada;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
