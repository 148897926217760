import React, { useEffect, useState } from 'react';
import { Icon } from '@vds/icons';
import { Body } from '@vds/typography';
import './autocomplete.scss';
import { useTranslation } from 'react-i18next';
  const Autocomplete = (props) => {
    const {setZipCode} = props;
  const [active, setActive] = useState(0);
  const [filtered, setFiltered] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [input, setInput] = useState('');
  const [temp,setTemp]=useState('');
  const { t, i18n } = useTranslation();
  const onChange = (e) => {
    console.log("Onchange of Auto complete")
    let filterTimeout;
    props.locationValue('');
    props.inputValue(e.currentTarget.value);
    setActive(0);
    setIsShow(true);
    setInput(e.currentTarget.value);
    props.suggestValue();
    setZipCode(e.target.value)
   
    
  };
  
  useEffect(() => {
   if (props.locValue !== '') {
      setInput(props.locValue);
    }
  }, [props.locValue]);
  const onClick = (e) => {
    setActive(0);
    setFiltered([]);
    setIsShow(false);
    setInput(e.currentTarget.innerText);
    props.locationValue(e.currentTarget.innerText);
  };
  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      // enter key
      setActive(0);
      setIsShow(false);
     setInput(temp);
     props.locationValue(temp);
    } else if (e.keyCode === 38) {
      // up arrow
    
      setTemp(props.suggestions[active-1])
    
      return active === 0 ? null : setActive(active - 1);
    } else if (e.keyCode === 40) {
      // down arrow
      setTemp(props.suggestions[active+1])
      return active - 1 === props?.suggestions?.length ? null : setActive(active + 1);
    }
  };
  const renderAutocomplete = () => {
    if (isShow && input) {
      if (props?.suggestions?.length > 0) {
        return (
          <ul className="autocomplete">
            {props?.suggestions?.map((suggestion, index) => {
              let className;
              if (suggestion.indexOf(input) !== -1) {
                if (index === active) {
                  className = "active";
                  
              }
                return (
                  <li className={className} key={suggestion} onClick={onClick}> 
                    {/* {suggestion} */}
                    <span style={{ fontWeight: 'bold' }}>
                      {' '}
                      {suggestion.slice(
                        suggestion.indexOf(input),
                        suggestion.indexOf(input) + input.length
                      )}
                    </span>
                    {suggestion.substring(input.length, suggestion.length)}
                  </li>
                );
              }
              else {
                <></>
              }
            })}
          </ul>
        );
      } else {
        
        return (
          <div className="no-autocomplete">
            <Body size="large" color="#000000" bold={false} name={props.errorMsg} ariaLabelledBy={props.errorMsg}tabIndex={0}>
              {props.errorMsg}
            </Body>
          </div>
        );
      }
    }
    return <></>;
  };
  const clearAutocomplete = () => {

    setZipCode('')
    setInput('');
    props.locationValue('');
  };
  return (
    <>
      <div className="app-autocomplete">
        <div className="autocomplete-wrapper">
          <input
            aria-label={t('Enter zipcode')}
            name={t("Enter zipcode")}
            type="text"
            onChange={onChange}
            onKeyDown={onKeyDown}
            value={input}
            className={`autocomplete-input ${isShow && input && filtered.length ? 'input-focus' : ''
              }`}
          />
          {input !== '' && (
            <span className="close-icon" onClick={clearAutocomplete}>
              <Icon name="close" size="medium" color="#000000" />
            </span>
          )}
        </div>
        {renderAutocomplete()}
      </div>
    </>
  );
};

export default Autocomplete;
