import { Col, Grid, Row } from '@vds/grids';
import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import './QuickTips.scss';
import { Body, Title } from '@vds/typography';
import { Button } from '@vds/buttons';
import { Carousel } from '@vds/carousels';
import { isDesktop, isMobile } from 'react-device-detect';
import CourseCardComponent from '../common/CourseCards/CourseCards.jsx';
import { courseDetailssecondary, filterOptions } from './quickTips.js';
import { Icon } from '@vds/icons';
import FilterBadge from '../common/FilterBadge/FilterBadge.jsx';
import { Modal, ModalTitle, ModalBody, ModalFooter } from '@vds/modals';
import { useEffect, useState } from 'react';
import { Checkbox, CheckboxGroup } from '@vds/checkboxes';
import mockdata from './mockdata.json';
import mockdataSpanish from './mockdataSpanish.json';
import { useTranslation } from 'react-i18next';
// import { creators } from '../../../shared/_actions';
import { ButtonIcon } from '@vds/button-icons';
import { useNavigate } from "react-router";

const QuickTips = () => {
  const userDataFromStore = useSelector((state) => state.data);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [carouselData, setCarouselData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [recommendedData, setRecommendedData] = useState([]);
  const [newData, setNewData] = useState([]);
  const [filter, setfilter] = useState([]);
  const [selectedFilterOptions, setSelectedFilterOptions] = useState({ id: '1', label: 'Recommended', data: '' });
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [topicsAnalytics, setTopicsAnanlytics] = useState([]);
  const history = useNavigate();

  useEffect(() => {
    let lng = localStorage.getItem("lng");
    if(lng === null){
      lng = userDataFromStore.business?.attributes?.Language??'en';
    }

    const data = lng === 'en' ? mockdata : mockdataSpanish;

    // creators.loadQuickTips(lng).then((quicktip) => {
    //   let formattedData = {recommended:[],new:[]};
    //   Object.keys(data.recommended).forEach((item) => {
    //     let currentTopicList = data.recommended[item];
    //     formattedData.recommended[item] = currentTopicList.map(e => quicktip[e]);
    //   });
    //   formattedData.new = data.new.map(e => quicktip[e]);

    //   //Setting new tips in the state
    //   const newTips = (formattedData.new??[]).sort((a,b) => new Date(b.publishedOnDate) - new Date(a.publishedOnDate)).slice(0, 12);
    //   setNewData(newTips);

    //   //Setting new tips in the state
    //   let recommendedFilteredData = getRecommendedData(formattedData);
    //   setRecommendedData(recommendedFilteredData);

    //   //Setting filtered data onload as recommended
    //   setCarouselData(formattedData);
    //   setFilteredData(recommendedFilteredData);

    //   dispatch({
    //     type: "UPDATE_QUICK_TIPS",
    //     payload: Object.values(quicktip)
    //   });
    // });
  },[]);

  useEffect(() => {
    const label = selectedFilterOptions.label;
    if(label === 'New'){
      setFilteredData(newData);
    } else if(label === 'Recommended') {
      setFilteredData(recommendedData);
    }
  },[selectedFilterOptions]);

  const getRecommendedData = (carouselData) => {
    const attributes = userDataFromStore.business?.attributes??{};
    let attr = Object.keys(attributes).filter(key => attributes[key] === 'Yes');
    setTopicsAnanlytics(attr);
    const data = structuredClone(carouselData.recommended??{});
    // console.log(attr, 'attr')
    // console.log(data, 'data')
    const mappedArrays = attr.map((key) => data[key]).filter(item => item !== undefined);
    // console.log(mappedArrays, 'mapped')
    const combinedArray = mappedArrays.reduce((result, arr) => {
      arr.forEach((item, index) => {
        if (!result[index]) {
          result[index] = [];
        }
        result[index].push(item);
      });
      return result;
    }, []);

    console.log(combinedArray, 'combinedArray');

    const output = combinedArray.reduce((result, arr) => {
      arr.forEach((item) => {
        if (!result.includes(item)) {
          result.push(item);
        }
      });
      return result;
    }, []);

    const recommendedArr = ([...(output??[]), ...(data["I'm not sure"]??[])]).slice(0,12);
    return recommendedArr;
  }

  const modalFunction = (e) => {
    setShowFilterModal(true);
  };
  const modalChange = (modalOpened) => {
    if (modalOpened) {
      setShowFilterModal(true);
    } else {
      setShowFilterModal(false);
    }
  };

  const handleChange = (e) => {
    setSelectedFilterOptions(e);
  };
  return (
    <div className='quick-tips bg-white pv-16X sm-pv-12X'>
      <Grid>
        <Row>
          <Col
            colSizes={{
              mobile: 4,
              tablet: 12,
              desktop: 12,
            }}
          >
            <section className='text-area mb-8X'>
              <div className=''>
                <Title tabIndex={0} ariaLabel={"Quick tips"} size='XLarge' bold={true}>{t('Quick tips.')}</Title>
              </div>
              <div className='top-action-links' tabIndex={0} aria-label="View all">
                <span className='action-link n-cursor-auto'>
                  <Body
                    size="large"
                    color="#000000"
                    bold={false}>
                    {t('View all')}
                  </Body>
                  <span className="ml-3X">
                    <ButtonIcon
                      kind="ghost"
                      size="large"
                      renderIcon={() => <Icon
                        name="right-arrow"
                        size="medium"
                        color="#000000"
                      />}
                      onClick={e => {history("/category/quicktips");}}
                    />
                  </span>
                </span>
              </div>
            </section>
            <div className='mb-12X'>
              <FilterBadge handleChange={handleChange} options={filterOptions} selectedOptions={selectedFilterOptions} showFilterBadge={false} iconKind="lowContrast" surfaceType="colorFill" onChange={e => {console.log("Inside quickTips filter",e);}}/>
            </div>
          </Col>
        </Row>
      </Grid>
     {filteredData.length > 0 && <Carousel
        peek="standard"
        layout={isMobile ? "1UP" : "3UP"}
        gutter="24px"
        surface="light"
        paginationInset="12px"
        paginationDisplay="persistent"
        aspectRatio='none'
        data={filteredData}
        renderItem={props => <CourseCardComponent data={props} topics={topicsAnalytics} />}
      />}
      <Modal
        opened={showFilterModal}
        surface="light"
        fullScreenDialog={false}
        disableAnimation={false}
        disableOutsideClick={false}
        ariaLabel="Testing Modal"
        onOpenedChange={modalChange}
      >
        <ModalTitle>
          {t('Filter your results.')}
        </ModalTitle>
        <ModalBody>
          <div className='filter-check-list pb-6X'>
            <CheckboxGroup
              data={[
                {
                  children: "Articles",
                  value: "Articles"
                },
                {
                  children: "Audio clips",
                  value: "Audio clips"
                },
                {
                  children: "Videos",
                  value: "Videos"
                },
                {
                  children: "Show all media formats",
                  value: "Show all media formats"
                },
              ]}
            />
          </div>
          <div className='pt-6X tips-checkbox'>
            <Checkbox
              name="default"
              width="auto"
              label="Hide previously viewed Quick Tips"
              disabled={false}
              error={false}
              errorText="Please agree to the terms and conditions."
              selected={true}
            >
            </Checkbox>
          </div>
        </ModalBody>
        <ModalFooter
          buttonData={{
            primary: {
              children: 'View results',
              onClick: () => alert('Clicked Primary'),
            },
            close: {
              children: 'Clear all',
              onClick: () => alert('Clicked Secondary'),
            },
          }}
        />
      </Modal>
    </div>
  );
};

export default QuickTips;