import { useSelector } from 'react-redux';

import { Carousel } from '@vds/carousels';
import { Feature, Title } from '@vds/typography';

import SocialProofCardComponent from '../elements/SocialProofCardComponent';
import './SocialProofComponent.scss';
import { Col, Grid, Row } from '@vds/grids';
import { t } from 'i18next';
import { VideoPlayer } from '../elements/VideoPlayer/VideoPlayer.jsx';

const SocialProofComponent = () => {
  const aemData = useSelector((state) => state.Aem.data);
  const isMobile = useSelector((state) => state.MediaType.isMobile);
  // const {videoPath, posterImage, cards} = aemData?.socialProofSection;
  return (
    <>
      {aemData?.socialProofSection && (
        <div className="main pv-24X">
          <div className="header mb-24X">
            <div className="title">
              <Feature size="XSmall" bold={false} color="#FFFFFF">
                {aemData?.socialProofSection?.videoTitle}
              </Feature>
            </div>
            <div className="details mt-8X mb-24X">
              <div className="name">
                <Title size="small" bold={true} color="#FFFFFF">
                {aemData?.socialProofSection?.videoName}
                </Title>
              </div>
              <div className="location-details" >
                <Title size="small" bold={false} color="#FFFFFF">
                {aemData?.socialProofSection?.videoLocation}
                </Title>
              </div>
            </div>
          </div>
          <Grid
          className=""
          bleed="1440"
          colSizes={{
            mobile: 4,
            tablet: 12,
            desktop: 12,
          }}>
          <Row>
            <Col>
          <div className="video-wrapper" aria-label='the experience of the satisfied customer have used the courses.'>
            {/* <video
             role="video"
             controls
             id="landing-video"
             className="videos"
             poster={aemData?.socialProofSection?.posterImage}
           
      >
              <source
                src={aemData?.socialProofSection?.videoPath}
                type="video/mp4"
              />
            </video> */}
            <VideoPlayer role="video" src={aemData?.socialProofSection?.videoPath} poster={aemData?.socialProofSection?.posterImage}  label={aemData?.socialProofSection?.videoTitle ? aemData?.socialProofSection?.videoTitle : aemData?.socialProofSection?.videoPath}/>
          </div>

          <div className="mt-24X mobileCards" >
            <Carousel
              layout={isMobile?"1UP":"3UP"}
              gutter="24px"
              surface="light"
              paginationFill="light"
              paginationInset="12px"
              paginationDisplay="persistent"
              peek="minimum"
              aspectRatio='4:3'
              data={aemData?.socialProofSection?.cards}
              renderItem={(props) => (
                <SocialProofCardComponent {...props}></SocialProofCardComponent>
              )}
            />
          </div>
          </Col>
          </Row>
          </Grid>
        </div>
      )}
    </>
  );
};

export default SocialProofComponent;
