// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Application-submission-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #F3EDE0;
}
@media (max-width: 767px) {
  .Application-submission-div {
    flex-direction: column;
    align-items: flex-start;
  }
}

.Application-submission-sub-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #F3EDE0;
}
@media (max-width: 767px) {
  .Application-submission-sub-div {
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.Application-submission-link {
  margin-left: 8px;
}
@media (max-width: 767px) {
  .Application-submission-link {
    margin-top: 10px;
    margin-left: 28px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/specialGrants/SuperBowl/ApplicationSubmissionLink/ApplicationSubmissionLink.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,yBAAA;AACJ;AACI;EAPJ;IAQQ,sBAAA;IACA,uBAAA;EAEN;AACF;;AACA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,yBAAA;AAEJ;AAAI;EAPJ;IAQQ,uBAAA;IACA,2BAAA;EAGN;AACF;;AAAA;EACI,gBAAA;AAGJ;AADI;EAHJ;IAIQ,gBAAA;IACA,iBAAA;EAIN;AACF","sourcesContent":[".Application-submission-div {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    background-color: #F3EDE0;\n\n    @media (max-width: 767px) {\n        flex-direction: column;\n        align-items: flex-start;\n    }\n}\n\n.Application-submission-sub-div {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    background-color: #F3EDE0;\n\n    @media (max-width: 767px) {\n        align-items: flex-start;\n        justify-content: flex-start;\n    }\n}\n\n.Application-submission-link {\n    margin-left: 8px;\n\n    @media (max-width:767px) {\n        margin-top: 10px;\n        margin-left: 28px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
