import React from 'react';
import './superbowlGrantLanding.scss';
import { Title } from '@vds/typography';
import { ButtonIcon } from '@vds/button-icons';
import { Icon } from '@vds/icons';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Line } from '@vds/lines';
import { TextLink } from '@vds/buttons';

const SuperbowlGrantLanding = ({ baseBoxColor = '#ff8027', topBoxColor = '#f3ede0', grantPromocolor = '#ffffff' }) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const aemData = useSelector((state) => state.Aem.data);
    //   const img = global.config.AEM_URL + `images/superbowl/${isMobile?'mobile':'desktop'}/InfoHub_SB_${isMobile?'Mobile':'Desktop'}.png`;
    const liixlogo = global.config.AEM_URL + `images/superbowl/${isMobile ? 'mobile' : 'desktop'}/SBPage_SBLogo_${isMobile ? 'Mobile' : 'Desktop'}.png`;
    const vzlogo = global.config.AEM_URL + `images/superbowl/${isMobile ? 'mobile' : 'desktop'}/SBPage_VZLogo_${isMobile ? 'Mobile' : 'Desktop'}.png`;


    return (
        <div className='superbowl-landing-wrapper' style={{ backgroundColor: baseBoxColor }} >
            <div style={{ backgroundColor: topBoxColor, height: '48px' }}></div>
            <div className='superbowl-promo-landing pa-8X' style={{ backgroundColor: grantPromocolor }}>
                <div style={{ aspectRatio: isMobile ? '90:72' : '134:108', height: isMobile ? '104px' : '100%' }}>
                    <div className="SuperBowl-Image-grants">
                        <img className={isMobile ? "mr-4X" : "mr-4X"} src={vzlogo} height={'24px'} width={'107.06px'} />
                        <Line type="primary" orientation="vertical" style={{ height: '103.88px' }}></Line>
                        <img className={isMobile ? "ml-4X" : "ml-4X"} src={liixlogo} height={'103.88px'} width={'106px'} />
                    </div>
                </div>
                <div>
                    <Title size='large' bold>{aemData?.superbowl?.superbowlInfohub} </Title>
                    <TextLink ariaLabel={t('LegalDisclaimerAccessibilityText')} onClick={()=>{navigate('/super-bowl?scroll_to=promotion_details')}} size="small"  type="standAlone">{t('Legal disclaimer')}</TextLink>
                </div>
                <div className='btnn-ico'>
                    <ButtonIcon
                        kind="lowContrast"
                        ariaLabel={t('Apply for Super Bowl Grant link')}
                        size="large"
                        floating={false}
                        hideBorder={true}
                        onClick={e => navigate(`/super-bowl`)}
                        renderIcon={(props) => <Icon name="right-arrow" {...props} />}
                    />
                </div>

            </div>
        </div>
    );
};

export default SuperbowlGrantLanding;