/** @format */
import { AudioPlayer } from './AudioPlayer';
import './CoursePlayerComponent.scss';
import { VideoPlayer } from './VideoPlayer';

const CoursePlayerComponent = (props) => {
  const { type, source, thumbnailImage, closedCaptionFileURL } = props.deliveryMethod;
  const label=props?.label;
  const publishedOnDate=props?.publishedOnDate;
  switch (type) {
    case 'article':
      return (
        <div tabIndex={0} className="expert-image-container">
          <img src={source} height="455px" />
        </div>
      );
    case 'audio':
      return <AudioPlayer src={source}  label={props?.label} publishedOnDate={publishedOnDate} />;

    case 'video':
      return <VideoPlayer role="video" src={source} poster={thumbnailImage} closedCaptionFileURL={closedCaptionFileURL} label={label}/>;

    default:
      return <></>;
  }
};

export default CoursePlayerComponent;
