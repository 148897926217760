import enquire from 'enquire.js';

import { useEffect, useState } from 'react';

import { Button, TextLink } from '@vds/buttons';
import { Input } from '@vds/inputs';
import { Notification } from '@vds/notifications';
import { Title } from '@vds/typography';

import './signin.scss';

const SignIn = () => {
  const [emailValue, setEmailValue] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [passwordValue, setPasswordValue] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [mobileViewport, setMobileViewport] = useState(false);

  useEffect(() => {
    enquire.register('screen and (max-width:767px)', () => {
      console.log('mobile');
      setMobileViewport(true);
    });
    enquire.register('screen and (min-width:767px)', () => {
      console.log('desktop');
      setMobileViewport(false);
    });
  }, []);

  useEffect(() => {
    if (emailValue !== '') {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(String(emailValue).toLowerCase())) {
        setEmailError(false);
      } else {
        setEmailError(true);
      }
    }
    if (passwordValue !== '') {
      setPasswordError(false);
    } else {
      setPasswordError(true);
    }
  }, [emailValue, passwordValue]);

  return (
    <div className="signin-container pt-12X">
      <div>
        {emailError && (
          <div className="n-textLeft max-w-384x sm-n-maxWidthFull mb-8X mh-auto sm-mb-6X">
            <Notification
              type="error"
              title="Email not recognized"
              subtitle="The email you entered isn't connected to an account. Please try again, or register now."
              fullBleed={false}
              inline={false}
              disableFocus={true}
              layout={'vertical'}
              buttonData={[
                {
                  children: 'Register',
                  onClick: (e) => alert('Button clicked!'),
                },
              ]}
            />
          </div>
        )}
        {passwordError && (
          <div className="n-textLeft max-w-384x sm-n-maxWidthFull mb-8X mh-auto sm-mb-6X">
            <Notification
              type="error"
              title="Your account Is temporarily disabled"
              subtitle="For your security, the account associated with this email has been disabled due to the number of incorrect password attempts."
              fullBleed={false}
              inline={false}
              disableFocus={true}
              layout={'vertical'}
              buttonData={[
                {
                  children: 'Reset Password',
                  onClick: (e) => alert('Button clicked!'),
                },
              ]}
            />
          </div>
        )}
      </div>
      <div className="n-textCenter sm-n-textLeft mb-8X">
        <div className="mb-4X">
          <Title
            size={mobileViewport ? '2XLarge' : 'XLarge'}
            color="#000000"
            bold={true}
          >
            Welcome back.
          </Title>
        </div>
        <Title size="medium" color="#000000" bold={false}>
          Sign in and let's get down to business.
        </Title>
      </div>
      <form className="max-w-384x sm-n-maxWidthFull mh-auto">
        <div className="n-textLeft mb-6X">
          <Input
            type="text"
            label="Enter email address"
            readOnly={false}
            required={true}
            disabled={false}
            error={emailError}
            success={emailValue !== '' && !emailError}
            defaultValue={emailValue}
            errorText="Please enter a valid email address"
            onChange={(e) => setEmailValue(e.target.value)}
          />
        </div>
        <div className="n-textLeft mb-12X">
          <Input
            type="password"
            label="Enter your new password"
            mask="toggle"
            readOnly={false}
            required={true}
            disabled={false}
            maxLength={6}
            error={passwordError}
            errorText="Incorrect password"
            defaultValue={passwordValue}
            tooltipTitle="Password requirements"
            tooltipContent="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            onChange={(e) => setPasswordValue(e.target.value)}
          />
        </div>
        <div className="n-textCenter pb-6X">
          <Button
            size="large"
            disabled={false}
            use="primary"
            className="mh-auto"
            onClick={(e) => alert('Button clicked')}
          >
            Sign In
          </Button>
        </div>
        <div className="n-textCenter">
          <TextLink
            type="standAlone"
            size="small"
            onClick={(e) => alert('TextLink clicked')}
          >
            Forgot your password?
          </TextLink>
        </div>
      </form>
    </div>
  );
};

export default SignIn;
