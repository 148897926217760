// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.network-hero-section {
  position: relative;
  width: 100%;
}
.network-hero-section .content-block {
  display: block;
  text-align: center;
  max-width: 808px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 100%;
}
.network-hero-section .content-block .slack-image {
  position: relative;
}
.network-hero-section .content-block .slack-image img {
  height: 60px;
  object-fit: contain;
}
.network-hero-section .content-block .community-button,
.network-hero-section .content-block .community-link {
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Networking/Hero/Hero.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;AACF;AACE;EACE,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;EACA,WAAA;AACJ;AACI;EACE,kBAAA;AACN;AACM;EACE,YAAA;EACA,mBAAA;AACR;AAGI;;EAEE,aAAA;EACA,uBAAA;AADN","sourcesContent":[".network-hero-section {\n  position: relative;\n  width: 100%;\n\n  .content-block {\n    display: block;\n    text-align: center;\n    max-width: 808px;\n    margin-left: auto;\n    margin-right: auto;\n    position: relative;\n    width: 100%;\n\n    .slack-image {\n      position: relative;\n\n      img {\n        height: 60px;\n        object-fit: contain;\n      }\n    }\n\n    .community-button,\n    .community-link {\n      display: flex;\n      justify-content: center;\n    }\n  }\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
