import { useSelector } from "react-redux";
import { useLocation, useParams } from 'react-router-dom';
import CourseDetails from "./CourseContentPage";
import CourseOverview from "./CourseOverviewPage";
import { getWithExpiry } from '../utility/utils'
import { useEffect } from "react";
import { Loader } from "@vds/loaders";

const CourseContentWrapperComponent = () => {
    let courseType = 'quicktips';
    let { courseId } = useParams();
    const allQuickTips = useSelector((state) => state.AdobeResources.allQuickTips);
    const aemData = useSelector((state) => state.Aem.data);
    const offersMock = useSelector((state) => state.Aem.offerData);
    const quickTips = useSelector((state) => state.Aem.quickTipData);
    const allEventsWithTimeZone = useSelector((state) => state.AdobeResources.allEventsWithTimeZone);
    let suggestion = getWithExpiry('suggestion');
    const location = useLocation();
    if (courseId.slice(0, 2) === 'id' || courseId === 'capital-offering') {
        courseType = 'offers';
    }
    if (suggestion?.events[courseId]) {
        courseType = 'event';
      } else if (suggestion?.courses[courseId]) {
        courseType = 'course';
      } else if (suggestion?.learningProgram[courseId]) {
        courseType = 'learningProgram';
      }
    
    useEffect(() => {
      if(localStorage.needReloadForChineseLang) {
        localStorage.removeItem('needReloadForChineseLang');
        window.location.reload();
      }
      if(localStorage.prevUrl && localStorage.prevUrl != location.pathname) {
        if(localStorage.Chinese) {
          localStorage.removeItem('Chinese');
        }
      }
      return () => {
        if(localStorage.Chinese) {
          localStorage.removeItem('Chinese');
          localStorage.setItem('needReloadForChineseLang', true);
        }
      };
    }, [location]);
    // return <>
    // {allQuickTips && quickTips && offersMock && aemData && allEventsWithTimeZone && <CourseDetails allQuickTips = {allQuickTips} quickTips={quickTips} offersMock={offersMock} aemData={aemData} />}
    // </>;
    const renderComponentBasedOnCourseType = () => {
        switch (courseType) {
          case 'quicktips' : 
            return (<>
            {(!allQuickTips || !quickTips || !offersMock || !aemData || !allEventsWithTimeZone) && <Loader fullscreen={true} active={true} surface="light"/>}
            {allQuickTips && quickTips && offersMock && aemData && allEventsWithTimeZone && <CourseDetails allQuickTips = {allQuickTips} quickTips={quickTips} offersMock={offersMock} aemData={aemData} />}
            </>);
          case 'event' : 
          return (<>
            {(!allQuickTips || !quickTips || !offersMock || !aemData || !allEventsWithTimeZone) && <Loader fullscreen={true} active={true} surface="light"/>}
            {allQuickTips && quickTips && offersMock && aemData && allEventsWithTimeZone && <CourseDetails allQuickTips = {allQuickTips} quickTips={quickTips} offersMock={offersMock} aemData={aemData} />}
            </>);
          case 'offers' : 
            return (<>
            {(!allQuickTips || !quickTips || !offersMock || !aemData || !allEventsWithTimeZone) && <Loader fullscreen={true} active={true} surface="light"/>}
            {allQuickTips && quickTips && offersMock && aemData && allEventsWithTimeZone && <CourseDetails allQuickTips = {allQuickTips} quickTips={quickTips} offersMock={offersMock} aemData={aemData} />}
            </>);
          case 'course' : 
            return (<>
            {(!allQuickTips || !quickTips || !offersMock || !aemData || !allEventsWithTimeZone) && <Loader fullscreen={true} active={true} surface="light"/>}
            {allQuickTips && quickTips && offersMock && aemData && allEventsWithTimeZone && <CourseDetails allQuickTips = {allQuickTips} quickTips={quickTips} offersMock={offersMock} aemData={aemData} />}
            </>);
          case 'learningProgram' : 
            return (<> 
             {(!allQuickTips || !quickTips || !offersMock || !aemData || !allEventsWithTimeZone) && <Loader fullscreen={true} active={true} surface="light"/>}
             {allQuickTips && quickTips && offersMock && aemData && allEventsWithTimeZone && <CourseDetails allQuickTips = {allQuickTips} quickTips={quickTips} offersMock={offersMock} aemData={aemData} />}
            </>);
        }
    }
  
    return (
      <div>
          {renderComponentBasedOnCourseType()}
      </div>
    );
}

export default CourseContentWrapperComponent;