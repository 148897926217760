/** @format */
import enquire from 'enquire.js';

import { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useSelector } from 'react-redux';

import { Checkbox, CheckboxGroup } from '@vds-core/checkboxes';
import { ButtonGroup, TextLink } from '@vds/buttons';
import { Input } from '@vds/inputs';
import { ListItem, UnorderedList } from '@vds/lists';
import { Notification } from '@vds/notifications';
import { Body, Micro, Title } from '@vds/typography';

import { ruless } from '../../../assets/mockdata/registermock';
import { userRegistrationStep } from '../../../common/Analytics/GAWrapper';
import Autocomplete from '../../../elements/autocomplete/autocomplete';
import EmailInputComponent from '../../../elements/inputs/emailInput';
import PasswordValidatorComponent from '../../../elements/inputs/passwordValidator';
import MultiselectChips from '../../../elements/multiselectChips/multiselectChips';
import StepIndicatorComponent from '../../../elements/stepper/stepper';
import { updateSpanish } from '../../../store/aem';
import store from '../../../store/store';
import './register.scss';

const RegisterStep1Component = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [titles, setTitles] = useState({});
  const [viewNotification, setViewNotification] = useState(false);
  const [mobileViewport, setMobileViewport] = useState(false);
  const selectedTopics = useSelector(
    (state) => state.AdobeResources.registerPageSelectedTopics
  );
  const [addTopics, setAddTopics] = useState(selectedTopics);
  const [addCheckbox, setAddCheckbox] = useState([]);
  const [isDisabled, setIsDisabled] = useState(selectedTopics.length > 0 ? false : true);
  const [isSelected, setIsSelected] = useState(false);
  // const aemData = useSelector((state) => state.Aem.registerData);
  const [busName, setBusName] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [isRegisterButtonEnabled, setIsRegisterButtonEnabled] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);
  const [reCAPTCHASiteKey, setReCAPTCHASiteKey] = useState('');
  const [locValue, setLocValue] = useState('');
  const [location, setLocation] = useState('');
  const [registerData, setRegisterData] = useState([]);
  const updateLang = useSelector((state) => state.Aem.spanishLang);
  const engData = useSelector((state) => state.Aem.registerData);
  const isSpanish = useSelector((state) => state.Aem.isSpanish);
  const aemData = !isSpanish ? engData : updateLang;
  const [topicsName, setTopicsName] = useState([]);
  const [childTiles, setChildTiles] = useState([]);
  const buttonGroup = [
    {
      children: t('Back'),
      size: 'large',
      use: 'secondary',
      width: 'auto',
      onClick: () => changeStep('back'),
    },
    {
      children: t('Next'),
      size: 'large',
      use: 'primary',
      width: 'auto',
      disabled: isDisabled,
      onClick: () => changeStep('next'),
    },
    {
      children: t('Register'),
      size: 'large',
      use: 'primary',
      width: 'auto',
      disabled: !isRegisterButtonEnabled,
      onClick: () => register('next'),
    },
  ];
  let groups = [];
  let topics = [];

  // useEffect(() => {
  //   const key = global.config.reCAPTCHASiteKey;
  //   console.log("RECAPTCHA KEY", key);
  //   console.log("Process.ENV", process.env);
  //   setReCAPTCHASiteKey(key);
  // },[])

  const verifyToken = (val) => {
    if (val) {
      setIsRecaptchaVerified(true);
    } else {
      setIsRecaptchaVerified(false);
    }
  };

  useEffect(() => {
    console.log('aemData ==> ', aemData);
    if (
      aemData &&
      aemData.registerModule &&
      aemData.registerModule.checkBoxGroup
    ) {
      const childTiles = aemData?.registerModule?.checkBoxGroup?.map(
        (card) => ({
          children: card.children,
          value: card.value,
          selected: addCheckbox.find((item) => card.children === item.ariaLabel)
            ? true
            : false,
        })
      );
      setChildTiles(childTiles);
      addCheckbox.forEach((card) => groups.push(card.ariaLabel));
    }

    if (aemData && aemData?.registerModule && aemData?.registerModule?.topics) {
      const topicsName = aemData?.registerModule?.topics?.map((card) => ({
        id: card.id,
        label: card.label,
        data: card.data,
      }));
      setTopicsName(topicsName);
      addTopics.forEach((card) => topics.push(card.label));
    }
  }, [aemData, setTopicsName, setChildTiles]);

  const register = () => {
    setRegisterData([
      ...registerData,
      {
        user: {
          email: 'test@test.com',
          //password: 'test@password',
        },
      },
    ]);

    const payload = {
      token: 'asdljasdjlsslslfjl',
      user: {
        email: 'vauser4024@sharklasers.com',
        //password: 'c7oQvGZ/3MzOTW0pc5/Iew==',
      },
      businessData: {
        groups: ['Asian-Indian owned business'],
        topics: ['Event - current', 'Learn - Financial basics'],
        city: 'Sunnyvale',
        state: 'CA',
        zipcode: '94087',
        uiLocale: 'en-US',
        contentLocale: 'en-US',
      },
    };

    try {
      let response = fetch(
        global.config.PLATFORM_API_URL + '/v2/user/profile',
        {
          method: 'POST',
          body: JSON.stringify(payload),
        }
      );
      let responseJson = response.json();
      console.log('responseJson---->', responseJson);
      return responseJson;
    } catch (error) {
      console.error(error);
      return null;
    }
    // setViewNotification(true);
  };

  const changeStep = (event) => {
    if (addCheckbox.length === 0 && currentStep === 2) {
      setAddCheckbox([
        ...addCheckbox,
        {
          value: aemData?.registerModule?.orOptionForGroup,
          ariaLabel: aemData?.registerModule?.orOptionForGroup,
        },
      ]);
    }
    if (currentStep === 3) {
      setRegisterData([
        ...registerData,
        {
          token: 'asdljasdjlsslslfjl',
          // user: {
          //     email: emailValue,
          //     password: pwdStrength
          // },
          businessData: {
            groups: JSON.stringify(groups),
            topics: JSON.stringify(topics),
            city: locValue.split(',')[0].substring(5),
            state: locValue.split(',')[1],
            zipcode: locValue.substring(0, 5),
            uiLocale: 'en-US',
            contentLocale: 'en-US',
          },
        },
      ]);
    }

    if (event === 'back') setRegisterData([]);
    setCurrentStep(event === 'next' ? currentStep + 1 : currentStep - 1);
  };

  const emailChange = (isValid) => {
    setIsEmailValid(isValid);
  };

  const passwordChange = (data, isValid) => {
    setIsPasswordValid(isValid);
  };

  useEffect(() => {
    setIsRegisterButtonEnabled(
      isEmailValid && isRecaptchaVerified && isPasswordValid
    );
  }, [isEmailValid, isRecaptchaVerified, isPasswordValid]);

  const addCheckboxValues = (e) => {
    if (e.target.checked) {
      if (e.target.value === 'no selected') {
        setAddCheckbox([]);
        setIsSelected(true);
      } else {
        setIsSelected(false);
        setAddCheckbox([
          ...addCheckbox,
          {
            id: e.target.id,
            value: e.target.value,
            ariaLabel: e.target.ariaLabel,
          },
        ]);
      }
    } else {
      if (e.target.value === 'no selected') {
        setIsSelected(false);
      }
      setAddCheckbox([
        ...addCheckbox.filter(
          (people) => people.ariaLabel !== e.target.ariaLabel
        ),
      ]);
    }
  };

  const setAddedTopics = (e) => {
    if (e.length > 0) {
      setIsDisabled(false);
      setAddTopics(e);
    } else {
      setIsDisabled(true);
      setAddTopics([]);
    }
  };
  const handleLanguge = () => {
    store.dispatch(updateSpanish(!isSpanish));
    setAddTopics([]);
  };

  useEffect(() => {
    if (currentStep === 3 && locValue !== '') {
      setIsDisabled(false);
    } else if (currentStep === 3 && locValue === '') {
      setIsDisabled(true);
    } else if (currentStep === 1 && addTopics?.length > 0) {
      setIsDisabled(false);
    } else if (currentStep === 2) {
      setIsDisabled(false);
    }
  }, [currentStep, locValue]);

  const handleThrottleSearch = async (data) => {
    if (!data.trim() && !isNaN(+data)) {
      return;
    }
    try {
      let response = await fetch(global?.config?.QA_LOC_URL + data);

      let responseJson = await response.json();
      if (responseJson.code === 200) {
        setSuggestions(responseJson?.payload?.location);
        return;
      } else {
        setSuggestions([]);
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };
  const locationChange = (data) => {
    handleThrottleSearch(data);

    setLocation(data);
    setLocValue('');
  };
  const locationValue = (data) => {
    setLocValue(data);
    if (currentStep === 3 && locValue !== '') {
      setIsDisabled(false);
    } else if (currentStep === 3 && locValue === '') {
      setIsDisabled(true);
    }
  };
  useEffect(() => {
    enquire.register('screen and (max-width:767px)', () => {
      setMobileViewport(true);
    });
    enquire.register('screen and (min-width:767px)', () => {
      setMobileViewport(false);
    });
  }, []);

  useEffect(() => {
    switch (currentStep) {
      case 1:
        setTitles({
          title: aemData?.registerModule?.registerTitle1,
          subtitle: aemData?.registerModule?.registersubTitle1,
        });
        userRegistrationStep(
          aemData?.registerModule?.registerTitle1,
          currentStep,
          addTopics
        );

        break;
      case 2:
        setTitles({
          title: aemData?.registerModule?.registerTitle2,
          subtitle: aemData?.registerModule?.registersubTitle2,
        });
        console.log('title2');
        userRegistrationStep(
          aemData?.registerModule?.registerTitle2,
          currentStep,
          addTopics
        );

        break;
      case 3:
        setTitles({
          title: aemData?.registerModule?.registerTitle3,
          subtitle: aemData?.registerModule?.registersubTitle3,
        });
        console.log('title3');
        userRegistrationStep(
          aemData?.registerModule?.registerTitle3,
          currentStep,
          addTopics
        );

        break;
      case 4:
        setTitles({
          title: aemData?.registerModule?.registerTitle4,
          subtitle: '',
        });
        console.log('title4');
        userRegistrationStep(
          aemData?.registerModule?.registerTitle4,
          currentStep,
          addTopics
        );

        break;
      default:
        setTitles({
          title: '',
          subtitle: '',
        });
    }
  }, [currentStep]);

  return (
    <>
      <div className="register-container pt-8X">
        {viewNotification && (
          <div className="notification-container mb-8X">
            <Notification
              type="error"
              title={aemData?.registerModule?.notificationTitleTxt}
              subtitle={aemData?.registerModule?.notificationMsgTxt}
              surface="light"
              disableAnimation={false}
              fullBleed={false}
              hideCloseButton={false}
              disableFocus={true}
              inline={false}
              layout="vertical"
              buttonData={[
                {
                  children: 'Sign in',
                  href: global.config.CIAM_SIGN_IN_URL,
                },
              ]}
            />
          </div>
        )}

        <div
          className={`title-container ${titles.subtitle !== '' ? 'mb-3X' : ''}`}
        >
          <Title
            size={mobileViewport ? 'XLarge' : 'large'}
            bold={true}
            color="#000000"
          >
            {titles.title}
          </Title>
        </div>
        <div className="widthfix">
          <Title size={mobileViewport ? 'medium' : 'small'} bold={false}>
            {titles.subtitle}
          </Title>
        </div>

        {currentStep === 1 && aemData?.registerModule?.orOption.length > 0 && (
          <div className="chips-container mt-8X sm-mt-6X">
            <MultiselectChips
              optionItems={topicsName}
              selected={addTopics}
              onChange={(e) => setAddedTopics(e)}
              maxSelect={3}
              orOption={aemData?.registerModule?.orOption}
            />
          </div>
        )}
        {currentStep === 2 && (
          <div className="chips-container mt-8X sm-mt-6X">
            <CheckboxGroup
              data={childTiles}
              onChange={(e) => addCheckboxValues(e)}
            />
            <div className="u-textLeft mv-4X">
              <Body size="small" color="#000000" bold={true}>
                or
              </Body>
            </div>
            <Checkbox
              name="default"
              width="auto"
              disabled={false}
              error={false}
              value="no selected"
              selected={isSelected}
              onChange={(e) => addCheckboxValues(e)}
            >
              {aemData.registerModule.orOptionForGroup}
            </Checkbox>
          </div>
        )}
        {currentStep === 3 && (
          <div className="autocomplete-container mt-8X sm-mt-6X max-w-384x">
            <Autocomplete
              errorMsg={aemData?.registerModule?.errorLocusApiTxt}
              suggestions={suggestions}
              inputValue={locationChange}
              locationValue={locationValue}
              locValue={locValue}
            />
          </div>
        )}
        {currentStep === 4 && (
          <div className="input-container mt-8X sm-mt-6X ">
            <div className="mb-6X">
              <Input
                type="text"
                label={
                  aemData?.registerModule?.passwordValidator?.businessNameLbl
                }
                readOnly={false}
                required={false}
                disabled={false}
                error={false}
                onChange={(e) => setBusName(e.target.value)}
              />
            </div>
            <div className="mb-6X n-textLeft">
              <EmailInputComponent
                type="text"
                label={aemData?.registerModule?.passwordValidator?.emailLabel}
                readOnly={false}
                required={true}
                disabled={false}
                errorText={
                  aemData?.registerModule?.passwordValidator?.pwdErrorTxt
                }
                //onChange={(e) => setEmailValue(e.target.value)
                childToParent={emailChange}
              />
            </div>
            <div className="mb-1X">
              <PasswordValidatorComponent
                type="password"
                label={aemData?.registerModule?.passwordValidator?.passwordlbl}
                readOnly={false}
                required={true}
                disabled={false}
                error={false}
                success={true}
                rules={ruless}
                errorText={aemData?.registerModule?.passwordValidator?.errorTxt}
                helperText={
                  aemData?.registerModule?.passwordValidator?.helperText
                }
                helperTextPlacement="bottom"
                // onChange={(e) => console.log(e)}
                childToParent={passwordChange}
                tooltip={{
                  title:
                    aemData?.registerModule?.passwordToolTip?.passwordTitleTxt,
                  children: (
                    <>
                      {/* <div><span>In order to meet security requirements, all passwords must: </span>
                                                    </div>
                                                    {'\n'}  */}
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ paddingBottom: '12px' }}>
                          <Micro size="medium">
                            {
                              aemData?.registerModule?.passwordToolTip
                                ?.passwordSubTitleTxt
                            }
                          </Micro>
                        </div>

                        <UnorderedList>
                          {aemData.registerModule.passwordToolTip?.passwordTips.map(
                            (tab) => {
                              return (
                                <ListItem size="micro" spacing="compact">
                                  {tab}
                                </ListItem>
                              );
                            }
                          )}
                        </UnorderedList>
                        <div style={{ paddingTop: '12px' }}>
                          <Micro size="medium">
                            <span style={{ fontWeight: 'bold' }}>
                              {aemData?.registerModule?.passwordToolTip?.tipTxt}
                            </span>
                            {
                              aemData?.registerModule?.passwordToolTip
                                ?.tipDesTxt
                            }
                          </Micro>
                        </div>
                      </div>
                    </>
                  ),
                }}
              />
            </div>
            <div className="captcha-container">
              <ReCAPTCHA
                className="g-recaptcha"
                sitekey="6LcrdOQnAAAAAD1PsP7jnISXareUNjSCNyLmCZ89"
                onChange={verifyToken}
              />
            </div>
          </div>
        )}

        <div className="button-container mt-12X sm-mt-8X mb-8X">
          <ButtonGroup
            childWidth={'100%'}
            viewport={'desktop'}
            rowQuantity={{ desktop: 2 }}
            data={
              currentStep > 1
                ? currentStep === 4
                  ? [buttonGroup[0], buttonGroup[2]]
                  : [buttonGroup[0], buttonGroup[1]]
                : [buttonGroup[1]]
            }
            alignment={'center'}
          />
        </div>
        {currentStep === 1 && (
          <TextLink
            type="standAlone"
            bold="true"
            size="small"
            className="n-bold"
            onClick={() => handleLanguge()}
          >
            
          </TextLink>
        )}
        <div className="stepper-container">
          <StepIndicatorComponent
            step={currentStep}
            totalStep={4}
            barHeight={4}
          />
        </div>
        {currentStep === 4 && (
          <div className="n-textCenter terms-bottom-text mh-auto mv-4X">
            {aemData?.commonTxt?.Registertxt}
            <br />
            <TextLink
              type="standAlone"
              bold="true"
              size="small"
              className="n-bold"
              onClick={(e) => alert('TextLink clicked')}
            >
              {aemData?.commonTxt?.termsTxt}
            </TextLink>{' '}
            and{' '}
            <TextLink
              type="standAlone"
              size="small"
              className="n-bold"
              onClick={(e) => alert('TextLink clicked')}
            >
              {aemData?.commonTxt?.privacy}
            </TextLink>
          </div>
        )}
      </div>
    </>
  );
};

export default RegisterStep1Component;
