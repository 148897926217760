import React from "react";
import { Title } from "@vds/typography";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import './SuperBowlEditorial.scss';
import { TextLink } from "@vds/buttons";
import { useNavigate } from "react-router-dom";

const SuperBowlEditorialCard = ({ props }) => {
    const isMobile = useSelector((state) => state.MediaType.isMobile);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    return (
        <>
            <div className={props?.imagePosition === "left" ? 'superBowl-container-1' : 'superBowl-container-2'} style={{ backgroundColor: props?.backgroundColor }}>
                <div className='SuperBowl-Editorial-image'>
                <img src={isMobile ? props?.mobileImageUrl : props?.desktopImageUrl} height={'100%'} width={'100%'} />
                </div>
                <div className='superBowl-Editorial-Text'>
                    <div className="mb-3X">
                        <Title size="XLarge" bold={true}>
                        {t(props?.title)}
                        </Title>
                    </div>
                    <div className="mt-2X">
                        <Title size="medium">{t(props?.subTitle)}</Title>
                        {props.hyperLink && <TextLink ariaLabel={t('LegalDisclaimerAccessibilityText')} onClick={()=>{navigate('/super-bowl?scroll_to=promotion_details', { replace: true })}} size="small"  type="standAlone">{t('Legal disclaimer')}</TextLink>}
                    </div>
                </div>
            </div >
        </>
    )
}

export default SuperBowlEditorialCard;