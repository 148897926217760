import React from 'react';
import "./Hero.scss";
import { Col, Grid, Row } from "@vds/grids";
// import { slackImage } from '../images/slack.svg';
// import { coverImage } from '../images/getty.jpg';
import { Title, Body, Feature } from '@vds/typography';
import { Button, TextLinkCaret } from '@vds/buttons';
import { TileContainer } from '@vds/tiles';
import { isMobile, isDesktop } from 'react-device-detect';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import store from '../../../store/store';
import { updateRegisterTriggeredFrom } from '../../../store/aem';

const Hero = ({ props}) => {
  const urlForUserImage = global.config.aemURL + "/content/dam/vsbdr/dashboard_images/user_avatar.png";
  const navigate = useNavigate();

  return (
    <div className='network-hero-section'>
      <Grid
        bleed="1440"
        colSizes={{
          mobile: 4,
          tablet: 12,
          desktop: 12,
        }}
      >
        <Row>
          <Col>
            <div className='content-block mb-12X sm-mb-8X'>
              <div className='slack-image mb-6X'>
                <img src={`${props.contentBlockImage}`} />
              </div>
              <div className='mb-6X sm-mb-4X'>
                <Feature
                  primitive='h1'
                  size="small"
                  color="#000000"
                  bold={true}>
                  {props.heading}
                </Feature>
              </div>
              <div className='mb-8X'>
                <Title
                  size="large"
                  color="#000000"
                  bold={false}>
                  {props.title}
                </Title>
              </div>
              <div className='mb-6X community-button'>
                <Button
                  size="large"
                  surface="light"
                  disabled={false}
                  use="primary"
                  onClick={e => {store.dispatch(updateRegisterTriggeredFrom(location.pathname));
                    localStorage.setItem('prevUrl',window.location.href);
                    navigate('/onboarding', { replace: true });
                    }}
                  alignment={'center'} >
                 {t('Register now')}
                </Button>
              </div>
              <div className='community-link'>
                <TextLinkCaret
                  surface="light"
                  disabled={false}
                  iconPosition="right"
                  onClick={e => window.scrollTo({top: document.getElementById('networking-guidelines').offsetTop, behavior: 'smooth'})}>
                 {props.communityGuidelines}
                </TextLinkCaret>

              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className='image-block mb-24X sm-mb-16X'>
              <TileContainer
                padding='0'
                height={isMobile ? '218px' : '480px'}
                width='100%'
                backgroundImage={props.backgroundImage}
              >
              </TileContainer>
            </div>
          </Col>
        </Row>
      </Grid>
    </div>
  )
};

export default Hero;