import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import Autocomplete from '../../../../elements/autocomplete/autocomplete';
import { updateRegistrationStepLocation } from '../../../../store/aem';
import store from '../../../../store/store';
import './LocationSelectionComponent.scss';
import { Body } from '@vds/typography';
const LocationSelectionComponent = (props) => {
  const { aemData,setZipCode } = props;
  console.log("Initail loading")
  const businessData = useSelector(
    (state) => state.Aem.registrationStepsData.businessData
  );
  const [selectedLocation, setSelectedLocation] = useState('');
  const [locationSearchText, setLocationSearchText] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetchLocations = async (data) => {
       try {
          if(data.length>=2){
          setIsLoading(true);
          let response = await fetch(
            global.config.QA_LOC_URL + data
          );
          let responseJson = await response.json();
          if (responseJson.code === 200) {
            setSuggestions(responseJson?.payload?.location);
            setIsLoading(false);
            return;
          } else {
            setIsLoading(false);
            setSuggestions([]);
            return;
          }
       }
       } catch (error) {
          console.error(error);
        }
      };

   useEffect(() => {
    if (locationSearchText.trim() && !isNaN(+locationSearchText)) {
   }}, [locationSearchText,suggestions]);

  useEffect(() => {
    console.log('businessData => ', businessData);
    const { city, state, zipcode } = businessData;

    if (city && state && zipcode) {
      const selectedLocationValue = zipcode + ' ' + city + ',' + state;
      console.log('selectedLocationValue => ', selectedLocationValue);
      setSelectedLocation(selectedLocationValue);
    }
  }, [businessData]);

  const extractAndUpdateSelectedLocationDetails = (data) => {
      if (data) {
      const state = data.split(',')[1];
      const cityAndZipcode = data.split(',')[0];
      let zipcode = '';
      let city = '';

      cityAndZipcode.split('').forEach((char) => {
        if (!isNaN(char)) {
          zipcode = zipcode + char;
        }
      });
      zipcode = zipcode.trim();
      city = cityAndZipcode.replace(zipcode, '');
      city = city.trim();

      const locationInfo = {
        city,
        state,
        zipcode,
      };
      console.log('locationInfo => ', locationInfo);
      store.dispatch(updateRegistrationStepLocation(locationInfo));
    }
    else{
      store.dispatch(updateRegistrationStepLocation(''));
      setSelectedLocation('')
    }
  };

  const updateLocationSearchText = (searchText) => {
    console.log('location ==> ', searchText);
   fetchLocations(searchText);
    setLocationSearchText(searchText);
    setSelectedLocation('');
  };
  const suggestionChange = (data) => {
    console.log('suggestionchange ==> ');

    setSuggestions([]);
  };
  return (
    <div className="autocomplete-container mt-8X sm-mt-6X max-w-384x">
      {props.label && <div className='mb-1X n-textLeft'>
        <Body size='small'>{props.label}</Body>
      </div>}
      <Autocomplete
        setZipCode={setZipCode}
        errorMsg={aemData?.registerModule?.errorLocusApiTxt}
        suggestions={suggestions}
        inputValue={updateLocationSearchText}
        locationValue={extractAndUpdateSelectedLocationDetails}
        locValue={selectedLocation}
        suggestValue={suggestionChange}
      />
    </div>
  );
};

export default LocationSelectionComponent;
