import React from 'react';
import "./Guidelines.scss";
import { Col, Grid, Row } from "@vds/grids";
import { Button } from '@vds/buttons';
import { Body, Title } from '@vds/typography';
import { UnorderedList, ListItem } from '@vds/lists';

const Guidelines = ({props}) => {
  return (<div id="networking-guidelines" className='networking-guidelines pv-24X sm-pv-16X'>
    <Grid
      bleed="1440"
      colSizes={{
        mobile: 4,
        tablet: 12,
        desktop: 12,
      }}
    >
      <Row>
        <Col>
          <div className='guideline-card'>
            <div className='col-left'>
              <div className='left-title mb-16X sm-mb-8X'>
                <Title
                  size="XLarge"
                  bold={true}
                  color="#000000">
                  {props.heading}
                </Title>
              </div>
              <img src={props.image} className='slack-image' />
            </div>
            <div className='col-right'>
              <div className='detail-item pb-12X sm-pb-8X mb-12X sm-mb-8X'>
                <div className='mb-4X'>
                  <Title
                    size="small"
                    bold={true}
                    color="#000000">
                   {props.detailItem1}
                  </Title>
                </div>
                <Body
                  size="large"
                  color="#000000"
                  bold={false}>
                   {props.detailItem2}
                  <br /><br />
                  {props.detailItem3}
                </Body>
              </div>
              <div className='detail-item pb-12X sm-pb-8X mb-12X sm-mb-8X'>
                <div className='mb-4X'>
                  <Title
                    size="small"
                    bold={true}
                    color="#000000">
                     {props.detailItem4}
                  </Title>
                </div>
                <Body
                  size="large"
                  color="#000000"
                  bold={false}>
                  <UnorderedList>
                    <ListItem
                      viewport="desktop"
                      surface="light"
                    >
                      {props.listItem1}
                    </ListItem>
                    <ListItem
                      viewport="desktop"
                      surface="light"
                    >
                      {props.listItem2}
                    </ListItem>
                    <ListItem
                      viewport="desktop"
                      surface="light"
                    >
                     {props.listItem3}
                    </ListItem>
                  </UnorderedList>
                  <br /><br />
                  {props.detailItem5}
                  <br /><br />
                  {props.detailItem6}
                </Body>
              </div>
              <div className='detail-item border-none pb-16X sm-pb-12X'>
                <div className='mb-4X'>
                  <Title
                    size="small"
                    bold={true}
                    color="#000000">
                     {props.detailItem7}
                  </Title>
                </div>
                <Body
                  size="large"
                  color="#000000"
                  bold={false}>
                  {props.detailItem8}
                </Body>
              </div>
              <Button
                size="large"
                surface="light"
                disabled={false}
                use="secondary"
                onClick={e => window.open(props.targetUrl,'_blank')}>
                {props.targetUrlText}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Grid>
  </div>);
};

export default Guidelines;