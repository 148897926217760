// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.networking-secondary-content {
  background-color: #A4E6BD;
  position: relative;
  width: 100%;
}
.networking-secondary-content .tips-wrapper {
  max-width: 1020px;
  margin: auto;
}
.networking-secondary-content .tips-wrapper .tips-header {
  border-bottom: 1px solid #000;
}
.networking-secondary-content .tips-wrapper .tips-content {
  display: flex;
  gap: 40px;
  width: 100%;
}
.networking-secondary-content .tips-wrapper .tips-content .tips-title {
  width: 384px;
}
.networking-secondary-content .tips-wrapper .tips-content .tips-description {
  width: calc(100% - 384px);
}
@media (max-width: 767px) {
  .networking-secondary-content .tips-wrapper .tips-content {
    gap: 24px;
    flex-wrap: wrap;
  }
  .networking-secondary-content .tips-wrapper .tips-content .tips-title,
  .networking-secondary-content .tips-wrapper .tips-content .tips-description {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Networking/SecondaryContent/SecondaryContent.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,kBAAA;EACA,WAAA;AACF;AACE;EACE,iBAAA;EACA,YAAA;AACJ;AACI;EACE,6BAAA;AACN;AAEI;EACE,aAAA;EACA,SAAA;EACA,WAAA;AAAN;AAEM;EACE,YAAA;AAAR;AAGM;EACE,yBAAA;AADR;AAIM;EAbF;IAcI,SAAA;IACA,eAAA;EADN;EAGM;;IAEE,WAAA;EADR;AACF","sourcesContent":[".networking-secondary-content {\n  background-color: #A4E6BD;\n  position: relative;\n  width: 100%;\n\n  .tips-wrapper {\n    max-width: 1020px;\n    margin: auto;\n\n    .tips-header {\n      border-bottom: 1px solid #000;\n    }\n\n    .tips-content {\n      display: flex;\n      gap: 40px;\n      width: 100%;\n\n      .tips-title {\n        width: 384px;\n      }\n\n      .tips-description {\n        width: calc(100% - 384px);\n      }\n\n      @media (max-width: 767px) {\n        gap: 24px;\n        flex-wrap: wrap;\n\n        .tips-title,\n        .tips-description {\n          width: 100%;\n        }\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
