import React, { useEffect } from 'react';
import { useState } from 'react'
import './multiselectChips.scss'
import { Micro } from '@vds/typography'
import { useTranslation } from 'react-i18next';

const MultiselectChips = ({ optionItems, selected, onChange, maxSelect, orOption, showOrOption, ariaLabel }) => {
  const [selectedChips, setSelectedChips] = useState(selected || [])
  const { t, i18n } = useTranslation();
  useEffect(() => {
    setSelectedChips(selected || []);
  }, [selected]);

  const selectChips = (item) => {
    console.log("item",item);
    console.log("orOption",orOption);
    if (selectedChips.filter((selected) => selected.label === item.label).length > 0) {
      if (item.label === orOption[0].label) {
        setSelectedChips([
          ...selectedChips.filter((selected) => selected.label !== item.label),
        ]);
        onChange([
          ...selectedChips.filter((selected) => selected.label !== item.label),
        ]);
      } else {
        setSelectedChips([
          ...selectedChips.filter((selected) => selected.label !== item.label),
        ]);
        onChange([
          ...selectedChips.filter((selected) => selected.label !== item.label),
        ]);
      }
    } else if (item.label === orOption[0].label) {
      setSelectedChips([item]);
      onChange([item]);
    } else if (maxSelect ? selectedChips.length < maxSelect : true) {
      if (item.label !== orOption[0].label) {
        setSelectedChips([
          ...selectedChips.filter(
            (selected) => selected.label !== orOption[0].label
          ),
          item,
        ]);
        onChange([
          ...selectedChips.filter(
            (selected) => selected.label !== orOption[0].label
          ),
          item,
        ]);
      }
    }
  };

  const onKeyDown = (event, item) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
              selectChips(item);
          }
  }
  const options = optionItems;
  console.log(options, selected, selectedChips);
  return (
    <>
      <div className='chip-container'>
        {
          options.map((item,index) => {
            return (
              <div role='button' onKeyDown={(e) => { onKeyDown(e, item) }} key={item.label} className={(selectedChips.filter(selected => selected.label === item.label).length > 0) 
                ? 'chip fs-3X ph-4X pv-2X chip-selected' : 'chip fs-3X ph-4X pv-2X chip-unselected'} onClick={() => { selectChips(item) }} tabIndex="0" 
                aria-label={`${(index==0) ? ariaLabel:''} ${selectedChips.length >= 3 ? "Only three Buttons can be selected" : selectedChips.filter(
                (selected) => selected.label === item.label
             ).length > 0
                ? "selected"+item.label
              : "unselected"+item.label}`}>
                <Micro bold={true}>{t(item.label)}</Micro>
              </div>
            )
          })
        }
        {showOrOption &&
          <>
            <div className='fs-3X ph-4X pv-2X mt-2x u-flex'>
              <Micro bold={true}>or</Micro>
            </div>
            <div role="button"
            aria-label={(selectedChips.filter(selected => selected.label === orOption[0].label).length > 0) ? 'selected'+orOption[0].label : 'unselected' +orOption[0].label}
            className={(selectedChips.filter(selected => selected.label === orOption[0].label).length > 0) ? 'chip fs-3X ph-4X pv-2X chip-selected' : 'chip fs-3X ph-4X pv-2X chip-unselected'} onClick={() => { selectChips(orOption[0]) }} tabIndex="0">
              <Micro bold={true}>{orOption[0].label}</Micro>
            </div>
          </>
        }
      </div>
    </>
  )
}

export default MultiselectChips