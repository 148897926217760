// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tileContainer {
  padding: "12px";
}

.resourceCard .description {
  position: absolute;
  top: 12px;
  width: 100%;
  padding: 10px;
  margin-left: 5px;
}

.resourceCard .description .label {
  width: 80%;
}

.resourceCard .imageDisplay {
  position: flex;
  bottom: 12px;
}

.resourceCard .imageDisplay img {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/elements/ResourceCardComponent.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AACA;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EACA,aAAA;EACA,gBAAA;AAEF;;AAAA;EACE,UAAA;AAGF;;AAAA;EACE,cAAA;EACA,YAAA;AAGF;;AAAA;EACE,WAAA;AAGF","sourcesContent":[".tileContainer {\n  padding: '12px';\n}\n.resourceCard .description {\n  position: absolute;\n  top: 12px;\n  width: 100%;\n  padding:10px;\n  margin-left:5px;\n}\n.resourceCard .description .label {\n  width: 80%;\n}\n\n.resourceCard .imageDisplay {\n  position: flex;\n  bottom: 12px;\n}\n\n.resourceCard .imageDisplay img {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
