// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-badge-wrap {
  display: flex;
  align-items: flex-start;
}
.filter-badge-wrap .filter-icon {
  cursor: pointer;
}
.filter-badge-wrap .filter-badges {
  display: inline-flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.filter-badge-wrap .filter-badges .disabled .chip.chip-unselected {
  border-color: #d8d8d8;
}
.filter-badge-wrap .filter-badges .disabled .chip.chip-unselected p {
  color: #d8d8d8;
}
.filter-badge-wrap .filter-badges .disabled .chip.chip-selected, .filter-badge-wrap .filter-badges .disabled .chip.chip-selected p {
  background-color: #d8d8d8;
  border-color: #d8d8d8;
}`, "",{"version":3,"sources":["webpack://./src/components/common/FilterBadge/FilterBadge.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;AACF;AAEE;EASE,eAAA;AARJ;AAWE;EAEE,oBAAA;EACA,uBAAA;EACA,eAAA;AAVJ;AAeO;EACC,qBAAA;AAbR;AAcQ;EACE,cAAA;AAZV;AAgBO;EACC,yBAAA;EACA,qBAAA;AAdR","sourcesContent":[".filter-badge-wrap {\n  display: flex;\n  align-items: flex-start;\n  // width: 100%;\n\n  .filter-icon {\n    // background-color: #F6F6F6;\n    // min-width: 32px;\n    // width: 32px;\n    // height: 32px;\n    // display: inline-flex;\n    // align-items: center;\n    // justify-content: center;\n    // border-radius: 100px;\n    cursor: pointer;\n  }\n\n  .filter-badges {\n    // width: 100%;\n    display: inline-flex;\n    align-items: flex-start;\n    flex-wrap: wrap;\n    // background-color: blue;\n\n    .disabled {\n      // background-color: red;\n       .chip.chip-unselected {\n        border-color: #d8d8d8;\n        p{\n          color: #d8d8d8;\n\n        }\n      }\n       .chip.chip-selected, .chip.chip-selected p {\n        background-color: #d8d8d8;\n        border-color: #d8d8d8;\n\n      }\n    }\n    \n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
