// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resourse-tag {
  padding: 8px 12px 8px 12px;
  border-radius: 8px 0px 8px 0px;
  width: fit-content;
  box-shadow: 2px 10px 10px 0px rgba(0, 0, 0, 0.1019607843);
  opacity: 0px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/ResourceTag/resourceTag.scss"],"names":[],"mappings":"AAAA;EACI,0BAAA;EACA,8BAAA;EACA,kBAAA;EACA,yDAAA;EACA,YAAA;AACJ","sourcesContent":[".resourse-tag {\n    padding: 8px 12px 8px 12px;\n    border-radius: 8px 0px 8px 0px;\n    width: fit-content;\n    box-shadow: 2px 10px 10px 0px #0000001A;\n    opacity: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
