import { createSlice } from '@reduxjs/toolkit';

export const mediatype = createSlice({
  name: 'mediatype',
  initialState: {
    isMobile: false,
    isIpad: false,
    isLowResolutionDesktop: false,
  },

  reducers: {
    updateIsMobile: (state, action) => {
      console.log(action);
      state.isMobile = action.payload;
    },
    updateIsIpad: (state, action) => {
      console.log(action);
      state.isIpad = action.payload;
    },
    updateIsLowResolutionDesktop: (state, action) => {
      console.log(action);
      state.isLowResolutionDesktop = action.payload;
    },
  },
});

export const { updateIsMobile, updateIsIpad, updateIsLowResolutionDesktop } = mediatype.actions;

export default mediatype.reducer;
