// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.superbowl-landing-wrapper {
  position: relative;
}
.superbowl-landing-wrapper .superbowl-promo-landing {
  max-width: 914px;
  margin: 0px auto;
  margin-top: -48px;
  border-radius: 32px;
  display: flex;
  align-items: center;
  gap: 24px;
  text-align: left;
}
.superbowl-landing-wrapper .btnn-ico {
  height: 44px;
  width: 44px;
}
@media screen and (max-width: 767px) {
  .superbowl-landing-wrapper .superbowl-promo-landing {
    margin-left: 16px;
    margin-right: 16px;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

.SuperBowl-Image-grants {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}`, "",{"version":3,"sources":["webpack://./src/components/specialGrants/superbowlGrantLanding.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AACI;EACI,gBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;EACA,gBAAA;AACR;AAEI;EACI,YAAA;EACA,WAAA;AAAR;AAGI;EACA;IACI,iBAAA;IACA,kBAAA;IACA,sBAAA;IACA,mBAAA;IACA,kBAAA;EADN;AACF;;AAMA;EAGI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,2BAAA;AALJ","sourcesContent":[".superbowl-landing-wrapper {\n    position: relative;\n\n    .superbowl-promo-landing {\n        max-width: 914px;\n        margin: 0px auto;\n        margin-top: -48px;  \n        border-radius: 32px;\n        display: flex;\n        align-items: center;\n        gap: 24px;\n        text-align: left;\n\n    }\n    .btnn-ico{\n        height: 44px;\n        width: 44px;\n    }\n\n    @media screen and (max-width: 767px) {\n    .superbowl-promo-landing {\n        margin-left: 16px;\n        margin-right: 16px;\n        flex-direction:column ;\n        align-items: center;\n        text-align: center;\n    }\n\n    }\n}\n\n.SuperBowl-Image-grants {\n    // height: 196px;\n    // width: 200px;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: flex-start;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
