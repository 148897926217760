// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tips-and-resources {
  background-color: #f6f6f6;
  height: 100%;
  width: 100%;
}
.tips-and-resources .button-area {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tips-and-resources .slotContainer > div {
  scroll-snap-align: center !important;
}

.landing-container .jCmIaz, .landing-container .kPfjuO, .landing-container .eLSqpv {
  width: 100%;
}
.landing-container .eLSqpv .slotContainer:last-child {
  padding-right: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/TipsAndResourcesComponent.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,YAAA;EACA,WAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ;AACE;EACE,oCAAA;AACJ;;AAGE;EACE,WAAA;AAAJ;AAEE;EACE,gBAAA;AAAJ","sourcesContent":[".tips-and-resources {\n  background-color: #f6f6f6;\n  height: 100%;\n  width: 100%;\n\n  .button-area {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n  .slotContainer > div {\n    scroll-snap-align: center !important;\n  }\n}\n.landing-container {\n  .jCmIaz, .kPfjuO, .eLSqpv {\n    width: 100%;\n  }\n  .eLSqpv .slotContainer:last-child {\n    padding-right: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
