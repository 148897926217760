import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import MultiselectChips from '../../../../elements/multiselectChips/multiselectChips';
import { updateRegistrationStepTopics } from '../../../../store/aem';
import store from '../../../../store/store';
import './TopicsSelectionComponent.scss';

const TopicsSelectionComponent = (props) => {
  const { aemData } = props;

  const selectedTopics = useSelector(
    (state) => state.Aem.registrationStepsData.businessData.topics
  );

  const [topicsName, setTopicsName] = useState([]);
  const [topicsNameFromSelectedTopics, setTopicsNameFromSelectedTopics] =
    useState([]);

  useEffect(() => {
    if (aemData && aemData?.registerModule && aemData?.registerModule?.topics) {
      const topicsNames = aemData?.registerModule?.topics?.map((card) => ({
        id: card.id,
        label: card.label,
        data: card.data,
      }));

      setTopicsName(topicsNames);
    }
  }, [aemData, setTopicsName]);

  useEffect(() => {
    console.log('selectedTopics => ', selectedTopics);
    if (topicsName && selectedTopics && selectedTopics.length > 0) {
      const selectedTopicsNames = topicsName.filter((x) =>
        selectedTopics.includes(x.data)
      );

      if (selectedTopics.includes("I'm not sure") || selectedTopics.includes("No estoy seguro"))  {
        selectedTopicsNames.push({
          id: aemData?.registerModule?.orOption[0].id,
          label: aemData?.registerModule?.orOption[0].label,
          data: aemData?.registerModule?.orOption[0].data,
        });
      }
      console.log('selectedTopicsNames => ', selectedTopicsNames);
      setTopicsNameFromSelectedTopics(selectedTopicsNames);
    }else{
      setTopicsNameFromSelectedTopics([]);
    }
  }, [selectedTopics, aemData, topicsName]);

  const checkAndAddTopic = (e) => {
    console.log('selected topics => ', e);
    if (e.length > 0) {
      const selectedTopicsDataNames = e.map((topic) => topic.data);
      console.log('selectedTopicsDataNames => ', selectedTopicsDataNames);
      store.dispatch(updateRegistrationStepTopics(selectedTopicsDataNames));
    } else {
      store.dispatch(updateRegistrationStepTopics([]));
    }
  };

  return (
    aemData?.registerModule?.orOption.length > 0 && (
      <div className="chips-container mt-8X sm-mt-6X">
        <MultiselectChips
          optionItems={topicsName}
          selected={topicsNameFromSelectedTopics}
          onChange={(e) => checkAndAddTopic(e)}
          maxSelect={3}
          orOption={aemData?.registerModule?.orOption}
          showOrOption={true}
        />
      </div>
    )
  );
};

export default TopicsSelectionComponent;
