import React from 'react';
import "./Spotlight.scss";
import { Col, Grid, Row } from "@vds/grids";
import { Body, Title } from '@vds/typography';
import { TextLink } from '@vds/buttons';

const Spotlight = ({props}) => {
  return (
    <div className='networking-spotlight pv-24X sm-pv-16X'>
      <Grid
        bleed="1440"
        colSizes={{
          mobile: 4,
          tablet: 12,
          desktop: 12,
        }}
      >
        <Row>
          <Col>
            <div className='spotlight-card'>
              <div className='spotlight-header mb-16X sm-mb-8X'>
                <Title
                  size="XLarge"
                  bold={true}
                  color="#000000">
                 {props.heading}
                </Title>
              </div>
              <div className='spotlight-container'>
                <div className='bio-wrapper'>
                  <img src={props.image} className='bio-image mb-12X sm-mb-8X' />
                  <div className='bio-name mb-3X sm-mb-2X'>
                    <Title
                      size="large"
                      bold={true}
                      color="#000000">
                     {props.title}
                    </Title>
                  </div>
                  <div className='bio-info'>
                    <Body
                      size="large"
                      color="#000000"
                      bold={false}>
                      {props.bioInfo}
                    </Body>
                  </div>
                </div>
                <div className='spotlight-content'>
                  <div className='detail-item pb-8X sm-pb-6X mb-8X sm-mb-6X'>
                    <div className='mb-3X sm-mb-2X'>
                      <Title
                        size="large"
                        bold={true}
                        color="#000000">
                       {props.detailItem1}
                      </Title>
                    </div>
                    <div className=''>
                      <Title
                        size="small"
                        bold={false}
                        color="#000000">
                        {props.detailItem2}
                      </Title>
                    </div>
                  </div>
                  <div className='detail-item pb-8X sm-pb-6X mb-8X sm-mb-6X'>
                    <div className='mb-3X sm-mb-2X'>
                      <Title
                        size="large"
                        bold={true}
                        color="#000000">
                        {props.detailItem3}
                      </Title>
                    </div>
                    <div className=''>
                      <Title
                        size="small"
                        bold={false}
                        color="#000000">
                        {props.detailItem4}
                      </Title>
                    </div>
                  </div>
                  <div className='detail-item pb-8X sm-pb-6X mb-8X sm-mb-6X'>
                    <div className='mb-3X sm-mb-2X'>
                      <Title
                        size="large"
                        bold={true}
                        color="#000000">
                         {props.detailItem5}
                      </Title>
                    </div>
                    <div className=''>
                      <Title
                        size="small"
                        bold={false}
                        color="#000000">
                     {props.detailItem6}
                      </Title>
                    </div>
                  </div>
                  <div className='detail-item pb-8X sm-pb-6X mb-8X sm-mb-6X'>
                    <div className='mb-3X sm-mb-2X'>
                      <Title
                        size="large"
                        bold={true}
                        color="#000000">
                       {props.detailItem7}
                      </Title>
                    </div>
                    <div className=''>
                      <Title
                        size="small"
                        bold={false}
                        color="#000000">
                        {props.detailItem8}
                      </Title>
                    </div>
                  </div>
                  <div className=''>
                    <Title
                      size="small"
                      color="#000000"
                      bold={false}>
                      <TextLink
                        type="inline"
                        surface="light"
                        disabled={false}
                        ariaLabel={props.targetUrlText}
                        role='link'
                        bold={true}
                        onClick={e => window.open(props.targetUrl,'_blank')}>
                        <b>{props.targetUrlText}</b>
                      </TextLink>&nbsp;
                      {props.item}
                    </Title>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default Spotlight;