import './stepper.scss';

const StepIndicatorComponent = ({ step, totalStep, barHeight }) => {
  return (
    <div className="mv-4X">
    <div className='fs-3X'> 
                {step}/{totalStep}
            </div>
            <div className="step-container mt-3X" style={{height: barHeight + 'px'}}>
                <div className='step-indicator' style={{width: step*100/totalStep + '%'}}/>
            </div>
         </div>
  );
};

export default StepIndicatorComponent;
