export const offers =
  [
    {
      name: 'Offer description (50 character maximum)',
      image: 'https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/mobile/Image-1.png',
      offerType: 'Smartphones',
      description: 'Lorem ipsum dolor  ',
      locked: false
    },
    {
      name: 'Offer description (50 character maximum)',
      image: 'https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/mobile/Image-1.png',
      offerType: 'Smartphones',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
      locked: false
    },
    {
      name: 'Offer description (50 character maximum)',
      image: 'https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/mobile/Image-1.png',
      offerType: 'Email marketing',
      description: 'Lorem ipsum dolor sit amet consectetur.Congue tempus pulvinar proin fringilla eu nun    ',
      locked: true
    },
    {
      name: 'Offer description (50 character maximum)',
      image: 'https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/mobile/Image-1.png',
      offerType: 'Taxes & accounting',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.    ',
      locked: true
    }
  ];

export const offersBG =
  [
    {
      name: 'Offer description (50 character maximum)',
      image: 'https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/mobile/Image-1.png',
      offerType: 'Smartphones',
      description: 'Lorem ipsum dolor  ',
      locked: false,
      bg: 'bg-white'
    },
    {
      name: 'Offer description (50 character maximum)',
      image: 'https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/mobile/Image-1.png',
      offerType: 'Smartphones',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
      locked: false,
      bg: 'bg-white'
    },
    {
      name: 'Offer description (50 character maximum)',
      image: 'https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/mobile/Image-1.png',
      offerType: 'Email marketing',
      description: 'Lorem ipsum dolor sit amet consectetur.Congue tempus pulvinar proin fringilla eu nun    ',
      locked: true,
      bg: 'bg-white'
    },
    {
      name: 'Offer description (50 character maximum)',
      image: 'https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/mobile/Image-1.png',
      offerType: 'Taxes & accounting',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.    ',
      locked: true,
      bg: 'bg-white'
    }
  ];

export const offersList =
  [
    {
      name: 'Offer description goes here (86 character max) lor ipsum dolor sit am quo vadis consect',
      image: 'https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/mobile/Image-1.png',
      offerType: 'Smartphones',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
      locked: false
    },
    {
      name: 'Offer description (50 character maximum)',
      image: 'https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/mobile/Image-1.png',
      offerType: 'Smartphones',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
      locked: false
    },
    {
      name: 'Offer description (50 character maximum)',
      image: 'https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/mobile/Image-1.png',
      offerType: 'Email marketing',
      description: 'Lorem ipsum dolor sit amet consectetur.Congue tempus pulvinar proin fringilla eu nun    ',
      locked: true
    },
    {
      name: 'Offer description (50 character maximum)',
      image: 'https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/mobile/Image-1.png',
      offerType: 'Taxes & accounting',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.    ',
      locked: true
    }, {
      name: 'Offer description (50 character maximum)',
      image: 'https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/mobile/Image-1.png',
      offerType: 'Smartphones',
      description: 'Lorem ipsum dolor  ',
      locked: false
    },
    {
      name: 'Offer description (50 character maximum)',
      image: 'https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/mobile/Image-1.png',
      offerType: 'Smartphones',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
      locked: false
    },
    {
      name: 'Offer description (50 character maximum)',
      image: 'https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/mobile/Image-1.png',
      offerType: 'Email marketing',
      description: 'Lorem ipsum dolor sit amet consectetur.Congue tempus pulvinar proin fringilla eu nun    ',
      locked: true
    },
    {
      name: 'Offer description (50 character maximum)',
      image: 'https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/mobile/Image-1.png',
      offerType: 'Taxes & accounting',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.    ',
      locked: true
    }, {
      name: 'Offer description (50 character maximum)',
      image: 'https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/mobile/Image-1.png',
      offerType: 'Smartphones',
      description: 'Lorem ipsum dolor  ',
      locked: false
    },
    {
      name: 'Offer description (50 character maximum)',
      image: 'https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/mobile/Image-1.png',
      offerType: 'Smartphones',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
      locked: false
    },
    {
      name: 'Offer description (50 character maximum)',
      image: 'https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/mobile/Image-1.png',
      offerType: 'Email marketing',
      description: 'Lorem ipsum dolor sit amet consectetur.Congue tempus pulvinar proin fringilla eu nun    ',
      locked: true
    },
    {
      name: 'Offer description (50 character maximum)',
      image: 'https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/mobile/Image-1.png',
      offerType: 'Taxes & accounting',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.    ',
      locked: true
    }
  ];

export const  updatedOffers=[
  {
      "id": "tablets1",
      "category": "Tablets",
      "partner": "Verizon",
      "summary": {
          "title": "Get help with connectivity & productivity in one value bundle.",
          "description": [
              "Digitize and improve your business operations with a locked down tablet, Jobber productivity app and an unlimited business data plan."
          ],
          "ctaText": "Learn more",
          "icon": "https://mb.verizonwireless.com/content/dam/vsbdr/images/incentives/verizon/offers_logo.png",
          "media": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/incentives/offers/Tablet_Logo1.png"
      },
      "detail": {
          "title": "Get help with connectivity & productivity in one value bundle.",
          "description": [
              "Do you have a team working on-site at multiple locations that you need to track and manage? Are you using paper forms or multiple tools to quote and invoice customers? With the Verizon field management tablet bundle you can save on costs and time with a rugged tablet, unlimited data, and pre - loaded Jobber field services management application ",
              "How it Works:",
              "1. Get a dedicated, Verizon-connected device with pre-installed productivity applications.",
              "2. Verizon will get you started with free support for easy software features, like...",
              "- Quoting",
              "- Job Tracking",
              "- Customer & staff communications",
              "- Invoicing/payments",
              "3. The software and device are all conveniently managed together on one bill.",
              "",
              "Key business operations benefits:",
              "1. Quick quotes to book new business, track and finish jobs on time, get paid on time.",
              "2. The owner, the team, and the customer know what's happening at every step of the job. ",
              "3. Less busy work, more business growth.",
              "",
              "Take the next step:",
              "Call 800.525.8693 to get more information, a demo, or a quote for your team."
          ],
          "legal": {
              "disclaimers": []
          },
          "ctaText": "Learn more",
          "ctaLink": "https://www.verizon.com/business/resources/lp/device-deals-promotions/?cmp=int:drdy:ac:smb:sdrdy:digital_ready&utm_medium=int&utm_source=drdy&utm_campaign=sdrdy&utm_content=ac:smb:digital_ready",
          "icon": "https://mb.verizonwireless.com/content/dam/vsbdr/images/incentives/verizon/learnmore_logo.png",
          "media": "https://mb.verizonwireless.com/content/dam/vsbdr/images/incentives/vzsmbdays/learnmore_image.png"
      }
  },
  {
      "id": "tablets2",
      "category": "Tablets",
      "partner": "Verizon",
      "summary": {
          "title": "$10 off $30 Business Unlimited Tablet Start Plan",
          "description": [
              "Get unlimited data for $20 per month on your tablet."
          ],
          "ctaText": "Learn more",
          "icon": "https://mb.verizonwireless.com/content/dam/vsbdr/images/incentives/verizon/offers_logo.png",
          "media": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/incentives/offers/Tablet_Logo2.png"
      },
      "detail": {
          "title": "$10 off $30 Business Unlimited Tablet Start Plan",
          "description": [
              "Do you and your workforce use tablets to stay productive in the office and in the field? You can now get an unlimited data plan for $20 per month when you switch to Verizon.  Discount can be applied to the Business Unlimited Tablet Start Plan for tablets or Samsung Galaxy Chromebooks and there is no limit to the number of tablets you can enroll. Switch today.",
              "Here’s how it works:",
              "1. Switch to Verizon.",
              "2. Purchase a qualifying device (select storage options only) iPhone 14 , , iPhone 14 Plus , , iPhone 15, iPhone 15 Plus,, iPhone 15 Pro, iPhone 15 Pro Max, Google Pixel 8, Pixel 8 Pro, Samsung Galaxy S23 or Galaxy Z Flip5.",
              "3. Create your account online and purchase a qualifying device with a device payment agreement and new line.",
              "4. Select Business Unlimited Pro plan. ",
              "5. Trade in a qualifying smartphone. ",
              "Take the next step:",
              "Call 800.525.8693 to get more information, a demo, or a quote for your team."
          ],
          "legal": {
              "disclaimers": [
                  "Taxes, fees and Economic Adjustment Charge apply. Tablet purchase w/new 2-yr or device payment agmt & $30 Business Unlimited Tablet Start plan req’d. Bring your own tablet w/month-to-month agmt also eligible for this offer. Tablet must be compatible with VZ network. 0% APR. For each qualifying tablet line, customer will receive a $10 mo credit which will continue as long as VZ provides & customer maintains Biz Unlimited Tablet Start plan at qualifying levels. Price plan changes not eligible for this promotion. Mo plan fee after credits: $20. Biz Unlimited Tablet Start plan includes unlimited 4G LTE & 5G data; in times of congestion, your data may be temporarily slower than other traffic. 5G Ultra Wideband access is not available. Terms apply. Offer ends 3.31.2024."
              ]
          },
          "ctaText": "Learn more",
          "ctaLink": "https://www.verizon.com/business/resources/lp/device-deals-promotions/?cmp=int:drdy:ac:smb:sdrdy:digital_ready&utm_medium=int&utm_source=drdy&utm_campaign=sdrdy&utm_content=ac:smb:digital_ready",
          "icon": "https://mb.verizonwireless.com/content/dam/vsbdr/images/incentives/verizon/learnmore_logo.png",
          "media": "https://mb.verizonwireless.com/content/dam/vsbdr/images/incentives/vzsmbdays/learnmore_image.png"
      }
  },
  {
      "id": "smartphone",
      "category": "Smartphones",
      "partner": "Verizon",
      "summary": {
          "title": "Get a free 5G phone when you switch to Verizon",
          "description": [
              "Get up to $1000 off a 5G phone when you switch to Verizon and a select Business Unlimited plan and trade in an eligible device."
          ],
          "ctaText": "Learn more",
          "icon": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/incentives/verizon/offers_logo.png",
          "media": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/incentives/smartphone/Apple-iPhone-14-Pro-iPhone-14-Pro-Max-2.jpg"
      },
      "detail": {
          "title": "Get a free 5G phone",
          "description": [
              "Here’s how it works:",
              "1. Switch to Verizon.",
              "2. Create your account online and purchase a qualifying device with a device payment agreement and new line.",
              "3. Select Business Unlimited Plus and Unlimited Pro plan.",
              "4. Trade in a qualifying smartphone.",
              "Eligible devices: Apple iPhone 14, Apple iPhone 14 Plus, Google Pixel 7 Pro, Samsung Galaxy S23, Samsung Galaxy S23+, Samsung Galaxy Z Flip4"
          ],
          "legal": {
              "disclaimers": [
                  "*Qualifying purchase: iPhone 14 128GB, iPhone 15 128GB, iPhone 15 Plus 128GB, iPhone 15 Pro 128GB, Google Pixel 7 Pro 128GB, Pixel 7 Pro 256GB, Pixel 8 128GB, Pixel 8 Pro 128GB, Samsung Galaxy S23 or Galaxy S23+ 256GB. Taxes & fees apply. New line w/device payment purchase & Business Unlimited Plus or Unlimited Pro plan req’d. 0% APR. Up to $1,000 credit, varying by smartphone purchase and trade-in, applied to acct over the term of your agmt (up to 36 mos); promo credit ends when eligibility requirements are no longer met. Credit will not exceed device price. Select biz customers w/6 or more mos of VZ service: credits begin in 1-2 bills. Other biz customers: credits begin 2-3 bills after trade-in is received by VZ. Smartphone trade-in must be received by VZ w/in 90 days & meet program requirements. Credit(s) will be charged back to acct if trade-in is not received within 90 days, differs from appraisal and/or does not meet program requirements. Most trade-in device conditions accepted; exclusions apply. 10-line trade-in limit per order. Cannot be combined with other device offers. Offer ends 1.03.2024 (iPhone 15 offers end 10.18.2023)."
              ]
          },
          "ctaText": "Learn more",
          "ctaLink": "https://www.verizon.com/business/resources/lp/device-deals-promotions/?cmp=int:drdy:ac:smb:sdrdy:digital_ready&utm_medium=int&utm_source=drdy&utm_campaign=sdrdy&utm_content=ac:smb:digital_ready",
          "icon": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/incentives/verizon/learnmore_logo.png",
          "media": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/incentives/vzsmbdays/learnmore_image.png"
      },
      "order": 25
  },
  {
      "id": "smartphone",
      "category": "Smartphones",
      "partner": "Verizon",
      "summary": {
          "title": "Save $600 when you switch to Verizon and bring your own smartphone & number",
          "description": [
              "Already have phones? Perfect! When you switch to Verizon, you can keep your smartphone and number and save $600 on a select Business Unlimited 5G plan. Discount is applied to your monthly bill over 24 months."
          ],
          "ctaText": "Learn more",
          "icon": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/incentives/verizon/offers_logo.png",
          "media": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/incentives/offers/smartphone_card.png"
      },
      "detail": {
          "title": "Save $600 when you switch to Verizon and bring your own smartphone & number",
          "description": [
              "Delivering superior experiences for your customers is your top priority and that includes how you connect with them.  Keep your smartphone and number when you switch to Verizon and save $600.  Just check your eligibility, then bring your own smartphone and number and activate them on a Business Unlimited plan. It’s that easy. Here’s the deal:",
              "Choose between the Business Unlimited Start, Business Unlimited Plus or Business Unlimited Pro plans",
              "Just check your smartphone compatibility, then bring it and your number to a new line of service",
              "Enjoy a month-to-month agreement."
          ],
          "legal": {
              "disclaimers": [
                  "*New line w/month-to-month agmt & Business Unlimited Plus 5G or Unlimited Pro 5G plan req’d. Cust must transfer smartphone & number to VZ; new line activation & number transfer must be completed in one transaction. Smartphone must be compatible w/VZ network. $600 credit applied to acct. over 24 mos; promo credit ends when eligibility requirements are no longer met. Credits begin in 1-2 bills & do not appear in Biz Unlimited 5G plan fee section. Biz Unlimited 5G plan fee increases in month 25. Cust must retain smartphone & remain on selected plan in order to receive credit(s). Cannot be combined with other device offers. Business Unlimited 5G plan terms apply. Offer ends 12.31.2023."
              ]
          },
          "ctaText": "Learn more",
          "ctaLink": "https://www.verizon.com/business/products/devices/bring-your-own-device/?cmp=int:drdy:ac:smb:sdrdy:digital_ready&utm_medium=int&utm_source=drdy&utm_campaign=sdrdy&utm_content=ac:smb:digital_ready",
          "icon": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/incentives/verizon/learnmore_logo.png",
          "media": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/incentives/vzsmbdays/learnmore_image.png"
      },
      "order": 30
  },
  {
      "id": "smartphone",
      "category": "Smartphones",
      "partner": "Verizon",
      "summary": {
          "title": "Save $200 on a new smartphone as a new business customer",
          "description": [
              "As a new Verizon customer you can receive $200 off each new smartphone purchased with a new phone line and select data plan. Available online only."
          ],
          "ctaText": "Learn more",
          "icon": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/incentives/verizon/offers_logo.png",
          "media": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/incentives/offers/iphone-13-green.png"
      },
      "detail": {
          "title": "Save $200 on a new smartphone as a new business customer",
          "description": [
              "Here’s how it works:",
              "1. Qualify as a first time business customer with Verizon.",
              "2. Purchase a qualifying smartphone on a new line.",
              "3. Select a Business Unlimited Plus or Unlimited Pro plan."
          ],
          "legal": {
              "disclaimers": [
                  "*Offer available to new VZ biz customers on initial new smartphone purchase only. Acct. creation & initial purchase must be completed in one transaction. New line w/device payment agmt & $34.99 or higher price plan req’d. 0% APR. $200 credit applied to acct after 2-3 bills. Can be combined with select offers. VZ reserves the right to charge back to your acct all or a portion of the value of any promotional credit you received as part of this offer in the event you no longer meet the eligibility requirements. Any such charge back may be subject to the terms of your VZ agmt. Offer available online only through 1.03.2024."
              ]
          },
          "ctaText": "Learn more",
          "ctaLink": "https://www.verizon.com/business/products/deals/device-promotion/?cmp=int:drdy:ac:smb:sdrdy:digital_ready&utm_medium=int&utm_source=drdy&utm_campaign=sdrdy&utm_content=ac:smb:digital_ready",
          "icon": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/incentives/verizon/learnmore_logo.png",
          "media": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/incentives/offers/samsung-s23-ultra-400x400.png"
      },
      "order": 30
  }
];