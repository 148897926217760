import React from 'react';
import SuperBowlEditorialCard from './SuperBowlEditorialCard.jsx';
import { useDispatch, useSelector } from 'react-redux';

import './SuperBowlEditorial.scss';

const SuperBowlEditorial = () => {
    const aemData = useSelector((state) => state.Aem.data);

    let EditorialData = [{
        imagePosition: 'left',
        desktopImageUrl: global.config.AEM_URL + "images/superbowl/desktop/img-1-desktop.png",
        mobileImageUrl: global.config.AEM_URL + "images/superbowl/mobile/img-1-mobile.png",
        hyperLink: true,
        title: `${aemData?.superbowl?.superbowlPromoSubHeader1}`,
        subTitle: `${aemData?.superbowl?.superbowlPromoSubHeaderText1}`,
        backgroundColor: '#F3EDE0'
    },
    {
        imagePosition: 'right',
        desktopImageUrl: global.config.AEM_URL + "images/superbowl/desktop/img-2-desktop.png",
        mobileImageUrl: global.config.AEM_URL + "images/superbowl/mobile/img-2-mobile.png",
        hyperLink: true,
        title: `${aemData?.superbowl?.superbowlPromosubHeader2}`,
        subTitle: `${aemData?.superbowl?.superbowlPromoSubHeaderText2}`,
        backgroundColor: ''
    },
    {
        imagePosition: 'left',
        desktopImageUrl: global.config.AEM_URL + "images/superbowl/desktop/img-3-desktop.png",
        mobileImageUrl: global.config.AEM_URL + "images/superbowl/mobile/img-3-mobile.png",
        title: `${aemData?.superbowl?.superbowlPromoSubHeader3}`,
        subTitle: `${aemData?.superbowl?.superbowlPromoSubHeaderText3}`,
        backgroundColor: ''
    }];

    return (
        <>
            {EditorialData?.map((data, index) => {
                return (<SuperBowlEditorialCard key={index} props={data} />)
            })}
        </>
    );
};
export default SuperBowlEditorial;