import React from 'react';
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector} from 'react-redux';
import { useEffect, useState } from 'react';
import { Col, Grid, Row } from '@vds/grids';
import { BreadcrumbItem, Breadcrumbs } from '@vds/breadcrumbs';

import CategoryQuickTipsComponent from './CategoryQuickTipsComponent.jsx';
import CategoryEventsComponent from './CategoryEventsComponent.jsx';
import CategoryOffersComponent from './CategoryOffersComponent.jsx';
import CategoryCoursesComponent from './CategoryCoursesComponent.jsx';
import OffersCategoryLandingComponent from './Offers/OffersCategory.jsx'
import './CategoryPage.scss';
import { pageLoadCompleted, pageLoadStarted } from '../../common/Analytics/GAWrapper.jsx';
import { getQuickTipForcedRanking, getRecommendedCourses } from '../../utility/common.js';
import CategoryLearningPathComponent from './CategoryLearningPathComponent.jsx';
import { Loader } from '@vds/loaders';

const CategoryLandingComponent = () => {
  const allEventsWithTimeZone = useSelector((state) => state.AdobeResources.allEventsWithTimeZone);
  const allCourses = useSelector((state) => state.AdobeResources.allCourses);
  const combinedQuickTips = useSelector((state) => state.AdobeResources.allQuickTips);
  const userLanguage = localStorage.getItem("lng") ? localStorage.getItem("lng") : 'en';
  const aemData = useSelector((state) => state.Aem.data);
  const aemOffersData = useSelector((state) => state.Aem.offerData);
  const selectedTopics = useSelector(
    (state) => state.Aem.registrationStepsData.businessData.topics
  );
  let { categoryType } = useParams();
  const { t, i18n } = useTranslation();
  const [forcedRankingQuickTip, setForcedRankingQuickTip] = useState();
  const [forcedRankingCourse, setForcedRankingCourse] = useState();
  const { filterType } = useParams();
  const learningPaths = useSelector((state) => state.AdobeResources.learningPaths);

  const topics = [
    { id: '1', label: 'Managing Money', data: 'Managing Money' },
    { id: '1', label: 'Funding', data: 'Funding' },
    { id: '1', label: 'Branding', data: 'Branding' },
    { id: '1', label: 'Online Marketing', data: 'Online Marketing' },
    { id: '1', label: 'Winning Customers', data: 'Winning Customers' },
    { id: '1', label: 'Website Design', data: 'Website Design' }, 
    { id: '1', label: 'Legal Essentials', data: 'Legal Essentials' },
    { id: '1', label: 'Operations', data: 'Operations' },
    { id: '1', label: 'Social Media', data: 'Social Media' },
    { id: '1', label: 'Strategy', data: 'Strategy' },
    { id: '1', label: 'Cybersecurity', data: 'Cybersecurity' },
    { id: '1', label: 'Wellness', data: 'Wellness' }
  ];

  const sortTopic = (a, b) => {
    let x = t(a.label).toLowerCase();
    let y = t(b.label).toLowerCase();
    if(x>y){return 1;}
    if(x<y){return -1;}
    return 0;
  }

  const loaderDiv = () => {
    return (<Loader fullscreen={true} active={true} surface="light"/>);
  }

  console.log("combinedQuickTips", combinedQuickTips);
  useEffect(() => {
    console.log("props,CategoryLandingComponent");
    pageLoadStarted('Category-'+categoryType,window.location.origin+localStorage.getItem("prevUrl"),selectedTopics?.toString());
    pageLoadCompleted();
    window.scrollTo({ top: 0 });
    localStorage.setItem('prevUrl',window.location.href);
    getQuickTipForcedRanking(userLanguage).then((forcedRankingQuickTips) => {
      setForcedRankingQuickTip(forcedRankingQuickTips);
    });

    getRecommendedCourses().then((forcedRankingCourses) => {
      let tempRecommendedCourses = [];
      topics.forEach((topic) => {
        let presentCourseIds = [];
        if(forcedRankingCourses[topic.label] && forcedRankingCourses[topic.label].length > 0) {
          forcedRankingCourses[topic.label].forEach((e) => {
            if(JSON.parse(localStorage.suggestion).value.courses[e]) {
              presentCourseIds.push(e);
            }
          });
        }
        tempRecommendedCourses[topic.label] = presentCourseIds;
      });
      let presentCourseIds = [];
      if(forcedRankingCourses["I'm not sure"] && forcedRankingCourses["I'm not sure"].length > 0) {
        forcedRankingCourses["I'm not sure"].forEach((e) => {
          if(JSON.parse(localStorage.suggestion).value.courses[e]) {
            presentCourseIds.push(e);
          }
        });
      }
      tempRecommendedCourses["I'm not sure"] = presentCourseIds;
      console.log("tempRecommendedCourses",tempRecommendedCourses);
      setForcedRankingCourse(tempRecommendedCourses);
    });
  }, []);
  const renderComponentBasedOnCategoryType = () => {
      switch (categoryType?.toLowerCase()) {
        case 'quicktips' : 
          return (<>
          {(!aemData || !combinedQuickTips || !forcedRankingQuickTip) && loaderDiv()}
          {aemData && combinedQuickTips && forcedRankingQuickTip && <CategoryQuickTipsComponent forcedRankingQuickTip={forcedRankingQuickTip} topics={topics.sort(sortTopic)}/>}
          </>);
        case 'events' : 
        return (<>
          {(!aemData || !allEventsWithTimeZone) && loaderDiv()}
          {aemData && allEventsWithTimeZone && <CategoryEventsComponent topics={topics.sort(sortTopic)}/>}
          </>);
        case 'offers' : 
          return (<>{aemData && aemOffersData && <OffersCategoryLandingComponent />}</>);
        case 'courses' : 
          return (<>
          {(!aemData || !allCourses) && loaderDiv()}
          {aemData && allCourses && forcedRankingCourse && <CategoryCoursesComponent forcedRankingCourse={forcedRankingCourse} topics={topics.sort(sortTopic)}/>}
          </>);
        case 'learningpath' : 
          return (<> 
            {(!aemData || !learningPaths) && loaderDiv()}
            {aemData && learningPaths && <CategoryLearningPathComponent/>}
          </>);
      }
  }


  return (
    <div>
        {renderComponentBasedOnCategoryType()}
    </div>
  );
};

export default CategoryLandingComponent;

