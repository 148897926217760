import { useEffect, useState } from 'react';

import { BreadcrumbItem, Breadcrumbs } from '@vds/breadcrumbs';
import { Tab, Tabs } from '@vds/tabs';

import { pageLoadCompleted } from '../../common/Analytics/GAWrapper';
import './onboardingLanding.scss';
import RegisterStep1Component from './register/registerSteps';
import SignIn from './signin/signin';

const OnboardingLanding = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const tabOption = [
    { tabIndex: 0, label: 'Register' },
    { tabIndex: 1, label: 'SignIn' },
  ];
  useEffect(() => {
   // pageLoadCompleted();
  }, []);

  return (
    <div className="pv-8X sm-pv-6X">
      <Breadcrumbs surface="light">
        <BreadcrumbItem href="https://www.verizon.com/">Home</BreadcrumbItem>
        <BreadcrumbItem href="https://www.verizon.com/">
          {selectedTab === 0 ? 'Register' : 'Signin'}
        </BreadcrumbItem>
      </Breadcrumbs>
      <main className="onbording-container mt-8X">
        <div className="tab-container">
          <Tabs
            orientation="horizontal"
            indicatorPosition="bottom"
            fillContainer={true}
            selectedIndex={selectedTab}
            onTabChange={(event, tabIndex) => {
              console.log(event, tabIndex);
              setSelectedTab(tabIndex);
            }}
          >
            {tabOption.map((tab) => {
              return <Tab label={tab.label} key={tab.tabIndex} />;
            })}
          </Tabs>
        </div>
        <div>
          {selectedTab === 0 && <RegisterStep1Component />}
          {selectedTab === 1 && <SignIn />}
        </div>
      </main>
    </div>
  );
};

export default OnboardingLanding;
