import { Col, Grid, Row } from '@vds/grids';
import React from 'react';
import './SuggestedCourses.scss';
import { Body, Title } from '@vds/typography';
import { Button } from '@vds/buttons';
import { Carousel } from '@vds/carousels';
import { isDesktop, isMobile } from 'react-device-detect';
import CourseCardComponent from '../../components/common/CourseCards/CourseCards.jsx'
import { filterOptions, courseDetailssecondary } from './course.js';
import { Icon } from '@vds/icons';
import FilterBadge from '../common/FilterBadge/FilterBadge.jsx';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {retrieveAdobeData} from '../../../src/utility/common.js'
import recommendedCourse from'./recommendedCourses.json'
import { getRecommendedCourses } from '../../../src/utility/common.js';
import { getSuggestedCoursesbyTopic } from '../../../src/utility/utils.js'
import { topicMap } from './questionsMock.js';
import { ButtonIcon } from '@vds/button-icons';
import { useNavigate } from 'react-router';
import { mapperForCourseCardDesktop } from '../../../src/utility/utils.js';
const SuggestedCourses = (props) => {
  const history=useNavigate();
  const userDataFromStore = useSelector((state) => state.data);
  const [recommended, setRecommended] = useState([]);
  const [newCourses, setNewCourses] = useState([]);
  const [popular, setPopular] = useState([]);
  const [courses, setCourses] = useState([]);
  //const [allCourses, setAllCourses] = useState([]);
  const { t, i18n } = useTranslation();
  const [filter, setFilter] = useState([]);
  const [hasValidCourses,setHasValidCourses] = useState(false);
  
  const aemData = useSelector((state) => state.Aem.data);
  const aemDataResults=aemData?.results;
  const recommendedCourse = useSelector((state) => state.AdobeResources.recommendedCourses);
  const allCourses = useSelector((state) => state.AdobeResources.allCourses);
  const [selectedFilterOptions, setSelectedFilterOptions] = useState({ id: '1', label: aemDataResults?.recommended, data: '' });
  const topicMapaem=aemData?.topicCard?.topicSubtext;
  console.log("topicMapaem===",topicMapaem.forEach((item)=>console.log(item[0])))
  const newTopic=topicMapaem.filter((item)=>item[0]===props.topic)
  const topicsOfInterest=[newTopic[0][1]];
  useEffect(()=>{
    const getData = async() => {

      let lng = localStorage.getItem("lng");
     // let topicOfinterest=["Branding"]
    if(lng === null){
      //lng = userDataFromStore?.business?.attributes?.Language??'en';
      lng = 'en';
    }

      //const service = new Services();
    let res;
    
     const suggestions = retrieveAdobeData();

     suggestions.then(function(result) {
      console.log("suggestionsresult",result) 
      
      console.log("suggestionsresult",topicsOfInterest)
     let  { recommended, newCourses, popular} = getSuggestedCoursesbyTopic(lng, allCourses,topicsOfInterest , recommendedCourse);
     // let { recommended, newCourses, popular} = getSuggestedCoursesbyTopic(lng, enrollments, suggestions, topicsOfInterest, recommendedCourses)
    
    
        recommended=recommended.filter(e=>e.tags?.includes(topicsOfInterest.toString()))
        recommended=recommended.length>0?recommended.splice(0,12):recommended;
        newCourses=newCourses.filter(e=>e.tags?.includes(topicsOfInterest.toString()))
        newCourses=newCourses.length>0?newCourses.splice(0,12):newCourses;
        popular=popular.filter(e=>e.tags?.includes(topicsOfInterest.toString()))
        popular=popular.length>0?popular.splice(0,12):popular;
        
      setCourses(recommended??[]);
      setRecommended(recommended??[]);
      setNewCourses(newCourses);
      setPopular(popular);
      aemDataResults?.filterOptionsTips?.map((option) => {
        if (recommended?.length > 0 && option?.label == 'Recommended') {
          setHasValidCourses(true);
          setSelectedFilterOptions(option);
        }
        else if (recommended?.length == 0 && newCourses?.length > 0 && option?.label == 'New') {
          setHasValidCourses(true);
          setSelectedFilterOptions(option);
        } else if (recommended?.length == 0 && newCourses?.length == 0 && popular?.length > 0 && option?.label == 'Popular') {
          setHasValidCourses(true);
          setSelectedFilterOptions(option);
        }
      });
      res=result// "Some User token"
   })
    
  
    };
   
    getData();

  },[]);

  useEffect(()=>{
    let lng = localStorage.getItem("lng");
    if(lng === null){
      lng = userDataFromStore?.business?.attributes?.Language??'en';
     // console.log("lng from user data",userDataFromStore.business?.attributes?.Language);
    }
  },[]);

  useEffect(() => {
    
      const label = selectedFilterOptions.label;
      if(label === aemDataResults?.new){
        setCourses(newCourses);
      }
      else if(label === aemDataResults?.recommended){
        setCourses(recommended);
      }else{
        setCourses(popular);
      }
    
  },[selectedFilterOptions]);

  const handleChange = (e) => {
    setSelectedFilterOptions(e);
  };
console.log("Courses",courses);
    return (
      <>
      {hasValidCourses && <div className={props.bgGray ? 'quick-tips bg-gray pv-16X sm-pv-12X' : 'quick-tips bg-white pv-16X sm-pv-12X'}>
        <Grid>
          <Row>
            <Col
              colSizes={{
                mobile: 4,
                tablet: 12,
                desktop: 12,
              }}
            >
              <section className='text-area mb-8X'>
                <div className=''  aria-label={aemDataResults?.suggestedCourses}>
                  <Title size='XLarge' bold={true}>{aemDataResults?.suggestedCourses}</Title>
                </div>
                <div className='top-action-links'>
               
                  <span className='action-link n-cursor-auto'  aira-label="View all">
                    <Body
                      size="large"
                      color="#000000"
                      bold={false}>
                      {aemDataResults?.viewall}
                    </Body>
                    <span className="ml-3X">
                    <ButtonIcon
                      role="link"
                      kind="ghost"
                      size="large"
                      ariaLabel={t('View all')+t('courses')}
                      renderIcon={() => <Icon
                        name="right-arrow"
                        size="medium"
                        color="#000000"
                      />}
                      onClick={e => {history(`/category/courses?topic=${topicsOfInterest}`);}}
                    />
                    </span>
                  </span>
                </div>
              </section>
              <div className='mb-12X'>
              <FilterBadge nav={t('courses')} handleChange={handleChange} options={aemDataResults?.filterOptionsTips} selectedOptions={selectedFilterOptions} showFilterBadge={false} iconKind="lowContrast" surfaceType="colorFill" onChange={e => {console.log("Inside Courses filter",e);}}/>
              </div>
            </Col>
          </Row>
        </Grid>
        {courses.length > 0 &&
          <div className='carousel-container-fix'>
          
          <Carousel
          peek="standard"
          layout={isMobile ? "1UP" : "3UP"}
          gutter="24px"
          surface="light"
          paginationInset="12px"
          paginationDisplay="persistent"
          data={courses.map(e=>mapperForCourseCardDesktop(e))}
          renderItem={props => <CourseCardComponent data={props} />}
        />
      </div>}
      </div>}
      </>
    );
  
};

export default SuggestedCourses;