import { configureStore } from '@reduxjs/toolkit';

import adoberesources from './adoberesources';
import aem from './aem';
import mediatype from './mediatype';

export default configureStore({
  reducer: {
    MediaType: mediatype,
    Aem: aem,
    AdobeResources: adoberesources,
  },
});
