// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chip-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* .chip-container .chip:first-child{
    margin-right: 8px ;
}

.chip-container .chip:last-child{
    margin-left: 8px ;
}

.chip-container .chip:not(:last-child):not(:first-child)  {
    margin-left: 8px ;
    margin-right: 8px;
} */
.chip-container .chip {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  isolation: isolate;
  border: 1px solid #000000;
  border-radius: 24px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 8px 8px;
}

.chip-container .chip.chip-selected,
.chip.chip-selected p {
  background: #000000;
  color: white;
}

.chip-container .chip.chip-unselected {
  color: black;
  background: #ffffff;
}

.u-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 767px) {
  .chip-container {
    justify-content: flex-start;
  }
}`, "",{"version":3,"sources":["webpack://./src/elements/multiselectChips/multiselectChips.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,uBAAA;AACF;;AAEA;;;;;;;;;;;GAAA;AAaA;EACE,eAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;EACA,kBAAA;EACA,yBAAA;EACA,mBAAA;EACA,UAAA;EACA,QAAA;EACA,YAAA;EACA,eAAA;AAAF;;AAGA;;EAEE,mBAAA;EACA,YAAA;AAAF;;AAGA;EACE,YAAA;EACA,mBAAA;AAAF;;AAGA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AAAF;;AAGA;EACE;IACE,2BAAA;EAAF;AACF","sourcesContent":[".chip-container {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n}\n\n/* .chip-container .chip:first-child{\n    margin-right: 8px ;\n}\n\n.chip-container .chip:last-child{\n    margin-left: 8px ;\n}\n\n.chip-container .chip:not(:last-child):not(:first-child)  {\n    margin-left: 8px ;\n    margin-right: 8px;\n} */\n\n.chip-container .chip {\n  cursor: pointer;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  padding: 8px 16px;\n  isolation: isolate;\n  border: 1px solid #000000;\n  border-radius: 24px;\n  flex: none;\n  order: 0;\n  flex-grow: 0;\n  margin: 8px 8px;\n}\n\n.chip-container .chip.chip-selected,\n.chip.chip-selected p {\n  background: #000000;\n  color: white;\n}\n\n.chip-container .chip.chip-unselected {\n  color: black;\n  background: #ffffff;\n}\n\n.u-flex {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n@media (max-width: 767px) {\n  .chip-container {\n    justify-content: flex-start;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
