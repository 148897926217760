import React, { useState, useRef,useEffect } from 'react';
import './Grants.scss';
import { Col, Grid, Row } from '@vds/grids';
import { useNavigate } from 'react-router-dom';
import { BreadcrumbItem, Breadcrumbs } from '@vds/breadcrumbs';
import { Body, Feature, Micro, Title } from '@vds/typography';
import { ButtonIcon } from '@vds/button-icons';
import { useDispatch, useSelector } from 'react-redux';
// import { isMobile } from 'react-device-detect';
import { Line } from '@vds/lines';
import { Button, ButtonGroup, TextLink } from '@vds/buttons';
import { Icon } from '@vds/icons';
import SocialProofComponent from '../components/SocialProofComponent.jsx';
import { Accordion, AccordionHeader, AccordionItem, AccordionDetail, AccordionTitle } from '@vds/accordions';
// import FilterBadge from '../InfoHub/FilterBadge/FilterBadge.jsx';
// import { filterOptions } from '../InfoHub/QuickTips/quickTips.js';
import { Carousel } from '@vds/carousels';
// import CourseCardComponent from '../InfoHub/Courses/CourseCards/CourseCards.jsx';
// import { courseDetailssecondary } from '../InfoHub/Courses/course.js';
import TagBasedCoursesComponent from '../elements/TagBasedCoursesComponent';

import { useTranslation } from 'react-i18next';
import store from '../store/store.js';
import { updateRegisterTriggeredFrom } from '../store/aem.js';
import { pageLoadStarted,pageLoadCompleted } from '../common/Analytics/GAWrapper.jsx';
import ImageHeadersComponent from '../elements/imageHeader.jsx';
import { isMobile } from 'react-device-detect';
import MultiGrantsComponent from '../components/specialGrants/MultiGrantsNavigation/MultiGrantsNavigation.jsx';

const GrantsPageComponent = () => {
  const navigate = useNavigate();
  const [offers, setOffers] = useState();
  const faqListRef = useRef(null);
  const aemData = useSelector((state) => state.Aem.data);
  const allEventsWithTimeZone = useSelector((state)  => state.AdobeResources.allEventsWithTimeZone);
  const dispatch = useDispatch();
  const handleFaqClick = () => {
    faqListRef.current.scrollIntoView({ behaviour: 'smooth', block: 'start' });
    faqListRef.current.focus();
  };
  const aemFAQ = useSelector((state) => state.Aem.FAQ);
  const aemIncentivesData = useSelector((state) => state.Aem.incentives);
  const selectedTopics = useSelector(
    (state) => state.Aem.registrationStepsData.businessData.topics
  );

  const { t, i18n } = useTranslation();
  let imgPath = 'https://s3-alpha-sig.figma.com/img/c5da/83b9/8868183db3e200a6027e8b26102e841f?Expires=1710115200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=W5G-eAo0g4O~Mq2nCSzGzSacn3kRz1Df7Lt7Qayrfcigv2MuOGLyOlrH7NPDiuKaV7ZYaQXVUnaWd4Rq6pd3gB9GMjc6wOct-HxihEyfJn2j7ClmSsmaK3stt6cFYC5ow4XXvLC2hqhrbEuI1Ec2Jvw2q6SsOMWza-Qj07PLKXyTDN83Itab7W8FyJm8cNFB-atIqbAZbRvKN3rlk184bU~hXO0iJM5VUmhBQSIA2rqnSKcEecHj8C1Znca0eLajO085zlejRUWey10U44mbAQOGqqk6Yd3djCSkj6gN6x3fTa8FUbb4fgZqBsk1r~nesE71JWOFcGoGBfSR2PT0Yw__';
  let faqs = [
    {
      text: 'When is the latest grant application open?',
      subText: ''
    },
    {
      text: 'Who is eligible to apply?',
      subText: ''
    }, {
      text: 'Is the application available in Spanish? ',
      subText: ''
    },
    {
      text: 'Can I change my application after it’s been submitted? ',
      subText: ''
    }, {
      text: 'When is the latest grant application open?',
      subText: ''
    },
    {
      text: 'Who is eligible to apply?',
      subText: ''
    }, {
      text: 'When is the latest grant application open?',
      subText: ''
    },
    {
      text: 'Who is eligible to apply?',
      subText: ''
    }
  ];

  let pageData = {
    category: {
      label: `${aemData?.capitalOffering?.grantsCategoryLabel}`,
      subText: `${aemData?.capitalOffering?.grantsCategorySubText}`,
      imageUrl : isMobile ? `${aemData?.capitalOffering?.mobileImageUrl}` : `${aemData?.capitalOffering?.imageUrl}`,
      textLink: { label: '', link: '', tooltipText: '' },
      buttons: [

        {
          children: `${aemData?.capitalOffering?.categoryCTAButton}`,
          size: 'large',
          use: 'primary',
          width: 'auto',
          onClick:()=> navigate(`/course-details/capital-offering`)
        }
      ],
      sideTextLink: { label: '', link: '' },
      sideIcon: { name: '', color: '' }
    },

  };

  // useEffect(() => { 
  //   pageLoadStarted("Grants",window.location.host + localStorage.getItem('prevUrl'),selectedTopics?.toString());
  // },[]);
  

   useEffect(() => { 
    pageLoadStarted("Grants",window.location.origin+localStorage.getItem('prevUrl'),selectedTopics?.toString());
    //localStorage.setItem('prevUrl',window.location.href);
    pageLoadCompleted();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  },[]);

  useEffect(()=>{
    let offer = 0;
    aemIncentivesData.forEach((val) => {
      if (val.id === 'lisc') {
        setOffers(aemIncentivesData[offer]);
      }
      offer++;
    });
  },[aemIncentivesData])

// >>>>>>> src/views/Grants.jsx
  return (
    <div id="grants-section" className="grants bg-white">
      <div className='bg-white'>
        <Grid
          bleed="1440"
          colSizes={{
            mobile: 4,
            tablet: 12,
            desktop: 12,
          }}
        >
         {/**
        <Row>
            <Col>
              <div className='mv-8X'>
                <Breadcrumbs surface="light">
                  <BreadcrumbItem href="/">
                    {aemData?.grants?.homeTxt}
                  </BreadcrumbItem>
                  <BreadcrumbItem href="https://www.verizon.com/">
                    {aemData?.grants?.grantTxt}
                  </BreadcrumbItem>
                </Breadcrumbs>
              </div>
            </Col>
          </Row>
        */} 
          <Row>
            <Col
              colSizes={{
                mobile: 0,
                tablet: 0,
                desktop: 1,
              }}
            >
            </Col>
            <Col colSizes={{
              mobile: 4,
              tablet: 12,
              desktop: 10,
            }}>
              <div className='grants-hero pv-12X pb-24X sm-pb-16X sm-pv-8X pt-16X sm-pt-12X mt-4X sm-mt-0X'>
                <div>
                  <Feature primitive='h1' size='XSmall' bold={true}> {aemData?.grants?.grantstitle1} <br />
                  {aemData?.grants?.inGrantTxt}</Feature>
                </div>
                <div className='mt-4X'>
                  <Title size='large'>{aemData?.grants?.tohelpTxt}</Title>
                </div>
                <div className='mt-12X'>
                  <Body size='large' bold={true} color='#75777'>{aemData?.grants?.partnerShipTxt}</Body>
                </div>
                <div className='mt-6X'>
                  <img src={aemIncentivesData.filter(val => val.id === 'lisc')[0].summary.icon} />
                </div>
              </div>
            </Col>
          </Row>
        </Grid>

      
        {offers && (offers?.rules?.startTimeStamp && offers?.rules?.startTimeStamp < Date.now()) && (offers?.rules?.endTimeStamp && offers?.rules?.endTimeStamp < Date.now()) &&
          <ImageHeadersComponent props={pageData.category} />}
        <div id='grantContainer' className='grant-status'>
          <Grid
            bleed="1440"
            colSizes={{
              mobile: 4,
              tablet: 12,
              desktop: 12,
            }}
          >
            <Row>
              <Col
                colSizes={{
                  mobile: 0,
                  tablet: 0,
                  desktop: 1,
                }}
              >
              </Col>
              <Col colSizes={{
                mobile: 4,
                tablet: 12,
                desktop: 10,
              }}>
              </Col>
            </Row>
            <Row>
              <Col
                colSizes={{
                  mobile: 0,
                  tablet: 0,
                  desktop: 1,
                }}
              >
              </Col>
              <Col colSizes={{
                mobile: 4,
                tablet: 12,
                desktop: 10,
              }}>
                {aemIncentivesData && aemIncentivesData.filter(val => val.id === 'lisc') ?
                  <div className='grant-status pv-24X sm-pv-16X bb-1x'>
                    <Title size='XLarge' bold>
                      
                      {aemData?.grants?.registeracc}
                    </Title>
                    <div className='mt-4X'>
                      <Title size='medium'>{aemData?.grants?.goalTxt}</Title>
                    </div>
                    <div className={aemData?.grants?.completeTxt && aemData?.grants?.completeTxt != '' ? 'instructions mv-16X sm-mv-12X' : 'instructions mt-6X'}>
                      <div>
                        <Title size='medium' bold>{aemData?.grants?.whocanApplyTxt}</Title>
                        {aemData?.grants?.whocanApplyTxt && aemData?.grants?.whocanApplyTxt != '' && <div className='mv-4X'>
                         <Line></Line>
                        </div>}
                        <div>
                          <Title size='medium'>{aemData?.grants?.owners18Txt}</Title>
                        </div>
                      </div>
                      <div>
                        <Title size='medium' bold>{aemData?.grants?.howApplyTxt}</Title>
                        <div>
                        {aemData?.grants?.howApplyTxt && aemData?.grants?.howApplyTxt != '' && <div className='mv-4X'>
                        <Line></Line>
                        </div>}
                        {aemData?.grants?.completeTxt && aemData?.grants?.completeTxt != '' && <div>
                          <Title size='medium'>
                          {aemData?.grants?.completeTxt}
                          {/* {t('Complete two grant-eligible courses or events between ')} {new Date(+aemIncentivesData.filter(val => val.id === 'lisc')[0].rules.startTimeBoundary).toDateString().split(' ').slice(1).join(' ')} {t('and')} {new Date(+aemIncentivesData.filter(val => val.id === 'lisc')[0].rules.endTimeStamp).toDateString().split(' ').slice(1).join(' ')} {t('to unlock the application, then submit the application by')} {new Date(+aemIncentivesData.filter(val => val.id === 'lisc')[0].rules.endTimeStamp).toDateString().split(' ').slice(1).join(' ')}. {t(' Late applications will not be considered.')} */}
                          </Title>
                        </div>}
                        </div>
                      </div>
                    </div>
                    <div className='button'>
                      <ButtonGroup
                        childWidth={'100%'}
                        viewport={'desktop'}
                        rowQuantity={{ desktop: 2 }}
                        data={[
                          {
                            children: aemData?.grants?.register,
                            size: 'large',
                            use: 'primary',
                            width: '280px',
                            onClick: () => {store.dispatch(updateRegisterTriggeredFrom(location.pathname));
                              localStorage.setItem('prevUrl',window.location.href);
                              navigate('/onboarding')},
                          },
                          {
                            children: aemData?.grants?.frequently,
                            size: 'large',
                            use: 'secondary',
                            width: '280px',
                            onClick: handleFaqClick,
                          },

                        ]}
                        alignment={'center'}
                      />
                    </div>
                  </div>
                  :
                  <div className='grant-status pv-24X sm-pv-16X bb-1x'>
                    <Title size='XLarge' bold>
                    {aemData?.grants?.noactivegrants}
                    </Title>
                    <div className='mt-4X'>
                      <Title size='medium'> {aemData?.grants?.chkBackTxt}</Title>
                    </div>
                  </div>
                }
              </Col>
            </Row>
            <Row>
              <Col>
              </Col>
            </Row>
          </Grid>
        </div>

        <Grid
          bleed="1440"
          colSizes={{
            mobile: 4,
            tablet: 12,
            desktop: 12,
          }}
        >
          <Row>
          </Row>
        </Grid>

        {aemIncentivesData && aemIncentivesData.filter(val => val.id === 'lisc') && <div className='resources bg-gray pv-24X sm-pv-16X'>
          <Grid>
            <Row>
              <Col
                colSizes={{
                  mobile: 4,
                  tablet: 12,
                  desktop: 12,
                }}
              >
                <section className='text-area mb-8X'>
                  <div className=''>
                    <Title size='XLarge' bold={true}>{aemData?.grants?.grantelliblecourses}</Title>
                    <div className='mt-4X'>
                      <Body size='large'>
                      {aemData?.grants?.exploreCOurses}</Body>
                    </div>
                  </div>
                  <div className='top-action-links'>
                    <span className='action-link'>
                      <Body
                        size="large"
                        color="#000000"
                        bold={false}>
                       {aemData?.grants?.viewCourses}
                      </Body>
                      <span className="ml-3X">
                        <ButtonIcon
                          kind="ghost"
                          size="large" 
                          ariaLabel={aemData?.grants?.viewCourses}                         
                          // fnam={props.title}
                          renderIcon={() => <Icon
                            name="right-arrow"
                            size="medium"
                            color="#000000"                            
                          />}
                          onClick={() => navigate("/category/courses")}
                        />
                      </span>
                    </span>
                    <span className='action-link'>
                      <Body
                        size="large"
                        color="#000000"
                        bold={false}>
                          {aemData?.grants?.viewEvents}
                      </Body>
                      <span className="ml-3X">
                        <ButtonIcon
                          kind="ghost"
                          size="large"
                          ariaLabel={aemData?.grants?.viewEvents}
                          // fnam={props.title}
                          renderIcon={() => <Icon
                            name="right-arrow"
                            size="medium"
                            color="#000000"
                          />}
                          onClick={() => navigate("/category/events")}
                        />
                      </span>
                    </span>
                  </div>
                </section>

              </Col>
            </Row>
          </Grid>
          <Carousel
            peek={"standard"}
            // layout={isMobile ? "1UP" : "3UP"}
            // gutter={isMobile ? "12px" : "24px"}
            surface="light"
            paginationInset="12px"
            paginationDisplay="persistent"
          // data={courseDetailssecondary}
          // renderItem={props => <CourseCardComponent data={props}></CourseCardComponent>}
          />
        </div>}

        {allEventsWithTimeZone && <TagBasedCoursesComponent tags={[]} isLandingPage={false} />}
        {offers && (offers?.rules?.startTimeStamp && offers?.rules?.startTimeStamp < Date.now()) && (offers?.rules?.endTimeStamp && offers?.rules?.endTimeStamp < Date.now()) ?
          <></> :
        <ImageHeadersComponent props={pageData.category} />}

        <SocialProofComponent></SocialProofComponent>
        <Grid
          bleed="1440"
          colSizes={{
            mobile: 4,
            tablet: 12,
            desktop: 12,
          }}
        >
          <Row>
            <Col>
              <div className='faqs mv-12X'>
                <div tabIndex="0" ref={faqListRef}>
                  <Title size='XLarge' bold>{aemData?.grants?.frequently}<br />
                  {aemData?.grants?.aboutTxt}</Title>
                </div>
                <div className='mt-12X'>
                  <Accordion id="faq"
                    bottomLine={true}
                  >
                    {aemIncentivesData && aemIncentivesData.filter(val => val.id === 'lisc') && aemFAQ && aemFAQ.map(item => {
                      let _element = <AccordionItem>
                        <AccordionHeader
                          trigger={{
                            type: "icon",
                            alignment: "top"
                          }}
                        >
                          <AccordionTitle>{t(item.text)}</AccordionTitle>
                        </AccordionHeader>
                        <AccordionDetail>{t(item.subText)}</AccordionDetail>
                      </AccordionItem>;
                      return _element;

                    })}
                  </Accordion>



                </div>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    </div >
  );
};
export default GrantsPageComponent;
