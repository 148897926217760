// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-container {
  max-width: 387px;
  width: 100%;
}
.title-container.partner-title {
  max-width: 596px;
}

.partner-sub-title {
  max-width: 596px;
}
.partner-sub-title a {
  color: #000;
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 1px;
  text-underline-position: under;
}
.partner-sub-title a:hover {
  color: #000;
  text-decoration-thickness: 2px;
}`, "",{"version":3,"sources":["webpack://./src/views/onboarding/RegistrationFlowComponent/RegistrationHeaderComponent/RegistrationHeaderComponent.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,WAAA;AACF;AACE;EACE,gBAAA;AACJ;;AAGA;EACE,gBAAA;AAAF;AAEE;EACE,WAAA;EACA,+BAAA;EACA,8BAAA;EACA,0BAAA;EACA,8BAAA;AAAJ;AAEI;EACE,WAAA;EACA,8BAAA;AAAN","sourcesContent":[".title-container {\n  max-width: 387px;\n  width: 100%;  \n  \n  &.partner-title {\n    max-width: 596px;\n  }\n}\n\n.partner-sub-title {\n  max-width: 596px;\n\n  a {\n    color: #000;\n    text-decoration-line: underline;\n    text-decoration-thickness: 1px;\n    text-underline-offset: 1px;\n    text-underline-position: under;\n\n    &:hover {\n      color: #000;\n      text-decoration-thickness: 2px;\n    }\n  }\n}\n// .jHYqYp {\n//   font-size: 3rem !important;\n//   font-weight: 700 !important;\n//   line-height: 3rem !important;\n//   font-family: Verizon-NHG-eDS,Helvetica,Arial,Sans-serif;\n//   color: #000000;\n//   margin: 0;\n//   text-decoration: none;\n// }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
