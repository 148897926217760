/** @format */
import { useNavigate } from 'react-router-dom';

import { BreadcrumbItem, Breadcrumbs } from '@vds/breadcrumbs';
import { TextLinkCaret } from '@vds/buttons';
import { Col, Grid, Row } from '@vds/grids';
import { Body, Title } from '@vds/typography';
import { useTranslation } from 'react-i18next';
import { Icon } from '@vds/icons';

import './CourseContentHeaderComponent.scss';
import { useEffect, useState } from 'react';

const CourseContentHeaderComponent = (props) => {
  const { t, i18n } = useTranslation();
  const { setChineseLng,coursedetail,chineseLng,headerBackLinkLabel, headerTitle, headerBreadcrumbs,breadCrumb, recordedEventTag, gutterCol=false } = props;
  const isCapitalOfferingDetailsPage = window.location.pathname.indexOf('capital-offering') !== -1 ? true : false;
  const [show,setShow]=useState(false);
  const [chinesetitle,setChinesetitle]=useState('');
  const navigate = useNavigate();

  useEffect(()=>{
    if(localStorage.getItem('Chinese')){
      coursedetail?.localizeData?.map((item) =>{
        if(item.locale=='zh-CN'){
          // console.log('$$$>>',item.name)
          setChinesetitle(item.name)
        }
       })
      

       
    } if( localStorage.getItem("lgflag")==='zh' || localStorage.getItem('Chinese')=='true' ){
      setShow(true);
      // console.log("dig>",chineseLng)
      
    }else{ setShow(false)
      // console.log("dig>",chineseLng)
     
    }
    
    // (localStorage.getItem('IsChinese'))
    
  },[chineseLng,chinesetitle]);
  

  const onBackLinkClicked = () => {
    console.log('back link clicked');
    navigate(-1);
  };
  return (
    <div className="course-content-header">
          <Grid colSizes={{mobile: 4,tablet: 12,desktop: 12}}>
        <Row>
          <Col>
          <div className='mt-8X'>
            {breadCrumb}
          </div>
          </Col>
        </Row>
        <Row>
          <Col
            colSizes={{
              mobile: 4,
              tablet: gutterCol ? 2 : 1,
              desktop: gutterCol ? 2 : 1,
            }}
          >
          {!breadCrumb &&
            <div className="mt-8X">
              <TextLinkCaret
                surface="dark"
                iconPosition="left"
                onClick={onBackLinkClicked}
              >
                {headerBackLinkLabel}
              </TextLinkCaret>
            </div>
          }
          </Col>
          
          <Col
            colSizes={{
              mobile: 4,
              tablet: 8,
              desktop: 8,
            }}
          >
            <div tabIndex={0} aria-label={headerTitle} className="mt-8X sm-mt-8X">
              <Title size="2XLarge" color="#ffffff" bold={true}>
              { show ? chinesetitle: headerTitle}
              </Title>
            </div>
            {(isCapitalOfferingDetailsPage || headerBreadcrumbs || recordedEventTag) && <div tabIndex={0} className='course-format mt-4X' aria-label='course-format'>
              {isCapitalOfferingDetailsPage && <> <Icon name='tag' color='white' size='small'></Icon> &nbsp;</>}
              <Body size="small" bold={false} color="#ffffff"> {recordedEventTag ? t("Event") : t(headerBreadcrumbs)} </Body>
            </div>}
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default CourseContentHeaderComponent;
