import { Icon } from '@vds/icons';
import { TileContainer } from '@vds/tiles';
import { Body, Micro, Title } from '@vds/typography';
import React, { useState ,useEffect} from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getWithExpiry } from '../../../utility/utils';
import './languageSupport.scss';
import { pageLoad_Started,pageLoadCompleted } from '../../../common/Analytics/GAWrapper';
import LanguageSwitchPopupComponent from '../LanguageSwitchPopup/LanguageSwitchPopup.jsx';


const LanguageCard = (props) => {
  const [isChinese , setIsChinese] = useState(false)
  const {setChineseLng}=props;
 
  
  const [popupFlag, setPopupFlag]= useState(false);
  const selectedTopics = useSelector((state) => state.Aem.registrationStepsData.businessData.topics);
  const minifiedCatalog = useSelector((state) => state.AdobeResources.catalogData);
  let { courseId } = useParams();
  const [newContentAdd,setNewContentAdd]=useState('no');
  var coursetitle = minifiedCatalog.find((course) => course.id == courseId);

  useEffect(()=>{
    const suggestions = getWithExpiry('suggestion');
    let courses = suggestions?.courses[courseId] || suggestions?.events[courseId] || suggestions?.learningProgram[courseId];
   
    const dateTimeLearningStamp = courses?.datePublished;
    const currentDate = new Date();
    const dateDiff = (currentDate - new Date(dateTimeLearningStamp)) / (1000 * 60 * 60 * 24);

          if (dateDiff <=30){
            setNewContentAdd("yes")
          }
        
  },[])
  
  // flag to show the Popup
  const handleLngSwitchPopup=()=>{
    if (props.data.desc === 'This course is available entirely in Spanish.') {
      setPopupFlag(true)
    }
  }

  // flag to close the Popup
  const flag=(e)=>{
    setPopupFlag(e)
  }
  const handleSpanishCardClick = (e) => {
    // console.log("##>>",props.data.value)
        // alert('Tile is clicked');
        if(props.data.value==='zh')
          { 
            // alert('Tile is clicked for zh');
            localStorage.setItem("Chinese",true);
            localStorage.setItem("lng",'en');
            localStorage.setItem("lgflag",'zh');
            setChineseLng(true);
           setIsChinese(true);
           window.location.reload();
        //    localStorage.setItem("lng", "en");
        // localStorage.setItem('changedLanguage', true);
        // window.location.reload();
        // i18n.changeLanguage("en");
        // window.MP.SrcUrl = location.pathname.replace('/es','');
        // window.location.pathname = location.pathname.replace('/es','')
          
        } else if(props.data.value==='es'){
          // alert('Tile is clicked for en or es');
          localStorage.setItem("Chinese",false);
          localStorage.setItem("lng",'es');
          localStorage.setItem("lgflag",'es')
          setChineseLng(false);
          setIsChinese(false);
          // window.location.reload();
         
          }else{
            localStorage.setItem("Chinese",false);
          setChineseLng(false);
          setIsChinese(false);
          localStorage.setItem("lgflag",'en')
          }
          
          handleLngSwitchPopup()
          pageLoad_Started(coursetitle.title,window.location.origin+localStorage.getItem("prevUrl")
          ,selectedTopics?.toString(),newContentAdd);
          pageLoadCompleted()
  }

  return (
    
    <div onKeyUp={(e)=>{
      // alert(e.keyCode)
      if(e.keyCode == 13){
         handleSpanishCardClick();
      
      }
    }}>
    <TileContainer
      padding='16px'
      aspectRatio='243:261'
      width='243px'
      role='button'
      ariaLabel={`${props.data.iconTitle}  ${props.data.desc}  ${props.data.bottomLabel}`}
      onClick={handleSpanishCardClick}
      backgroundColor="secondary"
      showDropShadow={true}
    >
      <div className='language-card'>
        <div className='icon-area'>
          <Icon name={props.data.icon} size='large' />
          <Micro>{props.data.iconTitle}</Micro>
        </div>
        <div className='description'>
          <Title size='XSmall'>{props.data.desc}</Title>
        </div>
        <div className="bottom-desc pt-4X">
          <Body size='small' bold>{props.data.bottomLabel}</Body>
          <Icon name='right-arrow' size='small' />
        </div>
      </div>
    </TileContainer>
    {popupFlag && <LanguageSwitchPopupComponent closeFlag={flag} />}
   </div>
  );
};

export default LanguageCard;