// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chips-container {
  max-width: 600px;
}

.group-container .iDPdTB {
  font-weight: normal;
}`, "",{"version":3,"sources":["webpack://./src/views/onboarding/RegistrationFlowComponent/GroupsSelectionComponent/GroupsSelectionComponent.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AACA;EACE,mBAAA;AAEF","sourcesContent":[".chips-container {\n  max-width: 600px;\n}\n.group-container .iDPdTB {\n  font-weight: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
