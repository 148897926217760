const quickTip_en = {
  "1":{
    "format": "Quick Tip",
    "desktopImage": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/tipsandresources_desktop.jpg",
    "mobileImage": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/tipsandresources_mobile.jpg",
    "resourceInfoTag": "New",
    "resourceType": "Video",
    "courseType": "video",
    "resourceTags": "",
    "label": "Making sound business spending decisions",
    "dateAndTimeIcon": "timer",
    "dateAndTimeValue": "3 minutes",
    "courseId": "1",
    "publishedOnDate": "10 Aug, 2023",
    "locale": ["en-US"],
    "icons": [
        {
            "icon": "clock",
            "label": "3 minutes"
        },
        {
            "icon": "share",
            "label": "Share",
            "shareLink": "/course-details/1"
        }
    ],
    "headerBreadcrumbs": [
        {
            "name": "Tips & Resources / Video",
            "link": "https://www.verizon.com/"
        }
    ],
    "deliveryMethod": {
        "type": "video",
        "source": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/videos/tipsandresources_video.mp4",
        "thumbnailImage": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/tipsandresources_desktop.jpg",
        "closedCaptionFileURL": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/static-contents/captions/Verizon TnR 1 - Making sound business spending decisions.vtt"
    },
    "tags": [
        "Managing Money",
        "Operations",
        "Strategy"
    ],
    "courseDescription": "If you’re trying to grow, there’s often no way around it: you have to spend money to take your business to the next level. But, how do you know what will give you the best bang for your buck? Jessica Spaulding, founder and CEO of The Harlem Chocolate Factory, shares tips on how to make smart choices when investing in your business, including how to weigh potential risk versus return.",
    "expertsDetails": {
        "profilePhoto": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/mobile/expert-1.png",
        "name": "Jessica Spaulding",
        "about": " is the owner and driving force behind the Harlem Chocolate Factory. She grew up in an anti-sugar home, but there was one exception to this rule—chocolate. Merging her passion for chocolate with her love of Harlem is an experience she wants to share with everyone. Before becoming a chocolate mogul, Spaulding worked in graphic design and marketing, primarily assisting small businesses. She decided to quit and go into business for herself and founded Harlem Chocolate Factory along with her Spelman sister, Asha Dixon. Soon, the brand garnered the attention of corporate clients like Chase, Kate Spade, and Sam Adams. Their flagship retail and production kitchen opened in the Historic Strivers’ Row district of Harlem in 2018 to rave reviews."
    }
  },
  "2":{
    "format": "Quick Tip",
    "desktopImage": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/tipsandresources_desktop2.jpg",
    "mobileImage": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/tipsandresources_mobile2.jpg",
    "resourceInfoTag": "New",
    "resourceType": "Audio",
    "courseType": "audio",
    "resourceTags": "",
    "label": "Improve your search rankings with meta tags",
    "dateAndTimeIcon": "timer",
    "dateAndTimeValue": "3 minutes",
    "courseId": "2",
    "publishedOnDate": "20 Aug, 2023",
    "locale": ["en-US"],
    "icons": [
        {
            "icon": "clock",
            "label": "3 minutes"
        },
        {
            "icon": "share",
            "label": "Share",
            "shareLink": "/course-details/2"
        }
    ],
    "headerBreadcrumbs": [
        {
            "name": "Tips & Resources / Video"
        }
    ],
    "deliveryMethod": {
        "type": "video",
        "source": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/videos/tipsandresources_audio.mp4",
        "thumbnailImage": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/Tips%20Center%20Article%20Feature%20Image%20Improve%20your%20SEO%20with%20meta%20tags.png",
        "closedCaptionFileURL": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/static-contents/captions/tipsandresources_audio.vtt"
    },
    "tags": [
        "Online Marketing",
        "Website Design",
        "Winning Customers",
        "Social Media"
    ],
    "courseDescription": "You know you want to improve your search engine rankings to get your website in front of more potential customers, but, search engine optimization (SEO) is no easy feat. What is one thing you can do to get started? Listen to digital marketing expert, Sharon Lee Thony, as she breaks down how to improve your website’s search rankings by refining your meta titles and descriptions – the text snippets that appear in search engine results pages (SERP).",
    "expertsDetails": {
        "profilePhoto": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/mobile/expert-2.png",
        "name": "Sharon Lee Thony",
        "about": " is a brand and marketing executive with nearly two decades of experience creating award-winning marketing campaigns for travel, beauty, and luxury lifestyle brands. She is the founder and CEO of SLT Consulting, a boutique digital marketing collective. Sharon is passionate about changing the face of leadership and the future of work, and she believes in creating opportunities for women and BIPOC professionals. She’s a mentor, advisor and investor in several innovative, female-led businesses. In her spare time, Sharon is co-host of Modern Minorities, a Top 10 Podcast on society and culture, and has taught over 1,000 classes and workshops in digital marketing as an Adjunct Professor at a number of universities. She earned a BA from New York University and an MBA from The Wharton School."
    }
  },
  "3":{
    "format": "Quick Tip",
    "desktopImage": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/tipsandresources_desktop3.png",
    "mobileImage": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/tipsandresources_mobile3.png",
    "resourceInfoTag": "New",
    "resourceType": "Article",
    "courseType": "article",
    "resourceTags": "",
    "label": "Combating three common cybersecurity threats",
    "dateAndTimeIcon": "timer",
    "dateAndTimeValue": "3 minutes",
    "courseId": "3",
    "publishedOnDate": "20 Aug, 2023",
    "locale": ["en-US"],
    "icons": [
        {
            "icon": "clock",
            "label": "3 minutes"
        },
        {
            "icon": "share",
            "label": "Share",
            "shareLink": "/course-details/3"
        }
    ],
    "headerBreadcrumbs": [
        {
            "name": "Tips & Resources / Article",
            "link": "https://www.verizon.com/"
        }
    ],
    "deliveryMethod": {
        "type": "article",
        "source": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/Tips%20Center%20Cyberthreats%20Article%20Feature%20Image.png",
        "thumbnailImage": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/Tips%20Center%20Cyberthreats%20Article%20Feature%20Image.png"
    },
    "tags": [
        "Cybersecurity",
        "Operations",
        "Strategy"
    ],
    "courseDescription": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/Article_content.html",
    "expertsDetails": {
        "profilePhoto": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/mobile/expert-3.png",
        "name": "Dr. Chanel Suggs",
        "about": " is The Duchess of Cybersecurity®—CEO and founder of Wyvern Security. She works with clients to uncover and identify emerging threats and defend against attacks. Wyvern Security was awarded the 8(a) and GSA HACs contract vehicle and are accredited for all subcategories (High-Value Asset Assessments, Risk and Vulnerability Assessments, Cyber Hunt, Incident Response, and Penetration Testing). Wyvern is ISO 9001, 20000-1, and 27001 certified. She is a proven thought leader, lecturer, keynote speaker and subject matter expert. Dr. Suggs is the author of Against All Odds: Overcoming Racial, Sexual, and Gender Harassment on the Digital Battle Field."
    }
  },
  "4":{
    "format": "Quick Tip",
    "desktopImage": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/tipsandresources_desktop4.jpg",
    "mobileImage": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/tipsandresources_mobile4.jpg",
    "resourceInfoTag": "New",
    "resourceType": "Video",
    "courseType": "audio",
    "resourceTags": "",
    "label": "Crafting your founder's story",
    "dateAndTimeIcon": "timer",
    "dateAndTimeValue": "4 minutes",
    "courseId": "4",
    "publishedOnDate": "20 Aug, 2023",
    "locale": ["en-US"],
    "icons": [
        {
            "icon": "clock",
            "label": "4 minutes"
        },
        {
            "icon": "share",
            "label": "Share",
            "shareLink": "/course-details/4"
        }
    ],
    "headerBreadcrumbs": [
        {
            "name": "Tips & Resources / Audio"
        }
    ],
    "deliveryMethod": {
        "type": "video",
        "source": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/videos/tipsandresources_audio_1.mp4",
        "thumbnailImage": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/tipsandresources2_preview.jpg",
        "closedCaptionFileURL": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/static-contents/captions/tipsandresources_audio_1.vtt"
    },
    "tags": [
        "Branding"
    ],
    "courseDescription": "Create a founder's story that engages and resonates with your target audience",
    "expertsDetails": {
        "profilePhoto": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/mobile/expert-4.jpg",
        "name": "Gbenga Ogunjimi",
        "about": " is a business storytelling expert. He is the Executive Producer of Good to Great Stories: A Small Business Documentary and the author of Borderless Voice: The Power of Telling Your Story and Defining Your Identity. His storytelling was recognized by Washington, DC Mayor Muriel Bowser, who, thanks to Ogunjimi’s efforts, proclaimed February 21st as Business Story Day in Washington, DC. For more info, please visit www.gbenga.org"
    }
  },
  "5":{
    "format": "Quick Tip",
    "desktopImage": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/tipsandresources_desktop5.jpg",
    "mobileImage": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/tipsandresources_mobile5.jpg",
    "resourceInfoTag": "New",
    "resourceType": "Video",
    "courseType": "video",
    "resourceTags": "",
    "label": "Supercharge your email marketing",
    "dateAndTimeIcon": "timer",
    "dateAndTimeValue": "2 minutes",
    "courseId": "5",
    "publishedOnDate": "20 Aug, 2023",
    "locale": ["en-US"],
    "icons": [
        {
            "icon": "clock",
            "label": "2 minutes"
        },
        {
            "icon": "share",
            "label": "Share",
            "shareLink": "/course-details/5"
        }
    ],
    "headerBreadcrumbs": [
        {
            "name": "Tips & Resources / Audio"
        }
    ],
    "deliveryMethod": {
        "type": "video",
        "source": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/videos/Verizon TnR 14 - Email Marketing.mp4",
        "thumbnailImage": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/tipsandresources5_preview.jpg",
        "closedCaptionFileURL": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/static-contents/captions/Verizon TnR 14 - Email Marketing.vtt"
    },
    "tags": [
        "Online Marketing"
    ],
    "courseDescription": "Top tips on how to stand out in your customers' crowded inboxes",
    "expertsDetails": {
        "profilePhoto": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/mobile/expert-5.jpg",
        "name": "Carolina Villarreal",
        "about": " is a mentor accomplished at building brands, growing lines of business, and expanding new markets in the Americas. She brings 25+ years of experience in consumer goods at Fortune 500 companies, as well as in entrepreneurial and multicultural environments. Villarreal is also the founder and CEO of Voz Growth LLC, a firm that specializes in all things branding, business, marketing strategy, and sales development to empower midsize and emerging businesses to propel growth."
    }
  },
  "6":{
    "format": "Quick Tip",
    "desktopImage": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/tipsandresources_desktop6.jpg",
    "mobileImage": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/tipsandresources_mobile6.jpg",
    "resourceInfoTag": "New",
    "resourceType": "Article",
    "courseType": "article",
    "resourceTags": "",
    "label": "How to reclaim your work schedule",
    "dateAndTimeIcon": "timer",
    "dateAndTimeValue": "3 minutes",
    "courseId": "6",
    "publishedOnDate": "20 Aug, 2023",
    "locale": ["en-US"],
    "icons": [
        {
            "icon": "clock",
            "label": "3 minutes"
        },
        {
            "icon": "share",
            "label": "Share",
            "shareLink": "/course-details/6"
        }
    ],
    "headerBreadcrumbs": [
        {
            "name": "Tips & Resources / Article",
            "link": "https://www.verizon.com/"
        }
    ],
    "deliveryMethod": {
        "type": "article",
        "source": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/tipsandresources6_preview.jpg",
        "thumbnailImage": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/tipsandresources6_preview.jpg"
    },
    "tags": [
        "Wellness"
    ],
    "courseDescription": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/Howtoreclaimyourworkschedule.html",
    "expertsDetails": {
        "profilePhoto": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/mobile/expert-6.jpg",
        "name": "",
        "about": "Work-life balance can feel out of reach for many entrepreneurs. In this course, we'll examine some of the common causes for an unhealthy work-life balance, and provide a self-assessment to help you determine what specific barriers you face and what changes you can start making to build a healthier relationship with your work."
    }
  }
}

const quickTip_es = {
  "1":{
    "format": "Quick Tip",
    "desktopImage": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/tipsandresources_desktop.jpg",
    "mobileImage": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/tipsandresources_mobile.jpg",
    "resourceInfoTag": "New",
    "resourceType": "Video",
    "courseType": "video",
    "resourceTags": "",
    "label": "Spanish Making sound business spending decisions",
    "dateAndTimeIcon": "timer",
    "dateAndTimeValue": "3 minutes",
    "courseId": "1",
    "publishedOnDate": "10 Aug, 2023",
    "locale": ["es-ES"],
    "icons": [
        {
            "icon": "clock",
            "label": "3 minutes"
        },
        {
            "icon": "share",
            "label": "Share",
            "shareLink": "/course-details/1"
        }
    ],
    "headerBreadcrumbs": [
        {
            "name": "Tips & Resources / Video",
            "link": "https://www.verizon.com/"
        }
    ],
    "deliveryMethod": {
        "type": "video",
        "source": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/videos/tipsandresources_video.mp4",
        "thumbnailImage": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/tipsandresources_desktop.jpg",
        "closedCaptionFileURL": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/static-contents/captions/Verizon TnR 1 - Making sound business spending decisions.vtt"
    },
    "tags": [
        "Managing Money",
        "Operations",
        "Strategy"
    ],
    "courseDescription": "If you’re trying to grow, there’s often no way around it: you have to spend money to take your business to the next level. But, how do you know what will give you the best bang for your buck? Jessica Spaulding, founder and CEO of The Harlem Chocolate Factory, shares tips on how to make smart choices when investing in your business, including how to weigh potential risk versus return.",
    "expertsDetails": {
        "profilePhoto": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/mobile/expert-1.png",
        "name": "Jessica Spaulding",
        "about": " is the owner and driving force behind the Harlem Chocolate Factory. She grew up in an anti-sugar home, but there was one exception to this rule—chocolate. Merging her passion for chocolate with her love of Harlem is an experience she wants to share with everyone. Before becoming a chocolate mogul, Spaulding worked in graphic design and marketing, primarily assisting small businesses. She decided to quit and go into business for herself and founded Harlem Chocolate Factory along with her Spelman sister, Asha Dixon. Soon, the brand garnered the attention of corporate clients like Chase, Kate Spade, and Sam Adams. Their flagship retail and production kitchen opened in the Historic Strivers’ Row district of Harlem in 2018 to rave reviews."
    }
  },
  "2":{
    "format": "Quick Tip",
    "desktopImage": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/tipsandresources_desktop2.jpg",
    "mobileImage": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/tipsandresources_mobile2.jpg",
    "resourceInfoTag": "New",
    "resourceType": "Audio",
    "courseType": "audio",
    "resourceTags": "",
    "label": "Spanish  Improve your search rankings with meta tags",
    "dateAndTimeIcon": "timer",
    "dateAndTimeValue": "3 minutes",
    "courseId": "2",
    "publishedOnDate": "20 Aug, 2023",
    "locale": ["es-ES"],
    "icons": [
        {
            "icon": "clock",
            "label": "3 minutes"
        },
        {
            "icon": "share",
            "label": "Share",
            "shareLink": "/course-details/2"
        }
    ],
    "headerBreadcrumbs": [
        {
            "name": "Tips & Resources / Video"
        }
    ],
    "deliveryMethod": {
        "type": "video",
        "source": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/videos/tipsandresources_audio.mp4",
        "thumbnailImage": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/Tips%20Center%20Article%20Feature%20Image%20Improve%20your%20SEO%20with%20meta%20tags.png",
        "closedCaptionFileURL": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/static-contents/captions/tipsandresources_audio.vtt"
    },
    "tags": [
        "Online Marketing",
        "Website Design",
        "Winning Customers",
        "Social Media"
    ],
    "courseDescription": "You know you want to improve your search engine rankings to get your website in front of more potential customers, but, search engine optimization (SEO) is no easy feat. What is one thing you can do to get started? Listen to digital marketing expert, Sharon Lee Thony, as she breaks down how to improve your website’s search rankings by refining your meta titles and descriptions – the text snippets that appear in search engine results pages (SERP).",
    "expertsDetails": {
        "profilePhoto": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/mobile/expert-2.png",
        "name": "Sharon Lee Thony",
        "about": " is a brand and marketing executive with nearly two decades of experience creating award-winning marketing campaigns for travel, beauty, and luxury lifestyle brands. She is the founder and CEO of SLT Consulting, a boutique digital marketing collective. Sharon is passionate about changing the face of leadership and the future of work, and she believes in creating opportunities for women and BIPOC professionals. She’s a mentor, advisor and investor in several innovative, female-led businesses. In her spare time, Sharon is co-host of Modern Minorities, a Top 10 Podcast on society and culture, and has taught over 1,000 classes and workshops in digital marketing as an Adjunct Professor at a number of universities. She earned a BA from New York University and an MBA from The Wharton School."
    }
  },
  "3":{
    "format": "Quick Tip",
    "desktopImage": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/tipsandresources_desktop3.png",
    "mobileImage": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/tipsandresources_mobile3.png",
    "resourceInfoTag": "New",
    "resourceType": "Article",
    "courseType": "article",
    "resourceTags": "",
    "label": "Spanish Combating three common cybersecurity threats",
    "dateAndTimeIcon": "timer",
    "dateAndTimeValue": "3 minutes",
    "courseId": "3",
    "publishedOnDate": "20 Aug, 2023",
    "locale": ["es-ES"],
    "icons": [
        {
            "icon": "clock",
            "label": "3 minutes"
        },
        {
            "icon": "share",
            "label": "Share",
            "shareLink": "/course-details/3"
        }
    ],
    "headerBreadcrumbs": [
        {
            "name": "Tips & Resources / Article",
            "link": "https://www.verizon.com/"
        }
    ],
    "deliveryMethod": {
        "type": "article",
        "source": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/Tips%20Center%20Cyberthreats%20Article%20Feature%20Image.png",
        "thumbnailImage": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/Tips%20Center%20Cyberthreats%20Article%20Feature%20Image.png"
    },
    "tags": [
        "Cybersecurity",
        "Operations",
        "Strategy"
    ],
    "courseDescription": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/Article_content.html",
    "expertsDetails": {
        "profilePhoto": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/mobile/expert-3.png",
        "name": "Dr. Chanel Suggs",
        "about": " is The Duchess of Cybersecurity®—CEO and founder of Wyvern Security. She works with clients to uncover and identify emerging threats and defend against attacks. Wyvern Security was awarded the 8(a) and GSA HACs contract vehicle and are accredited for all subcategories (High-Value Asset Assessments, Risk and Vulnerability Assessments, Cyber Hunt, Incident Response, and Penetration Testing). Wyvern is ISO 9001, 20000-1, and 27001 certified. She is a proven thought leader, lecturer, keynote speaker and subject matter expert. Dr. Suggs is the author of Against All Odds: Overcoming Racial, Sexual, and Gender Harassment on the Digital Battle Field."
    }
  },
  "4":{
    "format": "Quick Tip",
    "desktopImage": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/tipsandresources_desktop4.jpg",
    "mobileImage": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/tipsandresources_mobile4.jpg",
    "resourceInfoTag": "New",
    "resourceType": "Video",
    "courseType": "audio",
    "resourceTags": "",
    "label": "Spanish Crafting your founder's story",
    "dateAndTimeIcon": "timer",
    "dateAndTimeValue": "4 minutes",
    "courseId": "4",
    "publishedOnDate": "20 Aug, 2023",
    "locale": ["es-ES"],
    "icons": [
        {
            "icon": "clock",
            "label": "4 minutes"
        },
        {
            "icon": "share",
            "label": "Share",
            "shareLink": "/course-details/4"
        }
    ],
    "headerBreadcrumbs": [
        {
            "name": "Tips & Resources / Audio"
        }
    ],
    "deliveryMethod": {
        "type": "video",
        "source": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/videos/tipsandresources_audio_1.mp4",
        "thumbnailImage": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/tipsandresources2_preview.jpg",
        "closedCaptionFileURL": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/static-contents/captions/tipsandresources_audio_1.vtt"
    },
    "tags": [
        "Branding"
    ],
    "courseDescription": "Create a founder's story that engages and resonates with your target audience",
    "expertsDetails": {
        "profilePhoto": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/mobile/expert-4.jpg",
        "name": "Gbenga Ogunjimi",
        "about": " is a business storytelling expert. He is the Executive Producer of Good to Great Stories: A Small Business Documentary and the author of Borderless Voice: The Power of Telling Your Story and Defining Your Identity. His storytelling was recognized by Washington, DC Mayor Muriel Bowser, who, thanks to Ogunjimi’s efforts, proclaimed February 21st as Business Story Day in Washington, DC. For more info, please visit www.gbenga.org"
    }
  },
  "5":{
    "format": "Quick Tip",
    "desktopImage": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/tipsandresources_desktop5.jpg",
    "mobileImage": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/tipsandresources_mobile5.jpg",
    "resourceInfoTag": "New",
    "resourceType": "Video",
    "courseType": "video",
    "resourceTags": "",
    "label": "Spanish Supercharge your email marketing",
    "dateAndTimeIcon": "timer",
    "dateAndTimeValue": "2 minutes",
    "courseId": "5",
    "publishedOnDate": "20 Aug, 2023",
    "locale": ["es-ES"],
    "icons": [
        {
            "icon": "clock",
            "label": "2 minutes"
        },
        {
            "icon": "share",
            "label": "Share",
            "shareLink": "/course-details/5"
        }
    ],
    "headerBreadcrumbs": [
        {
            "name": "Tips & Resources / Audio"
        }
    ],
    "deliveryMethod": {
        "type": "video",
        "source": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/videos/Verizon TnR 14 - Email Marketing.mp4",
        "thumbnailImage": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/tipsandresources5_preview.jpg",
        "closedCaptionFileURL": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/static-contents/captions/Verizon TnR 14 - Email Marketing.vtt"
    },
    "tags": [
        "Online Marketing"
    ],
    "courseDescription": "Top tips on how to stand out in your customers' crowded inboxes",
    "expertsDetails": {
        "profilePhoto": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/mobile/expert-5.jpg",
        "name": "Carolina Villarreal",
        "about": " is a mentor accomplished at building brands, growing lines of business, and expanding new markets in the Americas. She brings 25+ years of experience in consumer goods at Fortune 500 companies, as well as in entrepreneurial and multicultural environments. Villarreal is also the founder and CEO of Voz Growth LLC, a firm that specializes in all things branding, business, marketing strategy, and sales development to empower midsize and emerging businesses to propel growth."
    }
  },
  "6":{
    "format": "Quick Tip",
    "desktopImage": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/tipsandresources_desktop6.jpg",
    "mobileImage": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/tipsandresources_mobile6.jpg",
    "resourceInfoTag": "New",
    "resourceType": "Article",
    "courseType": "article",
    "resourceTags": "",
    "label": "Spanish How to reclaim your work schedule",
    "dateAndTimeIcon": "timer",
    "dateAndTimeValue": "3 minutes",
    "courseId": "6",
    "publishedOnDate": "20 Aug, 2023",
    "locale": ["es-ES"],
    "icons": [
        {
            "icon": "clock",
            "label": "3 minutes"
        },
        {
            "icon": "share",
            "label": "Share",
            "shareLink": "/course-details/6"
        }
    ],
    "headerBreadcrumbs": [
        {
            "name": "Tips & Resources / Article",
            "link": "https://www.verizon.com/"
        }
    ],
    "deliveryMethod": {
        "type": "article",
        "source": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/tipsandresources6_preview.jpg",
        "thumbnailImage": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/tipsandresources6_preview.jpg"
    },
    "tags": [
        "Wellness"
    ],
    "courseDescription": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/Howtoreclaimyourworkschedule.html",
    "expertsDetails": {
        "profilePhoto": "https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/mobile/expert-6.jpg",
        "name": "",
        "about": "Work-life balance can feel out of reach for many entrepreneurs. In this course, we'll examine some of the common causes for an unhealthy work-life balance, and provide a self-assessment to help you determine what specific barriers you face and what changes you can start making to build a healthier relationship with your work."
    }
  }
}

export {
  quickTip_es, 
  quickTip_en
};