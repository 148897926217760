import { Body } from '@vds/typography';
import React from 'react';
import './resourceTag.scss';

const ResourceTag = ({ text, backgroundColor= "#ffffff" }) => {


  return (
    <div className='resourse-tag' style={{backgroundColor: backgroundColor}}>
      <Body size="small" bold>
        {text}
      </Body>
    </div>
  );
};

export default ResourceTag;