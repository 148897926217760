import { useEffect, useState } from 'react';

import { BreadcrumbItem, Breadcrumbs } from '@vds/breadcrumbs';
import { Tab, Tabs } from '@vds/tabs';

import { pageLoadCompleted } from '../../common/Analytics/GAWrapper';
import './OnboardingPage.scss';
import SignInComponent from './SignInComponent/SignInComponent';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PartnerRegistrationFlowComponent from './RegistrationFlowComponent/PartnerRegistrationFlowComponent';
import RegistrationFlowComponent from './RegistrationFlowComponent/RegistrationFlowComponent';
import { useParams } from 'react-router-dom';

const OnboardingPage = () => {
  
const { t, i18n } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(0);
  const registerTriggeredFrom = useSelector((state) => state.Aem.registerTriggeredFrom);
  const selectedCourse = useSelector((state) => state.Aem.selectedCourse);
  let { userType } = useParams();
  //bring window to top
  window.scrollTo(0, 0);
  const isPartnerLogin = window?.location?.pathname?.includes('/partner') ? true : false ;

  const getLoginURL = () => {
    let lng = localStorage.getItem("lng") && localStorage.getItem("lng") === 'es' ? 'es' :'en';
    if(registerTriggeredFrom != "") {
      if(registerTriggeredFrom == "courseDetails" && selectedCourse != "") {
        return global.config.SSO_LOGIN_COURSE + selectedCourse;
      } else if(registerTriggeredFrom == "resultsPage" && selectedCourse != "") {
        return global.config.CIAM_SIGN_IN_URL + encodeURI(`${global.config.URL_MAPPING_AUTHENTICATED_USER[registerTriggeredFrom]}${selectedCourse}`);
      } else {
        return global.config.CIAM_SIGN_IN_URL + encodeURI(`${global.config.URL_MAPPING_AUTHENTICATED_USER[registerTriggeredFrom]}?ciamLang=${lng}`);
      }
    }
    return `${global.config.CIAM_SIGN_IN_URL}${encodeURI(`${process.env.REACT_APP_AUTHENTICATED_URL_BASE_PATH}?ciamLang=${lng}`)}`;
  }

  console.log("getLoginURL", getLoginURL(), registerTriggeredFrom);

  const tabOption = [
    { tabIndex: 0, label: t('Register') },
    { tabIndex: 1, label: t('Sign In'), href: getLoginURL() },
  ];
  useEffect(() => {
    //pageLoadCompleted();
    if(userType == "partner" && sessionStorage.getItem("isPartnerVisited")) {
      location.pathname = '/onboarding';
    } else if (userType == "partner" ) {
      sessionStorage.setItem("isPartnerVisited", true);
    }
    
  }, []);

  return (
    <div className="pv-8X sm-pv-6X ml-8X sm-ml-4X sm-mr-4X">
      {userType != "partner" && (
        <Breadcrumbs surface="light">
          <BreadcrumbItem href="/">{t("Home")}</BreadcrumbItem>
          <BreadcrumbItem href="https://www.verizon.com/">
            {selectedTab === 0 ? t('Register') : t('Signin')}
          </BreadcrumbItem>
        </Breadcrumbs>
      )}
      
      <main className="onboarding-container mt-8X">
        <div className="tab-container">
          <Tabs
            orientation="horizontal"
            indicatorPosition="bottom"
            fillContainer={true}
            selectedIndex={selectedTab}
            onTabChange={(event, tabIndex) => {
              console.log(event, tabIndex);
              setSelectedTab(tabIndex);
            }}
          >
           
            { tabOption.map( (tab) => { 
            
              return <Tab uniqueId={`${tab.label.replace(/ /g, "")}TabElement`} label={tab.label} key={tab.tabIndex} onClick={() =>{ if(tab.tabIndex===1){window.location.href = tab.href
                document.cookie = `firstTimeLogin=true;domain=${global.config.APP_COOKIE_DOMAIN};path=/`
              }}} />;
            })}        
             </Tabs>
        </div>
        <div>
        {selectedTab === 0 && userType == "partner" && <PartnerRegistrationFlowComponent />}
        {selectedTab === 0 && userType != "partner" && <RegistrationFlowComponent />}
{/*         <RegistrationFlowComponent /> */}  

          {/* {selectedTab === 1 && <SignInComponent />} */}
        </div>
      </main>
    </div>
  );
};

export default OnboardingPage;
