import { useEffect, useState } from 'react';
//import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ButtonGroup } from '@vds/buttons';
import { Body, Feature, Title } from '@vds/typography';

import { pageLoadCompleted } from '../common/Analytics/GAWrapper';
import './ContactUSComponent.scss';

const ContactUSComponent = () => {
  const spanishData = useSelector((state) => state.Aem.spanishLang);
  const engData = useSelector((state) => state.Aem.registerData);
  let isSpanish = localStorage.getItem("lng")==='es' ? true : false;
  const [aemData, setAemData] = useState(!isSpanish ? engData : spanishData);
  const [enableFocus,setEnableFocus]=useState(true);
  useEffect(() => {
    setAemData(!isSpanish ? engData : spanishData);
  }, [isSpanish, engData, spanishData]);
  //const aemData = useSelector((state) => state.Aem.registerData);
  const isMobile = useSelector((state) => state.MediaType.isMobile);

  useEffect(() => {
    pageLoadCompleted();
  }, []);
  return (
    <section aria-label="start-learning" className="contactus-container pv-24X">
      <div className="mt-8X">
        <Title size="medium" color="#ffffff" bold={true}>
          {aemData?.registerModule?.contactUs?.contactUsTitle}
        </Title>
      </div>
      <div className="mt-8X">

        <ButtonGroup
          childWidth={'100%'}
          viewport={isMobile ? 'mobile' : 'desktop'}
          rowQuantity={{ mobile:1, desktop: 2 }}
          surface="dark"
          data={[
            {
              children: aemData?.registerModule?.contactUs?.emailBtnTxt,
              size: 'medium',
              use: 'primary',
              width: 'auto',
              href: aemData?.registerModule?.contactUs?.emailBtnHref,
            },
            {
              children: aemData?.registerModule?.contactUs?.callUsBtnTxt,
              size: 'medium',
              use: 'secondary',
              width: 'auto',
              href: aemData?.registerModule?.contactUs?.callUsBtnHref,
            },
          ]}
          alignment={'center'}
        />
      </div>
    </section>
  );
};

export default ContactUSComponent;
