// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mh-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}`, "",{"version":3,"sources":["webpack://./src/components/onboarding/signin/signin.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;EACA,6BAAA;AACF","sourcesContent":[".mh-auto {\n  margin-left: auto !important;\n  margin-right: auto !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
