/** @format */
import React from 'react';
import {
  MdFullscreen,
  MdFullscreenExit,
  MdPause,
  MdPlayArrow,
  MdVolumeDown,
  MdVolumeMute,
  MdVolumeOff,
  MdVolumeUp,
  MdClosedCaption,
  MdClosedCaptionOff,
} from 'react-icons/md';

import Tooltip from '@material-ui/core/Tooltip';

import './VideoPlayer.scss';

export const ControlsBar = React.forwardRef((props, ref) => {
  const {
    video,
    ctt,
    onMouseMove,
    ofwidth,
    onSeek,
    ct,
    calcTime,
    pause,
    play,
    va,
    Mute,
    playing,
    fulls,
    exitFullScreen,
    enterFullScreen,
    isClosedCaptionOn,
    turnOnClosedCaption,
    turnOffClosedCaption,
    closedCaptionFileURL
  } = props;
  const { div, vdiv } = ref;
  return (
    <div
      id="video-player-controlbar"
      className={
        playing
          ? 'video-react-lower-bar'
          : 'video-react-lower-bar stick-control-bar'
      }
    >
      <div className="hundred tooltip">
        <div
          className="progress-video-react"
          ref={div}
          onMouseMove={(e) => {
            onMouseMove(e);
          }}
          onClick={onSeek}
        >
          <span style={{ left: `${ofwidth}%` }} className="tooltiptext">
            {ctt}
          </span>
          <div
            className="finnished"
            style={
              video.current
                ? {
                    width: `${
                      (video.current.currentTime / video.current.duration) * 100
                    }%`,
                    background: props.primaryColor ? props.primaryColor : '',
                  }
                : { width: 0 }
            }
          >
            <div className="point"></div>
          </div>
        </div>
      </div>
      <div className="time-stamps">
        <div className="current">{ct}</div>
        <div className="fullstime">
          {video.current ? calcTime(video.current.duration) : <>--:--</>}
        </div>
      </div>
      <div className="video-react-controls">
        <div id="play-button-area" tabIndex={0}>
          {playing ? (
            <Tooltip title="Pause" aria-label="add" placement="top">
              <div tabIndex={0} className="video-react-pause" onClick={pause}>
                <MdPause />
              </div>
            </Tooltip>
          ) : (
            <Tooltip title="Play" aria-label="add" placement="top">
              <div tabIndex={0} className="video-react-play" onClick={play}>
                <MdPlayArrow />
              </div>
            </Tooltip>
          )}
        </div>
        <div id="volume-and-fullscreen-buttons-area">
          <Tooltip title="Volume" aria-label="add" placement="top">
            <div className="video-react-volume" tabIndex={0}>
              <div className="volume-add">
                <div className="volume-div" ref={vdiv} onClick={va}>
                  <div
                    className="finnished"
                    style={
                      video.current
                        ? { width: `${(video.current.volume / 1) * 100}%` }
                        : { width: 0 }
                    }
                  ></div>
                  <div className="point"></div>
                </div>
              </div>
              {video.current ? (
                <>
                  {video.current.volume === 0 ? (
                    <MdVolumeOff onClick={Mute} />
                  ) : (
                    <>
                      {video.current.volume < 0.3 ? (
                        <>
                          <MdVolumeMute onClick={Mute} />
                        </>
                      ) : (
                        <>
                          {video.current.volume < 0.7 ? (
                            <>
                              <MdVolumeDown onClick={Mute} />
                            </>
                          ) : (
                            <MdVolumeUp onClick={Mute} />
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <MdVolumeUp />
                </>
              )}
            </div>
          </Tooltip>
          {closedCaptionFileURL ? <Tooltip title="Closed Caption" aria-label="add" placement="top">
            {isClosedCaptionOn ? (
              <div tabIndex={0} className="video-react-closed-caption" onClick={turnOffClosedCaption}>
                <MdClosedCaption />
              </div>
            ) : (
              <div tabIndex={0} className="video-react-closed-caption" onClick={turnOnClosedCaption}>
                <MdClosedCaptionOff />
              </div>
            )}
          </Tooltip> : ''}
          <Tooltip title="Fullscreen" aria-label="add" placement="top">
            {fulls ? (
              <div tabIndex={0} className="video-react-fullscreen" onClick={exitFullScreen}>
                <MdFullscreenExit />
              </div>
            ) : (
              <div tabIndex={0} className="video-react-fullscreen" onClick={enterFullScreen}>
                <MdFullscreen />
              </div>
            )}
          </Tooltip>
        </div>
      </div>
    </div>
  );
});
