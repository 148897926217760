// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chip-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.chip-container .chip {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  isolation: isolate;
  border: 1px solid #000000;
  border-radius: 24px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 8px 8px;
}
.chip-container .chip.chip-selected {
  background: #000000;
  color: white;
}
.chip-container .chip.chip-selected p {
  background: #000000;
  color: white;
}
.chip-container .chip.chip-unselected {
  color: black;
  background: #ffffff;
}
.chip-container .chip.chip-disabled.chip-unselected {
  color: black;
  background: #e0e0e0;
}
.chip-container .chip.clear {
  border: 0;
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/elements/TagSelectionComponent.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,uBAAA;AACF;AACE;EACE,eAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;EACA,kBAAA;EACA,yBAAA;EACA,mBAAA;EACA,UAAA;EACA,QAAA;EACA,YAAA;EACA,eAAA;AACJ;AACI;EACE,mBAAA;EACA,YAAA;AACN;AAAM;EACE,mBAAA;EACA,YAAA;AAER;AAEI;EACE,YAAA;EACA,mBAAA;AAAN;AAGI;EACE,YAAA;EACA,mBAAA;AADN;AAKE;EACE,SAAA;EACA,0BAAA;AAHJ","sourcesContent":[".chip-container {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n\n  .chip {\n    cursor: pointer;\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    padding: 8px 16px;\n    isolation: isolate;\n    border: 1px solid #000000;\n    border-radius: 24px;\n    flex: none;\n    order: 0;\n    flex-grow: 0;\n    margin: 8px 8px;\n\n    &.chip-selected {\n      background: #000000;\n      color: white;\n      p {\n        background: #000000;\n        color: white;\n      }\n    }\n\n    &.chip-unselected {\n      color: black;\n      background: #ffffff;\n    }\n\n    &.chip-disabled.chip-unselected {\n      color: black;\n      background: #e0e0e0;\n    }\n  }\n\n  .chip.clear {\n    border: 0;\n    text-decoration: underline;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
