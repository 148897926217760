/**
 * Copyright Beingana Jim Junior, 2021 and all the contributors. License Cn be found in the LICENCE file
 *
 * @format
 */
import React, { useRef, useState } from 'react';
import {
  MdClose,
  MdErrorOutline,
  MdFlipToBack,
  MdLoop,
  MdPause,
  MdPlayArrow,
} from 'react-icons/md';

import { ControlsBar } from './ControlsBar.jsx';
import './CourseVideoPlayer.scss';
import VideoWrapper from './VideoWrapper.jsx';
import { Icon } from '@vds/icons';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import { AnalyticsLibrary } from 'ysb-ui-libraries';
import SeekProgressBar from '../../components/common/seek.js';
import { ButtonGroup } from '@vds/buttons';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { videoStarted,videoMileStoneReached, videoCompleted } from '../../common/Analytics/GAWrapper';

export const CourseVideoPlayer = (props) => {
  const dispatch = useDispatch();
  const [playing, setplaying] = useState(false);
  const video = useRef(null);
  const div = useRef(null);
  const sect = useRef(null);
  const vdiv = useRef(null);
  const [error, seterror] = useState(false);
  const [y, sety] = useState(false);
  const [x, setx] = useState(false);
  const [on, seton] = useState(false);
  const [loaded, setloaded] = useState(true);
  const [fulls, setfulls] = useState(false);
  const [mute, setmute] = useState(false);
  const [ct, setcurrenttime] = useState('00:00');
  const [ctt, setctt] = useState('00:00');
  const [ofwidth, setofwidth] = useState(0);
  const [isClosedCaption, setIsClosedCaption] = useState(true);
  const { t, i18n } = useTranslation();

  function va(e) {
    const x = e.nativeEvent.layerX;
    const { offsetWidth } = vdiv.current;

    let time = (x / offsetWidth) * 1;
    video.current.volume = time;
  }
  useEffect(() => {

    if (calcTime(video.current.duration * 0.25) === calcTime(video.current.currentTime) && calcTime(video.current.currentTime) !== '00:00') {
      console.log("25 percent reached", calcTime(video.current.currentTime));

       videoMileStoneReached(Math.floor(video.current.currentTime),props.label,'25%',props.src)
    }
    else if (calcTime(video.current.duration * 0.5) === calcTime(video.current.currentTime) && calcTime(video.current.currentTime) !== '00:00') {
      console.log("50 percent reached");
      videoMileStoneReached(Math.floor(video.current.currentTime),props.label,'50%',props.src)
    }
    else if (calcTime(video.current.duration * 0.75) === calcTime(video.current.currentTime) && calcTime(video.current.currentTime) !== '00:00') {
      console.log("75 percent reached");
      videoMileStoneReached(Math.floor(video.current.currentTime),props.label,'75%',props.src)
    }
    // else if (calcTime(video.current.duration * 0.8) === calcTime(video.current.currentTime) && calcTime(video.current.currentTime) !== '00:00') {
    //   console.log("80 percent reached");
    //   AnalyticsLibrary.Track.videoMileStoneReached(Math.floor(video.current.duration),props.label,'80%')
    // }
    else if (calcTime(video.current.duration) === calcTime(video.current.currentTime) && calcTime(video.current.currentTime) !== '00:00') {
      console.log("100 percent reached");
      videoCompleted(Math.floor(video.current.duration),props.label,props.src)
    }
  }, [ct]);
  function onSeek(e) {
    const x = e.nativeEvent.layerX;
    const { offsetWidth } = div.current;
    const { duration } = video.current;

    let time = (x / offsetWidth) * duration;
    video.current.currentTime = time;

    let xx = x - 12;

    let seekwidth = (xx / offsetWidth) * 100;
    setofwidth(seekwidth);

    if (props.onSeek) {
      props.onSeek();
    }
  }

  function onMove(e) {
    const x = e.nativeEvent.layerX;
    const { offsetWidth } = div.current;
    const { duration } = video.current;

    let time = (x / offsetWidth) * duration;
    setctt(calcTime(time));
    let xx = x - 12;

    let seekwidth = (xx / offsetWidth) * 100;
    setofwidth(seekwidth);

    if (props.onSeek) {
      props.onSeek();
    }
  }

  function TimeUpdate(e) {
    const { currentTime, duration } = video.current;
    setcurrenttime(calcTime(currentTime));
    if (props.onTimeUpdate) {
      props.onTimeUpdate(e, currentTime, duration);
    }
  }

  async function Mute(e) {
    await setmute(!mute);
    if (video.current.volume > 0) {
      video.current.volume = 0;
    } else {
      video.current.volume = 1;
    }
    if (props.onMute) {
      props.onMute(mute);
    }
  }

  function calcTime(d) {
    if (isNaN(d)) {
      return '00:00';
    }
    let hours = d / 3600;
    let hh = d % 3600;
    let time = hh / 60;
    let h = Math.floor(hours);
    let f = Math.floor(time);
    let seconds = d % 60;
    seconds = Math.floor(seconds);
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    if (f < 10) {
      f = `0${f}`;
    }
    if (h <= 0) {
      h = ``;
    } else if (h < 10 && h > 0) {
      h = `0${h}:`;
    }

    return `${h}${f}:${seconds}`;
  }

  function setClipboard(text) {
    navigator.clipboard.writeText(text).then(
      function () { },
      function () {
        // eslint-disable-next-line no-restricted-globals
        alert('failed to copy url');
      }
    );
  }

  const play = (e) => {
    video.current.play();
    setplaying(true);

    if (props.onPlay) {
      props.onPlay(e);
    }
  };

  const pause = (e) => {
    video.current.pause();
    setplaying(false);
    if (props.onPause) {
      props.onPause(e);
    }
  };

  function contextMenu(e) {
    const { clientY, clientX } = e.nativeEvent;
    setx(clientX);
    sety(clientY);
    seton(true);
  }

  const enterFullScreen = (e) => {
    sect.current.requestFullscreen();
    if (props.onEnterFullScreen) {
      props.onEnterFullScreen(e);
    }
    setfulls(true);
  };

  const exitFullScreen = () => {
    sect.current.ownerDocument.exitFullscreen();
    setfulls(false);
  };

  const turnOffClosedCaption = (e) => {
    setIsClosedCaption(false);
    document.getElementsByClassName("video-react")[0].textTracks[0].mode = "hidden";
  };

  const turnOnClosedCaption = () => {
    setIsClosedCaption(true);
    document.getElementsByClassName("video-react")[0].textTracks[0].mode = "showing";
  };

  function handleClose() {
    seton(false);
  }

  const [percentage, setPercentage] = useState(50);

  const handlePercentageChange = (newPercentage) => {
    console.log(`New percentage: ${newPercentage}`);
  };

  return (
    <div className='video-content-area'>
      <section
        onContextMenu={(e) => {
          e.preventDefault();
          contextMenu(e);
        }}
        onClick={(e) => {

          const controlBarElement = document.getElementById(
            'video-player-controlbar'
          );
          if (!controlBarElement.contains(e.target) && !on && playing) {
            console.log('pause player');
            pause(e);
          } else if (!controlBarElement.contains(e.target) && !on && !playing) {
            if (calcTime(video.current.currentTime) === '00:00') {
              //   console.log('videostarted here')
              videoStarted (0,props.label,props.src)
            }
            play(e);
          }
        }}
        onKeyDown={(e) => {
          console.log("Key", e.key, mute, on, playing, e.target.className);
          const controlBarElement = document.getElementById(
            'video-player-controlbar'
          );
          if (e.key === 'Enter') {
            console.log("controlBarElement.contains(e.target)", e.target.className);
            if (controlBarElement.contains(e.target) === true && e.target.className === 'video-react-pause' && !on && playing) {
              console.log('pause player');
              pause(e);
            }
            else if (controlBarElement.contains(e.target) === true && e.target.className === 'video-react-play' && !on && !playing) {
              console.log('pause player');
              play(e);
            }
            else if (controlBarElement.contains(e.target) === true && e.target.className === 'video-react-fullscreen' && !on && playing && !fulls) {
              console.log('fullscreen player');
              enterFullScreen(e);
            }
            else if (controlBarElement.contains(e.target) === true && e.target.className === 'video-react-fullscreen' && !on && playing && fulls) {
              console.log('fullscreen player');
              exitFullScreen(e);
            }
            else if (controlBarElement.contains(e.target) === true && e.target.className === 'video-react-closed-caption' && !on && !isClosedCaption) {
              console.log('turnOnClosedCaption');
              turnOnClosedCaption(e);
            }
            else if (controlBarElement.contains(e.target) === true && e.target.className === 'video-react-closed-caption' && !on && isClosedCaption) {
              console.log('turnOffClosedCaption');
              turnOffClosedCaption(e);
            }
            else if (!controlBarElement.contains(e.target) && !on && !playing) {
              // console.log('start player');
              play(e);
            }
            else if (!controlBarElement.contains(e.target) && !on && playing) {
              // console.log('pause player');
              pause(e);
            }
            else if (controlBarElement.contains(e.target) === true && e.target.className === "video-react-volume" && mute === false && !on) {
              console.log('muteplayer player');
              Mute(e);
            }
            else if (controlBarElement.contains(e.target) === true && e.target.className === "video-react-volume" && mute === true && !on) {
              console.log('unmuteplayer player');
              Mute(e);
            }
          }
          //if(e.key==='Enter')play(e)}
        }}

        onBlur={() => {
          handleClose();
        }}
        className={`one ${props.className}`}
        ref={sect}
      >
        <div
          onClick={() => {
            console.log('handle clicked!!');
          }}
        ></div>
        <VideoWrapper
          onError={() => {
            seterror(true);
          }}
          ref={{ video: video }}
          autoPlay={props.autoPlay ? true : false}
          onPause={() => {
            setplaying(false);
          }}
          onPlay={() => {
            setplaying(true);
          }}
          className="video-react"
          onTimeUpdate={(e) => {
            TimeUpdate(e);
          }}
          src={props.src}
          type={props.type ? props.type : 'video/mp4'}
          closedCaptionFileURL={props.closedCaptionFileURL}
        />
        {video.current ? (
          <>
            {video.current.seeking ? (
              <div className="video-react-loading"></div>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}

        <ControlsBar
          ref={{ div, vdiv }}
          video={video}
          ctt={ctt}
          onMouseMove={onMove}
          ofwidth={ofwidth}
          onSeek={onSeek}
          ct={ct}
          calcTime={calcTime}
          pause={pause}
          play={play}
          va={va}
          Mute={Mute}
          playing={playing}
          fulls={fulls}
          exitFullScreen={exitFullScreen}
          enterFullScreen={enterFullScreen}
          isClosedCaptionOn={isClosedCaption}
          turnOnClosedCaption={turnOnClosedCaption}
          turnOffClosedCaption={turnOffClosedCaption}
          closedCaptionFileURL={props.closedCaptionFileURL}
        />

        <div className="video-react-error" style={error ? { opacity: 1 } : {}}>
          <span>
            <MdErrorOutline />
          </span>{' '}
          <span>
            <b>Error:</b> Failed to load Video
          </span>
          <span
            className="cancel"
            onClick={() => {
              seterror(false);
            }}
          >
            <MdClose />
          </span>
        </div>

        {on ? (
          <div
            className="menu-c"
            onClick={() => {
              handleClose();
            }}
          >
            <div
              className="menu-contxt"
              onClick={() => {
                handleClose();
              }}
            >
              <div className="video-rect-context" style={{ top: y, left: x }}>
                <ul className="context-list">
                  {playing ? (
                    <li className="play" onClick={pause}>
                      <span className="i">
                        <MdPause />
                      </span>
                      <span className="t">Pause</span>
                    </li>
                  ) : (
                    <li className="play" onClick={play}>
                      <span className="i">
                        <MdPlayArrow />
                      </span>
                      <span className="t">Play</span>
                    </li>
                  )}
                  <li
                    onClick={() => {
                      setClipboard(
                        video.current ? video.current.currentSrc : ''
                      );
                    }}
                  >
                    <span className="i">
                      <MdFlipToBack />
                    </span>
                    <span className="t">Copy Video Url</span>
                  </li>
                  {video.current ? (
                    <>
                      {video.current.loop ? (
                        <li
                          onClick={() => {
                            video.current.loop = false;
                          }}
                        >
                          <span className="i">
                            <MdLoop />
                          </span>
                          <span className="t">Stop Loop</span>
                        </li>
                      ) : (
                        <li
                          onClick={() => {
                            video.current.loop = true;
                          }}
                        >
                          <span className="i">
                            <MdLoop />
                          </span>
                          <span className="t">Loop</span>
                        </li>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {playing === false && loaded === true ? (
          <div className="poster" aria-label='video' tabIndex={0}>
            <div>
              <img src={props.poster} alt="video poster" />
              <div className='buttons-container'
                style={
                  props.primaryColor ? { background: props.primaryColor } : {}
                }
                onClick={() => {

                }}
              >
                <ButtonGroup
                  childWidth={'100%'}
                  viewport={'desktop'}
                  surface="dark"
                  rowQuantity={{ desktop: !isMobile ? 2 : 1, mobile: 1 }}
                  data={[
                    {
                      children: t('Watch preview'),
                      size: 'large',
                      use: 'secondary',
                      width: 'auto',
                      onClick: () => {
                        play();
                        setloaded(false);
                      },
                    },

                  ]}
                  alignment={'center'}
                />
                {/*<Icon
                  name="play"
                  color={'#000000'}
                  size="XLarge"
              />*/}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {props.childern}
      </section>
      <div className="video-seek-controls ph-4X">
        <SeekProgressBar percentage={percentage} setPercentage={setPercentage} onPercentageChange={handlePercentageChange} barHeight={3} barbackground={'#d8d8d8'} progressBarBackground="#ffffff" buttonColor='#ffffff' buttonHeight={16} />
      </div>
      

    </div>
  );
};
