import { Carousel } from '@vds/carousels';
import { Grid, Row, Col } from '@vds/grids';
import { Icon } from '@vds/icons';
import { Title } from '@vds/typography';
import React from 'react';
import { isMobile } from 'react-device-detect';
import CourseCardComponent from '../common/CourseCards/CourseCards.jsx';
import LearningPathCard from './LerningPathCard.jsx';
import { useTranslation } from 'react-i18next';

const LearningPathFilteredItem = ({ item }) => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Grid bleed="1440" colSizes={{ mobile: 4, tablet: 12, desktop: 12, }} >
        <Row>
          <Col>
            <div className='mb-12X sm-mb-6X u-flex-left'>
              <Icon
                name={item?.icon}
                size="30px"
                color="#000000"
              />
              <div className='ml-3X'>
                <Title size="large" bold>{t(item?.topic)}.</Title>

              </div>
            </div>
          </Col>
        </Row>
      </Grid>
      {item?.filteredList && (
        <div className="carousel-container-fix-- learning-path-carousel mb-16X">
          <Carousel
            peek="standard"
            layout={"1UP"}
            gutter="24px"
            surface="light"
            paginationInset="12px"
            paginationDisplay="persistent"
            data={item?.filteredList}
            renderItem={props => isMobile ? <CourseCardComponent data={props} /> : <LearningPathCard data={props} />}
          /></div>
      )}

      {/* Render filteredList here if needed */}

    </div>
  );
};

export default LearningPathFilteredItem;