// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landing-container {
  text-align: center;
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/views/LandingPage.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,cAAA;AACF","sourcesContent":[".landing-container {\n  text-align: center;\n  overflow: auto;\n}\n\n// @media screen and (max-width: 1024px) and (min-width: 320px) {\n//   .sc-dkjKgF.fKkgOb, .sc-dkjKgF.kErDhf {\n//     padding-left: 0;\n//     padding-right: 0;\n//   }  \n//   .yEsNm.cgECuw {\n//     padding-left: 0 !important;\n//     padding-right: 0 !important;\n//   }\n// }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
