import { useSelector } from "react-redux";
// import GrantsPageComponent from "./Grants";
import GrantsPageComponent from "./GrantsSuperBowl.jsx";
import { Loader } from "@vds/loaders";
import { useEffect, useState } from "react";

const GrantsWrapperComponent = () => {

    const aemData = useSelector((state) => state.Aem.data);
    const aemFAQ = useSelector((state) => state.Aem.FAQ);
    const aemIncentivesData = useSelector((state) => state.Aem.incentives);
    const allEventsWithTimeZone = useSelector((state)  => state.AdobeResources.allEventsWithTimeZone);
    const [offers, setOffers] = useState();

    useEffect(()=>{
        let offer = 0;
        aemIncentivesData?.forEach((val) => {
          if (val.id === 'NFL') {
            setOffers(aemIncentivesData[offer]);
          }
          offer++;
        });
      },[aemIncentivesData])

    return (<>
    {(!offers || aemFAQ.length == 0 || !aemData || !allEventsWithTimeZone) && <Loader fullscreen={true} active={true} surface="light"/>}
    {offers && aemFAQ.length > 0 && aemData && allEventsWithTimeZone && <GrantsPageComponent offers={offers}/>}
    </>);
}

export default GrantsWrapperComponent;