import React, { useEffect, useState } from 'react';
import { Pagination } from '@vds/pagination';
//import { offersList, updatedOffers } from '../../InfoHub/Offers/OffersMock.js';
// import OffersCardSecondaryComponent from './OffersCard.Jsx';
import OffersCardComponent from './OffersCard';
import { Title } from '@vds/typography';
import { Button } from '@vds/buttons';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalTitle, ModalBody, ModalFooter } from '@vds/modals';
import store from '../../../store/store';
import { updateRegisterTriggeredFrom } from '../../../store/aem';
const OffersFilteredResultsComponent = ({totalPages,offers,handlePagination}) => {
  const aemData = useSelector((state) => state.Aem.data);
  const [selectedPage,setSelectedPage] = useState(1);
  const navigate=useNavigate();
  const handlePageChange = (currentpage) =>{
    handlePagination(currentpage);
    setSelectedPage(currentpage);
  }
  const [showUnlockModal, setShowUnlockModal] = useState(false);
  const modalFunction = (e) => {
    setShowUnlockModal(true);
  };
  const modalChange = (modalOpened) => {
    if (modalOpened) {
      setShowUnlockModal(true);
    } else {
      setShowUnlockModal(false);
    }
  };
  return (
    <div className='offers'>
      <div className='card-container'>
          <div className="card mb-8X offers-signup ph-8X">
          <div className="ph-16X pt-12X pb-4X n-textCenter">
          <Title size='large' bold>{aemData?.offers?.signupTxt}</Title>
          </div>
          <Button 
  size="large"
  disabled={false}
  use="primary"
  onClick={e=>{store.dispatch(updateRegisterTriggeredFrom(location.pathname));navigate("/onboarding")}}>
  {aemData?.offers?.register}
</Button>

          </div>
        {offers.map(props => {
          console.log(props);
          let card = <div className='card mb-8X'><OffersCardComponent data={props} secondary={true} modal={modalFunction}/></div>;
          return card;
        })}
      </div>
      <div className='u-flex'>
        {totalPages > 1 && <Pagination
          selectPage={(page) => {handlePageChange(page)}}
          total={totalPages}
          selectedPage={selectedPage}
          showArrow={true}
        />}
      </div>
      {/* <Modal
      opened={showUnlockModal}
      surface="light"
      fullScreenDialog={false}
      disableAnimation={false}
      disableOutsideClick={false}
      ariaLabel="Testing Modal"
      onOpenedChange={modalChange}
    >
      <ModalTitle>
        How to unlock your offer.
      </ModalTitle>
      <ModalBody>
        Lorem ipsum dolor sit amet consectetur. Elit viverra enim at venenatis integer. Ac platea quisque erat duis aliquet ultrices eu eu commodo. Fermentum morbi odio tellus tellus cras dictum neque.
        <br /><br />
        Eu vitae quisque ac convallis aliquam morbi morbi sem suspendisse. Ac adipiscing non sapien commodo. Odio commodo quam consequat vitae posuere aliquet.
        <br /><br />
        Amet in magna aliquet eros faucibus sed rhoncus proin senectus. Mattis erat libero ipsum neque. Diam urna tincidunt nibh amet justo pretium tristique luctus suscipit. Ornare orci semper in at in non.
      </ModalBody>
    </Modal> */}
    </div>
  );
};

export default OffersFilteredResultsComponent;
