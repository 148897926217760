// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.step-container {
  background-color: #d8dada;
  height: 4px;
}

.step-container .step-indicator {
  height: 100%;
  background-color: #6f7171;
}`, "",{"version":3,"sources":["webpack://./src/elements/stepper/stepper.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,WAAA;AACF;;AAEA;EACE,YAAA;EACA,yBAAA;AACF","sourcesContent":[".step-container {\n  background-color: #d8dada;\n  height: 4px;\n}\n\n.step-container .step-indicator {\n  height: 100%;\n  background-color: #6f7171;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
