import { useNavigate } from 'react-router-dom';

import { TileContainer } from '@vds/tiles';
import { Body, Title } from '@vds/typography';

import timerLogo from '../assets/images/cards/timer.svg';
import './SecondaryCourseCardComponent.scss';
import { contentClick, pageLoadCompleted, pageLoadStarted } from '../common/Analytics/GAWrapper';
import { Icon } from '@vds/icons';
import store from '../store/store';
import { updateSelectedCourse } from '../store/aem';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
const SecondaryCourseCardComponent = (props) => {
  const { t, i18n } = useTranslation();
  //console.log('props ===> ', props);
  const navigate = useNavigate();
  const selectedTopics = useSelector(
    (state) => state.Aem.registrationStepsData.businessData.topics
  );
  const trimTitle = (title) => {
    const MAX_LENGTH = 65 // maximum number of characters to show on the tiles

    if (title.length > MAX_LENGTH) {
      //trim the string to the maximum length
      let titleTrimmed = title.substr(0, MAX_LENGTH);

      //re-trim if we are in the middle of a word
      titleTrimmed = titleTrimmed.substr(0, Math.min(titleTrimmed.length, titleTrimmed.lastIndexOf(" ")));
      titleTrimmed += "...";
      return titleTrimmed;
    }
    return title;
  }

  const onCardClicked = (id) => {

    store.dispatch(updateSelectedCourse(id.split(':')[1]))
    console.log("Card clicked", props.courseFormat)
    if (props.courseFormat === ('Course' || 'Curso')) {
      localStorage.setItem("courseUrl",window.location.href)
      contentClick(
        props.label,
        window.location.origin +`/course-details/${id}`,
        selectedTopics.toString()
      );
    }
    //pageLoadStarted( props.label,window.location.origin+localStorage.getItem('prevUrl'),selectedTopics?.toString())
    navigate(`/course-details/${id}`);
    // pageLoadCompleted();
    window.scrollTo(0, 0);
  };

  return (
    <TileContainer
      ariaLabel={props.ariaLabel}
      padding="12px"
      aspectRatio="2:3"
      width="100%"
      showDropShadow={true}
      onClick={(e) => onCardClicked(props.courseId)}
      backgroundColor="white"
    >
      <div className="secondaryCourceCard">
        {props.tag ? (
          <div className="badge">
            <Body size="small" color="#ffffff" bold={false}>
              {t(props.tag)}
            </Body>
          </div>
        ) : null}
        <div
          className="bg-image"
          style={{ backgroundImage: 'url(' + props.image + ')' }}
          alt={props.label}
        ></div>
        <div className="description pl-3X pb-1X pt-3X">
          <div className="info mb-3X">
            <Body size="small" color="#000000" bold={true}>
              {t(props.courseFormat)}
            </Body>
          </div>
          <div className="label mb-3X">
            <Title bold={false} color="#000000" size="medium">
              {trimTitle(props.label)}
            </Title>
          </div>
          <div className="duration">
            {props.dateStart && props.dateStart != '' ?
              <div className="time">
                <Icon
                  name="calendar"
                  size="small"
                  color="#6F7171"
                />
                <div className="ml-2X">
                  <Body size="small" color='#6F7171' bold={true}>
                    {t(props.dateStart)}
                  </Body>
                </div>
              </div>
              :
              props.duration > 0 ? <div className="time">
                <Icon
                  name="count-down"
                  size="small"
                  color="#6F7171"
                />
                <div className="ml-2X">
                  <Body size="small" color="#6F7171" bold={true}>
                    {t(props.duration)} {t("minutes")}
                  </Body>
                </div>
              </div> : ''}
            <Icon
              name="right-arrow"
              size="small"
              color="#000000"
            />
          </div>
        </div>
      </div>
    </TileContainer>
  );
};

export default SecondaryCourseCardComponent;
