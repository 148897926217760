// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resources-standard {
  border-radius: 8px;
  background-color: white;
  padding: 16px;
  display: block !important;
  height: auto !important;
}
.resources-standard .eye-info {
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/views/ResourceStandard.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,uBAAA;EACA,aAAA;EACA,yBAAA;EAEA,uBAAA;AAAJ;AAEI;EACE,aAAA;EACA,mBAAA;AAAN","sourcesContent":[".resources-standard {\n    border-radius: 8px;\n    background-color: white;\n    padding: 16px;\n    display: block !important;\n    // width: 100% !important;\n    height: auto !important;\n  \n    .eye-info {\n      display: flex;\n      align-items: center;\n  \n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
