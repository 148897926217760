// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.onbording-container {
  text-align: center;
  width: 100%;
}

.onbording-container .tab-container {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 384px;
}

.onbording-container .tab-container > div {
  width: 100%;
}

@media (max-width: 767px) {
  .onbording-container {
    text-align: left;
  }
  .onbording-container .tab-container {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/onboarding/onboardingLanding.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,cAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE;IACE,gBAAA;EACF;EACA;IACE,WAAA;EACF;AACF","sourcesContent":[".onbording-container {\n  text-align: center;\n  width: 100%;\n}\n\n.onbording-container .tab-container {\n  display: flex;\n  justify-content: center;\n  margin: 0 auto;\n  width: 384px;\n}\n\n.onbording-container .tab-container > div {\n  width: 100%;\n}\n\n@media (max-width: 767px) {\n  .onbording-container {\n    text-align: left;\n  }\n  .onbording-container .tab-container {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
