import React, { useEffect, useState } from 'react';
import LearningPathFilteredItem from './LearningPathFilteredItem.jsx';
import FilterBadge from '../common/FilterBadge/FilterBadge.jsx';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalFooter, ModalTitle } from '@vds/modals';
import { Checkbox, CheckboxGroup } from '@vds/checkboxes';
import { Toggle } from '@vds/toggles';
import { Col, Grid, Row } from '@vds/grids';
import { Button, ButtonGroup, TextLink } from '@vds/buttons';
import { Body } from '@vds/typography';
import { Icon } from '@vds/icons';
import { useSelector } from 'react-redux';
import { sortArrayByName, sortLearningPathList, topicsCollection } from '../../utility/utils.js';
import MultiselectChips from '../../elements/multiselectChips/multiselectChips.jsx';

const LearningPathFilter = () => {
  const { t, i18n } = useTranslation();
  const [selectedFilterOptions, setSelectedFilterOptions] = useState({ id: '1', arialabel: 'View all', label: 'View all', data: '', selected: true });
  const [selectedFilterOptions2, setSelectedFilterOptions2] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const learningPaths = useSelector((state) => state.AdobeResources.learningPaths);
  const [allLearningPathList, setAllLearningPathList] = useState([]);
  const [filteredLearningPathList, setFilteredLearningPathList] = useState([]);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const NUMBER_OF_TOPICS_RESULTS = 5;
  const [filterSortData, setFilterSortData] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const userLanguage = localStorage.getItem("lng") ? localStorage.getItem("lng") : "en";
  const [onlySpanishLP, setOnlySpanishLP] = useState(false);
  const [showSpanish, setShowSpanish] = useState(false);
  const TAG_FOR_SPANISH = 'solo en español';

  let filterOptions2 = sortArrayByName(topicsCollection);

  useEffect(() => {
    setShowSpanish(learningPaths.filter(lp => lp?.tags && lp?.tags?.indexOf(TAG_FOR_SPANISH) != -1).length > 0)
    filterOptions2 = filterOptions2.filter(e => learningPaths.filter(lp => lp?.tags && lp?.tags?.indexOf(e.label) != -1).length > 0);
    let list = filterOptions2.map(e => createFilterObject(e.label, learningPaths, e.icon));
    setAllLearningPathList(list);

    let filterList = list;
    
    console.log("filteredLearningPathList", filterList);
    if (filterList.length < NUMBER_OF_TOPICS_RESULTS) {
      setFilteredLearningPathList(filterList);
      setShowLoadMore(false);
    } else {
      setFilteredLearningPathList(filterList.slice(0, NUMBER_OF_TOPICS_RESULTS));
      setShowLoadMore(true);
    }

    setFilteredLearningPathList(list.slice(0, 5));
  }, []);

  const filterLearningPath = (topicsOfInterest) => {
    let filterList = allLearningPathList;
    if(topicsOfInterest?.length > 0) {
      filterList = allLearningPathList.filter(e => topicsOfInterest.indexOf(e.topic) != -1);
    }
    
    console.log("filteredLearningPathList", filterList);
    if (filterList.length < NUMBER_OF_TOPICS_RESULTS) {
      setFilteredLearningPathList(filterList);
      setShowLoadMore(false);
    } else {
      setFilteredLearningPathList(filterList.slice(0, NUMBER_OF_TOPICS_RESULTS));
      setShowLoadMore(true);
    }
  };

  const handleFilter = (e) => {
    console.log("handleFilter",e, selectedTopics);
    setSelectedFilterOptions(e);
    switch (e.label) {
      case "My topics":
        let myTopicsSelectedTopics = [];
        topicsCollection.forEach((e) => {
          if (businessData?.attributes[e.label] == "Yes") {
            myTopicsSelectedTopics.push(e.label);
          }
        });
        setFilterSortData(myTopicsSelectedTopics.map(e => ({ label: e, value: e })));
        filterLearningPath(myTopicsSelectedTopics);
        break;
      case "View all":
      case "clear filter":
        setFilterSortData([]);
        filterLearningPath(filterOptions2.map(e => e.label));
        setSelectedFilterOptions2([]);
        setSelectedTopics([]);
        break;
      case "topic filter":
        if(selectedTopics.length > 0) {
          setFilterSortData(selectedTopics.map(e => ({ label: e, value: e })));
          filterLearningPath(selectedTopics);
        } else {
          handleFilter({ id: '1', arialabel: 'View all', label: 'View all', data: '' });
          filterLearningPath([]);
        }
        break;
      default:
        setFilterSortData(selectedFilterOptions2.map(e => ({ label: e.label, value: e.label })));
        filterLearningPath(selectedFilterOptions2.map(e => e.label));
    }
  };

  const modalChange = (modalOpened) => {
    if (modalOpened) {
      setShowFilterModal(true);
    } else {
      setShowFilterModal(false);
    }
  };

  const filterOptions = [
    { id: '1', arialabel: 'View all', label: 'View all', data: '' },
  ];

  const createFilterObject = (topic, lp, icon) => {
    return {
      topic: topic,
      icon: icon,
      filteredList: sortLearningPathList(lp.filter(e => e?.tags && e?.tags?.indexOf(topic) != -1), userLanguage)
    };
  };

  const loadMoreLearningPath = () => {
    setFilteredLearningPathList(allLearningPathList.slice(0, filteredLearningPathList.length + NUMBER_OF_TOPICS_RESULTS));
    if (allLearningPathList.length < filteredLearningPathList.length + NUMBER_OF_TOPICS_RESULTS) {
      setShowLoadMore(false);
    }
  };

  const handleRemoveFilter = (filterRemoved) => {
    let filteredTopics = filterSortData.filter(e => e.label != filterRemoved);
    if (filteredTopics.length > 0) {
      setFilterSortData(filteredTopics);
      filterLearningPath(filteredTopics.map(e => e.label));
      setSelectedFilterOptions2(filteredTopics.map(e => ({ label: e })));
    } else {
      handleFilter({ id: '1', arialabel: 'View all', label: 'View all', data: '' });
      setSelectedFilterOptions2([]);
    }
  };

  const handleRemoveAllFilter = () => {
    handleFilter({ id: '1', arialabel: 'View all', label: 'View all', data: '' });
    setSelectedFilterOptions2([]);
  };

  const handleSpanishToggle = (e, data) => {
    if (data.on) {
      //Show Only Spanish
      let spanishLP = learningPaths.filter(e => e?.tags && e?.tags?.indexOf(TAG_FOR_SPANISH) != -1);
      let filterOption = filterOptions2.filter(e => spanishLP.filter(lp => lp?.tags && lp?.tags?.indexOf(e.label) != -1).length > 0);
      let list = filterOption.map(e => createFilterObject(e.label, spanishLP, e.icon));
      setFilteredLearningPathList(list);
      setOnlySpanishLP(true);
      setShowLoadMore(false);
    } else {
      //Show filter for Spanish language
      filterLearningPath(allLearningPathList);
      setFilteredLearningPathList(allLearningPathList.slice(0, 5));
      if (allLearningPathList.length > NUMBER_OF_TOPICS_RESULTS) {
        setShowLoadMore(true);
      }
      setOnlySpanishLP(false);
    }
  };

  const onKeyDown = (event, item) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      handleRemoveFilter(item);
    }
  }

  return (
    <div className='bg-white pt-8X pb-16X'>
      <Grid>
        <Row>
          <Col
            colSizes={{
              mobile: 4,
              tablet: 12,
              desktop: 12,
            }}
          >
            {userLanguage == "es" && showSpanish &&
              <div className="spanish-toggle mb-6X">
                <span className="toggle-text mr-2X">
                  {t('solo en español')}
                </span>
                <span className='toggle-text mr-2X'></span>
                <Toggle
                  disabled={false}
                  showText={false}
                  onChange={handleSpanishToggle}
                  ariaLabel={t('solo en espanol toggle button')}
                />
              </div>
            }
            <div className='carousel-container-fix n-flex-learning mb-12X '>

              <div className='filter'>
                <FilterBadge nav={t('Courses')}
                  handleChange={handleFilter}
                  options={filterOptions}
                  selectedOptions={selectedFilterOptions}
                  showFilterBadge={true}
                  iconKind={selectedFilterOptions.label == "topic filter" ? "highContrast" : "lowContrast"}
                  filterReverse={true}
                  surfaceType="colorFill"
                  modal={modalChange}
                  onChange={e => { console.log("Inside Courses filter", e); }}
                  disabled={onlySpanishLP} />
              </div>
              {filterSortData.length > 0 && (
              <div className='options'>
                <div className='u-flex-wrap'>
                  {filterSortData.map((item, n) => {
                    return (
                      <div key={n} className='u-flex chips mr-3X mb-3X'>
                        <Body size='small'>{t(item.label)}</Body>
                        <span className='ml-2X u-flex'
                          onClick={e => handleRemoveFilter(item.value)}
                          onKeyDown={(e) => { onKeyDown(e, item.value) }}
                          tabIndex={0} aria-label={`${t('remove filter')} ${t(item.label)}`}>
                          <Icon name="close" size="small" color="#000000" /></span>
                      </div>);
                  })}
                  {filterSortData.length > 0 ? (
                    <span className='mb-2X'>
                      <TextLink type="standAlone"
                        size='small'
                        onClick={e => handleRemoveAllFilter()}
                        role='button'
                        ariaLabel={t('remove all filter')}>
                        {t("Clear All")}
                      </TextLink>
                    </span>

                  ) : ''}
                </div>

              </div>
                )}

            </div>
          </Col>
        </Row>
      </Grid>

      {
        filteredLearningPathList.map((item, index) => {
          let _html = <LearningPathFilteredItem key={index} item={item} />;
          return _html;
        })
      }
      {
        showLoadMore && <div className='n-textCenter mt-16X u-flex'>
          <Button
            size="large"
            disabled={false}
            use="secondary"
            onClick={e => loadMoreLearningPath()}>
            {t('Load more')}
          </Button>

        </div>
      }
      <Modal
        opened={showFilterModal}
        surface="light"
        fullScreenDialog={false}
        disableAnimation={false}
        disableOutsideClick={false}
        ariaLabel={t("Testing Modal")}
        onOpenedChange={modalChange}
      >
        <ModalTitle>
        {t('Select your topics.')}
        </ModalTitle>
        <ModalBody>
          <div className='learning-topics'>
            <MultiselectChips
              optionItems={filterOptions2}
              selected={selectedFilterOptions2}
              onChange={(e) => { setSelectedTopics(e.map(e => e.label)); }}
              maxSelect={''}
              orOption={[{ label: '' }]}
              ariaLabel={''}
              showOrOption={false}>
            </MultiselectChips>
          </div>

          <div className=' mt-8X sm-mt-6X'>
            <ButtonGroup
              childWidth={'100%'}
              viewport={'desktop'}
              rowQuantity={{ desktop: 2 }}
              alignment='left'
              data={[

                {
                  use: 'primary',
                  width: 'auto',
                  children: t('View results'),
                  disabled: selectedTopics.length == 0 ? true : false,
                  onClick: () => { setShowFilterModal(false); handleFilter({ label: 'topic filter' }); setSelectedFilterOptions2(selectedTopics.map(e => ({ label: e }))); }

                }, {
                  use: 'textLink',
                  width: 'auto',
                  children: t('Clear all'),
                  disabled: selectedTopics.length == 0 ? true : false,
                  onClick: () => { handleFilter({ id: '1', arialabel: 'View all', label: 'View all', data: '' }); setSelectedFilterOptions2([]);},

                }

              ]}
            />
          </div>


        </ModalBody>
       {/* <ModalFooter
          buttonData={{
            primary: {
              children: t('View results'),
              onClick: () => { setShowFilterModal(false); handleFilter({ label: 'topic filter' }); setSelectedFilterOptions2(selectedTopics.map(e => ({ label: e }))); },
            },
            close: {
              children: t('Clear all'),
              onClick: () => { handleFilter({ id: '1', arialabel: 'View all', label: 'View all', data: '' }); },
            },
          }}
        />*/}
      </Modal>
    </div >
  );
};

export default LearningPathFilter;