// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.multi-grants-component {
  margin: 0px auto;
  max-width: 1020px;
}
@media screen and (max-width: 767px) {
  .multi-grants-component {
    margin-left: 16px;
  }
}
@media screen and (max-width: 767px) {
  .multi-grants-component .card-body-auto {
    margin-bottom: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/specialGrants/MultiGrantsNavigation/MultiGrantsNavigation.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,iBAAA;AACJ;AAAI;EAHJ;IAIM,iBAAA;EAGJ;AACF;AAAM;EADF;IAEI,mBAAA;EAGN;AACF","sourcesContent":[".multi-grants-component {\n    margin: 0px auto;\n    max-width: 1020px;\n    @media screen and (max-width: 767px) {\n      margin-left: 16px;\n    }\n\n    .card-body-auto{\n      @media screen and (max-width: 767px) {\n        margin-bottom: 16px;\n      }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
