import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ButtonGroup, TextLink } from '@vds/buttons';
import { Notification } from '@vds/notifications';
import StepIndicatorComponent from '../../../elements/stepper/stepper';
import {
  updateRegistrationStepTopics,
  updateRegistrationStepGroups,
  updateRegistrationStepLocation,
  updateRegistrationStepUserDetails,
  updateSpanish,
  updateUiLocaledata,
  updateAccessId
} from '../../../store/aem';
import store from '../../../store/store';
import GroupsSelectionComponent from './GroupsSelectionComponent/GroupsSelectionComponent';
import LocationSelectionComponent from './LocationSelectionComponent/LocationSelectionComponent';
import RegistrationComponent from './RegistrationComponent/RegistrationComponent';
import './RegistrationFlowComponent.scss';
import RegistrationHeaderComponent from './RegistrationHeaderComponent/RegistrationHeaderComponent';
import TopicsSelectionComponent from './TopicsSelectionComponent/TopicsSelectionComponent';
import { userSignedIn, userRegistered, errorMessage } from '../../../common/Analytics/GAWrapper'
import blake2b from 'blake2b';
import { Buffer } from 'buffer';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { Micro } from '@vds/typography';
import { useNavigate, useParams } from 'react-router-dom';
import AccessCodeValidation from './PartnerLogin/AccessCodeValidation';
import { result } from 'lodash';
import { invalid } from 'moment';
const PartnerRegistrationFlowComponent = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const registrationStepsData = useSelector(
    (state) => state.Aem.registrationStepsData
  );
  let { userType } = useParams();
  const isPartner = userType == "partner" ? true : false;
  const spanishData = useSelector((state) => state.Aem.spanishLang);
  const partnerSpanishData = useSelector((state) => state.Aem.partnerSpanishLang);
  const registerPassword = useSelector((state) => state.Aem.password);
  const engData = useSelector((state) => state.Aem.registerData);
  const partnerEngData = useSelector((state) => state.Aem.partnerRegisterData);
  let isSpanish = localStorage.getItem("lng") === 'es' ? true : false;
  const selectedCourse = useSelector((state) => state.Aem.selectedCourse);
  const [aemData, setAemData] = useState(!isSpanish ? isPartner ? partnerEngData : engData : isPartner ? partnerSpanishData : spanishData);
  const [enableFocus, setEnableFocus] = useState(true);
  useEffect(() => {
    setAemData(!isSpanish ? isPartner ? partnerEngData : engData : isPartner ? partnerSpanishData : spanishData);
  }, [isSpanish, engData, spanishData, partnerEngData, partnerSpanishData]);
  const [viewNotification, setViewNotification] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [isDisabled, setIsDisabled] = useState(true);
  const [showError, setShowError] = useState('');
  const [showMessage, setShowMessage] = useState('');
  const [showErrTitle, setShowErrTitle] = useState('')
  const businessData = useSelector(
    (state) => state.Aem.registrationStepsData.businessData
  );


  const [showNextbtn, setShowNextbtn] = useState(true);
  const [organizationName, setOrganizationName] = useState("");
  const [accessCode, setAccessCode] = useState("");
  const [accessCodeError, setAccessCodeError] = useState("");
  const [accessCodeStatus, setAccessCodeStatus] = useState(false)
  const [partnerData, setPartnerData] = useState({ "accessCode": "" })
  const [zipCode, setZipCode] = useState("");

    useEffect(() => {
    const validateZipcode = (zipCode) => {
      const regex = /^[0-9\s]+$/;
      // console.log("@@@@>>",regex.test(zipCode));
      return regex.test(zipCode);
    }
    const {
     
      businessData: { city, state },
    } = registrationStepsData;

    console.log("accessCode Testing=> ", accessCode)
    console.log("Organization name Testing=> ", organizationName)
    setPartnerData({ "accessCode": accessCode })
    console.log("partner's data Testing=> ", partnerData)

    if(accessCode && organizationName && city && state && zipCode){
      if(validateZipcode(zipCode)){
        setShowNextbtn(false)
      }else{
        setShowNextbtn(true)
      }
      // setShowNextbtn(false)
    }else{setShowNextbtn(true) }

  }, [organizationName,accessCode,zipCode,registrationStepsData]);
  const verifyAccesscode = async () => {
    const queryParams = new URLSearchParams(partnerData).toString();
    const url = global.config.PLATFORM_API_URL+ `/v2/user/partner?${queryParams}`;
    // const url = "https://platform-api-qa1.np-digitalready.ebiz.verizon.com"+ `/v2/user/partner?${queryParams}`;
    
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        
        throw new Error('Invalid access code');
      }
      const result = await response.json();
      if (result.success == true) {
        setAccessCodeStatus(true);
        setAccessCodeError("")
        store.dispatch(updateAccessId(result?.data?.accessId));
        return { "accessCodeStatus": result.success, "data": result }
      }else{
        setAccessCodeStatus(false);
      }

    } catch (error) {
      console.log("mic Error>", error.message);
      setAccessCodeError(error.message)
      setAccessCodeStatus(false);
      return { status: "error ", message: error.message, "accessCodeStatus": false };
    }
    
    // return { "accessCodeStatus": false}

  }



  const BackButton = {
    children: t('Back'),
    size: 'large',
    use: 'secondary',
    width: 'auto',
    onClick: () => changeStep('back'),
  };
  const NextButton = {
    children: t('Next'),
    size: 'large',
    use: 'primary',
    width: 'auto',
    disabled: showNextbtn, //isDisabled,
    onClick: () => changeStep('next'),
  };
  const SkipButton = {
    children: t('Skip'),
    size: 'large',
    use: 'primary',
    width: 'auto',
    disabled: false, //isDisabled,
    onClick: () => {
      const langUI = localStorage.getItem('lng');
      store.dispatch(updateRegistrationStepTopics(["I'm not sure"]));
      if(langUI === 'es'){
        store.dispatch(updateRegistrationStepTopics(["No estoy seguro"]));
      }
      changeStep('next');
    },
  };
  const RegisterButton = {
    children: t('Register'),
    size: 'large',
    use: 'primary',
    width: 'auto',
    disabled: isDisabled,
    onClick: () => register('next'),
  };

  const buttonGroupData = {
    1: [NextButton],
    2: [BackButton, registrationStepsData?.businessData?.topics?.length > 0 ? NextButton : SkipButton],
    // 3: [BackButton, NextButton],
    3: [BackButton, RegisterButton],
  };

  // useEffect(() => {
  //   const {
  //     user: { email, password },
  //     businessData: { topics, groups, city, state, zipcode },
  //   } = registrationStepsData;
  //   if (currentStep === 1) {
  //     if (topics.length > 0) {
  //       setIsDisabled(false);
  //     } else {
  //       setIsDisabled(true);
  //     }
  //   } else if (currentStep === 2) {
  //     // if (groups.length > 0) {
  //     setIsDisabled(false);
  //     // } else {
  //     //   setIsDisabled(true);
  //     // }
  //   } else if (currentStep === 3) {
  //     setShowError('')
  //     setViewNotification(false)
  //     if (city && state && zipcode) {
  //       setIsDisabled(false);
  //     } else {
  //       setIsDisabled(true);
  //     }
  //   } else if (currentStep === 4) {
  //     if (showError === '') {
  //       localStorage.setItem('email', '')
  //     }
  //     if (email && password) {
  //       setIsDisabled(false);
  //     } else {
  //       setIsDisabled(true);
  //     }
  //   }
  // }, [currentStep, registrationStepsData]);

  useEffect(() => {
    const {
      user: { email, password },
      businessData: { topics, groups, city, state, zipcode },
    } = registrationStepsData;
    if (currentStep === 3) {
      if (email && password) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }
  }, [currentStep, registrationStepsData]);


  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {

      store.dispatch(updateSpanish(false));
      store.dispatch(updateRegistrationStepTopics([]));
      store.dispatch(updateRegistrationStepGroups([]));
      store.dispatch(updateRegistrationStepLocation(''));
      store.dispatch(updateRegistrationStepUserDetails(''));

    }
  }, [])




  const changeStep = async (event) => {
    // if (event === 'back') setRegisterData([]); //need to revisit
    if (currentStep === 3) {
      let uiLocale = isSpanish ? 'es-ES' : 'en-US';
      let contentLocale = isSpanish ? 'es-ES' : 'en-US';
      console.log('')
      const locale = {
        uiLocale,
        contentLocale,
      }
      store.dispatch(updateUiLocaledata(locale));
    }
    switch (currentStep) {
      case 1:
        localStorage.setItem('prevUrl', window.location.href + '- step1')
        break;
      case 2:
        localStorage.setItem('prevUrl', window.location.href + '- step2')
        break;
      case 3:
        localStorage.setItem('prevUrl', window.location.href + '- step3')
        break;
      case 4:
        localStorage.setItem('prevUrl', window.location.href + '-step4')
        break;
    }

    if (currentStep === 1 && event === 'next') {
      const status = await verifyAccesscode();
      console.log("mic>>",status)
      if (status.accessCodeStatus) {
        setCurrentStep(currentStep + 1);
      } else if(!status.aceessCodeStatus){
        setViewNotification(true);
        setShowErrTitle(t('Invalid access code'));
        setShowError(t('Your access code appears to be invalid. Please check your code and try again'));
        setEnableFocus(false);
        window.scrollTo({top:0,behavior:"smooth"});
        errorMessage("invalid access code entered")
      }

    } else {
      setViewNotification(false);
      setCurrentStep(event === 'next' ? currentStep + 1 : currentStep - 1);
    }

    document?.getElementById('RegisterTabElement')?.focus();
  };

  const handleLanguge = () => {
    store.dispatch(updateSpanish(!isSpanish));
    store.dispatch(updateRegistrationStepTopics([]));
  };
  useEffect(() => {
    const langUI = localStorage.getItem('lng');
    if (langUI === null || langUI === 'en') {
      store.dispatch(updateSpanish(false));
    } else if (langUI === 'es') {
      store.dispatch(updateSpanish(true));

    }
  }, []);

  const hideNotification = () => {
    setViewNotification(false);
    setEnableFocus(true);
  };
  const register = async () => {

    console.log('registrationStepsData => ', registrationStepsData);
    console.log('registrationStepsData => ', registrationStepsData.zipcode, registrationStepsData.topics, registrationStepsData.email);
    setViewNotification(false);
    setEnableFocus(true);
    document.cookie = `firstTimeLogin=true;domain=${global.config.APP_COOKIE_DOMAIN};path=/`
    // localStorage.setItem('email',registrationStepsData?.user?.email)
    //       setViewNotification(true);
    //       setShowErrTitle(aemData?.errorMessages?.notificationTitleTxt)
    //       setShowError(aemData?.errorMessages?.notificationMsgTxt)
    //       setEnableFocus(false);


    console.log('registrationStepsData => ', registrationStepsData);

    const output = new Uint8Array(32);
    console.log("ResponseDAta, ", registrationStepsData)
    const input = Buffer.from(registrationStepsData.user.email);
    const hashedEmail = blake2b(output.length).update(input).digest('hex');
    console.log("Email", registrationStepsData.user.email)
    console.log("HashedValue", hashedEmail)
    setIsDisabled(true);
    setShowMessage(aemData?.registerModule?.registerProgressTxt)
    try {
      let response = await fetch(
        global.config.PLATFORM_API_URL + '/v2/user/profile',
        {
          method: 'POST',
          body: JSON.stringify(registrationStepsData),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      let responseJson = await response.json();
      console.log('responseJson---->', responseJson);
      if (responseJson.success === true) {
        //1st call
        let loginResponse = await fetch(
          global.config.AUTO_LOGIN_URL,
          {
            method: 'POST',
          }
        );

        if (loginResponse) {
          let loginResponseJson = await loginResponse.json();

          loginResponseJson.callbacks[0].input[0].value = registrationStepsData.user.email;
          loginResponseJson.callbacks[1].input[0].value = registerPassword.password;
          console.log('loginResponseJson---->', loginResponseJson);
          // Set Cookie
          const hostname = window.location.hostname;
          const domain = hostname.substring(hostname.lastIndexOf(".", hostname.lastIndexOf(".") - 1));
          Cookies.set('isNewUser', 'true', { domain: domain, path: '/', secure: true })
          userRegistered(registrationStepsData?.businessData?.zipcode, [...registrationStepsData?.businessData?.topics]?.sort(), hashedEmail, responseJson.data.uuid, 'partner', isPartner, partnerData);
          //2nd call
          let loginResponse2 = await fetch(
            global.config.AUTO_LOGIN_URL,
            {
              method: 'POST',
              body: JSON.stringify(loginResponseJson),
              credentials: 'include',
              mode: 'cors',
              headers: {
                'Accept': 'application/json, text/plain, */*',
                'Accept-API-Version': 'resource=2.0',
                'Content-Type': 'application/json'
              },
            }
          );
          let loginResponseJson2 = await loginResponse2.json();
          console.log('loginResponseJson2', loginResponseJson2)
          userSignedIn();
          if (localStorage.getItem('learningPathId')) {
            let learningPathId = localStorage.getItem('learningPathId');
            localStorage.removeItem('learningPathId');
            window.location.href = `${global.config.SSO_LOGIN_COURSE}${learningPathId}`;
          } else {
            //Added route to spanish page on partner registration
            if(isSpanish) {
              window.location.href=`${global.config.SSO_LOGIN_WITHOUT_GA}es`;
            } else {
              window.location.href = global.config.SSO_LOGIN;
            }
          }
        } else {
          userSignedIn();
          if (localStorage.getItem('learningPathId')) {
            let learningPathId = localStorage.getItem('learningPathId');
            localStorage.removeItem('learningPathId');
            window.location.href = `${global.config.SSO_LOGIN_COURSE}${learningPathId}`;
          } else {
            //Added route to spanish page on partner registration
            if(isSpanish) {
              window.location.href=`${global.config.SSO_LOGIN_WITHOUT_GA}es`;
            } else {
              window.location.href = global.config.SSO_LOGIN;
            }
          }
        }
      } else if (responseJson.success === false) {


        //profile call fail
        setShowError(responseJson.error.message)
        setIsDisabled(false);
        setShowMessage('');
        if (responseJson?.error?.message === "User Already Registered" || responseJson?.error?.message === "Email already exist") {
          errorMessage("User Already Registered")
          localStorage.setItem('email', registrationStepsData?.user?.email)
          setViewNotification(true);
          setShowErrTitle(aemData?.errorMessages?.notificationTitleTxt)
          setShowError(aemData?.errorMessages?.notificationMsgTxt)
          setEnableFocus(false);

        }
        else if (responseJson?.error?.message === "Request failed with status code 302") {
          localStorage.setItem('email', registrationStepsData?.user?.email)
          setViewNotification(true);
          setShowErrTitle(aemData?.errorMessages?.notificationTitleTxt)
          setShowError(aemData?.errorMessages?.notificationMsgTxt)
          setEnableFocus(false);
          errorMessage(aemData?.errorMessages?.notificationMsgTxt)
        }
        else if (responseJson?.error?.message === "Captcha Verification Failed") {
          setViewNotification(true);
          setShowErrTitle(aemData?.errorMessages?.captchaErrTitleTxt)
          setShowError(aemData?.errorMessages?.captchaErrTxt)
          setEnableFocus(false);
          window.scrollTo({ top: 0, behavior: 'smooth' });
          errorMessage(aemData?.errorMessages?.captchaErrTxt)
        }
        else if (responseJson?.error?.message === "Internal Server Error") {
          setViewNotification(true);
          setShowErrTitle(aemData?.errorMessages?.intErrTitleTxt);
          setShowError(aemData?.errorMessages?.intErrTxt);
          setEnableFocus(false);
          window.scrollTo({ top: 0, behavior: 'smooth' });
          errorMessage(aemData?.errorMessages?.intErrTitleTxt)
        }
        else if (responseJson?.error?.message === "Email is not in a valid format" || responseJson?.error?.message === "Email id can't be null please enter valid email id") {
          setViewNotification(true);
          setShowErrTitle(aemData?.errorMessages?.intErrTitleTxt);
          setShowError(aemData?.errorMessages?.intErrTxt);
          setEnableFocus(false);
          window.scrollTo({ top: 0, behavior: 'smooth' });
          errorMessage(aemData?.registerModule?.passwordValidator?.pwdErrorTxt)
        }
        else if (responseJson?.error?.message === "Please enter a valid password") {
          setViewNotification(true);
          setShowErrTitle(aemData?.errorMessages?.intErrTitleTxt);
          setShowError(aemData?.errorMessages?.intErrTxt);
          setEnableFocus(false);
          window.scrollTo({ top: 0, behavior: 'smooth' });
          errorMessage(aemData?.errorMessages?.badPwdErrTxt400500)
        }

      } else {
        //profile call fail
        setIsDisabled(false)
        setShowError(aemData?.registerModule?.registerError)
        setShowMessage('');
      }
    } catch (error) {
      console.error(error);
      return null;
    }
    // setViewNotification(true);
  };

  return (
    <div className="register-container partner-login-container pt-8X pl-4X sm-pl-0X sm-pr-0X pr-4X">

      {(viewNotification && (currentStep === 4 || currentStep === 1)) && (
        <div className="notification-container mb-8X">
          <Notification
            type="error"
            title={showErrTitle}
            subtitle={showError}
            surface="light"
            disableAnimation={false}
            fullBleed={false}
            hideCloseButton={false}
            disableFocus={enableFocus}
            onCloseButtonClick={
              hideNotification
            }
            inline={false}
            layout="vertical"
            buttonData={showErrTitle === 'Email already exists' ? [
              {
                children: 'Sign in',
                href: global.config.CIAM_SIGN_IN_URL,
                onClick: () => { document.cookie = `firstTimeLogin=true;domain=${global.config.APP_COOKIE_DOMAIN};path=/` }
              },
            ] : []}
          />
        </div>
      )}
      <RegistrationHeaderComponent
        currentStep={currentStep}
        aemData={aemData}
        isPartner={isPartner}
      />
      {currentStep === 1 && (isPartner ? <AccessCodeValidation organizationName={organizationName} accessCode={accessCode} accessCodeError ={accessCodeError}setZipCode={setZipCode} setOrganizationName={setOrganizationName} setAccessCode={setAccessCode} aemData={aemData} isPartner={isPartner} /> : <TopicsSelectionComponent aemData={aemData} isPartner={isPartner} />)}
      {currentStep === 2 && (isPartner ? <TopicsSelectionComponent aemData={aemData} isPartner={isPartner} /> : <GroupsSelectionComponent aemData={aemData} isPartner={isPartner} />)}
      {currentStep === 3 && showError === '' && <RegistrationComponent aemData={aemData} message={""} parentemail="" isPartner={isPartner} />}
      {currentStep === 3 && showError != '' && <RegistrationComponent aemData={aemData} message={showError} parentemail={registrationStepsData?.user.email} isPartner={isPartner} />}
      <div className="button-container mt-12X sm-mt-8X mb-8X">
        <ButtonGroup
          childWidth={'100%'}
          // {currentStep === 3 && <LocationSelectionComponent aemData={aemData} isPartner={isPartner} />}
          disabled={true}
          viewport={'desktop'}
          rowQuantity={{ desktop: 2 }}
          data={buttonGroupData[currentStep]}
          alignment={'center'}
        />
      </div>
      <div className="message" role="status"><b>{showMessage}</b></div>

      {currentStep === 1 && (
        <TextLink
          type="standAlone"
          bold="true"
          size="small"
          className="n-bold"
          onClick={() => handleLanguge()}
        >

        </TextLink>
      )}
      <div className="stepper-container u-textCenter">
        <StepIndicatorComponent
          step={currentStep}
          totalStep={isPartner ? 3 : 4}
          barHeight={4}
        />
      </div>
      {(currentStep === 4 || (isPartner && currentStep === 3)) && (
        <div className="stepper-container u-textCenter">
          <div className="n-textCenter mt-4X ph-8X sm-ph-5X">
            <Micro>
              <div className="privacyPolicyText n-textCenter mt-4X ph-8X">
                {aemData.commonTxt.Registertxt} <a href={aemData?.commonTxt?.termsLink} target='_blank' rel="noreferrer">{aemData?.commonTxt?.termsTxt}</a>{` ${aemData?.commonTxt?.andTxt} `}<a href={aemData?.commonTxt?.privacyLink} target='_blank' rel="noreferrer">{aemData?.commonTxt?.privacy}</a>.
              </div>

              <div className='mt-4X' style={{width:'87%', paddingLeft:'22px'}}>
                {aemData.commonTxt.shareYourInfoTxt}
              </div>
            </Micro>
          </div>
        </div>
      )}



    </div>
  );
};

export default PartnerRegistrationFlowComponent;
