import { Carousel } from '@vds/carousels';
import { Col, Grid, Row } from '@vds/grids';
import { Title } from '@vds/typography';
import React from 'react';
import { isMobile } from 'react-device-detect';
import ImageCard from '../../common/ImageCard/ImageCard.jsx';
import { useSelector } from 'react-redux';
import { topicMap } from '../questionsMock.js';
import { sortLearningPathList } from '../../../utility/utils.js';
import { useTranslation } from 'react-i18next';

const ResultsLearningPath = ({ bgGray, topic }) => {
  const { t, i18n } = useTranslation();
  const newText = 'New!';
  const heading = 'Learning paths.';
  const subHeading = 'Explore guided recommendations on key topics that impact your business.';
  const learningPath = useSelector((state) => state.AdobeResources?.learningPaths);
  const paramTopic = topicMap.get(topic);
  const userLanguage = localStorage.getItem("lng") ? localStorage.getItem("lng") : 'en';
  const topicFilteredLP = learningPath?.filter((lp) => lp?.tags?.includes(paramTopic));
  const sortedLearningPath = topicFilteredLP?.length >0 ? sortLearningPathList(topicFilteredLP,userLanguage) : [];
  const recommendedCards = sortedLearningPath?.length > 3 ? sortedLearningPath?.slice(0,3): sortedLearningPath;
  
  return (
    // <div className='bg-gray '>
    <>
    {recommendedCards?.length > 0 &&
    <div className={bgGray ? 'bg-gray pv-16X sm-pv-12X' : 'bg-white pv-16X sm-pv-12X'}>
      <Grid>
        <Row>
          <Col
            colSizes={{
              mobile: 4,
              tablet: 12,
              desktop: 12,
            }}>
            <section className='text-area mb-8X'>
              <div className=''>
                <div className='mb-2X'>
                  <Title size='medium' color='#0089EC' bold={true}>{t(newText)}</Title>
                </div>
                <Title size='XLarge' bold={true}>{t(heading)}</Title>
                <div className='mt-2X'>
                  <Title size='medium' bold={false}>{t(subHeading)}
                  </Title>
                </div>
              </div>

            </section>
          </Col>
        </Row>
      </Grid>
      <div className='mt-6X'>
        {recommendedCards.length > 0 && <Carousel
          layout="1UP"
          gutter="24px"
          peek={isMobile ? "none" : "standard"}
          surface="light"
          paginationInset="12px"
          // pagination={{
          //   kind: 'lowContrast',
          //   hideBorder: true,
          //   floating: true,
          // }}
          paginationDisplay="persistent"
          aspectRatio='1:1'
          data={recommendedCards}
          renderItem={(props) =>

            <ImageCard {...props} cardSize="large" fullScreenCarousel="true" />}
        />}
        {console.log("recommendedCards", recommendedCards)}
      </div>
    </div>}
    </>
  );
};

export default ResultsLearningPath;
