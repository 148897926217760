// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course-filter .button-area {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-titel {
  display: flex;
  justify-content: center;
}
.filter-titel h1 {
  max-width: 596px;
  text-align: center;
}

.multiselect {
  display: flex;
  justify-content: center;
}
.multiselect div {
  max-width: 850px;
}`, "",{"version":3,"sources":["webpack://./src/components/CourseFilterComponent.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;AACF;AACE;EACE,gBAAA;EACA,kBAAA;AACJ;;AAIA;EACE,aAAA;EACA,uBAAA;AADF;AAGE;EACE,gBAAA;AADJ","sourcesContent":[".course-filter .button-area {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.filter-titel {\n  display: flex;\n  justify-content: center;\n\n  h1 {\n    max-width: 596px;\n    text-align: center;\n  }\n\n}\n\n.multiselect{\n  display: flex;\n  justify-content: center;\n\n  div {\n    max-width: 850px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
