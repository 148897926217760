import React from "react";
import { Feature, Title } from "@vds/typography";
import { useDispatch, useSelector } from 'react-redux';

import './SuperBowlHeader.scss';
import { isMobile } from "react-device-detect";
import { Line } from "@vds/lines";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TextLink } from "@vds/buttons";
const SuperBowlHeader = () => {
  const aemData = useSelector((state) => state.Aem.data);

  const liixlogo = global.config.AEM_URL + `images/superbowl/${isMobile?'mobile':'desktop'}/SBPage_SBLogo_${isMobile?'Mobile':'Desktop'}.png`;
  const vzlogo = global.config.AEM_URL + `images/superbowl/${isMobile?'mobile':'desktop'}/SBPage_VZLogo_${isMobile?'Mobile':'Desktop'}.png`;
  const lisclogo = global.config.AEM_URL + `images/superbowl/${isMobile?'mobile':'desktop'}/SBPage_LISCLogo_${isMobile?'Mobile':'Desktop'}.png`;
  const nfllogo = global.config.AEM_URL + `images/superbowl/${isMobile?'mobile':'desktop'}/SBPage_NFLLogo_${isMobile?'Mobile':'Desktop'}.png`;
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  return (
    <div className="superBowl-header-container">
      <div className="SuperBowl-Image">
        <img className={isMobile ? "mr-4X" : "mr-8X"} src={vzlogo} height={isMobile ? '24px' : '48px'} width={isMobile ? '107px' : '214px'} />
        <Line type="primary" orientation="vertical" style={{ height: isMobile ? '105px' : '196px' }}></Line>
        <img className={isMobile ? "ml-4X" : "ml-8X"} src={liixlogo} height={isMobile ? '105.84px' : '196px'} width={isMobile ? '108px' : '200px'} />
      </div>
      <Title size="medium" bold={true}>
      {aemData?.superbowl?.superbowlGrantRibbonApply}
      </Title>
      <Feature size="XSmall" primitive='h1' bold={true}>
      {aemData?.superbowl?.superbowlPromoHeader}
      </Feature>
      <div className="mt-2X mb-8X">
        <Title size="large" bold={false} >
        {aemData?.superbowl?.superbowlPromoHeaderText}
        </Title>
        <br/>
        <TextLink ariaLabel={t('LegalDisclaimerAccessibilityText')} onClick={()=>{navigate('/super-bowl?scroll_to=promotion_details')}} size="small"  type="standAlone">{t('Legal disclaimer')}</TextLink>
        <br/>
      </div>
      <div className="Partnership-Container">
        {/* <img src={vzlogo} height={isMobile ? '24px' : '48px'} width={isMobile ? '107px' : '214px'} /> */}
        {/* <img src={nfllogo} height={isMobile ? '72px' : '102px'} width={isMobile ? '73.41px' : '104px'} /> */}
        <img src={lisclogo} height={isMobile ? '48px' : '64px'} width={isMobile ? '63.53px' : '84.71px'} />
      </div>
    </div>
  );

};

export default SuperBowlHeader;