import React from 'react';
import "./Breadcrumbs.scss";
import { Col, Grid, Row } from "@vds/grids";
import { Breadcrumbs, BreadcrumbItem } from '@vds/breadcrumbs';

const BreadCrumbs = ({props}) => {
  return (
    <Grid
      bleed="1440"
    >
      <Row>
        <Col colSizes={{
          mobile: 4,
          tablet: 12,
          desktop: 12,
        }}>
          <div className='pv-8X sm-pv-6X'>
            <Breadcrumbs surface="light">
              <BreadcrumbItem href="/">{props.home}
              </BreadcrumbItem>
              <BreadcrumbItem href="#">
                {props.networking}
              </BreadcrumbItem>
            </Breadcrumbs>
          </div>
        </Col>
      </Row>
    </Grid>
  );
};

export default BreadCrumbs;