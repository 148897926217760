export const annualRevenue = [
    {
        children: "$0 I’m just starting out",
        value: "1"
    },
    {
        children: "Up to $50,000",
        value: "2"
    },
    {
        children: "$50,000 - $100,000",
        value: "3"
    },
    {
        children: "$100,000 - $500,000",
        value: "4"
    },
    {
        children: "More than $1,000,000",
        value: "5"
    },
    {
        children: "I prefer not to answer",
        value: "6"
    }
  ];
  
  export const employeeOption = [
    {
        children: "Just me",
        value: "justme"
    },
    {
        children: "2 - 10",
        value: "210"
    },
    {
        children: "11 - 20",
        value: "1120"
    },
    {
        children: "21 - 51",
        value: "2151"
    },
    {
        children: "51 - 100",
        value: "51100"
    },
    {
        children: "More than 500",
        value: "500"
    },
    {
        children: "I prefer not to answer",
        value: "I prefer not to answer"
    }
  ]
  
  export const yearsInBuisness = [
    {
        children: "Less than a year",
        value: "1"
    },
    {
        children: "1 - 2 years",
        value: "2"
    },
    {
        children: "3 - 5 years",
        value: "3"
    },
    {
        children: "6 - 10 years",
        value: "4"
    },
    {
        children: "More than 10 years",
        value: "5"
    },
    {
        children: "I prefer not to answer",
        value: "6"
    }
  ];
  
  export const groupsOptions = [
    {
        children: "Asian-Indian owned business",
        value: "Asian-Indian owned business"
    },
    {
        children: "Asian-Pacific Islander owned business",
        value: "Asian-Pacific Islander owned business"
    },
    {
        children: "Black owned business",
        value: "Black owned business"
    },
    {
        children: "Disability owned business",
        value: "Disability owned business"
    },
    {
        children: "Hispanic owned business",
        value: "Hispanic owned business"
    },
    {
        children: "LGBTQ owned business",
        value: "LGBTQ owned business"
    },
    {
        children: "Native American owned business",
        value: "Native American owned business"
    },
    {
        children: "Southwest Asian or North African owned business",
        value: "Southwest Asian or North African owned business"
    },
    {
        children: "Veteran owned business",
        value: "Veteran owned business"
    },
    {
        children: "Women owned business",
        value: "Women owned business"
    },
    {
        children: "Others",
        value: "Others"
    }
  ]
  
  export const moreResources = [
    { id: '1', label: 'Managing Money', data: 'test' },
    { id: '1', label: 'Branding', data: 'test' },
    { id: '1', label: 'Online Marketing', data: 'test' },
    { id: '1', label: 'Funding', data: 'test' },
    { id: '1', label: 'Winning Customers', data: 'test' },
    { id: '1', label: 'Website Design', data: 'test' },
    { id: '1', label: 'Legal Essentials', data: 'test' },
    { id: '1', label: 'Operations', data: 'test' },
    { id: '1', label: 'Wellness', data: 'test' },
    { id: '1', label: 'Strategy', data: 'test' },
    { id: '1', label: 'Cybersecurity', data: 'test' },
    { id: '1', label: 'Social Media', data: 'test' }
  ]
  
  export const topics = [
    { id: '1', label: 'Managing Money', data: 'test' },
    { id: '1', label: 'Funding', data: 'test' },
    { id: '1', label: 'Branding', data: 'test' },
    { id: '1', label: 'Online Marketing', data: 'test' },
    { id: '1', label: 'Winning Customers', data: 'test' },
    { id: '1', label: 'Website Design', data: 'test' },
    { id: '1', label: 'Legal Essentials', data: 'test' },
    { id: '1', label: 'Operations', data: 'test' },
    { id: '1', label: 'Social Media', data: 'test' },
    { id: '1', label: 'Strategy', data: 'test' },
    { id: '1', label: 'Cybersecurity', data: 'test' },
    { id: '1', label: 'Wellness', data: 'test' }
  ];
  
  export const likeToLearn = [
    { id: '1', label: 'On-demand courses', data: 'test' },
    { id: '1', label: 'Expert events', data: 'test' },
    { id: '1', label: '1-on-1 mentoring', data: 'test' },
    { id: '1', label: 'Peer networking', data: 'test' },
    { id: '1', label: 'Local meet-ups', data: 'test' },
    { id: '1', label: 'Product discounts', data: 'test' },
    { id: '1', label: 'Articles', data: 'test' },
    { id: '1', label: 'Videos', data: 'test' },
    { id: '1', label: 'Podcasts', data: 'test' }
  ];
  
  export const resources = [
    { id: '1', label: 'On-demand courses', data: 'test' },
    { id: '2', label: 'Expert events', data: 'test' },
    { id: '3', label: '1-on-1 mentoring', data: 'test' },
    { id: '4', label: 'Peer networking', data: 'test' },
    { id: '5', label: 'Local meet-ups', data: 'test' },
    { id: '6', label: 'Product discounts', data: 'test' },
    { id: '7', label: 'Articles', data: 'test' },
    { id: '8', label: 'Videos', data: 'test' },
    { id: '9', label: 'Podcasts', data: 'test' }
  ]
  
  export const topicMap=new Map([["managingmoney","Managing Money"],
   ["funding","Funding"],
   ["branding","Branding"],
   ["onlinemarketing","Online Marketing"],
   ["winningcustomers","Winning Customers"],
   ["websitedesign","Website Design"],
   ["legalessentials","Legal Essentials"],
   ["operations","Operations"],
   ["socialmedia","Social Media"],
   ["strategy","Strategy"],
   ["cybersecurity","Cybersecurity"],
   ["wellness","Wellness"]]
   );
   export const topicMapSpanish=new Map([["managingmoney","Managing Money"],
   ["funding","Marketing en línea"],
   ["branding","Financiación"],
   ["onlinemarketing","Marketing en línea"],
   ["winningcustomers","Ganar clientes"],
   ["websitedesign","Diseño de páginas web"],
   ["legalessentials","Fundamentos jurídicos"],
   ["operations","Operaciones"],
   ["socialmedia","Bienestar"],
   ["strategy","Strategy"],
   ["cybersecurity","Ciberseguridad"],
   ["wellness","Redes sociales"]]
   );
   export const topicIcon=new Map([["managingmoney","wallet"],
   ["funding","coin"],
   ["branding","small-business"],
   ["onlinemarketing","laptop-trends"],
   ["winningcustomers","custom-audience"],
   ["websitedesign","digital-content"],
   ["legalessentials","law"],
   ["operations","Operations"],
   ["socialmedia","chat"],
   ["strategy","survey"],
   ["cybersecurity","security-check"],
   ["wellness","emoji"]]
   );
   export const topicSubtext=new Map([["managingmoney","Managing Money"],
   ["funding","Funding"],
   ["branding","Branding"],
   ["onlinemarketing","Online Marketing"],
   ["winningcustomers","Winning Customers"],
   ["websitedesign","Website Design"],
   ["legalessentials","Legal Essentials"],
   ["operations","Operations"],
   ["socialmedia","Social Media"],
   ["strategy","Strategy"],
   ["cybersecurity","Cybersecurity"],
   ["wellness","Wellness"]]
   );