import { Input } from '@vds/inputs';
import React, { useEffect, useState } from 'react';
import LocationSelectionComponent from '../LocationSelectionComponent/LocationSelectionComponent';
import { Body } from '@vds/typography';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AccessCodeValidation = ({organizationName,accessCode,accessCodeError, aemData, isPartner,setAccessCode,setOrganizationName,setZipCode }) => {
const [enteredCode, setEnteredCode] = useState("");

const { t, i18n } = useTranslation();

    return (
        <section className='width-100 max-w-384x u-textLeft'>
            <div className='mt-8X'>
                <Input
                    defaultValue={organizationName}
                    type="text"
                    label={t("Enter your organization name")}
                    ariaLabel={t("Enter your organization name")}
                    name="organizationName"
                    readOnly={false}
                    required={true}
                    disabled={false}
                    error={false}
                    errorText={t('Enter a valid name.')}
                    onChange={(e)=>{
                        setOrganizationName(e.target.value);
                       
                    }}
                />
            </div>
            <div className='mt-8X sm-mt-6X'>

                <LocationSelectionComponent  setZipCode={setZipCode} aemData={aemData} label={t('Enter your ZIP code')} isPartner={isPartner} />


            </div>
            <div className='mt-8X sm-mt-6X'>
                <Input
                    defaultValue={accessCode}
                    type="text"
                    label={t("Enter your access code")}
                    ariaLabel={t("Enter your access code")}
                    readOnly={false}
                    required={true}
                    disabled={false}
                    error={!accessCodeError==""}
                    errorText={t('errorMsgAccessCodeEmpty')}
                    onChange={  (e) => {
                         setAccessCode(e.target.value)
                         setEnteredCode(e.target.value);
                         

                    }}
                />

            </div>
        </section>
    );
};

export default AccessCodeValidation;