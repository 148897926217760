import React, { useEffect, useState } from 'react';
import "./CommunityEvents.scss";
import { Col, Grid, Row } from "@vds/grids";
import { Body, Title } from "@vds/typography";
import ImageCard from '../../common/ImageCard/ImageCard.jsx'
import { Carousel } from "@vds/carousels";
import { isMobile, isDesktop } from 'react-device-detect';
import { useDispatch, useSelector} from 'react-redux'
import {mapperForCourseCardDesktop} from '../../../utility/utils.js';

const CommunityEvents = ({props}) => {
  const COMMUNITY_TEXT = "Community";
  const allEventsWithTimeZone = useSelector((state) => state.AdobeResources.allEventsWithTimeZone);
  const userLanguage = localStorage.getItem("lng") ? localStorage.getItem("lng") : 'en';
  const [communityEvents,setCommunityEvents] = useState([])
  const NUMBER_OF_TILES = 5;
  
  useEffect(() => {
   let events = allEventsWithTimeZone?.filter(event=>event.tags.indexOf(COMMUNITY_TEXT)!==-1);
   switch(userLanguage){
    case "en":
      setCommunityEvents(events.splice(0,NUMBER_OF_TILES).map(e=>mapperForCourseCardDesktop(e)));
      break
      case "es":
        setCommunityEvents(events.sort(sortBySpanishFirst).splice(0,NUMBER_OF_TILES).map(e=>mapperForCourseCardDesktop(e)));
      break
   }
  },[])
  
  const sortBySpanishFirst = (a, b) => {
    if(a.locale.indexOf("es-ES") != -1) {
      return -1;
    } else if(b.locale.indexOf("es-ES") != -1) {
      return 1;
    }
    return 0;
  }

  return (<div className='networking-community-events pv-24X sm-pv-16X'>
    <Grid
      bleed="1440"
      colSizes={{
        mobile: 4,
        tablet: 12,
        desktop: 12,
      }}
    >
      <Row>
        <Col>
          <div className='community-header'>
            <div className='mb-4X sm-mb-3X'>
              <Title
                size="XLarge"
                bold={true}
                color="#000000">
                {props.title}
              </Title>
            </div>
            <div className=''>
              <Title
                size="medium"
                bold={false}
                color="#000000">
                {props.subHeading}
              </Title>
            </div>
          </div>

        </Col>
      </Row>
    </Grid>
    <div className='community-swiper pv-12X sm-pv-8X'>
     {communityEvents?.length>0 && <Carousel
        layout="1UP"
        gutter={isMobile ? "12px" : "24px"}
        peek="standard"
        surface="light"
        paginationInset="12px"
        pagination={{
          kind: 'lowContrast',
          hideBorder: true,
          floating: true,
        }}
        paginationDisplay="persistent"
        aspectRatio={isMobile ? '2:1' : '602:1127'}
        data={communityEvents}
        renderItem={(props) => <ImageCard {...props} cardSize="medium" fullScreenCarousel="true" />}
      />}
    </div>
  </div>);
};

export default CommunityEvents;
