import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect,useState } from 'react';
import { Button } from '@vds/buttons';
import { Carousel } from '@vds/carousels';
import { Title } from '@vds/typography';
import {quickTip_en, quickTip_es} from '../../src/mocks/quick_tip';
import CourseCardComponent from '../elements/CourseCardComponent';
import './TipsAndResourcesComponent.scss';
import { pageLoadStarted, userRegistrationClick } from "../common/Analytics/GAWrapper";
import { Col, Grid, Row } from '@vds/grids';
const TipsAndResourcesComponent = () => {
  
  let aemQuickTips = useSelector((state) => state.Aem.quickTipData);

  const [carouselData, setCarouselData] = useState([])
 
  const aemData = useSelector((state) => state.Aem.data);
  const navigate = useNavigate();

  useEffect(() => {
    const data = Object.values(aemQuickTips);
    const carouselData = data?.splice(0,6);
    console.log(carouselData,"carouselData==")
    setCarouselData(carouselData)
  },[aemQuickTips])

  const handleClick = () => {
    //pageLoadStarted( 'OnboardingPage', window.location.href,selectedTags?.toString());
    userRegistrationClick('TipsAndResources', aemData?.TipsAndResourcesSectionData?.buttonText, window.location.host + '/onboarding')
    localStorage.setItem('prevUrl',window.location.href);
    navigate('/onboarding', { replace: true })
   
  };
  return (
    
    <section
      aria-label="tips-and-resources"
      className="tips-and-resources pt-16X sm-pt-24X pb-24X"
    >
      <Grid
        className=""
        bleed="1440"
        colSizes={{
          mobile: 4,
          tablet: 12,
          desktop: 12,
        }}>
        <Row>
          <Col>
            <div className="mt-24X sm-mt-32X">
              <Title size="2XLarge" bold={true} color="#000000">
                {aemData?.TipsAndResourcesSectionData?.title}
              </Title>
            </div>
          </Col>
        </Row>
      </Grid>
      <div className="mt-12X sm-mt-8X">
        {carouselData.length > 0 && (
            <Carousel
              layout="1UP"
              gutter="24px"
              surface="light"
              paginationFill="light"
              paginationInset="12px"
              paginationDisplay="persistent"
              peek="standard"
              //data={aemData?.TipsAndResourcesSectionData?.courseDetails}
              data={carouselData}
              renderItem={(props) => (
                <CourseCardComponent {...props}></CourseCardComponent>
              )}
            />
          )}
      </div>
      <div className="button-area mt-12X">
        <Button
          size="large"
          disabled={false}
          use="primary"
          onClick={handleClick}
        >
          {aemData?.TipsAndResourcesSectionData?.buttonText}
        </Button>
      </div>

    </section>
  );
};

export default TipsAndResourcesComponent;
