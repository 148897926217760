// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#closeButton {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 50;
  width: 32px;
  height: 32px;
  border: none !important;
  background-color: white;
  border-radius: 50%;
}

#closeButton:focus {
  outline: 1px dashed #000;
  outline-offset: 2px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/LanguageSwitchPopup/LanguageSwitchPopup.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,SAAA;EACA,WAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,uBAAA;EACA,uBAAA;EACA,kBAAA;AACJ;;AACA;EACI,wBAAA;EACA,mBAAA;AAEJ","sourcesContent":["#closeButton{\n    position:absolute;\n    top: 12px;\n    right: 12px;\n    z-index: 50;\n    width: 32px;\n    height: 32px;\n    border:none !important;\n    background-color: white;\n    border-radius: 50%;\n}\n#closeButton:focus{\n    outline: 1px dashed #000;\n    outline-offset: 2px;  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
