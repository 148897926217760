// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.networking-community-events {
  position: relative;
  width: 100%;
}
.networking-community-events .community-swiper {
  width: 100%;
}
.networking-community-events .community-swiper div > div > div[role=group] {
  padding-top: 0px;
  padding-left: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/Networking/CommunityEvents/CommunityEvents.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;AACF;AACE;EACE,WAAA;AACJ;AACI;EACE,gBAAA;EACA,kBAAA;AACN","sourcesContent":[".networking-community-events {\n  position: relative;\n  width: 100%;\n\n  .community-swiper {\n    width: 100%;\n\n    div>div>div[role=\"group\"] {\n      padding-top: 0px;\n      padding-left: 16px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
