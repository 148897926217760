/** @format */
import Moment from 'moment';
import DOMPurify from 'dompurify';

import { useSelector } from 'react-redux';
import { unstable_HistoryRouter, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Carousel } from '@vds/carousels';
import OffersCardComponent from '../components/Category/Offers/OffersCard';

import { Col, Grid, Row } from '@vds/grids';
import { Button, ButtonGroup } from '@vds/buttons';
import { Icon } from '@vds/icons';
import { Notification } from '@vds/notifications';
import { BreadcrumbItem, Breadcrumbs } from '@vds/breadcrumbs';
import { Body, Micro, Title } from '@vds/typography';
import { useTranslation } from 'react-i18next';
import { pageLoadCompleted, pageLoadStarted,pageLoad_Started } from '../common/Analytics/GAWrapper';
import CourseContentComponent from '../components/CourseContentComponent';
import CourseContentHeaderComponent from '../components/CourseContentHeaderComponent';
import StartCourseComponent from '../components/StartCourseComponent';
import CoursePlayerComponent from '../elements/CoursePlayerComponent';
import TagBasedCoursesComponent from '../elements/TagBasedCoursesComponent';
import './CourseContentPage.scss';
import Footer from '../layout/Footer';
import {forcedRankingOffersTopics} from '../utility/utils.js'
import { cloneDeep } from 'lodash';
import CourseCardComponent from '../elements/CourseCardComponent.jsx';
import LearningPathCard from '../components/LearningPath/LerningPathCard.jsx';
import ResourcesStandard from './ResourceStandard.jsx';
import { useNavigate,useNavigationType } from 'react-router';
import { getWithExpiry, sortLearningPathList } from '../utility/utils'

// import offersMock from '../components/Category/Offers/offersmock.json'

const CourseDetails = ({allQuickTips, quickTips, offersMock, aemData}) => {
  const isMobile = useSelector((state) => state.MediaType.isMobile);
  // const aemData = useSelector((state) => state.Aem.data);
  // const offersMock = useSelector((state) => state.Aem.offerData);
  // const quickTips = useSelector((state) => state.Aem.quickTipData);
  // const allQuickTips = useSelector((state) => state.AdobeResources.allQuickTips);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const minifiedCatalog = useSelector(
    (state) => state.AdobeResources.catalogData
  );
  let { courseId } = useParams();
  const [isCopied, setIsCopied] = useState(false);
  const [ifLearningPath, setIfLearningPath] = useState(false);
  const [courseInLearningPath,setCourseInLearningPath] = useState([]);
  const [learningPathObj,setLearningPathObj] = useState(null);
  const [dynamicBreadCrumb,setDynamicBreadcrumb] = useState(null);
  const [lpDetails,setLpDetails] = useState({lpResources:[],noofResoources:0,duration:0})
  let suggestion = getWithExpiry('suggestion');
  const availableLocale =suggestion?.courses[courseId]?.locale;
  const learningPaths = useSelector((state) => state.AdobeResources.learningPaths);
  const isLearningPath = window.location.pathname.indexOf('learningProgram') !== -1 ? true : false;
  const isCourseDetailPage =  window.location.pathname.indexOf('course:') !== -1 ? true : false;
  const isCapitalOffering =  window.location.pathname.indexOf('capital-offering') !== -1 ? true : false;
  const allEventsWithTimeZone = useSelector((state)  => state.AdobeResources.allEventsWithTimeZone);
  var coursetitle = minifiedCatalog.find((course) => course.id == courseId);
  const [chineseLng, setChineseLng] = useState(false);
  const [showBanner, setshowBanner] = useState(false);
  const [descZh, setdescZh] = useState('');
  
  useEffect(()=>{
    
    if(localStorage.getItem('lgflag')==='zh' || localStorage.getItem('Chinese')=='true' ){
      setshowBanner(true);
      let course = suggestion?.courses[courseId] || suggestion?.events[courseId] || suggestion?.learningProgram[courseId];
   
      let currentCourseLocalizedData = course?.localizeData?.find((item) => item.locale.indexOf('zh-CN') != -1);
      let courseDesc_zh = currentCourseLocalizedData?.richTextOverview;
      setdescZh(courseDesc_zh)
     
      
    }else{
      setshowBanner(false)
    }
  },[chineseLng])


  useEffect(()=>{
    
    if(localStorage.getItem('Chinese')){
      setTimeout(()=>{
       
        localStorage.setItem('lgflag','en');}, 1000)
     
    }
  
  },[])
  

  const selectedTopics = useSelector(
    (state) => state.Aem.registrationStepsData.businessData.topics
  );
  const offers = useSelector((state) => state.Aem.offerData);
  const navigationType = useNavigationType();
  Moment.locale('en');
  useEffect(() => {
    //window.scrollTo({ top: 0 });
    setIfLearningPath(window.location.pathname.indexOf('learning-details') ? true : false);
  }, []);
  // const [datePublished, setDatePublished] = useState();

  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
  },[])

  useEffect(()=>{
    const url = location.pathname.split('/');
    if(url.length>2 && url[2].includes('learningProgram')){
      const lpObj={
        'lpId' : suggestion?.learningProgram[url[2]]?.id,
        'lpTitle' : getEnEsTitles(suggestion?.learningProgram[url[2]]?.localizeData)
      }
      localStorage.setItem('learningPathObj',JSON.stringify(lpObj))
    }
  },[location.pathname])

  useEffect(()=>{
    let urlsArr = [];    
    if(sessionStorage.getItem('urlNavigated')){
      urlsArr = JSON.parse(sessionStorage.getItem('urlNavigated'))
    }
    let action = navigationType;
    if (action === "PUSH" || 
    (action === "POP" && window?.history?.state?.idx === 0 && urlsArr[urlsArr?.length-1] !== location?.pathname)) {
      urlsArr.push(location.pathname);        
      sessionStorage.setItem('urlNavigated',JSON.stringify(urlsArr));
      fetchLpObj(urlsArr);
    }
  },[location.pathname]);

  useEffect(() => {
    const lpId = sessionStorage.getItem('learningProgramId') && JSON.parse(sessionStorage.getItem('learningProgramId'));
    const locationUrl = location?.pathname?.startsWith('/es/') ? location?.pathname.slice(3,location?.pathname.length) : location?.pathname;
    if (lpId && (lpId[lpId?.length - 1]?.includes('learningProgram') || locationUrl === lpId[lpId?.length - 1] || locationUrl === lpId[0])) {
      if (lpId.length === 1) {
        // Condition to not Push location.pathname to lpId array when page reloads
        lpId.push(locationUrl);
        sessionStorage.setItem('learningProgramId', JSON.stringify(lpId));
      }
      fetchLpObj(lpId);
    } else {
      sessionStorage.removeItem('learningProgramId');
      fetchLpObj(null);
    }
  }, [location?.pathname]);

  const fetchLpObj=(urlsArr)=>{
    if (urlsArr && urlsArr[urlsArr?.length - 2]?.includes('learningProgram')) {
      const lpUrl = urlsArr[urlsArr.length - 2]?.split('/');
      const lpObj = {
        'lpId': suggestion?.learningProgram[lpUrl[2]]?.id,
        'lpTitle': getEnEsTitles(suggestion?.learningProgram[lpUrl[2]]?.localizeData)
      };
      setLearningPathObj(lpObj);
    } 
    else {
      setLearningPathObj(null);
    }
  }

  const getEnEsTitles = (titleArr) => {
    const lng = localStorage.getItem("lng") ? localStorage.getItem("lng") : 'en';
    let title = titleArr?.find((item) => item.locale == 'en-US') ? titleArr?.find((item) => item.locale == 'en-US').name : '';
    if (lng === 'es' && titleArr?.find((item) => item.locale == 'es-ES')) {
      title = titleArr?.find((item) => item.locale == 'es-ES').name;
    }
    return title;
  }
  
  useEffect(()=>{
    let learningProgramArr = [];

    for(let item in suggestion?.learningProgram){
      const learningProgramItem = suggestion?.learningProgram[item] ;

      if(learningProgramItem?.listOfResources[0]?.loIds.includes(courseId)){
        let lng = localStorage.getItem("lng") ? localStorage.getItem("lng") : 'en';
        let title = learningProgramItem.localizeData.find((item) => item.locale == 'en-US') ? learningProgramItem.localizeData.find((item) => item.locale == 'en-US').name : '';
        if (lng === 'es' && learningProgramItem.localizeData.find((item) => item.locale == 'es-ES')) {
          title = learningProgramItem.localizeData.find((item) => item.locale == 'es-ES').name;
        }

        learningProgramArr.push({
          icon: { name: 'timer', color: '#6f7171', label: t('Get Started')},
          topic: title,
          lpId:learningProgramItem?.id,
          viewFullPath: true,
          onClickViewFullPath:function(){
            localStorage.setItem('categoryPath','/category/learningpath')
            navigate(`/learning-details/${learningProgramItem?.id}`)
          }
        });
      }
    }
    let carouselCard = learningProgramArr.slice(0,3);
    if(learningPathObj){
      const lpNavigated = carouselCard?.filter(e => e?.lpId === learningPathObj?.lpId);
      if(lpNavigated.length > 0){
        const index = carouselCard.indexOf(lpNavigated[0]);
        if(index !== 0){
          let swapVal = carouselCard[0];
          carouselCard[0] = lpNavigated[0];
          carouselCard[index] = swapVal;
        }
      } else if(lpNavigated.length === 0){
        carouselCard[0] = learningProgramArr?.filter(e => e?.lpId === learningPathObj?.lpId)[0];
      }
    }
    
    setCourseInLearningPath(carouselCard.filter(e=>e!==undefined));
    if(isLearningPath){
      var resourcesInLP = [];
      var lpDuration = 0;
      course?.instances?.map((resources) => {
        let tempEvent = minifiedCatalog?.find((event) => event?.id == resources?.id)
        if (tempEvent != undefined) {
          resourcesInLP.push(tempEvent);
        }
      });
      resourcesInLP?.map((resource) => {
        lpDuration += Math.ceil(resource?.duration/60);
      });
      setLpDetails({lpResources:resourcesInLP,noofResoources:resourcesInLP?.length,duration:lpDuration});
    }
  },[courseId,learningPathObj]);

  useEffect(() => {
    
    const suggestions = getWithExpiry('suggestion');
    let courses = suggestions?.courses[courseId] || suggestions?.events[courseId] || suggestions?.learningProgram[courseId];
   
    const dateTimeLearningStamp = courses?.datePublished;
    const currentDate = new Date();
    const dateDiff = (currentDate - new Date(dateTimeLearningStamp)) / (1000 * 60 * 60 * 24);
    if (!selectedCourse) {
      dateDiff <= 30 ? pageLoad_Started(coursetitle.title, window.location.origin + localStorage.getItem("prevUrl")
      ,selectedTopics?.toString(), 'yes') : pageLoad_Started(coursetitle.title, window.location.origin + localStorage.getItem("prevUrl")
      ,selectedTopics?.toString(), 'no');
      pageLoadCompleted();
    }
    else {
      dateDiff <= 30 ? pageLoad_Started( selectedCourse?.label, window.location.origin + localStorage.getItem("prevUrl")
      ,selectedTopics?.toString(), 'yes') : pageLoad_Started( selectedCourse?.label, window.location.origin + localStorage.getItem("prevUrl")
      ,selectedTopics?.toString(), 'no');
      pageLoadCompleted();
    }
  }, [selectedCourse, courseId]);
  const {
    labels: {
      courseDetailsPage: {
        headerBackLinkLabel,
        registerSecTitle_Tips,
        registerSecTitle_Course,
        registerSecTitle_Event,
        registerSecDesc,
        registerButtonLabel,
        alreadyMemberLabel,
        signInLabel,
        topicsLabel,
        meetTheExpertsLabel,
        mayBeInterestedIn,
        relatedOffers
      },
    },
    TipsAndResourcesSectionData: { courseDetails },
  } = aemData;

  var selectedCourse = Object.values(quickTips) ? Object.values(quickTips).find((item) => item.courseId === courseId) : [];
  if (courseId === 'capital-offering') {
    let capOfrs = offers?.offers[0]?.['capital-offering'];
    var formattedDesc = '';
    var lang =  localStorage.getItem("lng") ? localStorage.getItem("lng") : 'en';
    var langDesc = lang == 'en' ? capOfrs?.detail?.guest_description_en : capOfrs?.detail?.guest_description_es ;
    langDesc?.map((item) => {
      formattedDesc = formattedDesc + item + "<br/>";
    })
    selectedCourse = {
      label: capOfrs?.detail?.title,
      publishedOnDate: '',
      icons: capOfrs?.icons,
      resourceTags: capOfrs?.headerBreadcrumbs?.[0]?.name,
      headerBreadcrumbs: '',
      deliveryMethod: {
        type: 'article',
        source: capOfrs.desktopImage,
        thumbnailImage: capOfrs.desktopImage,
      },
      tags: capOfrs?.tags,
      courseDescription: formattedDesc,
      disclaimers : lang == 'en' ? capOfrs?.detail?.legal?.disclaimers_en : capOfrs?.detail?.legal?.disclaimers_es
    }
  }
    if(!selectedCourse) {
    var selectedCourse = Object.values(allQuickTips) ? Object.values(allQuickTips).find((item) => item.courseId === courseId) : [];
  }
  // courseDetails.find(
  //   (course) => course.courseId == courseId
  // );
var langType = localStorage.getItem("lng") ? localStorage.getItem("lng") : 'en';
var listOfLoIds = [];

  //checking adobe data
  if (!selectedCourse) {
    var course = minifiedCatalog.find((course) => course.id == courseId);
    if(!course && isLearningPath) { course = suggestion?.learningProgram[courseId]}
    // const courseIdTemp = course.id.split(':').length === 2 ? course.id.split(':')[1] : '';

    if(isLearningPath && course?.listOfResources) {
      listOfLoIds = course.listOfResources[0].loIds.map((item) => {
        let eventDetails;
        if (suggestion?.events[item]) {
          eventDetails = suggestion?.events[item];
          
        } else if (suggestion?.courses[item]) {
          eventDetails = suggestion?.courses[item];
         
        }
        
        return eventDetails;
        
      })
    }
    
    const getLPtimertext = (courseDuration) =>{
      var hrs = 0;
      if(courseDuration > 60){
        hrs = Math.floor(courseDuration / 60);
      }
      var mins = Math.ceil((courseDuration - (hrs * 60)));
      var timeText ='';
      if(hrs > 0){
        timeText = hrs + " " + (hrs ==1 ? t('hour') : t('hours')) +" ";
      }
      if(mins > 0){
        timeText += mins + " " + (mins == 1 ? t('minute') : t('minutes'));
      }
      return timeText;
    }  

    //Fix for SBDR-174 - If the user has already bookmarked the event and the event is over or expired then the user should be directed to the landing page.
    if(!course) {
      location.href = "/";
    }
    
    const shareLink = location.href;//course.launchUrl;
    let nowTime = new Date();
    //Added fix for the language based content and defaulting it to whatever is present
    let courseLangDesc = '';
    var lPDescription = '';
    var title = '';
    let lng = localStorage.getItem("lng") ? localStorage.getItem("lng") : 'en';
   
    let currentCourseLocalizedData = course.localizeData.find((item) => item.locale.indexOf(lng) != -1);
    if(currentCourseLocalizedData) {
      courseLangDesc = currentCourseLocalizedData.richTextOverview;
      lPDescription = currentCourseLocalizedData.description;
      title = currentCourseLocalizedData.name;
    } else {
      courseLangDesc = course.localizeData[0].richTextOverview;
      lPDescription = course.localizeData[0].description;
      title = course.localizeData[0].name;
    }

      let userLanguageTitle = course.localizeData.filter(e => e.locale.indexOf(langType) != -1);
      let resourceTitle = course.title;
      if(userLanguageTitle.length > 0) {
        console.log("userLanguageTitle", userLanguageTitle);
        resourceTitle = userLanguageTitle[0].name
      }

      const dateTimeLearningStamp = course.datePublished;
      const currentDate = new Date();
      const dateDiff = (currentDate - new Date(dateTimeLearningStamp)) / (1000 * 60 * 60 * 24);
    selectedCourse = {
      resourceInfoTag: course.isNew ? 'New' : '',
      resourceType: course.type,
      resourceTags: course.format,
      label: resourceTitle,
      dateAndTimeIcon: 'calender',
      dateAndTimeValue: course.completionDeadline,
      courseId: course.id,
      icons: [
        course.dateStart != null && !isCapitalOffering
          ? {
            icon: 'calendar',
            label: Moment(course.dateStart).locale(i18n.language).format('MMMM DD, h:mm A'),     
          }
          : {},
          isLearningPath
          ? {
              icon: 'reminder',
              label:
                (isLearningPath ? lpDetails?.noofResoources : course.listOfResources[0].loIds.length) +t(' resources'),
            }
          : course.enrollmentDeadline != null
          ? {
              icon: 'reminder',
              label:
                t('Deadline to register: ') +
                Moment(course.enrollmentDeadline).format('MMMM DD, h:mm A'),
            }
          : {},
        course.duration > 0 ? { icon: 'clock', label: (isLearningPath ? getLPtimertext(lpDetails?.duration): ((Math.ceil(course.duration / 60))) +" " + t('minutes')) } : '',
        course.desiredDuration > 0
          ? {
            icon: 'video',
            label:
              Math.ceil(course.desiredDuration / 60) + ' mins learning video',
          }
          : {},
        course.format === "Self Paced" && langType === 'en' && course.locale.indexOf('es-ES') !== -1
          ? { icon: 'international', label: 'Disponible en Español'}
          : langType === 'es' && course.format === "Self Paced" && course.locale.indexOf('en-US') !== -1 ? {icon: 'international', label: 'Available in English'} : {},
          course.format !== "Self Paced" && course.locale.indexOf('en-US') !== -1 && course.locale.indexOf('es-ES') !== -1
          ? { icon: 'international', label: langType === 'es' ? 'Bilingue (inglés y español)' : 'Bilingual (English & Spanish)'} : {},
          availableLocale?.indexOf('zh-CN')!==-1 && suggestion?.courses[courseId] ? { icon: 'international', label: 'Chinese subtitles & worksheet available' } : { },
       

        course.availableSeats > 0 && course.enrollmentDeadline != null && course.enrollmentDeadline > nowTime.getTime()//Removed Seat Remaining as per requirement
          ? {
              icon: 'group-family',
              label: course.availableSeats + t(' seats remaining'),
            }
          : {},
          dateDiff <= 30 ? { icon: 'fire', label: t('Just Added')} : {},
        { icon: 'share', label: t('Share'), shareLink: shareLink },
      ],
      headerBreadcrumbs: [
        {
          name: 'Course',
          link: 'https://www.verizon.com/',
        },
        {
          name: 'Self-paced',
          link: 'https://www.verizon.com/',
        },
      ],
      // deliveryMethod: {
      //   type: 'article',
      //   source: course.imageUrl,
      //   thumbnailImage: course.imageUrl,
      // },
      tags: course.tags,
      courseDescription: courseLangDesc ? courseLangDesc : '',
      modules: course.modules,
    };
  }

  const {
    label,
    publishedOnDate,
    icons,
    resourceTags,
    headerBreadcrumbs,
    deliveryMethod,
    tags,
    courseDescription,
    expertsDetails,
    modules,
    disclaimers
  } = selectedCourse;

  const testTagsForCarousel = ['Learn - Financial basics', 'Group Session'];

  const shareButtonHandle = function (link) {
    const currentHost = (selectedCourse.format == 'Quick Tip' || selectedCourse.format == 'Offer') ? `${window.location.protocol}//${window.location.host}` : '';
    link = currentHost + link;
    navigator.clipboard.writeText(link);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  }

  let resourceHeadingTag = resourceTags == "" ? headerBreadcrumbs[0].name : resourceTags;
  let registerSecTitle = registerSecTitle_Tips;
  var recordedEventTag = tags && tags.find((item) => item === "Recorded Event") ? true : false

  switch (resourceHeadingTag) {
    case "Virtual Classroom":
    case "Blended":
      resourceHeadingTag = "Virtual Event";
      registerSecTitle = registerSecTitle_Event;
      break;
    case "Classroom":
      resourceHeadingTag = "In-Person Event";
      registerSecTitle = registerSecTitle_Event;
      break;
    case "Self Paced":
      resourceHeadingTag = "Course";
      registerSecTitle = registerSecTitle_Course;
      break;
  }
  // { 
    const path = location.pathname;
    let url = localStorage.getItem('url');
    if(url !== path){
      localStorage.setItem('prevUrl', url);
      localStorage.setItem('url', path);
    }
    
    console.log("Url", localStorage.getItem("prevUrl"));
    // }

    const resourcesStandard = {
      icon: { name: 'timer', color: '#6f7171', label: '12 resources' },
      topic: 'Learning path title (74 characters max) lorem ipsum dolor set amet consect',
      buttonGroup: [
  
      ],
      viewFullPath: true,
      secondaryCta: true
    };
    
    const isDesktop = isMobile ? "mobile" : "desktop"
    const imageUrl = global.config.AEM_URL + "images/learningpath/"+isDesktop+"/"+courseId.split(":").pop()+".png"

    useEffect(()=>{
      let resourceType = resourceTags === '' || resourceTags === undefined ? headerBreadcrumbs?.[0]?.name : resourceTags;
      if (learningPathObj && isCourseDetailPage) {
        setDynamicBreadcrumb(<Breadcrumbs surface="dark">
          
          <BreadcrumbItem onClick = {() => navigate("/")}>{t('Home')}</BreadcrumbItem>
          <BreadcrumbItem onClick={() => navigate("/category/learningpath")}>{t('Learning paths')}</BreadcrumbItem>
          <BreadcrumbItem onClick={() => navigate(`/course-details/${learningPathObj['lpId']}`)}>{learningPathObj['lpTitle']}</BreadcrumbItem>
          <></>
        </Breadcrumbs>);
      }
      else {
        if (resourceType === "Self Paced") {
          setDynamicBreadcrumb(<Breadcrumbs surface="dark">
            
            <BreadcrumbItem onClick = {() => navigate("/")}>{t('Home')}</BreadcrumbItem>
            <BreadcrumbItem onClick={() => navigate("/category/courses")}>{recordedEventTag ? t('Events') : t('Courses')}</BreadcrumbItem>
            <></>
          </Breadcrumbs>);
        } else if (resourceType === "Virtual Classroom" || resourceType === "Blended" || resourceType === "Classroom") {
          setDynamicBreadcrumb(<Breadcrumbs surface="dark">

            <BreadcrumbItem onClick = {() => navigate("/")}>{t('Home')}</BreadcrumbItem>
            <BreadcrumbItem onClick={() => navigate("/category/events")}>{t('Events')}</BreadcrumbItem>
            <></>
          </Breadcrumbs>);
        }
        else if (resourceType === "Tips & Resources / Article" || resourceType === "Tips & Resources / Video" || resourceType === "Tips & Resources / Audio" ) {
          setDynamicBreadcrumb(<Breadcrumbs surface="dark">
            <BreadcrumbItem onClick = {() => navigate("/")}>{t('Home')}</BreadcrumbItem>
            <BreadcrumbItem onClick={() => navigate("/category/quicktips")}>{t('Quick tips')}</BreadcrumbItem>
            <></>
          </Breadcrumbs>);
        } 
        else if(courseId === 'capital-offering'){
          setDynamicBreadcrumb(<Breadcrumbs surface="dark">
            <BreadcrumbItem onClick={() => navigate("/")}>{t('Home')}</BreadcrumbItem>
            <BreadcrumbItem onClick={() => navigate("/category/offers")}>{t('Offers')}</BreadcrumbItem>
            <></>
          </Breadcrumbs>);
        }
        else{
          setDynamicBreadcrumb(null);
        }
  
      }
    },[learningPathObj,resourceTags,title])

    const dateTimeLearningStap = minifiedCatalog.datePublished;
    const currentDate = new Date();
    const dateDiff = (currentDate - new Date(dateTimeLearningStap)) / (1000 * 60 * 60 * 24);


    const suggestionn = getWithExpiry('suggestion');
    
    let courses = suggestionn?.courses[courseId] || suggestionn?.events[courseId] || suggestionn?.learningProgram[courseId];
   
    const dateTimeLearningStamp = courses?.datePublished;
    const currentDat = new Date();
    const dateDifff = (currentDat - new Date(dateTimeLearningStamp)) / (1000 * 60 * 60 * 24);
    const newContentAdd= dateDifff<=30?"yes":"no"

    

  return (
 
    <>
      <section aria-label="details-page">
        {!isLearningPath && (
          <CourseContentHeaderComponent
            // gutterCol={true}
            breadCrumb={dynamicBreadCrumb}
            headerTitle={label}
            headerBreadcrumbs={t(resourceHeadingTag)}
            recordedEventTag={recordedEventTag}
            chineseLng={chineseLng}
            setChineseLng={setChineseLng}
            coursedetail={suggestion?.courses[courseId]}
          />
        )}
        {isLearningPath && (
          <div className='categories-header bg-gray image-bg' style={{
             background: `linear-gradient(0deg, rgba(246, 246, 246, 0.50) 0%, rgba(246, 246, 246, 0.50) 100%), linear-gradient(90deg, #FFF 24.72%, rgba(255, 255, 255, 0.00) 97.08%), url(${DOMPurify.sanitize(imageUrl)}), lightgray 273.519px -239.75px / 114.2% 228.281% no-repeat`,
            backgroundBlendMode: 'screen, normal, normal',
            backgroundSize: isMobile ? 'cover' : 'contain',
            backgroundPosition: 'top',
            backgroundRepeat: 'no-repeat',
        }}>
            <Grid
              bleed="1440"
              colSizes={{
                mobile: 4,
                tablet: 12,
                desktop: 12,
              }}
            >
              <Row>
                <Col>
                  <div className='mt-8X'>
                    <Breadcrumbs surface="light">

                      <BreadcrumbItem onClick = {() => navigate("/")}>{t('Home')}</BreadcrumbItem>
                      <BreadcrumbItem onClick={() => navigate("/category/learningpath")}>{t('Learning paths')}</BreadcrumbItem>
                      <></>
                    </Breadcrumbs>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className='pv-24X maxw-808'>
                    <Body size='large' primitive='div'>
                      <div className='pb-4X'>{t('Learning path:')}</div>
                      <Title size='XLarge' bold={true} primitive="h1">{title}</Title>
                      <div className='pt-4X'>{lPDescription}</div>
                    </Body>
                  </div>
                </Col>
              </Row>
            </Grid>
          </div>
        )}
        <div className='mt-8X carousel-container-fix '>
       { showBanner &&   <Notification
            type="info"
            title="You are viewing the Chinese version of this course. The video supports Chinese subtitles."
            surface="light"
            disableAnimation={false}
            fullBleed={false}
            hideCloseButton={false}
            disableFocus={true}
            inline={false}
            layout={null}
            buttonData={[
              {
                children: 'View course in English',
                onClick: e =>{
                  localStorage.setItem("lngflag",'en')
                  if(  localStorage.getItem("lng")!=='es'){ 
                      localStorage.setItem("lng", "es");
                    }
                  const langValue = localStorage.getItem('lng');
                 
                  if (langValue !=='en' || chineseLng ) {
                    let language = 'en';
                    // localStorage.setItem("lng", "en");
                    localStorage.setItem("lng", "en");
                    localStorage.setItem('changedLanguage', true);
                    i18n.changeLanguage("en");
                    window.MP.SrcUrl = location.pathname.replace('/es','');
                    window.location.pathname = location.pathname.replace('/es','');
                    // i18n.changeLanguage("en");
                    localStorage.setItem('Chinese',false);

                    window.location.reload();
                                 
                                      
                    localStorage.setItem('languageSwitchHref', Util.getLanguageBasedHref());
                      window.MP.SrcUrl = localStorage.getItem('languageSwitchHref');
                    sessionStorage.setItem('isSwitchedLanguage', true);
                    //Changing user profile
                    const service = new Services();
                    businessData.attributes.Language = language;
                    businessData.attributes.contentLocale = language == "es" ? "es-ES" : 'en-US';
                    businessData.attributes.uiLocale = language == "es" ? "es-ES" : 'en-US';
                    const payload = {
                      userProfile,
                      businessData: businessData,
                      services: userDataFromStore.services
                    }
                    service.updateUserFullProfile(payload).then((resp) => {
                      console.log("successful update and resp is", resp);
                      loader.classList.add("hidden");
                    }).catch((error) => {
                      console.log("error while update and error is", error);
                      loader.classList.add("hidden");
                    });
                  }

                  alert('Button clicked!');
                pageLoad_Started(coursetitle.title, window.location.origin + localStorage.getItem("prevUrl")
                ,selectedTopics?.toString(),newContentAdd);
                
                }
              }
               
             
            ]}
          /> }

        </div>
        <div className="course-details-body">
          <Grid>
          {/* <Row>
              <Col
                colSizes={{
                  mobile: 4,
                  tablet: 12,
                  desktop: 12,
                }}>
                <div className='mb-12X'>
                  <Notification
                    type="info"
                    title="A new line of event is added to this Learning Path."
                    fullBleed={false}
                    inline={false}
                    disableFocus={true}
                    layout='vertical'

                    buttonData={[
                      {
                        children: 'View now',
                        onClick: e => alert('Button clicked!')
                      }]}
                  />


                </div>

              </Col>
            </Row> */}
            <Row>
              <Col
                colSizes={{
                  mobile: 4,
                  tablet: 1,
                  desktop: 1,
                }}
              >
                <div className={isMobile ? 'ico-mobile' : ''} >
                  {icons.map((item) => {
                    return (
                      <>

                        {item.icon === 'share' ?
                          item.shareLink !== null ? <div className="n-textCenter mb-8X">
                            <div className='share-content' aria-label='share-content' onKeyDown={(e) => {
                              if (e.key === "Enter") { shareButtonHandle(item.shareLink) }
                            }} onClick={() => shareButtonHandle(item.shareLink)}><Icon name={item.icon} size="XLarge" color="#000000" /></div>
                            <Micro color="#000000">{t(item.label)}</Micro>
                            <span className={isCopied ? 'share-content-copy-message' : 'share-content-copy-message hidden'}>Copied to clipboard</span>
                          </div> : ''
                          : item?.icon === "fire" && dateDiff >= 30 ? <div className="n-textCenter mb-8X">
                          <div>
                            <Icon name={item.icon} size="XLarge" color="#000000" />
                          </div>
                          <Micro color="#000000">{t(item.label)}</Micro>
                        </div> 
                          : item.icon ? <div className="n-textCenter mb-8X">
                            <div>
                              <Icon name={item.icon} size="XLarge" color="#000000" />
                            </div>
                            <Micro color="#000000">{t(item.label)}</Micro>
                          </div> : ''
                        }
                      </>
                    );
                  })}
                </div>

                {isMobile && (
                  <StartCourseComponent
                  registerSecTitle={isLearningPath ? t("Register now to start this learning path.") : registerSecTitle}
                    registerSecDesc={registerSecDesc}
                    registerButtonLabel={registerButtonLabel}
                    alreadyMemberLabel={alreadyMemberLabel}
                    signInLabel={signInLabel}
                    resourceType={t(resourceHeadingTag)}
                    collectionId={courseId}
                    isLearningPath={isLearningPath}
                    recordedEventTag={recordedEventTag}
                  />
                )}
              </Col>
              <Col
                colSizes={{
                  mobile: 4,
                  tablet: 8,
                  desktop: 8,
                }}
              >
                {deliveryMethod && <CoursePlayerComponent deliveryMethod={deliveryMethod} label={label}  publishedOnDate={publishedOnDate} />}
                <CourseContentComponent
                  topics={tags}
                  topicsLabel={topicsLabel}
                  courseDescription={( showBanner && descZh ) ? descZh : courseDescription}
                  resourceType={t(resourceHeadingTag)}
                  resourceTags={resourceTags}
                  publishedOnDate={publishedOnDate}
                  meetTheExpertsLabel={meetTheExpertsLabel}
                  expertsDetails={expertsDetails}
                  modules={modules}
                  recordedEventTag={recordedEventTag}
                  sourceAvilable={deliveryMethod?.source?true:false}
                  listOfLoIds={listOfLoIds.filter(function( element ) {
                    return element !== undefined;
                 })}
                 disclaimers={disclaimers}
                 setChineseLng={setChineseLng}
                />
              </Col>
              <Col
                colSizes={{
                  mobile: 4,
                  tablet: 3,
                  desktop: 3,
                }}
              >
                {!isMobile && (
                  <StartCourseComponent
                  registerSecTitle={isLearningPath ? t("Register now to start this learning path.") : registerSecTitle}
                    registerSecDesc={registerSecDesc}
                    registerButtonLabel={registerButtonLabel}
                    alreadyMemberLabel={alreadyMemberLabel}
                    signInLabel={signInLabel}
                    resourceType={t(resourceHeadingTag)}
                    collectionId={courseId}
                    isLearningPath={isLearningPath}
                    recordedEventTag={recordedEventTag}
                  />                  
                )}
                {courseInLearningPath?.length > 0 && resourceHeadingTag === "Course" && isCourseDetailPage && (
                  <div className='grey-widget mt-12X'>
                    <div className='ph-6X pt-6X pb-0X'>
                      <Title size='small' bold={true}>
                        {t("This course is also included in at least one learning path:")}
                      </Title>
                    </div>
                    {courseInLearningPath?.length === 1 && (<div className='ph-6X pb-6X pt-4X'>
                    <ResourcesStandard data={courseInLearningPath[0]} />
                    </div>)}
                    {courseInLearningPath?.length > 1 && (
                      <div className="resource-standard-carousel-container pb-6X pt-4X">
                        <Carousel
                          layout="1UP"
                          // ph-6X pt-6X mb-8X
                          gutter="12px"
                          peek="standard"
                          surface="light"
                          paginationInset="12px"
                          paginationDisplay="persistent"
                          aspectRatio='none'
                          data={courseInLearningPath}
                          renderItem={props => <ResourcesStandard data={props} />}
                        />
                      </div>
                    )}
                  </div>
                )}
              </Col>
            </Row>
          </Grid>
        </div>
        <div className="intrested-in-carousel-container">
          <Grid
            className=""
            bleed="1440"
            colSizes={{
              mobile: 4,
              tablet: 12,
              desktop: 12,
            }}>
            <Row>
              <Col>
                <Title size='medium' bold={true}>
                  <div className="intrested-in-title ml-16X">
                    {relatedOffers}
                  </div>
                </Title>

              </Col>
            </Row>
          </Grid>
          {Object.keys(offersMock).length > 0 && <Grid
            className=""
            bleed="1440"
            colSizes={{
              mobile: 4,
              tablet: 12,
              desktop: 12,
            }}>
            <Row>
              <Col>
                <Title size='medium' bold={true}>
                  <div className="intrested-in-title">
                    {t("Related offers:")}
                  </div>
                </Title>

              </Col>
            </Row>
          </Grid>}
          {Object.keys(offersMock).length > 0 && (<div className="carousel-container-fix intrested-in-carousel" >
            <Carousel
              peek="standard"
              layout={isMobile ? "1UP" : "3UP"}
              gutter="24px"
              surface="light"
              paginationInset="12px"
              paginationDisplay="persistent"
              aspectRatio='none'
              data={forcedRankingOffersTopics(offersMock,selectedTopics,12).map((item) => {
                let _item = cloneDeep(item)
                _item.bg='bg-white height-correct'
                console.log(_item)
                return _item;
              })}
              renderItem={props => <OffersCardComponent data={props} />}
            />
          </div>)}

        </div>
       {(!isLearningPath || (isLearningPath && (learningPaths.filter(function(item) {return item.courseId !== courseId})).filter(e => tags.some(r => e.tags?.includes(r))).length>0)) && <div className="intrested-in-carousel-container">
          <Grid
            className=""
            bleed="1440"
            colSizes={{
              mobile: 4,
              tablet: 12,
              desktop: 12,
            }}>
            <Row>
              <Col>
              <Title size='medium' bold={true}>
              <div className="intrested-in-title">
                  {mayBeInterestedIn}
                </div>
              </Title>
                
              </Col>
            </Row>
          </Grid>
          {isLearningPath && (
            <div className="intrested-in-carousel" >
              <Carousel
                peek="standard"
                layout={"1UP"}
                gutter="24px"
                surface="light"
                paginationInset="12px"
                paginationDisplay="persistent"
                data={sortLearningPathList(learningPaths.filter(function(item) {
                  return item.courseId !== courseId
              })).filter(e => tags?.some(r => e.tags?.includes(r)))}
                renderItem={props => isMobile ? <CourseCardComponent data={props} /> : <LearningPathCard data={props} />}
              />
            </div>
          )}
          {!isLearningPath && (
            <div className="intrested-in-carousel" >
              {allEventsWithTimeZone && <TagBasedCoursesComponent tags={tags} isLandingPage={false} />}
            </div>
          )}
        </div>}
          
      </section>
      <Footer />
    </>
  );
};

export default CourseDetails;
