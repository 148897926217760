/** @format */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Icon } from '@vds/icons';
import { Tilelet } from '@vds/tiles';
import { Micro, Title } from '@vds/typography';
import { useTranslation } from 'react-i18next';
import ellipsis from '../assets/images/Ellipse 490.svg';
import { contentClick } from '../common/Analytics/GAWrapper';
import './CourseCardComponent.scss';
import { pageLoadStarted } from '../common/Analytics/GAWrapper';
const CourseCardComponent = (props) => {
  const { t, i18n } = useTranslation();
  //console.log('props ===> ', props);
  const navigate = useNavigate();
  const isMobile = useSelector((state) => state.MediaType.isMobile);
  const selectedTopics = useSelector(
    (state) => state.Aem.registrationStepsData.businessData.topics
  );
  const onCardClicked = (id) => {
    console.log("Initaila load")
    // contentClick(
    //   props.label,
    //   window.location.host + `/course-details/${id}`,
    //   ''
    // );
    contentClick(
      props?.data?.title,
      window.location.origin+`/course-details/${id}`,
      selectedTopics.toString()
    );
   navigate(`/course-details/${id}`);
    window.scrollTo(0, 0);
  };

  let resourceType = props.resourceType;
  switch(resourceType) {
    case "Virtual Classroom":
    case "Blended":
      resourceType = "Virtual Event";
      break;
    case "Classroom":
      resourceType = "In-Person Event"
      break;
    case "Self Paced":
      resourceType = "Course"
      break;
  }

  return (
    <Tilelet
      title={{
        children: (
          <div className='resource-textwrapper'>
            <div className="resource-details">
              <Micro color="#FFFFFF" bold={true}>
                {t(resourceType)}
              </Micro>
            </div>
            <Title
              color="#FFFFFF"
              bold={false}
              size={isMobile ? 'medium' : 'XLarge'}
            >
              {t(props.label)}
            </Title>
            <div className="duration-wrapper">
              {props.dateAndTimeValue ? (
                <div className="time-details">
                  {props.resourceType === "Course" ? <Icon name="timer" size="small" color="#ffffff" /> : <Icon name="calendar" size="small" color="#ffffff" />}
                  <Micro color="#FFFFFF" bold={true}>
                    {t(props.dateAndTimeValue)}
                  </Micro>
                </div>
              ) : null}
              {props.duration ? (
                <div className="duration">
                  <div class="time">
                    <Icon name="timer" size="small" color="#ffffff" />
                    <div className="ml-2X">
                      <Micro color="#FFFFFF" bold={true}>
                        {props.duration} {t("minutes")}
                      </Micro>
                    </div>

                  </div>

                </div>
              ) : null}
            </div>
          </div>
        ),
      }}
      aspectRatio={isMobile ? '1:1' : '16:9'}
      textPosition="bottom"
      surface="light"
      // backgroundImage={isMobile ? props.mobileImage : props.desktopImage}
      // backgroundColor="black"
      background={`linear-gradient(0deg, #2E2E2E 0%, rgba(46, 46, 46, 0.00) 67.21%), no-repeat center/cover url('${isMobile ? props.mobileImage : props.desktopImage}')`}
      width="100%"
      onClick={() => onCardClicked(props.courseId)}
      showBorder={true}
      innerPadding="32px"
      directionalIcon={{
        size: 'small'
      }}
    />
  );
};

export default CourseCardComponent;
