// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course-carousel .iXXzmD, .course-carousel .ftGZCX, .course-carousel .ORzcW {
  scroll-snap-align: start;
}`, "",{"version":3,"sources":["webpack://./src/elements/TagBasedCoursesComponent.scss"],"names":[],"mappings":"AACI;EACI,wBAAA;AAAR","sourcesContent":[".course-carousel {\n    .iXXzmD, .ftGZCX, .ORzcW {\n        scroll-snap-align: start;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
