import { Title } from '@vds/typography';
import React, { useEffect,  useState } from 'react';
import './languageSupport.scss';
import LanguageCard from './languageCard.jsx';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';



const LanguageSupported = ({setChineseLng,courseId,suggestions}) => {
  const [showCardHeading,setShowCardHeading] = useState(false);
  const [showCardES,setShowCardES] = useState(false);
  const [showCardZH,setShowCardZH] = useState(false);
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const languageCardData = [{
    icon: 'international',
    iconTitle: 'En español',
    desc: 'This course is available entirely in Spanish.',
    navigationUrl: '',
    bottomLabel: 'View course in Spanish',
    value:'es'
  },
  {
    icon: 'international',
    iconTitle: 'Chinese subtitles & worksheet available',
    desc: 'This course has subtitles and downloadable resources available in Chinese.',
    navigationUrl: '',
    bottomLabel: 'Set subtitles in Chinese',
    value:"zh"
  }];
  const locale =suggestions?.courses[courseId]?.locale;

  
  useEffect(()=>{

      if( (localStorage.getItem('Chinese')==undefined || localStorage.getItem('Chinese')==null || localStorage.getItem('Chinese')=='false' ) && localStorage.getItem('lng')==='en' ){
        if(locale?.includes('zh-CN')){setShowCardZH(true)}
        if(locale?.includes('es-ES')){setShowCardES(true) }
       
        setShowCardHeading(true)
      }else {
        if(locale?.includes('zh-CN')){setShowCardZH(false)}
        if(locale?.includes('es-ES')){setShowCardES(false) }
        setShowCardHeading(false)
      }
  },[location]);

  
  return (
    <div>
      { ( showCardHeading && locale?.length > 1) ? <Title size='large' bold>{t('Languages supported.')}</Title> : "" }
      {/* { ( showCardHeading && (showCardES || showCardZH) && locale?.length > 1) ? <Title size='large' bold>{ t('Languages supported.')}</Title> : (locale?.includes('zh-CN') && showCardZH) ? <Title size='large' bold>{ t('Languages supported.')}</Title> : (locale?.includes('es-ES') && showCardES) ? <Title size='large' bold>{ t('Languages supported.')}</Title> : "" } */}
     
      
      <div className='mt-6X'>
      </div>
      <div className='language-card-container'>
       {
        
        locale?.map((lang)=>{
          console.log("##",suggestions?.courses[courseId]?.locale)
             if(lang ==='es-ES'){
              if(showCardES){
               
                return <LanguageCard setChineseLng={setChineseLng} data={languageCardData[0]} ></LanguageCard>;
              }
              }else if(lang ==='zh-CN'){
                if(showCardZH){
              return <LanguageCard setChineseLng={setChineseLng} data={languageCardData[1]} ></LanguageCard>;
             }
            }
          })
          
       
       }
      </div>
    </div>
  );
};

export default LanguageSupported;