import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Checkbox, CheckboxGroup } from '@vds-core/checkboxes';
import { Body } from '@vds/typography';

import { updateRegistrationStepGroups } from '../../../../store/aem';
import store from '../../../../store/store';
import './GroupsSelectionComponent.scss';

const GroupsSelectionComponent = (props) => {
  const { aemData } = props;

  const selectedGroups = useSelector(
    (state) => state.Aem.registrationStepsData.businessData.groups
  );

  const [groups, setGroups] = useState([]);

  useEffect(() => {
    if (
      aemData &&
      aemData.registerModule &&
      aemData.registerModule.checkBoxGroup
    ) {
      const groups = aemData.registerModule.checkBoxGroup.map((card) => ({
        name: card.children,
        label: card.label,
        value: card.value,
        selected: selectedGroups
          ? selectedGroups.includes(card.children)
          : false,
      }));
      setGroups(groups);
    }
  }, [aemData, setGroups, selectedGroups]);

  const checkAndAddGroup = (e) => {
    
    const selectedValues = selectedGroups.filter((x) => x !== aemData?.registerModule?.orOptionForGroup)

    if (e.target.checked) {
      store.dispatch(
        updateRegistrationStepGroups([...selectedValues,e.target.name])
      );
    } else {
      store.dispatch(
        updateRegistrationStepGroups([
          ...selectedValues.filter((x) => x !== e.target.name),
        ])
      );
    }
  };

  const checkOthers = (e) => {
    if(e.target.checked){
      store.dispatch(
        updateRegistrationStepGroups([e.target.name])
      );
    } else {
      store.dispatch(
        updateRegistrationStepGroups([])
      );
    }
  }

  return (
    groups.length > 0 &&
    aemData?.registerModule?.orOption.length > 0 && (
      <div className="chips-container group-container mt-8X sm-mt-6X">
        <CheckboxGroup data={groups} required= {false} onChange={(e) => checkAndAddGroup(e)} bold={false} />
        <div className="u-textLeft mv-4X">
          <Body size="small" color="#000000" bold={true}>
            or
          </Body>
        </div>
        <Checkbox
          name={aemData?.registerModule?.orOptionForGroup}
          width="auto"
          disabled={false}
          error={false}
          value="no selected"
          selected={
            selectedGroups &&
            selectedGroups.includes(aemData?.registerModule?.orOptionForGroup)
          }
          onChange={(e) => checkOthers(e)}
        >
          {aemData?.registerModule?.orOptionForGroup}
        </Checkbox>
      </div>
    )
  );
};

export default GroupsSelectionComponent;
