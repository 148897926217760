// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.validator-wrapper {
  width: 100%;
  text-align: left;
}
.validator-wrapper span[class^=DialogWrapper-VDS] {
  border: 0.0625rem solid rgb(111, 113, 113) !important;
}

.validator-wrapper div[role=alert] div p {
  display: none;
}

.error {
  font-weight: 700;
  color: #c44904;
}

.success {
  font-weight: 700;
  color: #008330;
}

.medium {
  font-weight: 700;
  color: #FCD667;
}

.validation-points {
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/elements/inputs/passwordValidator.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;AACF;AACE;EACE,qDAAA;AACJ;;AAGA;EACE,aAAA;AAAF;;AAGA;EACE,gBAAA;EACA,cAAA;AAAF;;AAGA;EACE,gBAAA;EACA,cAAA;AAAF;;AAEA;EAEE,gBAAA;EACA,cAAA;AAAF;;AAGA;EACE,aAAA;EACE,mBAAA;AAAJ","sourcesContent":[".validator-wrapper {\n  width: 100%;\n  text-align: left;\n  \n  span[class^='DialogWrapper-VDS'] {\n    border: 0.0625rem solid rgb(111, 113, 113) !important;\n  }\n}\n\n.validator-wrapper div[role='alert'] div p {\n  display: none;\n}\n\n.error {\n  font-weight: 700;\n  color: #c44904;\n}\n\n.success {\n  font-weight: 700;\n  color: #008330;\n}\n.medium\n{\n  font-weight: 700;\n  color: #FCD667;\n}\n\n.validation-points{\n  display: flex;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
