import React from 'react';
import "./UpcomingEvents.scss";
import { Col, Grid, Row } from '@vds/grids';
import { Icon } from '@vds/icons';
import { Carousel } from '@vds/carousels';
import { isDesktop, isMobile } from 'react-device-detect';
import { Body, Title, Micro } from '@vds/typography';
import { useState } from 'react';
import { Calendar } from '@vds/calendars';
import { ButtonIcon } from '@vds/button-icons';
import FilterBadge from '../common/FilterBadge/FilterBadge.jsx';
import { courseDetailssecondary, tabs, eventList, activeEventList, filterOptions } from './upcomingEvents.js';
import CalendarViewCardComponent from './CalendarViewCards.jsx';
//import TileViewCardComponent from './TileViewCards.jsx';
import { Button, TextLink } from '@vds/buttons';
import { useDispatch, useSelector} from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
//import {topics} from './questionsMock.js'
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import CourseCardComponent from './CourseCardComponent.jsx'
import { topicMap } from './questionsMock.js';
import {recommendedEvents, comingSoonEvents,mapperForCourseCardDesktop} from '../../utility/utils.js'
//import {creators as _InfohubCreators} from '../../actions/Infohub.js';
const UpcomingEvents = (props) => {

  const dispatch = useDispatch();
  const history = useNavigate();
 
  const aemDatabn = useSelector((state) => state.Aem.registerData);
  const topics=aemDatabn?.registerModule?.topics;
 
  const [activeView, setActiveView] = useState('calendar-view');
  const [viewTabs, setViewTabs] = useState(tabs);
  const [eventArray, setEventArray] = useState(eventList);
  const [eventStarted, setEventStarted] = useState(true);
  const [dateFilteredEvent, setDateFilteredEvent] = useState([]);
  const [activeEventList, setActiveEventList] = useState([]);
  const [onGoingingEvent, setOnGoingingEvent] = useState({});
  const [selectedDate, setSelectedDate] = useState("");
  const [time, setTime] = useState({});
  const [timer, setTimer] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const aemData = useSelector((state) => state.Aem.data);
  const topicMapaem=aemData?.topicCard?.topicSubtext;
  console.log("topicMapaem===",topicMapaem.forEach((item)=>console.log(item[0])))
  const newTopic=topicMapaem.filter((item)=>item[0]===props.topic)
  
  console.log("newTopicnnn===",newTopic[0][1])
  const aemDataResults=aemData?.results;
 // const businessData = useSelector((state) => state.data.business);
  const allEventsWithTimeZone = useSelector((state) => state.AdobeResources.allEventsWithTimeZone);
  const [userTimeZone, setUserTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  //const userDataFromStore = useSelector((state) => state.data);
 // const [userData, setUserData] = useState(userDataFromStore.user);
  //const [businessData, setBusinessData] = useState(userDataFromStore.business);
  const [tileViewEvents,setTileViewEvents]=useState([]);
  const { t, i18n } = useTranslation();
  const [recommendedEventList, setRecommendedEventList] = useState([]);
  const [comingSoonEventList, setComingSoonEventList] = useState([]);
  const [selectedFilterOptions, setSelectedFilterOptions] = useState({ id: '1', label: aemDataResults?.recommended, data: '' });
  const [hasValidEvents, setHasValidEvents] = useState(false);
  //const userLanguage = localStorage.getItem("lng");
  const isSpanish = useSelector((state) => state.Aem.isSpanish);
 
  const userLanguage = localStorage.getItem("lng")??isSpanish?'es':'en';
  const topicsI=[newTopic[0][1]];
  const [topicAnalytics, setTopicAnalytics] = useState("");
  const MINUTES_FOR_ACTIVE_EVENTS = 30;
  const SECONDARY_HOURS_FOR_ACTIVE_EVENTS = 24;
  
  useEffect(() => {
    let userTZ =  userTimeZone;
    console.log("userLanguage", userLanguage);
    setUserTimeZone(userTZ);
    setSelectedDate(new Date(new Date().toLocaleString('en-US', { timeZone: userTZ })));

    let dateFilteredEvents = [];
    //allEventsWithTimeZone?.filter(event => new Date(event.startDate).toDateString() == new Date(new Date().toLocaleString('en-US', { timeZone: userTZ })).toDateString());
    console.log("dateFilteredEvents!==null==",allEventsWithTimeZone)
    if(userLanguage == "es" || (allEventsWithTimeZone?.length > 0 && dateFilteredEvents?.length == 0)) {
      changeTab('tile-view');
    }
    calendarViewEvents(dateFilteredEvents);
    checkValidEvents();
    tileViewEventsFiltering(allEventsWithTimeZone, selectedFilterOptions);
  },[]);

  useEffect(() => {
    const interval = window.setInterval(() => {
      if(seconds >= 0) {
        setSeconds(seconds => seconds - 1);
        countDown(seconds);
      }
    }, 1000);
    return () => {
      window.clearInterval(interval);
    };
  }, [seconds]);
  const handleChange = (e) => {
    setSelectedFilterOptions(e);
    tileViewEventsFiltering(allEventsWithTimeZone, e);
  };
  const compareSoonestTopicOfInterest=(a, b)=> {
    let aStartTime = a.dateStart;
    let bStartTime = b.dateStart;
    if(aStartTime < bStartTime) {
      return -1;
    } else if(aStartTime > bStartTime) {
      return 1;
    }
    return 0;
  };
  const secondsToTime = (secs) => {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    
    let hourLength = hours.toString().length;
    let minuteLength = minutes.toString().length;
    let secoundLength = seconds.toString().length;

    let obj = {
      "h": hourLength == 2 ? hours : hourLength == 1 ? "0" + hours : "00",
      "m": minuteLength == 2 ? minutes : minuteLength == 1 ? "0" + minutes : "00",
      "s": secoundLength == 2 ? seconds : secoundLength == 1 ? "0" + seconds : "00",
    };
    
    return obj;
  };

  const countDown = (seconds) => {
    // Remove one second, set state so a re-render happens.
    setTime(secondsToTime(seconds));
    
    // Check if we're at zero.
    if (seconds == 0) { 
      clearInterval(timer);
    }
  };

  const updateUpcomingEvent = (e) => {
    setSelectedDate(e);
    let dateFilteredEvents = allEventsWithTimeZone.filter(item => new Date(item.startDate).toDateString() == e.toDateString());
    calendarViewEvents(dateFilteredEvents);
  };

  const calendarViewEvents = (dateFilteredEvents) => {

    let upcomingEnrolledEvents = dateFilteredEvents.filter(event => event.enrollmentStatus == "ENROLLED");
    let activeEvents = upcomingEnrolledEvents.filter(event => event.duration.timeInMinutes <= MINUTES_FOR_ACTIVE_EVENTS);
    let onGoingingEventCurrentDate = {};
    if(activeEvents.length > 0) {
      onGoingingEventCurrentDate = activeEvents[0];
    } else {
      activeEvents = upcomingEnrolledEvents.filter(event => event.duration.timeInHours <= SECONDARY_HOURS_FOR_ACTIVE_EVENTS);
      if(activeEvents.length > 0) {
        onGoingingEventCurrentDate = activeEvents[0];
      }
    }
    //Setting ongoing event
    if(activeEvents.length == 1) {
      if(Object.keys(onGoingingEventCurrentDate).length > 0) {
        setOnGoingingEvent(onGoingingEventCurrentDate);
        let timeLeftVar = secondsToTime(onGoingingEventCurrentDate.duration.timeInSeconds);
        setTime(timeLeftVar);
        setSeconds(onGoingingEventCurrentDate.duration.timeInSeconds);
      }
    }

    setActiveEventList(activeEvents);
    console.log("activeEvents", activeEvents);

    //excluding events from datefiltered List
    let removedActiveEvents = [];
    dateFilteredEvents.forEach(item => {
      if(activeEvents.filter(event => event.id == item.id).length == 0){
        removedActiveEvents.push(item);
      }
    });
    removedActiveEvents.sort(sortEnrollment);
    //setDateFilteredEvent(removedActiveEvents);
  };

  const tileViewEventsFiltering = (events, selectedFilterOptions) => {
    let tileViewEvents = [];
    let topicsClone = cloneDeep(topics);
    topicsClone.push({ id: '1', label: "I'm not sure", data: 'test' }); //Adding I'm not sure to get all TOI
    let topicsOfInterest=topicsI;
    //topicsClone.filter(e=>businessData.attributes[e.label]=="Yes").map(value => value.label);
    setTopicAnalytics(topicsOfInterest);
    switch (selectedFilterOptions.label) {
      case aemDataResults?.recommended:
        if(recommendedEventList.length == 0) {
          let list = recommendedEvents(events, topicsOfInterest, userLanguage, events.length);
          list=list.filter(e=>e.tags.includes(topicsI.toString()))
          setRecommendedEventList(list);
          tileViewEvents =  list?.splice(0,12);
        } else {
          tileViewEvents = recommendedEventList?.splice(0,12);
        }
        break;
      case aemDataResults?.startingsoon:
        if(comingSoonEventList.length == 0) {
          let list = comingSoonEvents(events, topicsOfInterest, userLanguage, false, events.length, recommendedEventList, events);
          list=list.filter(e=>e.tags.includes(topicsI.toString()))
          setComingSoonEventList(list);
          tileViewEvents = (list.length>0)?list?.splice(0,12):list;
        } else {
          tileViewEvents =(comingSoonEventList?.length>0)? comingSoonEventList.splice(0,12):comingSoonEventList;
        }
        break;
    }
    console.log("tileViewEvents", tileViewEvents);
    switch(userLanguage) {
      case "en":
        tileViewEvents.sort(sortEventsByEnglish);
        break;
      case "es":
        tileViewEvents.sort(sortEventsBySpanish);
        break;
    }
    console.log("tileViewEvents", tileViewEvents, topicsI);
    if(tileViewEvents?.length > 0 ){
      setHasValidEvents(true);
    }
    setTileViewEvents(tileViewEvents);
  };

  const changeTab = (e) => {
    setActiveView(e);
    let _viewTabs = [...viewTabs];
    _viewTabs.forEach((data) => {
      if (data.type == e) {
        data.isActive = true;
      } else {
        data.isActive = false;
      }
    });
    setViewTabs(_viewTabs);
  };

  const sortEnrollment = (a, b)  =>{
    if(a.enrollmentStatus == "ENROLLED" || a.enrollmentStatus == "WAITING") {
      return -1;
    } else if(b.enrollmentStatus == "ENROLLED" || b.enrollmentStatus == "WAITING") {
      return 1;
    }
    return 0;
  };

  const sortEventsByEnglish= (a, b)  =>{
    return 0;
  };

  const sortEventsBySpanish= (a, b)  =>{
    return 0;
  };
 
  const checkValidEvents = () => {
    var events = allEventsWithTimeZone;
    let topicsOfInterest = topicsI;
    let recommondedTempEvents = recommendedEvents(events, topicsOfInterest, userLanguage, events.length)?.filter(e=>e.tags.includes(topicsI.toString()));
    let comingsoonTempEvents = comingSoonEvents(events, topicsOfInterest, userLanguage, false, events.length, recommendedEventList, events)?.filter(e=>e.tags.includes(topicsI.toString()));
    aemDataResults?.filterOptions?.map((option) => {
      if (recommondedTempEvents?.length == 0 && option?.label == aemDataResults?.startingsoon) {
        if(comingsoonTempEvents.length > 0) {
          setSelectedFilterOptions(option);
        } 
      }
    });
    if(recommondedTempEvents?.length > 0 || comingsoonTempEvents?.length >0){
      setHasValidEvents(true);
    }
  };
  return (
    <>
    {hasValidEvents &&
    <div className={props.bgGray ? 'upcoming-events bg-gray pv-16X sm-pv-12X' : 'upcoming-events bg-white pv-16X sm-pv-12X'}>
      <Grid>
        <Row>
          <Col
            colSizes={{
              mobile: 4,
              tablet: 12,
              desktop: 12,
            }}
          >
            <div className='mb-12X sm-mb-8X'>
              <section className='text-area'>
                <div className=''>
                  <Title size='XLarge' bold={true}>{aemDataResults?.upcomingEvents}</Title>
                </div>
                <div className='top-action-links'>
                
                  <span className='action-link'>
                    <Body
                      size="large"
                      color="#000000"
                      bold={false}>
                      {aemDataResults?.viewall}
                    </Body>
                    <span className="ml-3X">
                      <ButtonIcon
                      role="link"
                        kind="ghost"
                        size="large"
                        ariaLabel={`${aemDataResults?.viewall} ${aemDataResults?.upcomingEvents}`}
                        renderIcon={() => <Icon
                          name="right-arrow"
                          size="medium"
                          color="#000000"
                        />}
                        onClick={e => {history(`/category/events?topic=${topicsI}`);}}
                      />
                    </span>
                  </span>
                </div>
              </section>
              {(activeView == 'tile-view') &&
                <div className='mt-8X'>
                  <FilterBadge nav={t('Events')} handleChange={handleChange} options={aemDataResults?.filterOptions} selectedOptions={selectedFilterOptions} showFilterBadge={false} iconKind="lowContrast" surfaceType="colorFill" onChange={e => {console.log("Inside events filter change",e);}}/>
                </div>
              }
            </div>
          </Col>
        </Row>
        <Row>
          {(activeView == 'calendar-view') &&
            <Col colSizes={{
              mobile: 4,
              tablet: 12,
              desktop: 12,
            }}>
              <div className='calendar-grid'>
                <div className='calendar-section'>
                  <div className='calendar-header mb-6X'>
                    <Title
                      size="medium"
                      color="#6F7171"
                      bold={true}>
                      {t('Today -')} {new Date().toLocaleString('default', { timeZone: userTimeZone, month: 'long' })} {new Date().toLocaleString('default', { timeZone: userTimeZone, day: 'numeric' })}
                    </Title>
                  </div>
                  <div className='caledar-body'>
                    <Calendar
                      minDate={new Date(new Date().toLocaleString('en-US', { timeZone: userTimeZone }))}
                      indicators={
                        [{ label: "Today's date", date: new Date(new Date().toLocaleDateString('default', { timeZone: userTimeZone})) }]
                      }
                      onChange={(e) => {updateUpcomingEvent(e);}}
                      selectedDate={selectedDate}
                    />
                  </div>
                </div>
                <div className='calendar-events'>
                  {isDesktop &&
                    <div className='event-list pr-4X'>
                      {Object.keys(onGoingingEvent).length > 0 && 
                        <div className='event-start-wrap'>
                          <div className='event-start-content'>
                            <div className='mb-3X sm-mb-2X'>
                              {onGoingingEvent.duration.timeInMinutes > 0 && <Title
                                size="large"
                                color="#000000"
                                bold={true}>
                                {t('You have an event starting soon.')}
                              </Title>}
                              {onGoingingEvent.duration.timeInMinutes <= 0 && <Title
                                size="large"
                                color="#000000"
                                bold={true}>
                                {t('Your event has started.')}
                              </Title>}
                            </div>
                            <div className='mb-6X sm-mb-0X'>
                              <Title
                                size="small"
                                color="#000000"
                                bold={false}>
                                {t('Event starts in')} <b className="event-timer" style={{ color: eventStarted ? '#FF8027' : '#006FC1' }}>{time.h}:{time.m}:{time.s}</b>
                              </Title>
                            </div>
                          </div>
                          <div className='event-btn'>
                            {onGoingingEvent.duration.timeInMinutes <= MINUTES_FOR_ACTIVE_EVENTS && <Button
                              size="large"
                              surface="light"
                              disabled={false}
                              use="primary"
                              onClick={e => window.open(onGoingingEvent.location, '_blank').focus()}>
                              {t('Join now')}</Button>}
                          </div>
                        </div>
                      }
                      { activeEventList.length > 0 && 
                        <div className='active-event-items'>
                        {
                          activeEventList.map((data, n) => (
                          <div className='pb-6X mb-6X'>
                            { activeEventList.length > 1 &&
                              <div className='event-start-wrap'>
                                <div className='event-start-content'>
                                  <div className='mb-3X sm-mb-2X'>
                                    <Title
                                      size="large"
                                      color="#000000"
                                      bold={true}>
                                      {t('You have an event starting soon.')}
                                    </Title>
                                  </div>
                                </div>
                                <div className='event-btn'>
                                  {data.duration.timeInMinutes <= MINUTES_FOR_ACTIVE_EVENTS && <Button
                                    size="large"
                                    surface="light"
                                    disabled={false}
                                    use="primary"
                                    onClick={e => window.open(data.location, '_blank').focus()}>
                                    {t('Join now')}</Button>}
                                </div>
                              </div>
                            }
                            <div className='event-item' key={n}>
                              <div className='event-profile pa-3X' style={{ backgroundImage: 'url(' + data.image + ')' }}>
                              </div>
                              <div className='event-info pa-6X sm-pa-3X'>
                                <div className='event-top mb-2X'>
                                  <span className='event-left'>
                                    {data?.duration.timeInHours >= 0 && data?.duration &&
                                      <span className='event-time mr-1X'>
                                        <Micro
                                          color={data?.duration?.color} bold={true}>
                                          {data?.duration?.dateTime}
                                        </Micro>
                                      </span>
                                    }
                                    {data?.additionalStatus &&
                                      <span className='additional-tag'>
                                        <span className='tag-icon mr-1X'>
                                          <Icon
                                            name={data?.additionalStatus?.tagIcon}
                                            size="medium"
                                            color={data?.additionalStatus?.color}
                                          />
                                        </span>
                                        <Micro
                                          color={data?.additionalStatus?.color} bold={false}>
                                          {data?.additionalStatus?.tagName}
                                        </Micro>
                                        {data.additionalStatus.recordingAvailable && 
                                          <TextLink href={data.additionalStatus.link} size='small' >Recording available</TextLink>
                                        }
                                        {data.additionalStatus.recordingAvailable && 
                                          <TextLink href={data.additionalStatus.link} size='small' >Recording available</TextLink>
                                        }
                                      </span>
                                    }
                                  </span>
                                  <span className='event-right'>
                                    {(data?.status && data?.status != '') &&
                                      <span className='event-tag ph-3X pv-2X'>
                                        <Body
                                          size="small"
                                          color="#000000"
                                          bold={true}>
                                              {data.status}  {t(data.tag)} 
                                        </Body>
                                      </span>
                                    }
                                  </span>
                                </div>
                                <div className='event-content'>
                                  <div className='event-description'>
                                    <Title
                                      size="small"
                                      color="#000000"
                                      bold={false}>
                                      {data.title}
                                    </Title>
                                  </div>
                                  <span className='event-right-arrow' tabIndex={0}>
                                    <Icon
                                      name="right-arrow"
                                      size="medium"
                                      color="#000000"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          ))
                        }
                      </div>
                      }
                      
                      {
                        dateFilteredEvent.map((data, n) => (
                          <div className='event-item mb-6X' key={n}>
                            <div className='event-profile pa-3X' style={{ backgroundImage: 'url(' + data.image + ')' }}>
                            </div>
                            <div className='event-info pa-6X sm-pa-3X'>
                              <div className='event-top mb-2X'>
                                <span className='event-left'>
                                  {data?.duration.timeInHours >= 0 && data?.duration &&
                                    <span className='event-time'>
                                      <span className='calendar-icon mr-1X'>
                                        {(data.enrollmentStatus == "ENROLLED" || data.enrollmentStatus == "WAITING" || data.enrollmentStatus == "STARTED") && <Icon
                                          name={data?.additionalStatus?.registeredIcon}
                                          size="medium"
                                          color={data?.additionalStatus?.color}
                                        />}
                                        {data.enrollmentStatus == "NOT_ENROLLED" && <Icon
                                          name="calendar"
                                          size="medium"
                                          color={data?.duration?.color}
                                        />}
                                      </span>
                                      <Micro
                                        color={data?.duration?.color} bold={true}>
                                        {data?.duration?.dateTime}
                                      </Micro>
                                    </span>
                                  }
                                  {data?.additionalStatus &&
                                    <span className='additional-tag mr-1X'>
                                      <span className='tag-icon mr-1X'>
                                        <Icon
                                          name={data?.additionalStatus?.tagIcon}
                                          size="medium"
                                          color={data?.additionalStatus?.color}
                                        />
                                      </span>
                                      <Micro
                                        color={data?.additionalStatus?.color} bold={false}>
                                        {data?.additionalStatus?.tagName}
                                      </Micro>
                                    </span>
                                  }
                                </span>
                                <span className='event-right'>
                                  {(data?.status && data?.status != '') &&
                                    <span className='event-tag ph-3X pv-2X'>
                                      <Body
                                        size="small"
                                        color="#000000"
                                        bold={true}>
                                              {data.status}  {t(data.tag)} 
                                      </Body>
                                    </span>
                                  }
                                </span>
                              </div>
                              <div className='event-content'>
                                <div className='event-description'>
                                  <Title
                                    size="small"
                                    color="#000000"
                                    bold={false}>
                                    {data.title}
                                  </Title>
                                </div>
                                <span className='event-right-arrow' onClick={e => history("/course-details/"+data.id)} tabIndex={0}>
                                  <Icon
                                    name="right-arrow"
                                    size="medium"
                                    color="#000000"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        ))
                      }
                      {dateFilteredEvent.length == 0 && activeEventList.length == 0 && 
                          <div className='empty-event-start-content'>
                            <div className='mb-3X sm-mb-2X'>
                              <Title
                                size="large"
                                color="#000000"
                                bold={true}>
                                {t('No events scheduled on the selected day.')}
                              </Title>
                            </div>
                            <div className='mb-6X sm-mb-0X'>
                              <Body
                                size="medium"
                                color="#000000"
                                bold={false}>
                                  {t('Please choose another day from calendar to view available events for specific date.')}
                                </Body>
                            </div>
                          </div>
                        }
                    </div>
                  }
                  {isMobile && 
                    <div className='event-list-mobile'>
                      {Object.keys(onGoingingEvent).length > 0 &&
                        <div className='event-start-wrap sm-mb-6X'>
                          <div className='event-start-content'>
                            <div className='mb-3X sm-mb-2X'>
                              <Title
                                size="large"
                                color="#000000"
                                bold={true}>
                                {t('You have an event starting soon.')}
                              </Title>
                            </div>
                            <div className='mb-6X sm-mb-0X'>
                              <Title
                                size="small"
                                color="#000000"
                                bold={false}>
                                Event starts in <b className="event-timer" style={{ color: eventStarted ? '#FF8027' : '#006FC1' }}>{time.h}:{time.m}:{time.s}</b>
                              </Title>
                            </div>
                          </div>
                          <div className='event-btn'>
                          {onGoingingEvent.duration.timeInMinutes <= MINUTES_FOR_ACTIVE_EVENTS && <Button
                              size="large"
                              surface="light"
                              disabled={false}
                              use="primary"
                              onClick={e => window.open(onGoingingEvent.location, '_blank').focus()}>
                              {t('Join now')}</Button>}
                          </div>
                        </div>
                      }
                      {activeEventList.length > 0 &&
                        <div className='active-event-items'>
                        {
                          activeEventList.map((data, n) => (
                          <div className='pb-6X mb-6X'>
                            { activeEventList.length > 1 &&
                              <div className='event-start-wrap'>
                                <div className='event-start-content'>
                                  <div className='mb-3X sm-mb-2X'>
                                    <Title
                                      size="large"
                                      color="#000000"
                                      bold={true}>
                                      {t('You have an event starting soon.')}
                                    </Title>
                                  </div>
                                </div>
                                <div className='event-btn'>
                                  {data.duration.timeInMinutes <= MINUTES_FOR_ACTIVE_EVENTS && <Button
                                    size="large"
                                    surface="light"
                                    disabled={false}
                                    use="primary"
                                    onClick={e => window.open(data.location, '_blank').focus()}>
                                    {t('Join now')}</Button>}
                                </div>
                              </div>
                            }
                            <div className='event-item' key={n}>
                              <div className='event-profile pa-3X' style={{ backgroundImage: 'url(' + data.image + ')' }}>
                              </div>
                              <div className='event-info pa-6X sm-pa-3X'>
                                <div className='event-top mb-2X'>
                                  <span className='event-left'>
                                    {data?.duration &&
                                      <span className='event-time mr-1X'>
                                        <Micro
                                          color={data?.duration?.color} bold={true}>
                                          {data?.duration?.dateTime}
                                        </Micro>
                                      </span>
                                    }
                                    {data?.additionalStatus &&
                                      <span className='additional-tag'>
                                        <span className='tag-icon mr-1X'>
                                          <Icon
                                            name={data?.additionalStatus?.tagIcon}
                                            size="medium"
                                            color={data?.additionalStatus?.color}
                                          />
                                        </span>
                                        <Micro
                                          color={data?.additionalStatus?.color} bold={false}>
                                          {data?.additionalStatus?.tagName}
                                        </Micro>
                                      </span>
                                    }
                                  </span>
                                  <span className='event-right'>
                                    {(data?.status && data?.status != '') &&
                                      <span className='event-tag ph-3X pv-2X'>
                                        <Body
                                          size="small"
                                          color="#000000"
                                          bold={true}>
                                          {data.status}
                                        </Body>
                                      </span>
                                    }
                                  </span>
                                </div>
                                <div className='event-content'>
                                  <div className='event-description'>
                                    <Title
                                      size="small"
                                      color="#000000"
                                      bold={false}>
                                      {data.title}
                                    </Title>
                                  </div>
                                  <span className='event-right-arrow' tabIndex={0}>
                                    <Icon
                                      name="right-arrow"
                                      size="medium"
                                      color="#000000"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          ))
                        }
                      </div>
                      }
                      
                      <div className='eventlist-carousel-area'>
                        {/* {dateFilteredEvent.length > 0 && <Carousel
                          peek="standard"
                          layout={isMobile ? "1UP" : "3UP"}
                          gutter="24px"
                          surface="light"
                          paginationInset="12px"
                          paginationDisplay="onHover"
                          aspectRatio='none'
                          data={dateFilteredEvent}
                          renderItem={props => <CalendarViewCardComponent data={props} />}
                        />} */}
                        {dateFilteredEvent.length == 0 && activeEventList.length == 0 && 
                          <div className='empty-event-start-content'>
                            <div className='mb-3X sm-mb-2X'>
                              <Title
                                size="large"
                                color="#000000"
                                bold={true}>
                                 {t('No events scheduled on the selected day.')}
                              </Title>
                            </div>
                            <div className='mb-6X sm-mb-0X'>
                            <Body
                              size="medium"
                              color="#000000"
                              bold={false}>
                                {t('Please choose another day from calendar to view available events for specific date.')}
                              </Body>
                            </div>
                          </div>
                        }
                      </div>

                    </div>
                  }
                </div>
              </div>
            </Col>
          }
        </Row>
      </Grid>
      {(activeView == 'tile-view') &&
        <div className='tile-view'>
          <div className='carousel-container-fix'>
           {tileViewEvents.length > 0 && <Carousel 
              peek="standard"
              layout={isMobile ? "1UP" : "3UP"}
              gutter="24px"
              surface="light"
              paginationInset="12px"
              paginationDisplay="persistent"
              aspectRatio='none'
              data={tileViewEvents.map(e => mapperForCourseCardDesktop(e))}//.concat(tileViewEvents.map(e => mapperForCourseCardDesktop(e)),tileViewEvents.map(e => mapperForCourseCardDesktop(e)),tileViewEvents.map(e => mapperForCourseCardDesktop(e)))}
              renderItem={props => <CourseCardComponent data={props} topics={topicAnalytics}></CourseCardComponent>}
            />}
          </div>
        </div>
      }
      <Grid>
        <Row>
          <Col
            colSizes={{
              mobile: 4,
              tablet: 12,
              desktop: 12,
            }}
          >
            {/* <section className='tab-wrapper pv-8X'>
              {viewTabs?.map((data, n) => (
                <div className={data.type == activeView ? 'tab active-tab' : 'tab'} onClick={e => changeTab(data.type)} key={n} tabIndex={0}>
                  <Icon
                    name={data?.icon}
                    size="medium"
                    color={data.type == activeView ? '#000000' : '#6F7171'}
                  />
                  <span className='ml-1X'>
                    <Micro
                      color={data.type == activeView ? '#000000' : '#6F7171'}>
                      {data.name}
                    </Micro>
                  </span>
                </div>
              ))
              }
            </section> */}
            {/* <div>
              <Body
                size="medium"
                color="#000000"
                bold={false}>
                {t('*All event times are shown in the time zone set by your browser’s location setting . You can manually adjust your time zone in your')}
                &nbsp;<TextLink
                  type="inline"
                  surface="light"
                  disabled={false}
                  onClick={e => history.push("/account/settings")}>
                  {t('account settings')}
                </TextLink>
                .
              </Body>
            </div> */}
            {/* <div className='mt-16X'>
              <LiveEvent />
            </div> */}
          </Col>
        </Row>
      </Grid>
    </div>
    }</>
);
};

export default UpcomingEvents;