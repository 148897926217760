import React from 'react';
import { Body, Title, Micro } from '@vds/typography'
import './ImageCard.scss';
import { Badge } from '@vds/badges';
import { Icon } from '@vds/icons';
import { ButtonIcon } from '@vds/button-icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { TextLink } from '@vds/buttons';
const ImageCard = (props) => {
  const { t, i18n } = useTranslation();
  const history = useNavigate();
  const newLabel = props?.ariaLabel?.includes('undefined')? `${props?.ariaLabel?.replace('undefined','')} ${t(props?.label)} ${t(props?.format)}`: `${t(props?.ariaLabel)} ${t(props?.label)} ${t(props?.format)}`;
  return (
    <div className={props.cardSize == "large" ? 'tilet-wrapper large-card' : 'tilet-wrapper medium-card'} style={{ backgroundImage: 'url(' + props?.desktopImage + ')' }}>
      <div className='top-icons'>
      {props.tag &&//props.isViewed {t("Is Viewed")}
            <Badge
              fillColor="white">{t(props.tag)}
            </Badge>
       }
        {props.showSaveIcon &&
          <span className='ml-auto'>
            <ButtonIcon
              kind="lowContrast"
              size="small"
              surfaceType="media"
              renderIcon={(props) => <Icon name="bookmark" {...props} />}
            />
          </span>
        }
      </div>
      <div className={props?.fullScreenCarousel ? 'bottom-card customize-copy-card pa-6X' : 'bottom-card pa-6X'}>
        <div className='event-type mb-2X'>
          <Micro
            color="#000000" bold={true}>
            {t(props.format)}
          </Micro>
          {props.resourceType && <><span className='dot-wrap mh-2X'></span>
          <Micro
            color="#000000">
            {t(props.resourceType)}
          </Micro></>}
        </div>
        <div className='event-title mb-2X'>
          <Title
            size={props.cardSize}
            color="#000000"
            bold={false}
            >
            {props?.label?.slice(0, 72)}
          </Title>
        </div>
        <div className="durations mt-auto">
          <div aria-label={props?.dateAndTimeValue}>
            {props?.duration?.preTextIcon && <Icon
              name={props?.duration?.preTextIcon ? props?.duration?.preTextIcon : "calendar"}
              size="small"
              color={props?.duration?.color}
            />}
            {props.duration?.preText && (<Micro
              size="small"
              color='#6F7171'
              bold={true}
              primitive="span">
              <span className='mr-2X'> {t(props?.duration?.preText)}</span>
            </Micro>)}
            {props?.duration?.icon && props?.duration?.icon != "" && <Icon
              name={props?.duration?.icon ? props?.duration?.icon : "count-down"}
              size="small"
              color={props?.duration?.color}
            />}
            {props.dateAndTimeValue && (<Micro
              size="small"
              color='#6F7171'
              bold={true}
              primitive="span">
              <span className='mr-2X'> {t(props?.dateAndTimeValue)}</span>
            </Micro>)}
            <Micro
              size="small"
              color={props?.duration?.color}
              bold={true}
              primitive="span">
              <span className="ml-2X">{t(props?.duration?.time)}</span>
            </Micro>
            {props.duration?.extLink && (
              <TextLink
                type='inline'
                size='small'
                onClick={e => window.open(props?.duration?.extLink?.link, '_blank').focus()}>
                <span className="ml-2X" style={{color:props?.duration?.color}}>{props?.duration?.extLink?.text}</span>
              </TextLink>)}
          </div>

          <div>
            <ButtonIcon
              kind="ghost"
              size="large"
              ariaLabel={newLabel}
              role='button'
              renderIcon={(props) => <Icon name="right-arrow" {...props}  />}
              onClick={(e) => {
                localStorage.setItem("courseUrl",window.location.href)
                history(`/course-details/${props.courseId}`)}}
            />
          </div>
        </div>
        {/* <div className='event-date'>
          {<Icon
            name="calendar"
            size="small"
            color="#6F7171"
          />}
          <span className='ml-1X'>
            <Micro
              color="#6F7171">
             {props.duration?.dateTime}
            </Micro>
          </span>
        </div> */}
      </div>
    </div>
  )
}
export default ImageCard
