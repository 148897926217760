import React from 'react';
import "./SecondaryContent.scss";
import { Col, Grid, Row } from "@vds/grids";
import { TextLinkCaret } from '@vds/buttons';
import { Title } from '@vds/typography';

const SecondaryContent = ({ props}) => {
  return (<div className='networking-secondary-content pv-16X'>
    <Grid
      bleed="1440"
      colSizes={{
        mobile: 4,
        tablet: 12,
        desktop: 12,
      }}
    >
      <Row>
        <Col>
          <div className='tips-wrapper'>
            <div className='tips-header pb-4X sm-pb-3X mb-12X sm-mb-4X'>
              <Title
                size="medium"
                bold={true}
                color="#000000">
                {props.tips}
              </Title>
            </div>
            <div className='tips-content'>
              <div className='tips-title'>
                <Title
                  size="XLarge"
                  bold={true}
                  color="#000000">
                   {props.tipsContent1}<br />{props.tipsContent2} 
                </Title>
              </div>
              <div className='tips-description'>
                <div className='pb-8X sm-pb-6X'>
                  <Title
                    size="medium"
                    bold={false}
                    color="#000000">
                    {props.tipsDescription}
                  </Title>
                </div>
                <div>
                  <TextLinkCaret
                    surface="light"
                    disabled={false}
                    iconPosition="right"
                    onClick={e => window.open(props.tipLink,'_blank')}>
                    {props.tipLinkText}
                  </TextLinkCaret>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Grid>
  </div>);
};

export default SecondaryContent;