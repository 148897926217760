import { Body, Title } from '@vds/typography';
import './SocialProofCardComponent.scss';

const SocialProofCardComponent = (props) => {
  console.log('testimonies',props)

  return (
    <section className="testimoniesCard pa-6X">
            <div className="heading-Container">
                <img src={props.imagePath} alt={props.name} role="image" />
                <div className="labels pl-4X">
                    <Body
                        size="large"
                        color="#000000"
                        bold={true}>
                        {props.title}

                    </Body>
                    <Body
                        size="large"
                        color="#000000"
                        bold={false}>
                        {props.subTiitle}
                    </Body>
                    <Body
                        size="large"
                        color="#000000"
                        bold={false}>
                        {props.location}

                    </Body>
                </div>
            </div>
            <div className="mt-4X description">
                <Title
                    size="medium"
                    color="#000000"
                    bold={false}>
                    {props.description}


                </Title>

            </div>
        </section>
  );
};

export default SocialProofCardComponent;
