// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.result-header-wrapper {
  position: relative;
  width: 100%;
}
.result-header-wrapper .result-bread-crumb {
  background-color: #E3F2FD;
}`, "",{"version":3,"sources":["webpack://./src/components/results/Header.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,WAAA;AACJ;AACI;EACE,yBAAA;AACN","sourcesContent":[".result-header-wrapper {\n    position: relative;\n    width: 100%;\n  \n    .result-bread-crumb {\n      background-color: #E3F2FD;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
