// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logos-main {
  background-color: #f6f6f6;
}

.logos-title {
  font-size: 24px;
  font-weight: 700;
}

.logos {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 0px;
  width: 80%;
  margin: auto;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 172px;
  height: 172px;
  margin: auto;
}

@media (max-width: 767px) {
  .logos {
    grid-template-columns: 1fr;
  }
  .logo {
    width: auto;
    height: 53px;
    margin-bottom: 48px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/PartnersComponent.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,sCAAA;EACA,QAAA;EACA,UAAA;EACA,YAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,aAAA;EACA,YAAA;AACF;;AAEA;EACE;IACE,0BAAA;EACF;EACA;IACE,WAAA;IACA,YAAA;IACA,mBAAA;EACF;AACF","sourcesContent":[".logos-main {\n  background-color: #f6f6f6;\n}\n\n.logos-title {\n  font-size: 24px;\n  font-weight: 700;\n}\n\n.logos {\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr 1fr;\n  gap: 0px;\n  width: 80%;\n  margin: auto;\n}\n\n.logo {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 172px;\n  height: 172px;\n  margin: auto;\n}\n\n@media (max-width: 767px) {\n  .logos {\n    grid-template-columns: 1fr;\n  }\n  .logo {\n    width: auto;\n    height: 53px;\n    margin-bottom: 48px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
