export const courseDetailssecondary = [
  {
    image: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test',
    label: 'Building remote work into your business: Tools to keep your team',
    duration: { time: '38 minutes', color: "#6F7171" },
    courceLabel: 'Online Marketing',
    // tag: 'Viewed',
    showBookmarkIcon: false,
  },
  {
    image: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test',
    label: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    duration: { time: '30 - 60 minutes', color: "#6F7171" },
    courceLabel: 'Course',
    courceType: '[Course type]',
    // tag: 'Recommended',
    showBookmarkIcon: false,
  },
  {
    image: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test',
    label: 'Lorem ipsum',
    duration: { time: '24 minutes remaining', color: "#006FC1" },
    courceLabel: '[Article, Video, Audio]',
    // tag: 'Live Event',
    showBookmarkIcon: false,
  },
  {
    image: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test',
    label: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    duration: { time: '38 minutes', color: "#6F7171" },
    courceLabel: 'online marketing',
    // tag: 'Course',
    showBookmarkIcon: false,
  }, 
  {
    image: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test',
    label: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit last. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    duration: { time: '38 minutes', color: "#6F7171" },
    courceLabel: 'online marketing',
    // tag: 'Course',
    showBookmarkIcon: false,
  }
];

export const filterOptions =  [
  { id: '1', label: 'Recommended', data: '' },
  { id: '2', label: 'New', data: '' },
// { id: '3', label: 'Popular', data: '' },
];
