/** @format */
import React from 'react';

const VideoWrapper = React.forwardRef((props, ref) => {
  const { video } = ref;
  return (
    <video
      onError={() => {
        props.onError();
      }}
      ref={video}
      autoPlay={props.autoPlay ? true : false}
      onPause={() => {
        props.onPause();
      }}
      onPlay={() => {
        props.onPlay();
      }}
      className="video-react"
      onTimeUpdate={(e) => {
        props.onTimeUpdate(e);
      }}
      controlsList="nodownload"
      tabIndex={0}
      aria-labelledby='Video'
      crossorigin="anonymous"
    >
      <source src={props.src} type={props.type ? props.type : 'video/mp4'} />
      {props.closedCaptionFileURL ? 
      <track src={props.closedCaptionFileURL} kind="subtitles" srcLang='en' label='english' default></track>
      : ''}
    </video>
  );
});

export default VideoWrapper;
