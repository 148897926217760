import { Icon } from '@vds/icons';
import { Body, Micro, Title } from '@vds/typography';
import React, { useEffect } from 'react';
import './Offers.scss';
import { ButtonIcon } from '@vds/button-icons';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
const OffersCardComponent = (props) => {
  const aemData = useSelector((state) => state.Aem.data);
  const aemDataResults=aemData?.offers;
  useEffect(() => {
    console.log('carddata', props);
  }, []);
  const modalClick = (event) => {
    if (!event) {
      props.modal(event);
    }
  };
  const handleClick = () => {
    console.log("offers card click")
  }
  return (
    <section className='offer-section'>
      {!props.secondary && (
        <div className='mb-4X'>
          <Body size='large' bold={true} color='#6F7171'>{props?.data?.category}</Body>
        </div>)}
      <div className={(!props?.data?.bg ? 'bg-white height-correct' : props?.data?.bg) + ' offer-card pa-4X'}>
        <div className='offer-profile'>
          <img src={props?.data?.detail?.media}></img>
        </div>
        <div className='offer-content ml-4X'>
          <div className='card-title'>
            <Title size='small' bold={true}>
              {props?.data?.summary?.title}
            </Title>
          </div>
          {props.secondary && (
            <span className='mt-2X secondary-badge'>
              <Body size='small' bold={true}>{props?.data?.category}</Body>
            </span>
          )}

          <div className='card-description mv-2X'>
            <Body size='small'>
              {props?.data?.detail?.description}
            </Body>
          </div>
          <div className={props.secondary ? 'locked-section mt-4X' : 'locked-section mt-auto'}>
            <div className='unlock-wrap' onClick={() => modalClick(props?.data?.locked)}>
              <span className='mr-1X'><Icon name={props?.data?.locked ? 'lock-open' : 'lock-closed'} color={props?.data?.locked ? '#008331' : '#B95319'}></Icon></span>
              <Micro color={props?.data?.locked ? '#008331' : '#B95319'} primitive='span'>{props?.data?.locked ? t('Unlocked' ): t('Locked')}</Micro>
            </div>
            <span className='cursor-pointer'>
              {/* <Icon name='right-arrow' size='large'></Icon> */}
              {/* <ButtonIcon
                kind="ghost"
                size="large"
                renderIcon={(props) => <Icon name="right-arrow" {...props}  />}
                onClick={e=>handleClick()}
              /> */}
            </span>
          </div>
        </div>
      </div>
    </section >

  );
          }
          

export default OffersCardComponent;
