import { Icon } from '@vds/icons';
import { Micro, Title } from '@vds/typography';
import React from 'react';
import './ResourceStandard.scss';
import { Button, ButtonGroup } from '@vds/buttons';
import { useTranslation } from 'react-i18next';

const ResourcesStandard = ({ data }) => {
  console.log('resource data', data);
  const { t } = useTranslation();
  // const data = {
  //   icon: { name: 'user-registration', color: '#00FC1', label: '12 resources' },
  //   topic: 'Learning path title (74 characters max) lorem ipsum dolor set amet consect',
  //   buttonGroup: [
  //     {
  //       children: 'Go to next course',
  //       size: 'large',
  //       use: 'primary',
  //       width: '100%',
  //       onClick: () => alert('You clicked the Button example!'),
  //     },
  //     {
  //       children: 'View full path',
  //       size: 'large',
  //       use: 'secondary',
  //       width: '100%',
  //       onClick: () => alert('You clicked the Button example!'),
  //     },
  //   ],
  //   viewFullPath: false,
  //   secondaryCta: false
  // };
  return (
    <div className="resources-standard">
      <div className='eye-info'>
        <Icon name={data?.icon?.name} size={'small'} color={data?.icon?.color}></Icon>
        <Micro size='small' color={data?.icon?.color}>
          <span className='ml-1X'> {data?.icon?.label}
          </span>
        </Micro>
      </div>
      <div className='mt-2X'>
        <Title size='small' >{data?.topic}</Title>
      </div>
      {
        data?.viewFullPath && (<div className='mt-3X'>
          <Button ariaLabel={`View full path ${data?.topic}`} size='small' use='secondary' onClick={data?.onClickViewFullPath}>{t('View full path')}</Button>
        </div>)
      }
    </div >
  );
};

export default ResourcesStandard;