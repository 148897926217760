// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contactus-container {
  background-color: #000000;
  color: #f6f6f6;
}`, "",{"version":3,"sources":["webpack://./src/components/ContactUSComponent.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,cAAA;AACF","sourcesContent":[".contactus-container {\n  background-color: #000000;\n  color: #f6f6f6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
