import blackbg from '../images/Image1.png';
import whitebg from '../images/Image.png';
import image1 from '../images/partners/image 2-1.png';
import image2 from '../images/partners/image 2.png';
import image3 from '../images/partners/image 3-1.png';
import image4 from '../images/partners/image 3.png';
import image5 from '../images/partners/image 5-1.png';
import image6 from '../images/partners/image 5-2.png';
import image7 from '../images/partners/image 5-3.png';
import image8 from '../images/partners/image 5.png';
import per1 from '../images/testimonies/GettyImages-143222624311.png';
import per2 from '../images/testimonies/GettyImages-143222624312.png';
import per3 from '../images/testimonies/GettyImages-143222624313.png';

export const testimonies = [
  {
    id: '1',
    name: 'Name',
    location: 'Business Name, Location',
    image: per1,
    testimony:
      '"Another short impactful quote from a real business owner goes here."',
    onClick: () => {},
  },
  {
    id: '1',
    name: 'Name',
    location: 'Business Name, Location',
    image: per2,
    testimony:
      '"Another short impactful quote from a real business owner goes here."',
    onClick: () => {},
  },
  {
    id: '1',
    name: 'Name',
    location: 'Business Name, Location',
    image: per3,
    testimony:
      '"Another short impactful quote from a real business owner goes here."',
    onClick: () => {},
  },
];

export const resources = [
  { id: '1', label: 'On-demand courses', data: 'test' },
  { id: '1', label: 'Expert events', data: 'test' },
  { id: '1', label: '1-on-1 mentoring', data: 'test' },
  { id: '1', label: 'Peer networking', data: 'test' },
  { id: '1', label: 'Local meet-ups', data: 'test' },
  { id: '1', label: 'Product discounts', data: 'test' },
  { id: '1', label: 'Articles', data: 'test' },
  { id: '1', label: 'Videos', data: 'test' },
  { id: '1', label: 'Podcasts', data: 'test' },
];

export const moreResources = [
  { id: '1', label: 'Managing Money', data: 'Event - current' },
  { id: '2', label: 'Branding', data: 'Learn - Financial basics' },
  { id: '3', label: 'Online Marketing', data: 'Connect - Marketing expert' },
  { id: '4', label: 'Funding', data: 'Connect - Finance expert' },
  { id: '5', label: 'Winning Customers', data: 'Customers' },
  { id: '6', label: 'Website Design', data: 'Learn - Online presence' },
  { id: '7', label: 'Legal Essentials', data: 'Learn - Business structure' },
  { id: '8', label: 'Operations', data: 'Operations' },
  { id: '9', label: 'Wellness', data: 'Constant Contact' },
  { id: '10', label: 'Strategy', data: 'Networking/Workshop' },
  { id: '11', label: 'Cybersecurity', data: 'Money' },
  { id: '12', label: 'Social Media', data: 'Group Session' },
];

export const TipsAndResourceSectionData = {
  title: 'Start learning in 3 minutes or less.',
  resourceDetails: [
    {
      desktopImage:
        'https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/Component%20Base%20-%20DO%20NOT%20USE.png',
      mobileImage:
        'https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/Component%20Base%20-%20DO%20NOT%20USE.png',
      resourceInfoTag: 'New',
      resourceType: 'Course',
      resourceTags: '[Self paced, micro-course]',
      label: 'Building remote work into your business: Tools to keep your team',
      dateAndTimeIcon: 'calender',
      dateAndTimeValue: '07/06 12:30-02:30PM',
    },
    {
      desktopImage:
        'https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/Component%20Base%20-%20DO%20NOT%20USE-1.png',
      mobileImage:
        'https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/tipsandresourcesmob2.png',
      resourceInfoTag: 'New',
      resourceType: 'Course',
      resourceTags: '[Self paced, micro-course]',
      label: 'Building remote work into your business: Tools to keep your team',
      dateAndTimeIcon: 'calender',
      dateAndTimeValue: '07/06 12:30-02:30PM',
    },
  ],
  buttonText: 'Register for more',
};

export const courseDetailssecondary = [
  {
    image: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test',
    label: 'Building remote work into your business: Tools to keep your team',
    duration: '38 minutes',
    courceLabel: 'Online Marketing',
    tag: 'Course',
  },
  {
    image: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test',
    label: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    duration: '38 minutes',
    courceLabel: 'online marketing',
    tag: 'Course',
  },
  {
    image: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test',
    label: 'Building remote work into your business: Tools to keep your team',
    duration: '38 minutes',
    courceLabel: 'Online Marketing',
    tag: 'Live Event',
  },
  {
    image: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test',
    label: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    duration: '38 minutes',
    courceLabel: 'online marketing',
    tag: 'Course',
  },
];

export const topics = [
  { id: '1', label: 'Podcasts', data: 'test' },
  { id: '1', label: 'Funding', data: 'test' },
  { id: '1', label: 'Branding', data: 'test' },
  { id: '1', label: 'Online marketing', data: 'test' },
  { id: '1', label: 'Winning customers', data: 'test' },
  { id: '1', label: 'Website design', data: 'test' },
  { id: '1', label: 'Legal essentials', data: 'test' },
  { id: '1', label: 'Operations', data: 'test' },
  { id: '1', label: 'Social media', data: 'test' },
  { id: '1', label: 'Cybersecurity', data: 'test' },
  { id: '1', label: 'Wellness', data: 'test' },
];

export const ruless = [
  {
    regx: /[a-zA-Z0-9$&+,:;=?@#|'.^*()%!-]{8,20}/g,
    ruleStatement: 'Password must have between 8-20 characters',
  },
  { regx: /[a-z]/g, ruleStatement: 'At least one lower case letter [a-z]' },
  { regx: /[A-Z]/g, ruleStatement: 'At least one upper case letter [A-Z]' },
  { regx: /[0-9]/g, ruleStatement: 'At least one numeral [0-9]' },
  { regx: /^[^<>\"]*$/g, ruleStatement: 'Cannot contain <,>, or " ' },
  { regx: /[@#$%^&*()_!']/g, ruleStatement:'At least one special characters @#$%^&*()_!'}
];

export const rulessSpanish = [
  { regx: /[a-zA-Z0-9$&+,:;=?@#|'.^*()%!-]{8,20}/g, ruleStatement: 'Las contraseñas deben tener entre 8 y 20 caracteres' },
  { regx: /[a-z]/g, ruleStatement: 'Al menos una letra minúscula [a-z}]' },
  { regx: /[A-Z]/g, ruleStatement: 'Al menos una letra mayúscula [A-Z]' },
  { regx: /[0-9]/g, ruleStatement: 'Al menos un número [0-9]]' },
  { regx: /^[^<>\"]*$/g, ruleStatement: 'No puede contener <, > o "' },
  { regx: /[@#$%^&*()_!]/g, ruleStatement:'Al menos especial caracteres @#$%^&*()_!'}
];

export const moreResourcesCard = [
  {
    label: 'Get matched to a mentor',
    bgColor: '#000000',
    color: '#ffffff',
    image: blackbg,
    onClick: () => {},
  },
  {
    label: 'Savings on business tech',
    bgColor: '#ffffff',
    color: '#000000',
    image: whitebg,
    onClick: () => {},
  },
  {
    label: 'In person events',
    bgColor: '#000000',
    color: '#ffffff',
    image: blackbg,
    onClick: () => {},
  },
  {
    label: 'Peer networking',
    bgColor: '#ffffff',
    color: '#000000',
    image: whitebg,
    onClick: () => {},
  },
];

export let partnerImageList = [
  {
    id: 1,
    alt: 'partner',
    src: image1,
    name: '12',
  },
  {
    id: 1,
    alt: 'partner',
    src: image2,
    name: '12',
  },
  {
    id: 1,
    alt: 'partner',
    src: image3,
    name: '12',
  },
  {
    id: 1,
    alt: 'partner',
    src: image4,
    name: '12',
  },
  {
    id: 1,
    alt: 'partner',
    src: image5,
    name: '12',
  },
  {
    id: 1,
    alt: 'partner',
    src: image6,
    name: '12',
  },
  {
    id: 1,
    alt: 'partner',
    src: image7,
    name: '12',
  },
  {
    id: 1,
    alt: 'partner',
    src: image8,
    name: '12',
  },
];
