// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.onboarding-container {
  text-align: center;
  width: 100%;
}

.onboarding-container .tab-container {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 384px;
}

.onboarding-container .tab-container > div {
  width: 100%;
}

@media (max-width: 767px) {
  .onboarding-container {
    text-align: left;
  }
  .onboarding-container .tab-container {
    width: 100%;
  }
}
.register-container div[class^=CheckboxGroupWrapper] span[class^=StyledWrapper-VDS] span, .register-container div[class^=CheckboxWrapper] span[class^=StyledWrapper-VDS] span {
  border-style: solid;
  border-color: #6f7171;
  border-radius: 0.125rem;
  border-width: 0.0625rem;
}
.register-container .group-container span[class^=LabelWrapper-VDS] p[class^=StyledTypography-VDS] {
  font-weight: normal;
}`, "",{"version":3,"sources":["webpack://./src/views/onboarding/OnboardingPage.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,cAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE;IACE,gBAAA;EACF;EACA;IACE,WAAA;EACF;AACF;AAkBM;EACE,mBAAA;EACA,qBAAA;EACA,uBAAA;EACA,uBAAA;AAhBR;AAuBM;EACE,mBAAA;AArBR","sourcesContent":[".onboarding-container {\n  text-align: center;\n  width: 100%;\n}\n\n.onboarding-container .tab-container {\n  display: flex;\n  justify-content: center;\n  margin: 0 auto;\n  width: 384px;\n}\n\n.onboarding-container .tab-container > div {\n  width: 100%;\n}\n\n@media (max-width: 767px) {\n  .onboarding-container {\n    text-align: left;\n  }\n  .onboarding-container .tab-container {\n    width: 100%;\n  }\n}\n\n// .eXgmaP:focus:not(:hover)::before {\n//   border: none;\n//   top: 105%;\n//   height: 4px;\n//   width: 100%;\n// }\n\n:hover:not(:active) span {\n  //  border: outset !important; \n}\n\n.register-container {\n\n  div[class^=CheckboxGroupWrapper] , div[class^=CheckboxWrapper] {\n\n    span[class^=StyledWrapper-VDS] {\n      span{\n        border-style: solid;\n        border-color: #6f7171;\n        border-radius: 0.125rem;\n        border-width: 0.0625rem;\n      }\n    }\n  }\n\n  .group-container{\n    span[class^=LabelWrapper-VDS] {\n      p[class^=StyledTypography-VDS]{\n        font-weight: normal;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
