// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.superBowl-header-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 80px 104px;
  text-align: center;
  gap: 24px;
}
@media (max-width: 767px) {
  .superBowl-header-container {
    padding: 48px 32px 64px 32px;
  }
}

.SuperBowl-Image {
  height: 196px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.Partnership-Container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 48px;
}
@media (max-width: 767px) {
  .Partnership-Container {
    gap: 24px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/specialGrants/SuperBowl/SuperBowlHeader/SuperBowlHeader.scss"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,sBAAA;EACA,6BAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;EACA,SAAA;AAAJ;AAEI;EAVJ;IAWQ,4BAAA;EACN;AACF;;AAEA;EACI,aAAA;EAEA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,2BAAA;AAAJ;;AAGA;EACI,aAAA;EAGA,mBAAA;EACA,uBAAA;EACA,SAAA;AAFJ;AAGI;EAPJ;IAQQ,SAAA;EAAN;AACF","sourcesContent":[".superBowl-header-container {\n    // height: 954px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-evenly;\n    align-items: center;\n    padding: 80px 104px;\n    text-align: center;\n    gap: 24px;\n\n    @media (max-width:767px) {\n        padding: 48px 32px 64px 32px;\n    }\n}\n\n.SuperBowl-Image {\n    height: 196px;\n    // width: 200px;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: flex-start;\n}\n\n.Partnership-Container {\n    display: flex;\n    // height: 102px;\n    // min-width: 10px;\n    align-items: center;\n    justify-content: center;\n    gap: 48px;\n    @media (max-width:767px) {\n        gap: 24px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
