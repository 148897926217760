// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.networking-spotlight {
  width: 100%;
  background-color: #F6F6F6;
}
.networking-spotlight .spotlight-card {
  padding: 64px 102px;
  background-color: #fff;
  border-radius: 20px;
}
@media (max-width: 767px) {
  .networking-spotlight .spotlight-card {
    padding: 48px 24px;
  }
}
.networking-spotlight .spotlight-card .spotlight-header {
  display: flex;
  justify-content: center;
}
.networking-spotlight .spotlight-card .spotlight-header > * {
  text-align: center;
}
.networking-spotlight .spotlight-card .spotlight-container {
  display: flex;
  gap: 146px;
  width: 100%;
}
.networking-spotlight .spotlight-card .spotlight-container .bio-wrapper {
  width: 278px;
  text-align: center;
}
.networking-spotlight .spotlight-card .spotlight-container .bio-wrapper .bio-image {
  width: 278px;
  height: 278px;
  border-radius: 100%;
  object-fit: cover;
}
@media (max-width: 767px) {
  .networking-spotlight .spotlight-card .spotlight-container .bio-wrapper .bio-image {
    width: 156px;
    height: 156px;
  }
}
.networking-spotlight .spotlight-card .spotlight-container .spotlight-content {
  width: calc(100% - 278px);
}
.networking-spotlight .spotlight-card .spotlight-container .spotlight-content .detail-item {
  border-bottom: 1px solid #D8DADA;
}
@media (max-width: 767px) {
  .networking-spotlight .spotlight-card .spotlight-container {
    flex-wrap: wrap;
    gap: 32px;
  }
  .networking-spotlight .spotlight-card .spotlight-container .bio-wrapper {
    width: 100%;
  }
  .networking-spotlight .spotlight-card .spotlight-container .spotlight-content {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Networking/Spotlight/Spotlight.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,yBAAA;AACF;AACE;EACE,mBAAA;EACA,sBAAA;EACA,mBAAA;AACJ;AACI;EALF;IAMI,kBAAA;EAEJ;AACF;AAAI;EACE,aAAA;EACA,uBAAA;AAEN;AAAM;EACE,kBAAA;AAER;AAEI;EACE,aAAA;EACA,UAAA;EACA,WAAA;AAAN;AAEM;EACE,YAAA;EACA,kBAAA;AAAR;AAEQ;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,iBAAA;AAAV;AAEU;EANF;IAOI,YAAA;IACA,aAAA;EACV;AACF;AAGM;EACE,yBAAA;AADR;AAGQ;EACE,gCAAA;AADV;AAKM;EA9BF;IA+BI,eAAA;IACA,SAAA;EAFN;EAIM;IACE,WAAA;EAFR;EAKM;IACE,WAAA;EAHR;AACF","sourcesContent":[".networking-spotlight {\n  width: 100%;\n  background-color: #F6F6F6;\n\n  .spotlight-card {\n    padding: 64px 102px;\n    background-color: #fff;\n    border-radius: 20px;\n\n    @media (max-width: 767px) {\n      padding: 48px 24px;\n    }\n\n    .spotlight-header {\n      display: flex;\n      justify-content: center;\n\n      >* {\n        text-align: center;\n      }\n    }\n\n    .spotlight-container {\n      display: flex;\n      gap: 146px;\n      width: 100%;\n\n      .bio-wrapper {\n        width: 278px;\n        text-align: center;\n\n        .bio-image {\n          width: 278px;\n          height: 278px;\n          border-radius: 100%;\n          object-fit: cover;\n\n          @media (max-width: 767px) {\n            width: 156px;\n            height: 156px;\n          }\n        }\n      }\n\n      .spotlight-content {\n        width: calc(100% - 278px);\n\n        .detail-item {\n          border-bottom: 1px solid #D8DADA;\n        }\n      }\n\n      @media (max-width: 767px) {\n        flex-wrap: wrap;\n        gap: 32px;\n\n        .bio-wrapper {\n          width: 100%;\n        }\n\n        .spotlight-content {\n          width: 100%;\n        }\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
