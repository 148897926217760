// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.register-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.stepper-container {
  max-width: 387px;
  width: 100%;
}

.error {
  color: "red";
  white-space: pre-wrap;
}

.message {
  color: "green";
}

.privacyPolicyText a {
  color: black;
}

@media (min-width: 767px) {
  .partner-login-container {
    padding-bottom: 72px;
  }
}`, "",{"version":3,"sources":["webpack://./src/views/onboarding/RegistrationFlowComponent/RegistrationFlowComponent.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,WAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE,gBAAA;EACA,WAAA;AACF;;AAEA;EACE,YAAA;EACA,qBAAA;AACF;;AACA;EACE,cAAA;AAEF;;AACE;EACE,YAAA;AAEJ;;AAGE;EADF;IAEI,oBAAA;EACF;AACF","sourcesContent":[".register-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.button-container {\n  display: flex;\n  width: 100%;\n  align-items: center;\n  justify-content: center;\n}\n\n.stepper-container {\n  max-width: 387px;\n  width: 100%;\n}\n\n.error {\n  color: 'red';\n  white-space: pre-wrap;\n}\n.message {\n  color: 'green';\n}\n.privacyPolicyText{\n  a{\n    color: black;\n  }\n}\n\n.partner-login-container{\n  @media (min-width:767px) {\n    padding-bottom: 72px;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
