// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chips-container {
  max-width: 640px;
}

@media (max-width: 767px) {
  .chip-container {
    justify-content: left;
  }
}`, "",{"version":3,"sources":["webpack://./src/views/onboarding/RegistrationFlowComponent/TopicsSelectionComponent/TopicsSelectionComponent.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AACA;EACE;IACE,qBAAA;EAEF;AACF","sourcesContent":[".chips-container {\n  max-width: 640px;\n}\n@media (max-width: 767px) {\n  .chip-container {\n    justify-content: left;\n  }  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
