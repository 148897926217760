export const filterOptions = [
    { id: '1', label: 'Recommended', data: '' },
    { id: '2', label: 'Starting soon', data: '' }
  ];
  
  export const eventList = [
    { image: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test', additionalStatus: { tagIcon: 'user-registration', tagName: 'Registered', color: "#006FC1" }, duration: { dateTime: '07/06 12:30-2:30pm', color: '#006FC1' }, title: 'Resource title (74 characters max)', status: '' },
    { image: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test', additionalStatus: { tagIcon: 'real-time', tagName: 'Waitlisted', color: "#006FC1" }, duration: { dateTime: '07/06 12:30-2:30pm', color: '#006FC1' }, title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', status: '' },
    { image: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test', duration: { dateTime: '07/06 12:30-2:30pm', color: '#6F7171' }, title: 'Lorem ipsum', status: '' },
    { image: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test', duration: { dateTime: '07/06 12:30-2:30pm', color: '#6F7171' }, title: 'Resource title (74 characters max)', status: '' },
    { image: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test', duration: { dateTime: '07/06 12:30-2:30pm', color: '#6F7171' }, title: 'Resource title (74 characters max)', status: '' },
    { image: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test', duration: { dateTime: '07/06 12:30-2:30pm', color: '#6F7171' }, title: 'Resource title (74 characters max)', status: '' },
    { image: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test', duration: { dateTime: '07/06 12:30-2:30pm', color: '#6F7171' }, title: 'Resource title (74 characters max)', status: '' },
    { image: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test', duration: { dateTime: '07/06 12:30-2:30pm', color: '#6F7171' }, title: 'Resource title (74 characters max)', status: '' },
  ];
  
  export const activeEventList = [
    { image: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test', additionalStatus: { tagIcon: 'warning', tagName: 'Registered', color: "#FF8027" }, duration: { dateTime: '07/06 3pm', color: '#6F7171' }, title: 'Resource title (74 characters max)', status: '' },
  ];
  
  export const tabs = [
    { name: 'Tile View', type: 'tile-view', icon: 'inventory', isActive: false },
    { name: 'Calendar View', type: 'calendar-view', icon: 'calendar', isActive: true }];
  
  export const courseDetailssecondary = [
    {
      image: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test',
      label: 'Building remote work into your business: Tools to keep your team',
      duration: { dateTime: '07/06 12:30-2:30pm', color: "#006FC1" },
      bottomStatus: { tagIcon: 'user-registration', tagName: 'Registered', color: "#006FC1" },
      courceLabel: 'Online Marketing',
      // tag: 'Viewed',
      showBookmarkIcon: false,
    },
    {
      image: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test',
      label: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod sadfsd asdf asdfsdfsdf  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      duration: { dateTime: '07/06 12:30-2:30pm', color: "#006FC1" },
      bottomStatus: { tagIcon: 'real-time', tagName: 'Waitlisted', color: "#006FC1" },
      courceLabel: 'Course',
      courceType: '[Course type]',
      // tag: 'Recommended',
      showBookmarkIcon: false,
    },
    {
      image: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test',
      label: 'Building remote work into your business: Tools to keep your team',
      duration: { dateTime: '07/06 12:30-2:30pm', color: "#006FC1" },
      courceLabel: '[Article, Video, Audio]',
      // tag: 'Live Event',
      showBookmarkIcon: false,
    },
    {
      image: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test',
      label: 'Lorem ipsum dolor',
      duration: { dateTime: '07/06 12:30-2:30pm', color: "#6F7171" },
      courceLabel: 'online marketing',
      // tag: 'Course',
      showBookmarkIcon: false,
    },
    {
      image: 'https://ss7.vzw.com/is/image/VerizonWireless/black-test',
      label: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit last.',
      duration: { dateTime: '07/06 12:30-2:30pm', color: "#6F7171" },
      courceLabel: 'online marketing',
      // tag: 'Course',
      showBookmarkIcon: false,
    }
  ];