// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.superBowl-container-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767px) {
  .superBowl-container-1 {
    padding: 0;
    flex-direction: column;
  }
}

.superBowl-container-2 {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
}
@media (max-width: 767px) {
  .superBowl-container-2 {
    padding: 0;
    flex-direction: column;
  }
}

.SuperBowl-Editorial-image {
  aspect-ratio: 720/405;
  width: 50%;
  height: 405px;
}
@media (max-width: 767px) {
  .SuperBowl-Editorial-image {
    padding: 0;
    aspect-ratio: 360/203;
    width: 100%;
    height: auto;
  }
}

.superBowl-Editorial-Text {
  display: flex;
  width: 50%;
  flex: 1 1;
  padding: 0px 104px;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
}
@media (max-width: 767px) {
  .superBowl-Editorial-Text {
    padding: 32px 32px 48px 32px;
    width: auto;
    text-align: center;
    gap: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/specialGrants/SuperBowl/SuperBowlEditorial/SuperBowlEditorial.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;AACJ;AACI;EANJ;IAOQ,UAAA;IACA,sBAAA;EAEN;AACF;;AACA;EACI,aAAA;EACA,2BAAA;EACA,uBAAA;EACA,mBAAA;AAEJ;AAAI;EANJ;IAOQ,UAAA;IACA,sBAAA;EAGN;AACF;;AAAA;EAEI,qBAAA;EACA,UAAA;EACA,aAAA;AAEJ;AAAI;EANJ;IAOQ,UAAA;IACA,qBAAA;IACA,WAAA;IACA,YAAA;EAGN;AACF;;AAAA;EACI,aAAA;EACA,UAAA;EACA,SAAA;EACA,kBAAA;EACA,sBAAA;EACA,uBAAA;EACA,SAAA;AAGJ;AADI;EATJ;IAUQ,4BAAA;IAEA,WAAA;IACA,kBAAA;IACA,SAAA;EAGN;AACF","sourcesContent":[".superBowl-container-1 {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: center;\n\n    @media (max-width:767px) {\n        padding: 0;\n        flex-direction: column;\n    }\n}\n\n.superBowl-container-2 {\n    display: flex;\n    flex-direction: row-reverse;\n    justify-content: center;\n    align-items: center;\n\n    @media (max-width:767px) {\n        padding: 0;\n        flex-direction: column;\n    }\n}\n\n.SuperBowl-Editorial-image {\n    // height: 405px;\n    aspect-ratio: 720/405;\n    width: 50%;\n    height: 405px;\n\n    @media (max-width:767px) {\n        padding: 0;\n        aspect-ratio: 360/203;\n        width: 100%;\n        height: auto;\n    }\n}\n\n.superBowl-Editorial-Text {\n    display: flex;\n    width: 50%;\n    flex: 1;\n    padding: 0px 104px;\n    flex-direction: column;\n    justify-content: center;\n    gap: 24px;\n\n    @media (max-width:767px) {\n        padding: 32px 32px 48px 32px;\n        // aspect-ratio: 360/203;\n        width: auto;\n        text-align: center;\n        gap: 16px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
