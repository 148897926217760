import { useState } from 'react';

import { Micro } from '@vds/typography';

import { topicSearch } from '../common/Analytics/GAWrapper';
import { updateSelectedTags } from '../store/adoberesources';
import { updateRegistrationStepTopics } from '../store/aem';
import store from '../store/store';
import './TagSelectionComponent.scss';
import { useTranslation } from 'react-i18next';
var selectedTags = [];

const TagSelectionComponent = ({
  optionItems,
  selected,
  onChange,
  maxSelect,
}) => {
  let [selectedChips, setSelectedChips] = useState(selected || []);
  const { t, i18n } = useTranslation();
  const selectChips = (item) => {
    if (item.label === t('Clear')) {
      selectedChips = [];
      setSelectedChips([]);
      onChange([]);
      store.dispatch(updateSelectedTags([]));
      store.dispatch(updateRegistrationStepTopics([]));
      selectedTags = [];
    } else {
      if (
        selectedChips.filter((selected) => selected.data === item.data)
          .length > 0
      ) {
        setSelectedChips([
          ...selectedChips.filter((selected) => selected.data !== item.data),
        ]);
        onChange([
          ...selectedChips.filter((selected) => selected.data !== item.data),
        ]);
        store.dispatch(
          updateSelectedTags([
            ...selectedChips
              .filter((selected) => selected.data !== item.data)
              .map((item) => item.data),
          ])
        );
        store.dispatch(updateRegistrationStepTopics([
          ...selectedChips
            .filter((selected) => selected.data !== item.data)
            .map((item) => item.data),
        ]));
        selectedTags = selectedChips
          .filter((selected) => selected.data !== item.data)
          .map((item) => item.data);
      } else {
        if (maxSelect ? selectedChips.length < maxSelect : true) {
          setSelectedChips([...selectedChips, item]);
          onChange([...selectedChips, item]);
          store.dispatch(
            updateSelectedTags([
              ...selectedChips.map((item) => item.data),
              item.data,
            ])
          );
          store.dispatch(updateRegistrationStepTopics([
            ...selectedChips.map((item) => item.data),
            item.data,
          ]));
          selectedTags.push(item.data);
          console.log( selectedTags.length);
          console.log( selectedTags.join(","));
          topicSearch( selectedTags.join(","));
        }
      }
    }
  };

  const options = optionItems;
  return (
    <>
      <div className="chip-container"
     >
        {options.map((item) => {
          var baseClass = '';
          if (
            selectedChips.filter((selected) => selected.data === item.data)
              .length > 0
          ) {
            baseClass = 'chip fs-3X ph-4X pv-2X mb-6X chip-selected';
          } else {
            baseClass = 'chip fs-3X ph-4X pv-2X mb-6X chip-unselected';
          }
          if (selectedChips.length >= 3) {
            baseClass += ' chip-disabled';
          }
          if (item.data === 'Clear') {
            baseClass = 'chip fs-3X ph-4X pv-2X mb-6X clear';
          }
          return (
            <div
              role="button"
              key={item.label}
              className={baseClass}
              onClick={() => {
                selectChips(item);
              }}
              tabIndex={0}
              aria-label={item.label==="Clear"?"Clear":selectedChips.length>=3 ?"Only three Buttons can be selected":
              
              selectedChips.filter(
                   (selected) => selected.data === item.data
                ).length > 0
                   ? "selected"+item.data
                 : "unselected" + item.data}
            >
              <Micro bold={true}>{item.label}</Micro>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default TagSelectionComponent;
