// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quick-tips .text-area {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}
.quick-tips .text-area .top-action-links {
  display: inline-flex;
  align-items: center;
  gap: 32px;
}
.quick-tips .text-area .top-action-links .action-link {
  display: inline-flex;
  align-items: center;
}
.quick-tips .text-area .top-action-links .action-link:focus-visible {
  outline: 1px dashed #000;
  outline-offset: 2px;
}
@media (max-width: 767px) {
  .quick-tips .text-area {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

.offers-card {
  background-color: #F6F6F6;
  height: auto;
  width: 100%;
  border-radius: 12px;
  display: flex;
}
.offers-card .locked-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.offers-card img {
  border-radius: 50%;
  height: 80px;
  width: 80px;
}

.tips-checkbox {
  border-top: 1px solid #6F7171;
}`, "",{"version":3,"sources":["webpack://./src/components/QuickTips/QuickTips.scss"],"names":[],"mappings":"AAEE;EACE,aAAA;EACA,8BAAA;EACA,SAAA;AADJ;AAGI;EACE,oBAAA;EACA,mBAAA;EACA,SAAA;AADN;AAGM;EAEE,oBAAA;EACA,mBAAA;AAFR;AAIQ;EACE,wBAAA;EACA,mBAAA;AAFV;AAQE;EACE;IACE,aAAA;IACA,sBAAA;IACA,SAAA;EANJ;AACF;;AAaA;EACE,yBAAA;EACA,YAAA;EACA,WAAA;EACA,mBAAA;EACA,aAAA;AAVF;AAYE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAVJ;AAaE;EACE,kBAAA;EACA,YAAA;EACA,WAAA;AAXJ;;AAeA;EACE,6BAAA;AAZF","sourcesContent":[".quick-tips {\n\n  .text-area {\n    display: flex;\n    justify-content: space-between;\n    gap: 40px;\n\n    .top-action-links {\n      display: inline-flex;\n      align-items: center;\n      gap: 32px;\n\n      .action-link {\n        // cursor: pointer;\n        display: inline-flex;\n        align-items: center;\n\n        &:focus-visible {\n          outline: 1px dashed #000;\n          outline-offset: 2px;\n        }\n      }\n    }\n  }\n\n  @media (max-width: 767px) {\n    .text-area {\n      display: flex;\n      flex-direction: column;\n      gap: 24px;\n    }\n  }\n\n}\n\n\n\n.offers-card {\n  background-color: #F6F6F6;\n  height: auto;\n  width: 100%;\n  border-radius: 12px;\n  display: flex;\n\n  .locked-section {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n  }\n\n  img {\n    border-radius: 50%;\n    height: 80px;\n    width: 80px;\n  }\n}\n\n.tips-checkbox {\n  border-top: 1px solid #6F7171;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
