import { Col, Grid, Row } from '@vds/grids';
import React from 'react';
import './Offers.scss';
import { Body, Title } from '@vds/typography';
import { Button } from '@vds/buttons';
import { Carousel } from '@vds/carousels';
import { useSelector } from 'react-redux';
import { isDesktop, isMobile } from 'react-device-detect';

import { offersBG } from './OffersMock.js';
import OffersCardComponent from './OffersCard.jsx';
import { useEffect, useState } from 'react';
import { Modal, ModalTitle, ModalBody, ModalFooter } from '@vds/modals';

import { useNavigate } from 'react-router-dom';
import { retrieveIncentive } from '../../utility/common.js';
import { useTranslation } from 'react-i18next';
import { forcedRankingOffersTopics } from '../../utility/utils.js';
const OffersComponent = (props) => {
  const { t, i18n } = useTranslation();
  const [showUnlockModal, setShowUnlockModal] = useState(false);
  //const userDataFromStore = useSelector((state) => state.data);
  //const [language, setLanguage] = useState(userDataFromStore.business.attributes.Language);
  const [_completedCourses, setCompletedCourses] = useState([]);
  const [_completedEvents, setCompletedEvents] = useState([]);
  const isSpanish = useSelector((state) => state.Aem.isSpanish);
  const [language,setLanguage]=useState(localStorage.getItem("lng")??isSpanish?'es':'en');
  let history = useNavigate();
  //const [offers, setOffers] = useState([]);
  const [enrollments, setEnrollments] = useState([]);
  const aemData = useSelector((state) => state.Aem.data);
  const aemDataResults=aemData?.results;
  const offers = useSelector((state) => state.Aem.offerData);
  const selectedTopics = useSelector(
    (state) => state.Aem.registrationStepsData.businessData.topics
  );
  console.log("selected topic",selectedTopics)
 
 
  const topicMapaem=aemData?.topicCard?.topicSubtext;
  console.log("topicMapaem===",topicMapaem.forEach((item)=>console.log(item[0])))
  const newTopic=topicMapaem.filter((item)=>item[0]===props.topic)
  const topicsOfInterest=[newTopic[0][1]];
  const modalFunction = (e) => {
    setShowUnlockModal(true);
  };
  const modalChange = (modalOpened) => {
    if (modalOpened) {
      setShowUnlockModal(true);
    } else {
      setShowUnlockModal(false);
    }
  };
    const handleClick = () => {
      history("/category/offers");
  };
  useEffect(() => {
    //needto check this
    //setLanguage(localStorage.getItem("lng")??'en');
  
   
  }, [language]);
  //[userDataFromStore.business.attributes.Language, setOffers]);
  useEffect(() => {
    //needto check this
    //setLanguage(localStorage.getItem("lng")??'en');
  
    //fetchOffers(language);
    //let off=fetchOffers(language);
    //console.log("Off==",off)
  }, []);
//   const fetchOffers = (language) => {
//     let offer;
//     retrieveIncentive(language)
//         .then((offers) => {
//           let newOffers = [];
//           offers.forEach((val, i) => {            
//             if (val.id !== 'lisc') { 
//               newOffers.push(val)  
//             }
//           });
//           offer= newOffers.slice(0,12);
//           //const userZipCode = userDataFromStore.business.address.postalCode;
//           console.log("Offers===",newOffers)
//           setOffers(offer); 
//           return offer;
//           });
//         // return offers;
// };


//   return (
//     <div className={(!bg ? 'bg-white' : bg) + ' offers pv-16X sm-pv-12X'}>
//       <Grid>
//         <Row>
//           <Col
//             colSizes={{
//               mobile: 4,
//               tablet: 12,
//               desktop: 12,
//             }}
//           >

//             <section className='text-area'>
//               <div>
//                 <div className='mb-8X'>
//                   <Title size='XLarge' bold={true}>Offers picked for you.</Title>
//                 </div>
//               </div>
//               <div>
//                 {isDesktop && (
//                   <div >
//                     <Button use='secondary' onClick={e => handleClick()}>View all offers</Button>
//                   </div>
//                 )}

//               </div>
//             </section>
//           </Col>
//         </Row>
//       </Grid>
//       {console.log('offers.....>>>>',offers)}
//       {offers.length > 0 && <Carousel
//         peek="standard"
//         layout={isMobile ? "1UP" : "3UP"}
//         gutter="24px"
//         surface="light"
//         paginationInset="12px"
//         paginationDisplay="onHover"
//         aspectRatio='none'
//         data={offers.map(item=>{item.bg='bg-white';
//         console.log("item==",item)
//            return item;
//          })}
//         renderItem={props => <OffersCardComponent data={props} modal={modalFunction} />}
//       />
//       }
//       {isMobile && (
//         <div className='mb-4X sm-mt-6X n-textCenter center-button'>
//           <Button use='secondary'>Get started</Button>
//         </div>
//       )}
//       <Modal
//         opened={showUnlockModal}
//         surface="light"
//         fullScreenDialog={false}
//         disableAnimation={false}
//         disableOutsideClick={false}
//         ariaLabel="Testing Modal"
//         onOpenedChange={modalChange}
//       >
//         <ModalTitle>
//           How to unlock your offer.
//         </ModalTitle>
//         <ModalBody>
//           Lorem ipsum dolor sit amet consectetur. Elit viverra enim at venenatis integer. Ac platea quisque erat duis aliquet ultrices eu eu commodo. Fermentum morbi odio tellus tellus cras dictum neque.
//           <br /><br />
//           Eu vitae quisque ac convallis aliquam morbi morbi sem suspendisse. Ac adipiscing non sapien commodo. Odio commodo quam consequat vitae posuere aliquet.
//           <br /><br />
//           Amet in magna aliquet eros faucibus sed rhoncus proin senectus. Mattis erat libero ipsum neque. Diam urna tincidunt nibh amet justo pretium tristique luctus suscipit. Ornare orci semper in at in non.
//         </ModalBody>
//       </Modal>
//     </div>
//   );
// };
return (
  <>
  {Object.keys(offers).length > 0 &&
  <div className={(!props.bg ? 'bg-white' :props.bg) + ' offers pv-16X sm-pv-12X'}>
    <Grid>
      <Row>
        <Col
          colSizes={{
            mobile: 4,
            tablet: 12,
            desktop: 12,
          }}
        >

          <section className='text-area'>
            <div>
              <div className='mb-8X'>
                <Title size='XLarge' bold={true}>{aemDataResults?.offersPicked}</Title>
              </div>
            </div>
            <div>
              {isDesktop && (
                <div >
                  <Button use='secondary' onClick={e => handleClick()}>{aemDataResults?.viewalloff}</Button>
                </div>
              )}

            </div>
          </section>
        </Col>
      </Row>
    </Grid>
    {console.log('offers.....>>>>',offers)}
    {Object.keys(offers).length > 0 && (<div className="intrested-in-carousel" >
            <Carousel
              peek="standard"
              layout={isMobile ? "1UP" : "3UP"}
              gutter="24px"
              surface="light"
              paginationInset="12px"
              paginationDisplay="persistent"
              aspectRatio='none'
              data={forcedRankingOffersTopics(offers,topicsOfInterest,12).map((item) => {
                // item.bg= ''//props?.bg ? 'bg-gray height-correct' : 'bg-white height-correct'
                let _item={...item}
                _item.bg = props?.bg ? 'bg-gray height-correct' : 'bg-white height-correct'
                return _item;
              })}
              renderItem={props => <OffersCardComponent data={props} />}
            />
          </div>)}
    
    {isMobile && (
      <div className='mb-4X sm-mt-6X n-textCenter center-button'>
        <Button use='secondary' onClick={e => handleClick()}>{aemDataResults?.viewalloff}</Button>
      </div>
    )}
    {/* <Modal
      opened={showUnlockModal}
      surface="light"
      fullScreenDialog={false}
      disableAnimation={false}
      disableOutsideClick={false}
      ariaLabel="Testing Modal"
      onOpenedChange={modalChange}
    >
      <ModalTitle>
        How to unlock your offer.
      </ModalTitle>
      <ModalBody>
      {aemData?.offers?.
        <br /><br />
        Eu vitae quisque ac convallis aliquam morbi morbi sem suspendisse. Ac adipiscing non sapien commodo. Odio commodo quam consequat vitae posuere aliquet.
        <br /><br />
        Amet in magna aliquet eros faucibus sed rhoncus proin senectus. Mattis erat libero ipsum neque. Diam urna tincidunt nibh amet justo pretium tristique luctus suscipit. Ornare orci semper in at in non.
      </ModalBody>
    </Modal> */}
  </div> }
  </>
);
};

export default OffersComponent;
