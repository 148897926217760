import { Modal, ModalBody, ModalFooter, ModalTitle } from "@vds/modals";
import { Body } from "@vds/typography";
import React, { useEffect } from "react";
import store from '../../../store/store'
import { getLanguageBasedHref } from "../../../utility/utils";
import { updateSpanish } from "../../../store/aem";
import { Icon } from "@vds/icons";
import './LanguageSwitchPopup.scss'
import { useTranslation } from 'react-i18next';

const LanguageSwitchPopupComponent = (props) => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        //updating srcurl on router update for language change.
        window.MP.SrcUrl = window.location.href;
    }, [location]);

    const handleSwitchLang = () => {
        
        const userLang = 'es';
        localStorage.setItem('lng', userLang);
        sessionStorage.setItem('isSwitchedLanguage', true);
        window.gnavdl.variation = 'prospect-es';
        const isSpanish = true;
        store.dispatch(updateSpanish(isSpanish));
        let languageBasedHref = getLanguageBasedHref();
        if (languageBasedHref != null) {
            location.pathname = languageBasedHref;
        } else {
            window.location.reload();
        }
    }

    const handleClose = (e) => {
        
        props.closeFlag(false)
    }

    return (
        <Modal
            opened={true}
            surface="light"
            fullScreenDialog={false}
            disableAnimation={false}
            disableOutsideClick={true}
            closeButton={
                <button id="closeButton" style={{cursor:'pointer'}} tabIndex={0} aria-label="Redirecting to Spanish site popup close" onClick={() => handleClose()} role='button' className='modalIcon'><Icon
                    name="close"
                    size="medium"
                    color="#000000"
                />
                </button>
            }
            
        >
            <ModalTitle>
                {t('SpanishRedirectModalTitle')}
            </ModalTitle>
            <ModalBody>
                <div className="mb-8X">
                    <Body size="large">
                        {t('SpanishRedirectBodyText')}
                    </Body>
                </div>
                <Body size="small">
                    {t('SpanishRedirectNoteText')}    
                </Body>
            </ModalBody>
            <ModalFooter
                buttonData={{
                    primary: {
                        width: '100%',
                        children: 'Continue to Spanish',
                        onClick: () => {handleSwitchLang()}
                    },
                    close: {
                        width: '100%',
                        children: 'Stay on this page',
                        onClick: () => handleClose()
                    },
                }}
            />
        </Modal>
    )

}

export default LanguageSwitchPopupComponent;