import React from 'react';
// import { TileContainer } from '@vds/tiles';
import { TileContainer } from "@vds/tiles";
import { Body, Micro, Title } from '@vds/typography';
//import '.';
import { Icon } from '@vds/icons';
import { Badge } from '@vds/badges';
import { ButtonIcon } from '@vds/button-icons';
import { TextLink } from '@vds/buttons';
//import TileViewCardComponent from 'Components/InfoHub/UpcomingEvents/TileViewCards/TileViewCards.jsx';
//import { AnalyticsLibrary } from 'ysb-ui-libraries';
//import { useHistory } from "react-router-dom";
import { useNavigate } from 'react-router';


const CourseCardComponent = (props) => {
  let history = useNavigate();

  const handleClick = () => {

    console.log("Inside on click of quick tips ")
    localStorage.setItem("courseUrl",window.location.href)
    history(`/course-details/${props.data.courseId}`)
  }
  
  return (
    <TileContainer
      padding='0px'
      aspectRatio='3:3.7'
      backgroundColor="white"
      showBorder={false}
      showDropShadow={true}
      tabIndex={-1}
      onClick={e=>handleClick()
        // AnalyticsLibrary.Track.pageLoadStarted("en",props?.data?.label,"Infohub",localStorage.getItem("user"))
        // //alert('Tile is clickedbbbbb')
        // AnalyticsLibrary.Track.Content_Click("", window.location.origin+"/course-details/" + props.data.courseId, props.tags,localStorage.getItem("user"));
      
    }
    >
      <div className="secondaryCourceCard">
        <div className='image-icons'>
          {props?.data?.tag &&
            <Badge
              fillColor="white">{props?.data?.tag}
            </Badge>
          }
          {props?.data?.showBookmarkIcon &&
            <span className='ml-auto' role='button'>
              <ButtonIcon
                kind="lowContrast"
                size="small"
                surfaceType="media"
                renderIcon={(props) => <Icon name="bookmark" {...props} />}
              />
            </span>
          }
        </div>
        <div className="image">
          <img src={props?.data?.desktopImage} alt={props?.data?.label} />
        </div>
        <div className="description pa-4X sm-pa-4X">
          <div className='label mb-3X sm-mb-2X'>
            <Micro>
              <div className='course-label' aria-label={props?.data?.resourceType}>
                <b>{props?.data?.format}</b>
                {props?.data?.resourceType &&
                  <span className='dot-wrap mh-2X'></span>}
                <span className=''>{props?.data?.resourceType}</span>
              </div>
            </Micro>
          </div>
          <div className="title-label label mb-3X sm-mb-2X" aria-label={props?.data?.label}>
            <Title bold={false} color='#000000' size='medium'> {props?.data?.label.slice(0, 72)}</Title>
          </div>
          <div className="durations mt-auto">

            <div aria-label={props?.data?.dateAndTimeValue}>
              {props?.data?.duration?.preTextIcon && <Icon
                name={props?.data?.duration?.preTextIcon ? props?.data?.duration?.preTextIcon : "calendar"}
                size="small"
                color={props?.data?.duration?.color}
              />}
              {props.data?.duration?.preText && (<Micro
                size="small"
                color='#6F7171'
                bold={true}
                primitive="span">
                <span className='mr-2X'> {props?.data?.duration?.preText}</span>
              </Micro>)}
              {props?.data?.duration?.icon && props?.data?.duration?.icon != "" && <Icon
                name={props?.data?.duration?.icon ? props?.data?.duration?.icon : "count-down"}
                size="small"
                color={props?.data?.duration?.color}
              />}
              {props.data?.dateAndTimeValue && (<Micro
                size="small"
                color='#6F7171'
                bold={true}
                primitive="span">
                <span className='mr-2X'> {props?.data?.dateAndTimeValue}</span>
              </Micro>)}
              <Micro
                size="small"
                color={props?.data?.duration?.color}
                bold={true}
                primitive="span">
                <span className="ml-2X">{props?.data?.duration?.time}</span>
              </Micro>
              {props.data?.duration?.extLink && (
                <TextLink
                  type='inline'
                  size='small'
                  color={props?.data?.duration?.color}
                  onClick={e => alert('TextLink clicked')}>
                  <span className="ml-2X">{props?.data?.duration?.extLink?.text}</span>
                </TextLink>)}
            </div>

            <div>
              <ButtonIcon
                kind="ghost"
                size="large"
                role='link'
                ariaLabel={`${props?.data?.ariaLabel ? props?.data?.ariaLabel?.replace('undefined',''):""} ${props?.data?.label} ${(props?.data?.format)}`}
                renderIcon={(props) => <Icon name="right-arrow" {...props}  />}
                onClick={e=>handleClick()}
              />
            </div>
          </div>

        </div>

      </div>
      
    </TileContainer>
  );
};

export default CourseCardComponent;